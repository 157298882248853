import _ from "lodash";

export const omitNilValues = obj => _.pickBy(obj, _.identity);

export const flatPathToObject = obj => {
  const result = {};

  _.forEach(obj, (value, key) => {
    _.set(result, key, value);
  });

  return result;
};

export const toUppercaseValues = (
  obj,
  omit = [
    "userId",
    "addressBookId",
    "parcelId",
    "stagingShipmentId",
    "shipmentId",
    "profileCode",
    "groupId",
  ]
) =>
  _.mapValues(obj, (value, key) => {
    if (_.isString(value) && !omit.includes(key)) {
      value = value.toUpperCase();
    } else if (_.isPlainObject(value)) {
      value = toUppercaseValues(value);
    } else if (_.isArray(value)) {
      value = value.map(item => toUppercaseValues(item));
    }

    return value;
  });

export const getAllObjectKeysWhereValueIsTrue = object =>
  Object.keys(object).filter(key => object[key]) ?? [];

export const replaceEmptyStringToUndefined = obj =>
  _.mapValues(obj, value => {
    if (_.isString(value) && value.trim() === "") {
      value = undefined;
    } else if (value === null) {
      value = undefined;
    } else if (_.isPlainObject(value)) {
      value = replaceEmptyStringToUndefined(value);
    }

    return value;
  });

export const getDeepKeys = obj => {
  let keys = [];

  for (const key in obj) {
    if (_.isObject(obj[key]) && !_.isEmpty(obj[key])) {
      const subKeys = getDeepKeys(obj[key]);
      keys = keys.concat(
        subKeys.map(function (subkey) {
          return key + "." + subkey;
        })
      );
    } else {
      keys.push(key);
    }
  }
  return keys;
};

export const getValue = (object, path, defaultValue) => {
  const value = _.get(object, path);
  if (value === undefined || value === null) {
    return defaultValue;
  } else {
    return value;
  }
};

export const setUndefinedToEmptyRequiredFields = (values, requiredKeys) => {
  const result = _.cloneDeep(values);
  _.forEach(requiredKeys, path => {
    _.set(result, path, getValue(values, path, ""));
  });
  return replaceEmptyStringToUndefined(result);
};

/**
 * Returns flat array of the routes for the entity created by createNamespace function.
 *
 * @param {object} entity The entity to get routes from.
 * @return {array} Flat array of the routes - ["outboundConsignment.shipmentType", "outboundConsignment.deliveryDetails.address.countryCode", ...].
 */
export const flattenEntityRoutes = entity =>
  Object.values(entity).reduce((prev, curr) => {
    if (_.isObject(curr)) {
      return [...prev, ...flattenEntityRoutes(curr)];
    }
    return [...prev, curr];
  }, []);

export const mapArrayToObjectByKey = (array, key) => {
  const object = {};

  array.forEach(item => {
    _.set(object, item[key], item);
  });

  return object;
};

/**
 * Assigns target object`s values to the destination object based on dictionary properties.
 *
 * @param {object} config
 * @param {object} config.destination - destination object.
 * @param {object} config.source - source object.
 * @param {object} config.dictionary - source to destination mapper.
 * @param {function} config.customizer - to customize assigned value
 * @returns {object} returns result object setup
 */
export const assignWithDictionary = ({
  destination,
  source,
  dictionary,
  customizer = (targetValue, srcValue) => targetValue || srcValue,
}) => {
  const keys = Object.keys(dictionary);
  const result = _.cloneDeep(destination);

  keys.forEach(key => {
    _.set(
      result,
      dictionary[key],
      customizer(getValue(source, key), getValue(destination, dictionary[key]))
    );
  });

  return result;
};
