import { createSelector } from "reselect";

import { ShipmentEntity } from "~/constants/forms";
import { ShipmentSelectors } from "~/pages/Shipment/redux";

export const getEditShipmentDisabledFields = createSelector(
  ShipmentSelectors.getCommonDisabledFields,
  commonFields => ({
    ...commonFields,
    [ShipmentEntity.PROFILE_CODE]: true,
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTRY_CODE]:
      true,
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE]:
      true,
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_1]: true,
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_2]: true,
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_3]: true,
    [ShipmentEntity.SHIPMENT_TYPE]: true,
    [ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTRY_CODE]:
      true,
    [ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE]:
      true,
  })
);
