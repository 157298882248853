import { compose } from "recompose";

import { withTrack, withTrackProps } from "@dpdgroupuk/react-event-tracker";

import { CREATE_SHIPMENT_PAGE } from "~/constants/analytics";
import { FilterFields, ShipmentEntity } from "~/constants/forms";

const MAP_FIELD_TO_ANALYTICS = {
  [FilterFields.SEARCH_CRITERIA_VALUE]:
    CREATE_SHIPMENT_PAGE.BLUR_ADDRESS_BOOK_SEARCH,
  [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE]:
    CREATE_SHIPMENT_PAGE.BLUR_TEXT_POSTAL_ZIP_CODE,
  [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.ORGANISATION]:
    CREATE_SHIPMENT_PAGE.BLUR_ORGANISATION,
  [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.TOWN]:
    CREATE_SHIPMENT_PAGE.BLUR_CITY,
  [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTY]:
    CREATE_SHIPMENT_PAGE.BLUR_COUNTY_STATE,
  [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.STREET]:
    CREATE_SHIPMENT_PAGE.BLUR_ADDRESS_LINE_1,
  [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.LOCALITY]:
    CREATE_SHIPMENT_PAGE.BLUR_ADDRESS_LINE_2,
  [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_INSTRUCTION]:
    CREATE_SHIPMENT_PAGE.BLUR_DELIVERY_INFORMATION,
  [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.CONTACT_DETAILS
    .CONTACT_NAME]: CREATE_SHIPMENT_PAGE.BLUR_CONTACT,
  [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.CONTACT_DETAILS
    .TELEPHONE]: CREATE_SHIPMENT_PAGE.BLUR_TELEPHONE,
  [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.NOTIFICATION_DETAILS
    .MOBILE]: CREATE_SHIPMENT_PAGE.BLUR_NOTIFICATION_TEXT,
  [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.NOTIFICATION_DETAILS
    .EMAIL]: CREATE_SHIPMENT_PAGE.BLUR_NOTIFICATION_EMAIL,
  [ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_1]:
    CREATE_SHIPMENT_PAGE.BLUR_YOUR_REFERENCE_1,
  [ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_2]:
    CREATE_SHIPMENT_PAGE.BLUR_YOUR_REFERENCE_2,
  [ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_3]:
    CREATE_SHIPMENT_PAGE.BLUR_YOUR_REFERENCE_3,
  [ShipmentEntity.OUTBOUND_CONSIGNMENT.NUMBER_OF_PARCELS]:
    CREATE_SHIPMENT_PAGE.BLUR_TOTAL_NO_OF_PACKAGES,
  [ShipmentEntity.OUTBOUND_CONSIGNMENT.TOTAL_WEIGHT]:
    CREATE_SHIPMENT_PAGE.BLUR_TOTAL_WEIGHT,
  [ShipmentEntity.OUTBOUND_CONSIGNMENT.PRODUCT]:
    CREATE_SHIPMENT_PAGE.SELECT_PRODUCT,
  [ShipmentEntity.OUTBOUND_CONSIGNMENT.NETWORK_CODE]:
    CREATE_SHIPMENT_PAGE.SELECT_SERVICE,
  [ShipmentEntity.OUTBOUND_CONSIGNMENT.LIABILITY]:
    CREATE_SHIPMENT_PAGE.SELECT_EXTENDED_LIABILITY,
  [ShipmentEntity.SHIPMENT_DATE]:
    CREATE_SHIPMENT_PAGE.SELECT_SHIPMENT_DATE_DATE_PICKER,
};

export default compose(
  withTrack(CREATE_SHIPMENT_PAGE.LOAD),
  withTrackProps({
    onFieldEntry: (e, prevValues, nextValues, path) =>
      MAP_FIELD_TO_ANALYTICS[path],
    onFindClick: CREATE_SHIPMENT_PAGE.CLICK_FIND_POSTCODE,
    onClickPackageDetailsHelp: CREATE_SHIPMENT_PAGE.CLICK_PACKAGE_DETAILS_HELP,
    onClickSaveToAddressBook: CREATE_SHIPMENT_PAGE.CLICK_SAVE_TO_ADDRESS_BOOK,
    onRememberDateChange: CREATE_SHIPMENT_PAGE.SELECT_REMEMBER_DATE,
    onClickClear: CREATE_SHIPMENT_PAGE.CLICK_CLEAR,
    onClickPrint: printNow =>
      printNow
        ? CREATE_SHIPMENT_PAGE.CLICK_PRINT_NOW
        : CREATE_SHIPMENT_PAGE.CLICK_PRINT_LATER,
    onPostcodeSelectionChange:
      CREATE_SHIPMENT_PAGE.SELECT_ADDRESS_BOOK_SEARCH_TYPE,
    onChangeAddressBookFilter:
      CREATE_SHIPMENT_PAGE.SELECT_ADDRESS_BOOK_SEARCH_TYPE,
    onSelectionChange: CREATE_SHIPMENT_PAGE.SELECT_ADDRESS_BOOK_AUTO_COMPLETE,
  })
);
