import get from "lodash/get";
import { withHandlers } from "recompose";

import { ShipmentEntity } from "~/constants/forms";
import { GB } from "~/constants/strings";
import { LiabilityModels, ShipmentModels } from "~/models";
import { getValue } from "~/utils/object";

export default withHandlers({
  onProfileChange:
    ({
      preferences,
      reInitializeForm,
      profilesRaw,
      createShipmentValues,
      securitySettings,
      selectedService,
    }) =>
    event => {
      const profile = profilesRaw.find(
        ({ profileCode }) => profileCode === event?.target?.value
      );
      const countryCode =
        get(
          createShipmentValues,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
            .COUNTRY_CODE
        ) || GB;
      const defaultInformation = getValue(
        profile,
        "defaultInformation",
        ""
      ).toUpperCase();
      const deliveryInstructions =
        (!profile?.useMyDpdAccountSettings && defaultInformation) || "";
      const allowedLiability =
        selectedService &&
        LiabilityModels.getIsExtendedLiabilityFlagAllowed(
          securitySettings,
          selectedService
        );
      const data = {
        [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_INSTRUCTION]:
          deliveryInstructions,
        [ShipmentEntity.OUTBOUND_CONSIGNMENT.TOTAL_WEIGHT]:
          ShipmentModels.getDefaultTotalWeight(
            profile,
            preferences,
            countryCode,
            getValue(
              createShipmentValues,
              ShipmentEntity.OUTBOUND_CONSIGNMENT.NETWORK_CODE,
              ""
            )
          ),
        [ShipmentEntity.OUTBOUND_CONSIGNMENT.NUMBER_OF_PARCELS]:
          ShipmentModels.getDefaultNumberOfParcels(preferences, countryCode),
        ...(allowedLiability && {
          [ShipmentEntity.OUTBOUND_CONSIGNMENT.LIABILITY]:
            LiabilityModels.getDefaultLiability(
              preferences,
              profile,
              countryCode
            ),
        }),
      };

      // NOTE: use reInitializeForm to trigger onBlurServiceRelatedField
      reInitializeForm(data);
    },
});
