import { useCallback, useEffect, useState } from "react";

import { get, uniqBy } from "lodash";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";

import { FormControl } from "@dpdgroupuk/mydpd-ui";

import { ShipmentEntity } from "~/constants/forms";
import * as S from "~/constants/strings";
import { ServiceModels } from "~/models";
import { getValue } from "~/utils/object";
import { formatMessage } from "~/utils/string";

const DomesticService = ({
  input,
  values,
  meta,
  shipmentSection,
  serviceDisabled,
  ...props
}) => {
  const { onFocus, onChange, onBlur, ...inputProps } = input;

  const [productCode, setProductCode] = useState();
  const [serviceCode, setServiceCode] = useState();
  const [services, setServices] = useState([]);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const network = values?.find(
      ({ networkKey }) => networkKey === input?.value
    );

    setProductCode(network?.product?.productKey);
    setServiceCode(network?.service?.serviceKey);

    setProducts(
      uniqBy(
        values.map(outbound => ({
          label: get(outbound, "product.productDesc"),
          value: get(outbound, "product.productKey"),
        })),
        "value"
      )
    );

    setServices(
      ServiceModels.getProductRelatedServicesKeyValue(
        network?.product?.productKey,
        values
      )
    );
  }, [input?.value, values]);

  const onProductChange = useCallback(
    (e, value) => {
      const networks = ServiceModels.getProductRelatedServices(value, values);

      const currentService = ServiceModels.getServiceByProductAndServiceKeys(
        values,
        value,
        serviceCode
      );

      const defaultServiceCode = getValue(networks, "[0].service.serviceKey");
      const service = ServiceModels.getServiceByProductAndServiceKeys(
        values,
        value,
        defaultServiceCode
      );

      onChange(currentService?.networkKey || service?.networkKey);
    },
    [onChange, values, serviceCode]
  );

  const onServiceChange = useCallback(
    (e, value) => {
      const service = ServiceModels.getServiceByProductAndServiceKeys(
        values,
        productCode,
        value
      );

      onChange(service?.networkKey);
    },
    [productCode, onChange, values]
  );

  const handleBlur = useCallback(() => {
    onBlur?.(input?.value);
  }, [onBlur, input?.value]);

  const handleFocus = useCallback(() => {
    onFocus?.(input?.value);
  }, [onFocus, input?.value]);

  return (
    <>
      <Row>
        <Col>
          <FormControl.Dropdown
            {...props}
            {...inputProps}
            label={S.PRODUCT}
            meta={meta}
            value={productCode}
            values={products}
            helperText={formatMessage(S.PLEASE_SELECT_$, S.YOUR_PRODUCT_TYPE)}
            name={ShipmentEntity[shipmentSection].PRODUCT}
            onChange={onProductChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            disabled={props.disabled || props.productDisabled}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormControl.Dropdown
            {...props}
            {...inputProps}
            disabled={props.disabled || serviceDisabled}
            label={S.SERVICE}
            meta={meta}
            value={serviceCode}
            values={services}
            helperText={formatMessage(
              S.PLEASE_SELECT_$,
              S.YOUR_PRODUCT_SERVICE
            )}
            name={ShipmentEntity[shipmentSection].NETWORK_CODE}
            onChange={onServiceChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        </Col>
      </Row>
    </>
  );
};

DomesticService.propTypes = {
  disabledFields: PropTypes.object,
  input: PropTypes.object,
  meta: PropTypes.object,
  values: PropTypes.array,
  shipmentSection: PropTypes.string,
  disabled: PropTypes.bool,
  serviceDisabled: PropTypes.bool,
  productDisabled: PropTypes.bool,
};

export default DomesticService;
