import { ActionTypes } from "./actions";

export const initialState = {
  labels: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_LABELS: {
      return {
        ...state,
        labels: action.payload,
      };
    }

    default:
      return state;
  }
};
