import { forwardRef } from "react";

import PropTypes from "prop-types";

const AddAddressBook = forwardRef(({ color, size, ...rest }, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width={size}
    height={size}
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.64795 8.70077L6.37168 9.55375V0H4.11384V9.56559L4.85752 8.69869C4.87539 8.67791 4.89472 8.65859 4.9157 8.64093C5.13451 8.45516 5.46219 8.48197 5.64795 8.70077ZM2 3.44183V14.1368C2 14.341 2.03221 14.5374 2.09101 14.7208C2.5363 13.691 3.55011 12.9617 4.72641 12.9617H13.9448V1.58086H7.41042V10.969C7.41042 11.1215 7.34331 11.2663 7.22715 11.365C7.00835 11.5508 6.68046 11.5238 6.4949 11.305L5.24983 9.83738L3.98875 11.3073C3.89005 11.4222 3.74626 11.4883 3.59478 11.4885C3.30783 11.4887 3.07531 11.2562 3.0751 10.9692V1.74252C2.44716 2.03197 2 2.68111 2 3.44183ZM4.72641 14.0009H13.9446H13.9448V16H3.80943C3.47676 16 3.16341 15.9063 2.89391 15.7428C2.96684 14.7657 3.76683 14.0009 4.72641 14.0009Z"
      fill={color}
    />
  </svg>
));

AddAddressBook.displayName = "AddAddressBook";
AddAddressBook.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
AddAddressBook.defaultProps = {
  color: "#438DED",
  size: "10",
};

export default AddAddressBook;
