import PropTypes from "prop-types";
import { Col, Container, Row } from "react-bootstrap";

import ShipmentCard from "~/components/ShipmentCard";

const TableContainer = ({
  importCardValues,
  footerActions: FooterActions,
  headerActions: HeaderActions,
  children,
  containerId,
}) => {
  const { step, title, columns = 6 } = importCardValues;

  return (
    <Col
      key={step}
      xs={{ order: step, span: 12 }}
      md={columns}
      className={
        columns === 5
          ? "pr-md-2 p-0 d-flex align-items-stretch"
          : "pl-md-2 p-0 d-flex align-items-stretch"
      }
    >
      <ShipmentCard step={step} title={title}>
        <Container>
          <Row>
            {HeaderActions && <HeaderActions />}
            <Col className="col-12 px-0" id={containerId}>
              {children}
            </Col>
            <Col className={"col-12 d-flex justify-content-sm-end py-2 px-0"}>
              {FooterActions && <FooterActions containerId={containerId} />}
            </Col>
          </Row>
        </Container>
      </ShipmentCard>
    </Col>
  );
};

TableContainer.propTypes = {
  step: PropTypes.number,
  children: PropTypes.node,
  headerActions: PropTypes.elementType,
  footerActions: PropTypes.elementType,
  importCardValues: PropTypes.object,
  containerId: PropTypes.string,
};

export default TableContainer;
