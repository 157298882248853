import Joi from "joi";

import { SHIPMENT_TYPES } from "@dpdgroupuk/mydpd-enums";
import { joiExtension } from "@dpdgroupuk/mydpd-express-helpers/utils";
import { CommonValidators } from "@dpdgroupuk/mydpd-joi-validator";

import * as S from "~/constants/strings";
import { formatMessage } from "~/utils/string";

import { BUSINESS_EORI_REGEX, EMAIL } from "./regex";

export const emptyCases = ["", null];

export const town = CommonValidators.town().label(S.CITY);

export const county = CommonValidators.county().label(S.COUNTY_STATE);

export const deliveryInstructions =
  CommonValidators.deliveryInstructions().label(S.DELIVERY_INFORMATION);

export const street = CommonValidators.street().label(S.ADDRESS_LINE_1);

export const organisation = CommonValidators.organisation().label(
  S.ORGANISATION
);

export const locality = CommonValidators.locality().label(S.ADDRESS_LINE_2);

export const contactName = CommonValidators.contactName().label(S.CONTACT_NAME);

const baseTelephone = CommonValidators.phoneNumber();

export const telephone = baseTelephone.label(S.CONTACT_TELEPHONE).error(
  joiExtension.messages({
    "any.required": formatMessage(S.$_MUST_BE_ENTERED, S.CONTACT_TELEPHONE),
    "string.regex.base": formatMessage(
      S.$_MUST_BE_VALID_$,
      S.CONTACT_TELEPHONE,
      S.TELEPHONE_NUMBER
    ),
  })
);

export const mobile = baseTelephone.label(S.MOBILE_NOTIFICATION_TEXT).error(
  joiExtension.messages({
    "any.required": formatMessage(
      S.$_MUST_BE_ENTERED,
      S.NOTIFICATION_EMAIL_OR_MOBILE_TEXT
    ),
    "string.regex.base": formatMessage(
      S.$_MUST_BE_VALID_$,
      S.MOBILE_NOTIFICATION_TEXT,
      S.TELEPHONE_NUMBER
    ),
  })
);

export const importerTelephone = baseTelephone.label(S.CONTACT_TELEPHONE).error(
  joiExtension.messages({
    "any.required": formatMessage(
      S.$_MUST_BE_ENTERED,
      S.IMPORTER_EMAIL_OR_IMPORTER_TELEPHONE
    ),
    "string.regex.base": formatMessage(
      S.$_MUST_BE_VALID_$,
      S.CONTACT_TELEPHONE,
      S.TELEPHONE_NUMBER
    ),
  })
);

export const email = Joi.string()
  .max(100)
  .regex(EMAIL, S.EMAIL_ADDRESS)
  .label(S.NOTIFICATION_EMAIL)
  .error(
    joiExtension.messages({
      "any.required": formatMessage(
        S.$_MUST_BE_ENTERED,
        S.NOTIFICATION_EMAIL_OR_MOBILE_TEXT
      ),
    })
  );

export const numberOfParcels = () =>
  CommonValidators.numberOfParcels()
    .label(S.TOTAL_PACKAGES_NUMBER)
    .error(
      joiExtension.messages({
        "number.integer": ({ context }) =>
          formatMessage(S.$_HAS_INVALID_TYPE, context.label),
      })
    )
    // @see https://it.dpduk.live/version/customer-shipping/sprint-1.20/diag_EPiTmj6AUAA0y5KW.html?id=1641397618953
    .when("$values.shipmentType", {
      is: SHIPMENT_TYPES.SWAP_IT,
      then: Joi.number()
        .integer()
        .max(1)
        .error(
          joiExtension.messages({
            "number.max": ({ context }) =>
              formatMessage(
                S.TOTAL_NUMBER_OF_PARCELS_SHOULD_BE_EQUAL_$,
                context.limit
              ),
          })
        ),
    })
    .label(S.TOTAL_PACKAGES_NUMBER);

export const numberOfNewParcels = () =>
  CommonValidators.numberOfParcels()
    .required()
    .label(S.TOTAL_NEW_PARCELS)
    .when("$values.shipmentType", {
      is: SHIPMENT_TYPES.SWAP_IT,
      then: Joi.number()
        .integer()
        .max(1)
        .error(
          joiExtension.messages({
            "number.max": ({ context }) =>
              formatMessage(
                S.TOTAL_NUMBER_OF_PARCELS_SHOULD_BE_EQUAL_$,
                context.limit
              ),
          })
        ),
    })
    .label(S.TOTAL_NEW_PARCELS);

export const inboundNumberOfParcels = () =>
  numberOfParcels()
    .when("$values.shipmentType", {
      is: SHIPMENT_TYPES.REVERSE_IT,
      then: Joi.number()
        .integer()
        .max(5)
        .error(
          joiExtension.messages({
            "number.max": ({ context }) =>
              formatMessage(
                S.TOTAL_NUMBER_OF_PARCELS_SHOULD_BE_LESS_OR_EQUAL_$,
                context.limit
              ),
          })
        ),
    })
    .label(S.TOTAL_PACKAGES_NUMBER);

export const totalWeight = Joi.number()
  .max(99999)
  .min(0.01)
  .label(S.TOTAL_WEIGHT);

export const totalWeightOfNewParcels = Joi.number()
  .required()
  .max(99999)
  .min(0.01)
  .label(S.TOTAL_WEIGHT_OF_NEW_PARCELS);

export const currency = CommonValidators.currency().label(S.CURRENCY);

export const customsValue = CommonValidators.customsValue().label(
  S.CUSTOMS_VALUE
);

export const deliveryDescription = CommonValidators.deliveryDescription(
  45
).label(S.CONTENTS_DESCRIPTION);

const reference = CommonValidators.shippingRef();
export const shippingRef3 = reference.label(S.YOUR_REFERENCE_3);
export const shippingRef2 = reference.label(S.YOUR_REFERENCE_2);
export const shippingRef1 = reference.label(S.YOUR_REFERENCE_1);
export const generateCustomsData = Joi.boolean()
  .allow(null)
  .label(S.GENERATE_CUSTOMS_DATA);

export const shortName = Joi.string().max(45).label(S.SHORT_NAME);

export const postcode = Joi.string().max(8).label(S.POSTCODE);

export const countryCode = Joi.string().label(S.DESTINATION_COUNTRY);

export const product = Joi.label(S.PRODUCT).error(
  joiExtension.messages({
    "any.required": S.PRODUCT_AND_SERVICE_COMBINATION_INVALID,
  })
);

export const networkCode = Joi.error(
  joiExtension.messages({
    "any.required": S.PRODUCT_AND_SERVICE_COMBINATION_INVALID,
  })
);

export const pidNumber = Joi.string()
  .min(4)
  .max(45)
  .label(formatMessage(S.$_$_NUMBER, S.IMPORTERS, S.PID));

export const vatNumber = Joi.string().min(4).max(45);

export const importerEoriNumber = Joi.string()
  .min(4)
  .max(45)
  .label(formatMessage(S.$_$_NUMBER, S.IMPORTERS, S.EORI));

export const isBusiness = Joi.string();

export const exporterEoriNumber = Joi.string()
  .regex(BUSINESS_EORI_REGEX)
  .error(
    joiExtension.messages({
      "string.regex.base": S.VALID_EXPORTERS_EORI_NO_MUST_BE_ENTERED,
    })
  )
  .label(formatMessage(S.$_$_NUMBER, S.EXPORTERS, S.EORI));

export const returnItemDescription = Joi.string()
  .max(30)
  .label(S.ITEM_DESCRIPTION);

export const returnTotalWeight = Joi.number().min(0.01).label(S.TOTAL_WEIGHT);

export const contactDetails = Joi.object({
  contactName,
  telephone,
});

export const notificationDetails = Joi.object({
  mobile,
  email,
});

export const address = Joi.object({
  organisation,
  street,
  locality,
  town,
  county,
  postcode,
  countryCode,
});

export const deliveryDetails = Joi.object({
  address,
  contactDetails,
  notificationDetails,
});

export const invoiceReference = Joi.string().max(30).label(S.INVOICE_REFERENCE);

export const exportReason = Joi.string().label(S.REASON_FOR_EXPORT);

export const shippingCostBase = Joi.number()
  .max(99999999.99)
  .label(S.SHIPPING_FREIGHT_COST);

export const shippingCost = Joi.when("$customsFieldsFlags.shippingCost", {
  is: true,
  then: shippingCostBase.greater(0),
  otherwise: shippingCostBase.min(0),
}).label(S.SHIPPING_FREIGHT_COST);

export const commodityCodeGeneric = Joi.string()
  .allow("")
  .max(10)
  .label(S.COMMODITY_CODE);

export const commodityCodeStrict = Joi.string()
  .length(8)
  .error(
    joiExtension.messages({
      "string.length": S.COMMODITY_CODE_STRICT_ERROR_MESSAGE,
    })
  );

export const destinationTaxId = Joi.string()
  .max(45)
  .label(S.DEST_TAX_ID_REG_NUMBER);

export const gstVatPaid = Joi.boolean().label(S.GST_VAT_PAID);

export const fdaRegistrationNumber = Joi.string().max(30).label(S.FDA_NUMBER);

export const liabilityValue = Joi.number()
  .greater(0)
  .max(5000)
  .label(S.LIABILITY_VALUE);

// @see https://it.dpduk.live/version/customer-shipping/sprint-2.1/diag_H7qFnb6GAqAAhRD8.html?id=1644425100650
// @see https://it.dpduk.live/version/customer-shipping/sprint-2.1/diag_.qslnb6GAqAAhYjJ.html?id=1644425123939

export const lines = label => Joi.number().min(0).max(99).label(label);

// @see https://it.dpduk.live/version/customer-shipping/sprint-2.1/diag_xospnb6GAqAAhQPq.html?id=1644425143358
export const workDirectory = label => Joi.string().max(200).label(label);

// @see https://it.dpduk.live/version/customer-shipping/sprint-2.1/diag_NH5Vnb6GAqAAhZIm.html?id=1644425167513
export const overrideWeight = Joi.number()
  .min(1)
  .max(99)
  .label(S.OVERRIDE_WEIGHT);

// @see https://it.dpduk.live/version/customer-shipping/sprint-2.1/diag_oJPpnb6GAqAAhXqe.html?id=1644424855263
export const extension = label => Joi.string().max(4).label(label);

// @see https://it.dpduk.live/version/customer-shipping/sprint-2.4/diag_gv2xk36GAqCIahdp.html?id=1651748017873
export const delimiter = (label, field, fieldName) =>
  Joi.string()
    .disallow(Joi.ref(`$values.${field}`))
    .error(
      joiExtension.messages({
        "any.invalid": formatMessage(
          S.DELIMITERS_FIELDS_ERROR_$,
          label,
          fieldName
        ),
      })
    )
    .label(label);

export const exportFileBehavior = Joi.string().label(S.EXPORT_FILE_BEHAVIOR);

export const productCode = Joi.string().max(255).label(S.PRODUCT_CODE);

export const productType = Joi.string().max(40).label(S.PRODUCT_TYPE);

export const productDescription = Joi.string()
  .max(200)
  .label(S.DETAILED_PRODUCT_DESCRIPTION);

export const productComposition = Joi.string()
  .max(200)
  .label(S.PRODUCT_COMPOSITION);

export const countryOfOrigin = Joi.string().max(2).label(S.COUNTRY_OF_ORIGIN);

export const commodityCode = Joi.string().max(8).label(S.COMMODITY_CODE);

export const productWeight = Joi.number()
  .min(0.01)
  .max(99999.99)
  .label(S.PRODUCT_UNIT_WEIGHT);

export const productUnitValue = Joi.number()
  .min(0.01)
  .max(99999.99)
  .label(S.PRODUCT_UNIT_VALUE);

export const productUrl = Joi.string().max(255).label(S.WEBSITE_ADDRESS);

export const termsOfDelivery = Joi.string()
  .required()
  .label(S.INTERNATIONAL_BILLING_TERMS);

export const profileCode = CommonValidators.profileCode().label(S.PROFILE);

export const basePerShipment = Joi.number()
  .integer()
  .min(1)
  .max(99)
  .required()
  .example(1);
