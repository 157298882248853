import { ContainerFields } from "~/constants/forms";
import * as S from "~/constants/strings";

export const validate = values => {
  if (values[ContainerFields.NUMBER_OF_LABELS] < 1) {
    return {
      [ContainerFields.NUMBER_OF_LABELS]: S.NUMBER_LABELS_VALIDATION_ERROR,
    };
  }
};
