import Joi from "joi";

import { TEMPLATE_TYPE } from "@dpdgroupuk/mydpd-enums";

import { ImportsFields } from "~/constants/forms";
import * as S from "~/constants/strings";

import {
  delimiter,
  exportFileBehavior,
  extension,
  lines,
  overrideWeight,
  workDirectory,
} from "./commonSchema";

export default ({ definition }) =>
  Joi.object().keys({
    [ImportsFields.HEADER_LINES]: lines(S.HEADER_LINES).required(),
    [ImportsFields.FOOTER_LINES]: lines(S.FOOTER_LINES).required(),
    [ImportsFields.WORKING_DIR]: workDirectory(
      definition === TEMPLATE_TYPE.SHIPMENT.toString()
        ? S.IMPORT_DIRECTORY
        : S.EXPORT_DIRECTORY
    ).required(),
    [ImportsFields.OVERRIDE_WEIGHT]: overrideWeight.required(),
    [ImportsFields.EXTENSION]: extension(
      definition === TEMPLATE_TYPE.SHIPMENT.toString()
        ? S.IMPORT_EXTENSION
        : S.EXPORT_EXTENSION
    ).required(),
    [ImportsFields.EXPORT_FILE]: workDirectory(S.EXPORT_FILE).required(),
    [ImportsFields.DELIMITER]: delimiter(
      S.FIELD_DELIMITER,
      ImportsFields.PRODUCT_DELIMITER,
      S.PRODUCT_FIELD_DELIMITER
    ).required(),
    [ImportsFields.PRODUCT_DELIMITER]: delimiter(
      S.PRODUCT_FIELD_DELIMITER,
      ImportsFields.DELIMITER,
      S.FIELD_DELIMITER
    ).required(),
    [ImportsFields.EXPORT_FILE_BEHAVIOR]: exportFileBehavior.required(),
    [ImportsFields.FILE_FIELD_DELIMITER]: delimiter(
      S.FIELD_DELIMITER,
      ImportsFields.GROUP_NAME_FIELD_DELIMITER,
      S.GROUP_NAME_DELIMITER
    ).required(),
    [ImportsFields.GROUP_NAME_FIELD_DELIMITER]: delimiter(
      S.GROUP_NAME_DELIMITER,
      ImportsFields.FILE_FIELD_DELIMITER,
      S.FIELD_DELIMITER
    ).required(),
  });
