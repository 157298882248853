import Joi from "joi";
import { values } from "lodash";

import { ShipmentEntity } from "~/constants/forms";
import { INVOICE_TYPE } from "~/constants/invoice";
import * as M from "~/constants/strings";

import {
  contactDetails,
  countryCode,
  county,
  currency,
  customsValue,
  deliveryDescription,
  deliveryDetails,
  deliveryInstructions,
  destinationTaxId,
  exportReason,
  generateCustomsData,
  gstVatPaid,
  inboundNumberOfParcels,
  invoiceReference,
  liabilityValue,
  locality,
  networkCode,
  numberOfParcels,
  organisation,
  postcode,
  returnItemDescription,
  returnTotalWeight,
  shippingCost,
  shippingRef1,
  shippingRef2,
  shippingRef3,
  street,
  termsOfDelivery,
  totalWeight,
  town,
} from "./commonSchema";
import exporterDetails from "./exporterDetailsSchema";
import importerDetails from "./importerDetailsSchema";
import productSchema from "./productSchema";

const outboundConsignment = ({ productRequiredFields }) =>
  Joi.object({
    deliveryInstructions,
    networkCode,
    numberOfParcels: numberOfParcels(),
    totalWeight,
    deliveryDetails,
    shippingRef1,
    shippingRef2,
    shippingRef3,
    currency,
    customsValue,
    deliveryDescription,
    destinationTaxId,
    gstVatPaid,
    liabilityValue,
    parcels: Joi.array().items(
      Joi.object({
        products: Joi.array().items(
          productRequiredFields.length
            ? productSchema.requiredKeys(...productRequiredFields)
            : productSchema
        ),
      })
    ),
  });

const returnDeliveryDetails = Joi.object({
  address: Joi.object({
    county,
    town,
    deliveryInstructions,
    street,
    locality,
    postcode,
    countryCode,
    organisation,
  }),
  contactDetails,
});

const inboundConsignment = () =>
  Joi.object({
    deliveryDescription: returnItemDescription,
    shippingRef1,
    shippingRef2,
    shippingRef3,
    networkCode,
    numberOfParcels: inboundNumberOfParcels(),
    deliveryDetails: returnDeliveryDetails,
    totalWeight: returnTotalWeight,
  });

const invoice = Joi.object({
  invoiceReference,
  exportReason,
  invoiceType: Joi.number()
    .required()
    .valid(values(INVOICE_TYPE))
    .label(M.INVOICE_TYPE),
  termsOfDelivery,
  shippingCost,
  importerDetails,
  exporterDetails,
});

export const shipmentSchema = props =>
  Joi.object().keys({
    customerRef1: shippingRef1.label(M.SCAN_REFERENCE),
    [ShipmentEntity.SHIPMENT_DATE]: Joi.string(),
    generateCustomsData,
    outboundConsignment: outboundConsignment(props),
    inboundConsignment: inboundConsignment(props),
    invoice,
  });
