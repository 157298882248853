import { getFormValues } from "redux-form";
import { createSelector } from "reselect";

import { CONTAINER_FORM } from "~/constants/forms";

export const getContainer = (state, container) => container;
export const getContainerFormValues = state =>
  getFormValues(CONTAINER_FORM)(state);

export const getContainerLabels = createSelector(
  getContainer,
  ({ labels } = {}) => labels
);
