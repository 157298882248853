import { connect } from "react-redux";
import { compose } from "recompose";

import {
  INBOUND_CONSIGNMENT,
  RETURN_DETAILS_SEARCH_FORM,
  ShipmentEntity,
} from "~/constants/forms";
import { ADDRESS_BOOK_TYPES } from "~/constants/strings";
import withAddressBookAutocomplete from "~/pages/Shipment/hocs/withAddressBookAutocomplete";
import withPostcodeAutocomplete from "~/pages/Shipment/hocs/withShipmentPostcodeAutocomplete";

import { ShipmentActions } from "../redux";

export default compose(
  connect(null, (dispatch, { pageConfig }) => ({
    onCountryChange: selection =>
      dispatch(
        ShipmentActions.changeCountry(
          pageConfig,
          ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
            .COUNTRY_CODE,
          selection.value
        )
      ),
  })),
  withPostcodeAutocomplete(INBOUND_CONSIGNMENT),
  withAddressBookAutocomplete({
    formSection: INBOUND_CONSIGNMENT,
    searchFormName: RETURN_DETAILS_SEARCH_FORM,
    addressBookType: ADDRESS_BOOK_TYPES.RETURN,
  })
);
