import { AddressBookActionTypes } from "./actions";

export const initialState = {
  list: [],
  addressbookGroups: [],
  groupAddresses: {},
  defaultAddressBook: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AddressBookActionTypes.FETCH_ADDRESS_BOOKS.SUCCESS:
    case AddressBookActionTypes.FETCH_FILTERED_ADDRESS_BOOKS.SUCCESS: {
      return {
        ...state,
        list: action.payload,
      };
    }
    case AddressBookActionTypes.FETCH_ADDRESS_BOOK_GROUPS.SUCCESS: {
      return {
        ...state,
        addressbookGroups: action.payload.data,
      };
    }
    case AddressBookActionTypes.FETCH_ADDRESSES_BY_ADDRESS_BOOK_GROUPS_ID
      .SUCCESS: {
      return {
        ...state,
        groupAddresses: action.payload.data,
      };
    }
    case AddressBookActionTypes.CLEAR_ADDRESS_BOOK:
    case AddressBookActionTypes.CLEAR_ADDRESS_BOOK_GROUPS: {
      return initialState;
    }
    case AddressBookActionTypes.CLEAR_GROUP_ADDRESSES: {
      return {
        ...state,
        groupAddresses: initialState.groupAddresses,
      };
    }
    case AddressBookActionTypes.FETCH_DEFAULT_ADDRESS_BOOK.SUCCESS: {
      return {
        ...state,
        defaultAddressBook: action.payload,
      };
    }
    default:
      return state;
  }
};
