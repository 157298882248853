import classNames from "classnames";
import PropTypes from "prop-types";

import { CustomsOfficial, Info } from "~/components/Icon";
import { CUSTOMS_NOTICE, MANDATORY_INFORMATION } from "~/constants/strings";

import styles from "./Title.module.scss";

const Title = ({ title = MANDATORY_INFORMATION, className }) => (
  <span className={classNames(styles.title, className)}>
    <Info />
    {title}
  </span>
);

Title.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
};

export const CustomsNoticeTitle = ({ title = CUSTOMS_NOTICE, className }) => (
  <span className={classNames(styles.title, className)}>
    <CustomsOfficial />
    {title}
  </span>
);

CustomsNoticeTitle.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
};

export default Title;
