import { useCallback, useMemo, useState } from "react";

import { difference, get, isBoolean, isEmpty, map } from "lodash";
import { noop } from "lodash/fp";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose, lifecycle, withHandlers } from "recompose";

import {
  UserDataSelectors,
  UserSessionDataSelectors,
  withAppContext,
  withAppUserPreferences,
} from "@dpdgroupuk/mydpd-app";
import { BANNERS_TYPES } from "@dpdgroupuk/mydpd-enums";
import {
  Button,
  Card,
  Legend,
  Main,
  Tabs,
  withSnackbar,
} from "@dpdgroupuk/mydpd-ui";

import KeyValueList from "~/components/KeyValueList";
import ReviewCard from "~/components/ReviewCard";
import DetailsCard from "~/components/ReviewCard/cards/DetailsCard";
import { ShipmentEntity } from "~/constants/forms";
import { SHOW_ALERT_DISPLAY_TIME } from "~/constants/snackbar";
import * as S from "~/constants/strings";
import useToggle from "~/hooks/useToggle";
import { ServiceModels, ShipmentModels } from "~/models";
import { isShipmentEditable } from "~/models/shipment/shipment";
import { AuthSelectors, UmsSelectors } from "~/redux";
import * as routes from "~/router/constants";
import { isOldShipment, isWeekend } from "~/utils/date";
import { getErrorMessage } from "~/utils/error";
import { getValue } from "~/utils/object";

import ActionButtons from "../../components/ActionButtons";
import AddExtraLabelModal from "../../components/AddExtraLabelModal";
import ChangeDateModal from "../../components/ChangeDateModal";
import PrintManifestModal from "../../components/PrintManifestModal";
import PrintUnprintedModal from "../../components/PrintUnprintedModal";
import withShipmentUpdateActions from "../../hocs/withShipmentUpdateActions";
import {
  getExportShipmentsCsvHref,
  getPermissionsForSelectedShipments,
} from "../../models";
import * as ShipmentReviewActions from "../../redux/actions";
import { DEFAULT_SHIPMENT_COLUMNS } from "../ShipmentList/constants";
import { MAP_COLUMN_NAMES_FOR_EXPORT } from "../ShipmentList/models";
import InfoCard from "./components/InfoCard";
import PackageDetailsTable from "./components/PackageDetailsTable";
import PackageDetailsTitle from "./components/PackageDetailsTitle";
import ProductDetailsTable from "./components/ProductDetailsTable";
import {
  mapAddressAndContactInformation,
  mapInboundShipmentDetails,
  mapOutboundShipmentDetails,
  mapPickupLocation,
  mapReferences,
  mapSenderInformation,
} from "./models";
import styles from "./ShipmentView.module.scss";
import { SHIP_TO_SHOP } from "~/constants/services";

const ShipmentView = ({
  shipment,
  customerPrefs,
  onClickHold,
  onClickUnhold,
  onClickVoid,
  onClickVoidParcels,
  onClickUnvoidParcels,
  onClickUnvoid,
  onClickDelete,
  onShipmentPrint,
  history,
  onClickChangeDate,
  printInvoice,
  printLabelsByParcelNumber,
  reloadFn,
  printUnprintedLabels,
  getSendToFileHref,
  authUser,
  onClickAddExtraLabel,
  selectedColumnNames,
  shipmentListStorageSearchQuery,
  filterDate,
  getDatesRange,
  getPrintUnprintedDatesRange,
  eodUser,
}) => {
  const [activeTab, setActiveTab] = useState("0");

  const {
    outboundTotalWeight,
    outboundNumberOfParcels,
    allowCopy,
    outboundConsignment,
    inboundConsignment,
    shipmentId,
    outboundShipmentDetails,
    outboundSenderInformation,
    inboundShipmentDetails,
    receiverDetails,
    showReceiverDetails,
    shippingAccount,
    inboundTotalWeight,
    inboundNumberOfParcels,
    actionPermissions,
    allowAddExtraParcel,
    shipmentDate,
    shipmentType,
  } = useMemo(
    () => ({
      outboundTotalWeight: ServiceModels.roundTotalWeight(
        get(shipment, "outboundConsignment.displayWeight", 0),
        get(
          shipment,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
            .COUNTRY_CODE
        ),
        getValue(shipment, ShipmentEntity.OUTBOUND_CONSIGNMENT.NETWORK_CODE, "")
      ),
      outboundNumberOfParcels: get(
        shipment,
        "outboundConsignment.numberOfParcels",
        0
      ),
      allowCopy: get(shipment, "allowCopy", false),
      outboundConsignment: get(shipment, "outboundConsignment", {}),
      inboundConsignment: get(shipment, "inboundConsignment", {}),
      shipmentId: get(shipment, "shipmentId"),
      outboundShipmentDetails: mapOutboundShipmentDetails(shipment),
      outboundSenderInformation: mapSenderInformation(shipment),
      inboundShipmentDetails: mapInboundShipmentDetails(shipment),
      receiverDetails: mapPickupLocation(shipment),
      showReceiverDetails: SHIP_TO_SHOP.includes(
        getValue(shipment, ShipmentEntity.OUTBOUND_CONSIGNMENT.NETWORK_CODE, "")
      ),
      shippingAccount: get(shipment, "shippingAccount"),
      inboundTotalWeight: get(shipment, "isVoided", false)
        ? 0
        : get(shipment, "inboundConsignment.totalWeight", 0),
      inboundNumberOfParcels: get(shipment, "isVoided", false)
        ? 0
        : get(shipment, "inboundConsignment.numberOfParcels", 0),
      actionPermissions: getPermissionsForSelectedShipments([shipment]),
      allowAddExtraParcel:
        get(shipment, "allowAddExtraParcel") &&
        !SHIP_TO_SHOP.includes(
          getValue(
            shipment,
            ShipmentEntity.OUTBOUND_CONSIGNMENT.NETWORK_CODE,
            ""
          )
        ),
      shipmentDate: get(shipment, ShipmentEntity.SHIPMENT_DATE),
      shipmentType: get(shipment, ShipmentEntity.SHIPMENT_TYPE),
    }),
    [shipment]
  );

  const {
    outboundCollectionDetails,
    outboundDeliveryDetails,
    outboundAddress,
    outboundReferences,
    outboundParcels,
  } = useMemo(
    () => ({
      outboundCollectionDetails: mapAddressAndContactInformation(
        get(outboundConsignment, "collectionDetails", {})
      ),
      outboundDeliveryDetails: mapAddressAndContactInformation(
        get(outboundConsignment, "deliveryDetails")
      ),
      outboundAddress: get(
        outboundConsignment,
        "deliveryDetails.address.countryCode"
      ),
      outboundReferences: mapReferences(outboundConsignment),
      outboundParcels: get(outboundConsignment, "parcels", []),
    }),
    [outboundConsignment]
  );

  const {
    inboundCollectionDetails,
    inboundDeliveryDetails,
    inboundAddress,
    inboundParcels,
    inboundSenderInformation,
  } = useMemo(
    () => ({
      inboundCollectionDetails: mapAddressAndContactInformation(
        get(inboundConsignment, "collectionDetails")
      ),
      inboundDeliveryDetails: mapAddressAndContactInformation(
        get(inboundConsignment, "deliveryDetails")
      ),
      inboundAddress: get(
        inboundConsignment,
        "deliveryDetails.address.countryCode"
      ),
      inboundParcels: get(inboundConsignment, "parcels", []),
      inboundSenderInformation: [
        {
          name: "Account",
          value: shippingAccount,
        },
        ...mapReferences(inboundConsignment),
      ],
    }),
    [inboundConsignment]
  );

  const isOutboundTab = activeTab === "0" || isEmpty(inboundConsignment);

  const products = outboundParcels.reduce(
    (prev, { products }) => [...prev, ...products],
    []
  );

  const isReprintInvoiceAllowed = useMemo(
    () =>
      // @see: comments from https://geopost.jira.com/browse/CSHIP-6352
      !isEmpty(shipment.invoice) &&
      shipment.isPrinted &&
      !isOldShipment(shipmentDate),
    [shipment]
  );

  const handleClickHold = useCallback(() => onClickHold(shipment), [shipment]);
  const handleClickUnhold = useCallback(
    () => onClickUnhold(shipment),
    [shipment]
  );
  const handleClickVoid = useCallback(() => {
    onClickVoid(shipment);
  }, [shipment]);

  const handleClickVoidParcels = useCallback(
    async data => {
      await onClickVoidParcels(shipment, data);
    },
    [shipment]
  );
  const handleClickUnvoidParcels = useCallback(
    async data => {
      await onClickUnvoidParcels(shipment, data);
    },
    [shipment]
  );

  const handleClickReprintInvoice = useCallback(() => {
    printInvoice(shipment);
  }, [shipment]);

  const handleReprintByParcelNumber = useCallback(
    async parcelNumber => {
      await printLabelsByParcelNumber(shipment, parcelNumber);
      reloadFn(shipment.shipmentId);
    },
    [shipment]
  );

  const handleClickUnvoid = useCallback(() => {
    onClickUnvoid(shipment);
  }, [shipment]);

  const handleClickDelete = useCallback(() => {
    onClickDelete(shipment);
  }, [shipment]);

  const handleClickPrintShipment = useCallback(() => {
    onShipmentPrint(shipment);
  }, [shipment]);

  const handleClickReturnToList = useCallback(() => {
    history.push({
      pathname: routes.REVIEW,
      search: shipmentListStorageSearchQuery,
    });
  }, [history, shipmentListStorageSearchQuery]);

  const handleChangeDateClick = useCallback(
    () => onClickChangeDate(shipment, changeDateModalToggle.switchOff),
    [shipment]
  );

  const handleClickCopy = useCallback(() => {
    history.push({
      pathname: routes.CREATE,
      state: {
        shipment,
      },
    });
  }, [shipment]);

  const handleClickEdit = useCallback(() => {
    history.push({
      pathname: `/review/${shipmentId}/edit`,
    });
  }, [shipmentId]);

  const changeDateModalToggle = useToggle();

  const reviewCardClasses = {
    title: styles.title,
    body: styles.body,
    card: styles.card,
  };

  const printUnprintedToggle = useToggle();
  const printManifestToggle = useToggle();
  const addExtraLabelToggle = useToggle();

  const closePrintManifest = useCallback(
    async reload => {
      printManifestToggle.switchOff();
      isBoolean(reload) && reload && reloadFn(shipment.shipmentId);
    },
    [reloadFn, shipment.shipmentId]
  );

  const handleAddExtraLabel = useCallback(
    values => {
      const { totalNewParcels, totalWeightOfNewParcels } = values;

      const activeConsignment = isOutboundTab
        ? shipment.outboundConsignment
        : shipment.inboundConsignment;

      onClickAddExtraLabel(
        activeConsignment,
        shipmentId,
        totalNewParcels,
        totalWeightOfNewParcels,
        addExtraLabelToggle.switchOff
      );
    },
    [shipment]
  );

  const handlePrintUnprintedLabels = useCallback(
    async values => {
      const printed = await printUnprintedLabels(values);
      printUnprintedToggle.switchOff();
      isBoolean(printed) && printed && reloadFn(shipment.shipmentId);
    },
    [printUnprintedLabels, reloadFn, shipment.shipmentId]
  );

  const columnsForExport = useMemo(
    () =>
      selectedColumnNames
        .map(value => MAP_COLUMN_NAMES_FOR_EXPORT[value])
        .filter(v => v),
    [selectedColumnNames]
  );

  return (
    <>
      <Main.Body>
        <Legend
          rightMessage={get(authUser, "user.username")}
          classes={{ container: "pb-0" }}
        />
        <Card.Stack fluid>
          <Col>
            <Card>
              <Tabs className={styles.tabs}>
                {!isEmpty(inboundConsignment) ? (
                  <Tabs.Navigation
                    xs={12}
                    className={styles.tabsNavigation}
                    activeKey="0"
                    onClick={selectedKey => {
                      const attribute =
                        selectedKey.target.getAttribute("data-rb-event-key");
                      setActiveTab(attribute);
                    }}
                  >
                    <Tabs.NavItem
                      index={0}
                      key="outbound"
                      className={styles.tabsNavItem}
                    >
                      {S.OUTBOUND}
                    </Tabs.NavItem>
                    <Tabs.NavItem
                      index={1}
                      key="inbound"
                      className={styles.tabsNavItem}
                    >
                      {S.RETURN}
                    </Tabs.NavItem>
                  </Tabs.Navigation>
                ) : (
                  <Col xs={12}>
                    <Card.Title className={styles.title}>
                      {S.OUTBOUND}
                    </Card.Title>
                  </Col>
                )}
                <Tabs.Content>
                  <Tabs.ContentItem
                    index={0}
                    key="outbound"
                    className={styles.tabsContentItem}
                  >
                    <Row>
                      <Col xs={12} md={6} className="p-2">
                        <DetailsCard
                          title={S.COLLECTION_INFORMATION}
                          data={outboundCollectionDetails}
                          className="h-100"
                        />
                      </Col>
                      <Col xs={12} md={6} className="p-2">
                        <DetailsCard
                          title={S.DELIVERY_INFORMATION}
                          data={outboundDeliveryDetails}
                          className="h-100"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6} className="p-2">
                        <DetailsCard
                          title={S.SHIPMENT_DETAILS}
                          data={outboundShipmentDetails}
                          className="h-100"
                        />
                      </Col>
                      <Col xs={12} md={6} className="p-2">
                        <ReviewCard
                          rounded
                          title={
                            <PackageDetailsTitle
                              numberOfParcels={outboundNumberOfParcels}
                              totalWeight={outboundTotalWeight}
                            />
                          }
                          classes={{
                            card: styles.packageDetailsCard,
                            title: styles.packageDetailsCardTitle,
                          }}
                        >
                          <PackageDetailsTable
                            data={outboundParcels}
                            allowPrintShipment={shipment.allowPrintShipment}
                            onClickReprintByParcelNumber={
                              handleReprintByParcelNumber
                            }
                            onClickVoidParcels={handleClickVoidParcels}
                            onClickUnvoidParcels={handleClickUnvoidParcels}
                            showButtonsToolbar
                            actionPermissions={actionPermissions}
                          />
                        </ReviewCard>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs={12}
                        md={showReceiverDetails ? 6 : 12}
                        className="p-2"
                      >
                        <ReviewCard
                          rounded
                          title={S.SENDER_INFORMATION}
                          classes={reviewCardClasses}
                        >
                          <Row>
                            <Col xs={12} md={6}>
                              <KeyValueList
                                data={outboundSenderInformation}
                                variant="vertical"
                              />
                            </Col>
                            <Col xs={12} md={6}>
                              <KeyValueList
                                data={outboundReferences}
                                variant="vertical"
                              />
                            </Col>
                          </Row>
                        </ReviewCard>
                      </Col>
                      {showReceiverDetails && (
                        <Col xs={12} md={6} className="p-2">
                          <ReviewCard title={S.RECEIVER_DETAILS} rounded>
                            <Row>
                              <Col md={12}>
                                <KeyValueList
                                  variant="vertical"
                                  data={receiverDetails}
                                />
                              </Col>
                            </Row>
                          </ReviewCard>
                        </Col>
                      )}
                    </Row>
                    {!isEmpty(products) && (
                      <Row>
                        <Col xs={12} className="p-2">
                          <ReviewCard
                            rounded
                            title={S.CUSTOMS_DETAILS}
                            classes={{
                              card: styles.customsDetailsCard,
                              title: styles.customsDetailsCardTitle,
                            }}
                          >
                            <ProductDetailsTable data={products} />
                          </ReviewCard>
                        </Col>
                      </Row>
                    )}
                    {isReprintInvoiceAllowed && (
                      <Row>
                        <Col xs={12} className="p-2">
                          <InfoCard />
                        </Col>
                      </Row>
                    )}
                  </Tabs.ContentItem>
                  <Tabs.ContentItem
                    index={1}
                    key="inbound"
                    className={styles.tabsContentItem}
                  >
                    <Row>
                      <Col xs={12} md={6} className="p-2">
                        <DetailsCard
                          title={S.COLLECTION_INFORMATION}
                          data={inboundCollectionDetails}
                        />
                      </Col>
                      <Col xs={12} md={6} className="p-2">
                        <DetailsCard
                          title={S.DELIVERY_INFORMATION}
                          data={inboundDeliveryDetails}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6} className="p-2">
                        <ReviewCard
                          rounded
                          title={S.SHIPMENT_DETAILS}
                          classes={reviewCardClasses}
                        >
                          <KeyValueList
                            data={inboundShipmentDetails}
                            variant="vertical"
                          />
                        </ReviewCard>
                      </Col>
                      <Col xs={12} md={6} className="p-2">
                        <ReviewCard
                          rounded
                          title={
                            <PackageDetailsTitle
                              numberOfParcels={inboundNumberOfParcels}
                              totalWeight={inboundTotalWeight}
                            />
                          }
                          classes={{
                            card: styles.packageDetailsCard,
                            title: styles.packageDetailsCardTitle,
                          }}
                        >
                          <PackageDetailsTable
                            data={inboundParcels}
                            allowPrintShipment={shipment.allowPrintShipment}
                            onClickReprintByParcelNumber={
                              handleReprintByParcelNumber
                            }
                          />
                        </ReviewCard>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6} className="p-2">
                        <ReviewCard
                          rounded
                          title={S.SENDER_INFORMATION}
                          classes={reviewCardClasses}
                        >
                          <KeyValueList
                            data={inboundSenderInformation}
                            variant="vertical"
                          />
                        </ReviewCard>
                      </Col>
                    </Row>
                  </Tabs.ContentItem>
                </Tabs.Content>
              </Tabs>
              <Button.Toolbar className={styles.linkButtonGroup}>
                <Button.Toolbar className={styles.linkButtonGroupLeft}>
                  <Button variant="dark" onClick={handleClickReturnToList}>
                    {S.RETURN_TO_LIST}
                  </Button>
                  {activeTab === "0" && allowAddExtraParcel && (
                    <Button
                      variant="dark"
                      onClick={addExtraLabelToggle.switchOn}
                    >
                      {S.ADD_EXTRA_LABEL}
                    </Button>
                  )}
                </Button.Toolbar>
                <Button.Toolbar className={styles.linkButtonGroup}>
                  {isReprintInvoiceAllowed && (
                    <Button variant="dark" onClick={handleClickReprintInvoice}>
                      {S.REPRINT_INVOICE}
                    </Button>
                  )}

                  {isShipmentEditable(shipment, customerPrefs) ? (
                    <Button onClick={handleClickEdit}>{S.EDIT_SHIPMENT}</Button>
                  ) : (
                    allowCopy && (
                      <Button onClick={handleClickCopy}>
                        {S.COPY_SHIPMENT}
                      </Button>
                    )
                  )}
                </Button.Toolbar>
              </Button.Toolbar>
            </Card>
          </Col>
        </Card.Stack>
        <ChangeDateModal
          onSubmit={handleChangeDateClick}
          open={changeDateModalToggle.value}
          onCancel={changeDateModalToggle.switchOff}
          filterDate={filterDate}
          getDatesRange={getDatesRange}
        />
      </Main.Body>
      <Main.Footer className="dark">
        <ActionButtons
          onClickChangeDate={changeDateModalToggle.switchOn}
          onClickHold={handleClickHold}
          onClickUnhold={handleClickUnhold}
          onClickVoid={handleClickVoid}
          onClickUnvoid={handleClickUnvoid}
          onClickDelete={handleClickDelete}
          onClickPrintShipment={handleClickPrintShipment}
          onClickPrintManifest={printManifestToggle.switchOn}
          onClickPrintUnprinted={printUnprintedToggle.switchOn}
          sendToFileHref={getSendToFileHref(columnsForExport)}
          actionPermissions={actionPermissions}
          eodUser={eodUser}
        />
      </Main.Footer>
      <PrintManifestModal
        open={printManifestToggle.value}
        onCancel={closePrintManifest}
      />
      <PrintUnprintedModal
        open={printUnprintedToggle.value}
        onCancel={printUnprintedToggle.switchOff}
        onSubmit={handlePrintUnprintedLabels}
        filterDate={filterDate}
        getDatesRange={getPrintUnprintedDatesRange}
      />
      <AddExtraLabelModal
        open={addExtraLabelToggle.value}
        onCancel={addExtraLabelToggle.switchOff}
        onSubmit={handleAddExtraLabel}
        selectedCountry={{
          countryKey: isOutboundTab ? outboundAddress : inboundAddress,
        }}
        numberOfParcels={
          isOutboundTab ? outboundNumberOfParcels : inboundNumberOfParcels
        }
        outboundTotalWeight={outboundTotalWeight}
        shipmentType={shipmentType}
      />
    </>
  );
};

ShipmentView.propTypes = {
  authUser: PropTypes.object,
  customerPrefs: PropTypes.object,
  shipment: PropTypes.object,
  onClickVoid: PropTypes.func,
  onClickHold: PropTypes.func,
  onClickUnhold: PropTypes.func,
  onClickVoidParcels: PropTypes.func,
  onClickUnvoidParcels: PropTypes.func,
  onClickUnvoid: PropTypes.func,
  onClickDelete: PropTypes.func,
  onShipmentPrint: PropTypes.func,
  filterDate: PropTypes.func,
  getDatesRange: PropTypes.func,
  getPrintUnprintedDatesRange: PropTypes.func,
  history: PropTypes.object,
  enableWeekend: PropTypes.bool,
  eodUser: PropTypes.bool,
  onClickChangeDate: PropTypes.func,
  reloadFn: PropTypes.func,
  printUnprintedLabels: PropTypes.func,
  getSendToFileHref: PropTypes.func,
  printInvoice: PropTypes.func,
  printLabelsByParcelNumber: PropTypes.func,
  securitySettings: PropTypes.object,
  onClickAddExtraLabel: PropTypes.func,
  advancedCustomerConfiguration: PropTypes.object,
  preferences: PropTypes.object,
  selectedColumnNames: PropTypes.array,
  shipmentListStorageSearchQuery: PropTypes.string,
};

export default compose(
  withRouter,
  withAppContext,
  withAppUserPreferences,
  withSnackbar,
  connect(
    (state, { app, location }) => ({
      enableWeekend: AuthSelectors.getEnableWeekend(state),
      preferences: UmsSelectors.getPreferences(state),
      customerPrefs: UmsSelectors.getCustomerPrefs(state),
      securitySettings: UmsSelectors.getSecuritySettings(state),
      getSendToFileHref: columns =>
        getExportShipmentsCsvHref(
          app.apis.getApisBaseUrl(),
          location.search,
          columns
        ),
      advancedCustomerConfiguration:
        UmsSelectors.getAdvancedCustomerConfiguration(state),
      selectedColumnNames:
        UserDataSelectors?.getItem(state.app, "shipmentColumns") ||
        DEFAULT_SHIPMENT_COLUMNS,
      shipmentListStorageSearchQuery: UserSessionDataSelectors.getDataItem(
        "shipmentListSearchQuery"
      )(state),
      eodUser: UmsSelectors.isEodUser(state),
    }),
    dispatch => ({
      addExtraLabel: (shipmentId, numberOfParcels, totalWeight) =>
        dispatch(
          ShipmentReviewActions.updateShipmentParcels(shipmentId, {
            numberOfParcels,
            totalWeight,
          })
        ),
    })
  ),
  withShipmentUpdateActions,
  withHandlers({
    onClickAddExtraLabel: ({
      snackbar,
      notifier,
      reloadFn,
      overlay,
      addExtraLabel,
      printExtraLabelsWithoutInvoice,
    }) =>
      notifier.runAsync(
        async (
          activeConsignment,
          shipmentId,
          totalNewParcels,
          totalWeightOfNewParcels,
          onSuccess = noop
        ) => {
          try {
            overlay.show();
            const result = await addExtraLabel(
              shipmentId,
              totalNewParcels,
              totalWeightOfNewParcels
            );

            // We only need to print labels for new parcels so we are taking all parcels
            // and remove old ones that are already printed
            const currentParcelsNumbers = map(
              activeConsignment.parcels,
              val => val.parcelNumber
            );
            const updatedParcelsNumbers = get(
              result,
              "data.consignments[0].parcelNumber",
              []
            );
            const addedParcelsNumbers = difference(
              updatedParcelsNumbers,
              currentParcelsNumbers
            );

            onSuccess();
            await printExtraLabelsWithoutInvoice(
              shipmentId,
              addedParcelsNumbers
            );
            await reloadFn(shipmentId);

            snackbar.showSuccess({
              message: S.SHIPMENT_LABELS_ADDED_MESSAGE,
            });
          } catch (e) {
            snackbar.showAlert({
              message: getErrorMessage(e, S.SHIPMENT_LABELS_ADDED),
              displayTime: SHOW_ALERT_DISPLAY_TIME,
            });
          } finally {
            overlay.hide();
          }
        },
        { entityName: S.SHIPMENT }
      ),
    filterDate:
      ({ preferences }) =>
      date =>
        getValue(preferences, "shippingDefaults.enableWeekend", false) ||
        !isWeekend(date),
    getDatesRange:
      ({ preferences, advancedCustomerConfiguration }) =>
      () =>
        ShipmentModels.getAvailableDateRange(
          getValue(preferences, "shippingDefaults.enableWeekend", false),
          getValue(advancedCustomerConfiguration, "forwardDateOver7Days", false)
        ),
    getPrintUnprintedDatesRange:
      ({ preferences }) =>
      () =>
        // NOTE: always without advancedCustomerConfiguration! (allow select only 5-7 days)
        ShipmentModels.getAvailableDateRange(
          getValue(preferences, "shippingDefaults.enableWeekend", false)
        ),
  }),
  lifecycle({
    componentDidMount() {
      const { location, banner, history } = this.props;
      const error = get(location, "state.error");

      if (error) {
        banner.showByType(BANNERS_TYPES.ALERT, {
          message: error,
          closable: true,
          showIcon: true,
          actions: [],
        });
        // Cleanup error from the history state
        history.replace({
          pathname: location.pathname,
          state: {},
        });
      }
    },
    componentWillUnmount() {
      this.props.banner.hideByType(BANNERS_TYPES.SUCCESS);
      this.props.banner.hideByType(BANNERS_TYPES.ALERT);
    },
  })
)(ShipmentView);
