import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import { productBookApi } from "~/apis";

export const ProductBookActionTypes = createActionTypes("PRODUCT_BOOK", {
  FETCH_PRODUCT_BOOKS: createAsyncActionTypes("FETCH_PRODUCT_BOOKS"),
  FETCH_PRODUCT_BOOK_BY_ID: createAsyncActionTypes("FETCH_PRODUCT_BOOK_BY_ID"),
  CREATE_UPDATE: createAsyncActionTypes("CREATE_UPDATE"),
});

export const fetchProductBooks = createAsyncAction(
  (query, fetchOptions) =>
    productBookApi
      .fetchProductBooks(query, fetchOptions)
      .then(({ data: { results } }) => results),
  ProductBookActionTypes.FETCH_PRODUCT_BOOKS
);

export const fetchProductBookById = createAsyncAction(
  (id, query, options) =>
    productBookApi.fetchProductBookById(id, query, options),
  ProductBookActionTypes.FETCH_PRODUCT_BOOK_BY_ID
);

export const createOrUpdateProductBook = createAsyncAction(
  (productBookId, body) => async () => {
    let result;

    if (productBookId) {
      result = await productBookApi.updateProductBook(productBookId, body);
    } else {
      result = await productBookApi.insertProductBook(body);
    }

    return result;
  },
  ProductBookActionTypes.CREATE_UPDATE
);
