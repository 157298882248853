import { get, isFunction } from "lodash";
import moment from "moment";

import { DEFAULT_DATE_FORMAT, ISO_DATE_FORMAT } from "~/constants/dateFormats";
import { ShipmentEntity } from "~/constants/forms";
import * as S from "~/constants/strings";
import { roundToDecimal } from "~/utils/number";
import { getValue } from "~/utils/object";
import { formatMessage } from "~/utils/string";

const getBoolToStringByPath = (data, path) =>
  S.BOOLEAN_TO_STRING[getValue(data, path, false)];

const ADDRESS_CONTACT_TITLES_MAP = [
  {
    name: S.COUNTRY,
    path: "address.countryCode",
  },
  {
    name: S.POSTCODE,
    path: "address.postcode",
  },
  {
    name: S.ORGANISATION_OR_NAME,
    path: "address.organisation",
  },
  {
    name: S.ADDRESS_LINE_1,
    path: "address.street",
  },
  {
    name: S.ADDRESS_LINE_2,
    path: "address.locality",
  },
  {
    name: S.CITY,
    path: "address.town",
  },
  {
    name: S.COUNTY,
    path: "address.county",
  },
];

const ADDRESS_CONTACT_TITLES_THIRD_COLUMN_MAP = [
  {
    name: S.CONTACT,
    path: "contactDetails.contactName",
  },
  {
    name: S.TELEPHONE,
    path: "contactDetails.telephone",
  },
];

const OUTBOUND_SHIPMENT_DETAILS_MAP_FIRST_COLUMN = [
  {
    name: S.CONSIGNMENT_NUMBER,
    path: "outboundConsignment.consignmentNumber",
  },
  {
    name: `${S.PRODUCT} & ${S.SERVICE}`,
    path: "outboundConsignment.networkDescription",
  },
  {
    name: S.SHIPMENT_DATE,
    path: shipment =>
      moment(getValue(shipment, "shipmentDate", ""), ISO_DATE_FORMAT).format(
        DEFAULT_DATE_FORMAT
      ),
  },
  {
    name: S.PRINTED,
    path: shipment => getBoolToStringByPath(shipment, "isPrinted"),
  },
  {
    name: S.VOIDED,
    path: shipment => getBoolToStringByPath(shipment, "isVoided"),
  },
];

const OUTBOUND_SHIPMENT_DETAILS_MAP_SECOND_COLUMN = [
  {
    name: S.EXTENDED_LIABILITY,
    path: shipment =>
      getBoolToStringByPath(
        shipment,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.LIABILITY
      ),
  },
];

const OUTBOUND_SHIPMENT_DETAILS_MAP_THIRD_COLUMN = [
  {
    name: S.MANIFESTED,
    path: shipment => getBoolToStringByPath(shipment, "isManifested"),
  },
  {
    name: S.ADDITIONAL_INFO,
    path: "outboundConsignment.deliveryInstructions",
  },
];

const SENDER_INFORMATION_MAP = [
  {
    name: S.ACCOUNT,
    path: "shippingAccount",
  },
  {
    name: S.NOTIFICATION_EMAIL,
    path: "outboundConsignment.deliveryDetails.notificationDetails.email",
  },
  {
    name: S.NOTIFICATION_TEXT,
    path: "outboundConsignment.deliveryDetails.notificationDetails.mobile",
  },
  {
    name: S.PROFILE_CODE,
    path: "profileCode",
  },
  {
    name: S.PROFILE_NAME,
    path: "profileName",
  },
];

const REFERENCES_MAP = [
  {
    name: S.YOUR_REFERENCE,
    path: "shippingRef1",
  },
  {
    path: "shippingRef2",
  },
  {
    path: "shippingRef3",
  },
];

const INBOUND_SHIPMENT_DETAILS_MAP = [
  {
    name: S.CONSIGNMENT_NUMBER,
    path: "inboundConsignment.consignmentNumber",
  },
  {
    name: `${S.PRODUCT} & ${S.SERVICE}`,
    path: "inboundConsignment.networkDescription",
  },
  {
    name: S.SHIPMENT_DATE,
    path: shipment =>
      moment(getValue(shipment, "shipmentDate", ""), ISO_DATE_FORMAT).format(
        DEFAULT_DATE_FORMAT
      ),
  },
  {
    name: S.PRINTED,
    path: shipment => getBoolToStringByPath(shipment, "isPrinted"),
  },
  {
    name: S.MANIFESTED,
    path: shipment => getBoolToStringByPath(shipment, "isManifested"),
  },
  {
    name: S.ADDITIONAL_INFO,
    path: "inboundConsignment.deliveryInstructions",
  },
];

const PICKUP_DETAILS_MAP = [
  {
    name: S.PICKUP_LOCATION_CODE,
    path: ShipmentEntity.OUTBOUND_CONSIGNMENT.PICKUP_DETAILS
      .PICKUP_LOCATION_CODE,
  },
  {
    name: S.ORGANISATION_OR_NAME,
    path: ShipmentEntity.OUTBOUND_CONSIGNMENT.PICKUP_DETAILS
      .PICKUP_ORGANISATION,
  },
  {
    name: formatMessage(S.ADDRESS_$, 1),
    path: ShipmentEntity.OUTBOUND_CONSIGNMENT.PICKUP_DETAILS
      .PICKUP_ADDRESS_LINE1,
  },
  {
    name: formatMessage(S.ADDRESS_$, 2),
    path: ShipmentEntity.OUTBOUND_CONSIGNMENT.PICKUP_DETAILS
      .PICKUP_ADDRESS_LINE2,
  },
  {
    name: S.CITY,
    path: ShipmentEntity.OUTBOUND_CONSIGNMENT.PICKUP_DETAILS
      .PICKUP_ADDRESS_LINE3,
  },
  {
    name: S.COUNTY,
    path: ShipmentEntity.OUTBOUND_CONSIGNMENT.PICKUP_DETAILS
      .PICKUP_ADDRESS_LINE4,
  },
  {
    name: S.POSTCODE,
    path: ShipmentEntity.OUTBOUND_CONSIGNMENT.PICKUP_DETAILS.PICKUP_POST_CODE,
  },
];

const mapShipmentFieldsForReview = (data, config) =>
  config.map(({ name, path }) => ({
    name: isFunction(name) ? name(data) : name,
    value: isFunction(path) ? path(data) : getValue(data, path, ""),
  }));

export const mapAddressAndContactInformation = details => {
  const firstColumn = mapShipmentFieldsForReview(
    details,
    ADDRESS_CONTACT_TITLES_MAP
  );
  const thirdColumn = mapShipmentFieldsForReview(
    details,
    ADDRESS_CONTACT_TITLES_THIRD_COLUMN_MAP
  );
  return [firstColumn, [], thirdColumn];
};

export const mapOutboundShipmentDetails = shipment => {
  const currency =
    getValue(shipment, ShipmentEntity.OUTBOUND_CONSIGNMENT.CURRENCY) || S.GBP;

  const firstColumn = mapShipmentFieldsForReview(
    shipment,
    OUTBOUND_SHIPMENT_DETAILS_MAP_FIRST_COLUMN
  );

  const secondColumn = mapShipmentFieldsForReview(
    shipment,
    OUTBOUND_SHIPMENT_DETAILS_MAP_SECOND_COLUMN
  );

  const thirdColumn = mapShipmentFieldsForReview(
    shipment,
    OUTBOUND_SHIPMENT_DETAILS_MAP_THIRD_COLUMN
  );

  if (get(shipment, ShipmentEntity.OUTBOUND_CONSIGNMENT.LIABILITY_VALUE)) {
    secondColumn.push({
      name: formatMessage(S.LIABILITY_VALUE_$, currency),
      value: roundToDecimal(
        getValue(
          shipment,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.LIABILITY_VALUE,
          ""
        )
      ),
    });
  }

  if (get(shipment, ShipmentEntity.OUTBOUND_CONSIGNMENT.CUSTOMS_VALUE)) {
    thirdColumn.push(
      {
        name: formatMessage(S.CUSTOMS_VALUE_$, currency),
        value: roundToDecimal(
          get(shipment, ShipmentEntity.OUTBOUND_CONSIGNMENT.CUSTOMS_VALUE)
        ),
      },
      {
        name: S.CONTENTS,
        value: getValue(
          shipment,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DESCRIPTION,
          ""
        ),
      }
    );
  }

  return [firstColumn, secondColumn, thirdColumn];
};

export const mapInboundShipmentDetails = shipment =>
  mapShipmentFieldsForReview(shipment, INBOUND_SHIPMENT_DETAILS_MAP);

export const mapSenderInformation = shipment =>
  mapShipmentFieldsForReview(shipment, SENDER_INFORMATION_MAP);

export const mapReferences = data =>
  mapShipmentFieldsForReview(data, REFERENCES_MAP);

export const mapPickupLocation = shipment =>
  mapShipmentFieldsForReview(shipment, PICKUP_DETAILS_MAP);
