import Modal from "../../../../components/ShipmentReviewModal";
import PropTypes from "prop-types";
import * as S from "~/constants/strings";
import styles from "./ReimportModal.module.scss";
import { Col, Container, Row } from "react-bootstrap";
import { compose, lifecycle } from "recompose";
import { Field, reduxForm, reset } from "redux-form";
import { FAILURE_DETAILS, REIMPORT_FORM } from "~/constants/forms";
import { Form } from "@dpdgroupuk/mydpd-ui";
import { connect } from "react-redux";
import { useCallback } from "react";
import * as routes from "~/router/constants";
import * as ImporterActions from "~/features/Importer/redux/actions";

const ReimportModal = ({
  open,
  onCancel,
  clearForm,
  handleSubmit,
  onSubmit,
}) => {
  const onclickCancel = useCallback(() => {
    onCancel();
    clearForm();
  }, [onCancel, clearForm]);

  return (
    <Modal
      open={open}
      title={S.CLICK_RETRY}
      saveButton={{ visible: true, text: S.RETRY }}
      onCancel={onclickCancel}
      onSave={handleSubmit(onSubmit)}
      size="lg"
    >
      <Container>
        <Row>
          <Col sm={12}>{S.NAVIGATE_TO_IMPORT_ACTIVITY_PAGE}</Col>
          <Col sm={12}>
            <Field
              component={Form.MaterialTextInput}
              name={FAILURE_DETAILS}
              rows={4}
              multiline
              classes={{
                input: styles.textarea,
              }}
            />
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

ReimportModal.propTypes = {
  open: PropTypes.bool,
  onSubmit: PropTypes.func,
  handleSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  clearForm: PropTypes.func,
};

export default compose(
  connect(null, (dispatch, { history }) => ({
    clearForm: () => dispatch(reset(REIMPORT_FORM)),
  })),
  reduxForm({
    form: REIMPORT_FORM,
    onSubmit: (values, dispatch, { history }) => {
      dispatch(ImporterActions.setReimportData(values));
      history.push(routes.IMPORT_ACTIVITY);
    },
  }),
  lifecycle({
    componentWillUnmount() {
      this.props.clearForm();
    },
  })
)(ReimportModal);
