import { ActionTypes } from "~/pages/FailedImports/redux/actions";

export const initialState = {
  failedImportsList: [],
  selectedShipment: {
    data: {},
    isLoading: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH.SUCCESS: {
      return {
        ...state,
        failedImportsList: action.payload,
      };
    }
    case ActionTypes.FETCH_BY_ID.REQUEST: {
      return {
        ...state,
        selectedShipment: {
          isLoading: true,
        },
      };
    }
    case ActionTypes.FETCH_BY_ID.FAILURE: {
      return {
        ...state,
        selectedShipment: {
          isLoading: false,
        },
      };
    }
    case ActionTypes.FETCH_BY_ID.SUCCESS: {
      return {
        ...state,
        selectedShipment: {
          data: action.payload,
          isLoading: false,
        },
      };
    }
    default:
      return state;
  }
};
