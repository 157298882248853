import PropTypes from "prop-types";
import { Col, Container, Row } from "react-bootstrap";
import { formatMessage, joinStringsWithComma } from "~/utils/string";
import { ERRORS_ON_ADDRESS_$ } from "~/constants/strings";

const ValidationErrors = ({ data: { shortName, errors } }) => (
  <Container fluid>
    <Row>
      <Col xs={12} className="pl-0">
        {formatMessage(ERRORS_ON_ADDRESS_$, shortName)}
      </Col>
      <Col xs={12} className="pl-0">
        {joinStringsWithComma(errors)}
      </Col>
    </Row>
  </Container>
);

ValidationErrors.propTypes = {
  data: PropTypes.object,
};

ValidationErrors.defaultProps = {
  data: {},
};
export default ValidationErrors;
