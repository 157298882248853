import { ShipmentEntity } from "~/constants/forms";
import { assignWithDictionary } from "~/utils/object";

const importerRelatedFields = {
  [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTRY_CODE]:
    ShipmentEntity.INVOICE.IMPORTER_DETAILS.ADDRESS.COUNTRY_CODE,
  [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE]:
    ShipmentEntity.INVOICE.IMPORTER_DETAILS.ADDRESS.POSTCODE,
  [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.ORGANISATION]:
    ShipmentEntity.INVOICE.IMPORTER_DETAILS.ADDRESS.ORGANISATION,
  [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.TOWN]:
    ShipmentEntity.INVOICE.IMPORTER_DETAILS.ADDRESS.TOWN,
  [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTY]:
    ShipmentEntity.INVOICE.IMPORTER_DETAILS.ADDRESS.COUNTY,
  [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.STREET]:
    ShipmentEntity.INVOICE.IMPORTER_DETAILS.ADDRESS.STREET,
  [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.LOCALITY]:
    ShipmentEntity.INVOICE.IMPORTER_DETAILS.ADDRESS.LOCALITY,
  [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.CONTACT_DETAILS
    .CONTACT_NAME]:
    ShipmentEntity.INVOICE.IMPORTER_DETAILS.CONTACT_DETAILS.CONTACT_NAME,
  [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.CONTACT_DETAILS
    .TELEPHONE]:
    ShipmentEntity.INVOICE.IMPORTER_DETAILS.CONTACT_DETAILS.TELEPHONE,
  [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.NOTIFICATION_DETAILS
    .EMAIL]: ShipmentEntity.INVOICE.IMPORTER_DETAILS.CONTACT_DETAILS.EMAIL,
};

export default (state, action) => {
  switch (action.type) {
    case "@@redux-form/BLUR": {
      if (
        state.values?.generateCustomsData &&
        importerRelatedFields[action.meta.field]
      ) {
        const values = assignWithDictionary({
          destination: state.values,
          source: state.values,
          dictionary: importerRelatedFields,
          customizer: (targetValue, srcValue) => srcValue || targetValue,
        });

        return {
          ...state,
          values,
        };
      }

      return state;
    }
    case "@@redux-form/CHANGE": {
      if (
        action.meta.field === ShipmentEntity.GENERATE_CUSTOMS_DATA &&
        action.payload
      ) {
        const values = assignWithDictionary({
          destination: state.values,
          source: state.values,
          dictionary: importerRelatedFields,
          customizer: (targetValue, srcValue) => srcValue || targetValue,
        });

        return {
          ...state,
          values,
        };
      }

      return state;
    }
    default:
      return state;
  }
};
