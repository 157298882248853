import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { usePagination, useRowSelect } from "react-table/src";
import { Field } from "redux-form";
import { DndTable, FormControl } from "@dpdgroupuk/mydpd-ui";

import ShipmentCard from "~/components/ShipmentCard";
import { TOOLS_FIELDS } from "~/constants/forms";
import * as S from "~/constants/strings";
import { GroupDispatchModels } from "~/pages/Tools/pages/GroupDispatch/models";

import styles from "./GroupsCard.module.scss";
import { useMemo } from "react";

import { isElectronEnvironment } from "~/utils/checkUserAgent";
import classNames from "classnames";
import { useUserHeaderStatePersist } from "@dpdgroupuk/mydpd-app";

const GroupsCard = props => {
  const columns = useMemo(
    () => GroupDispatchModels.GROUP_DISPATCH_COLUMNS,
    [GroupDispatchModels.GROUP_DISPATCH_COLUMNS]
  );

  const stretchColumnWidth = useMemo(
    () =>
      props.selectedColumnNames.length >=
      GroupDispatchModels.GROUP_DISPATCH_COLUMNS.length,
    [props.selectedColumnNames, GroupDispatchModels.GROUP_DISPATCH_COLUMNS]
  );

  return (
    <ShipmentCard
      title={props.title}
      classes={{
        container: styles.cardContainer,
      }}
    >
      <Row>
        <Col xs={12}>
          {!props.isHiddenProfile && (
            <Field
              component={FormControl.Dropdown}
              label={S.PROFILE}
              name={TOOLS_FIELDS.PROFILE_CODE}
              values={props.profiles}
              textTransform={S.UPPERCASE}
            />
          )}
        </Col>
        <Col xs={12}>
          <Field
            component={FormControl.Dropdown}
            label={S.GROUP}
            name={TOOLS_FIELDS.GROUP}
            values={props.addressbookGroups}
            textTransform={S.UPPERCASE}
            placeholder={S.PLEASE_SELECT}
            onChange={props.onGroupsChange}
            disabled={!props.addressbookGroups.length}
          />
        </Col>
        <Col xs={12}>
          <DndTable
            data={props.groupAddresses}
            columns={columns}
            classes={{
              container: classNames(
                !props.isHiddenProfile
                  ? styles.tableContainer
                  : styles.higherTableContainer,
                isElectronEnvironment() &&
                  (!props.isHiddenProfile
                    ? styles.electronTableContainer
                    : styles.higherElectronTableContainer)
              ),
              table: classNames(
                props.groupAddresses.length && styles.table,
                isElectronEnvironment() && styles.electronTable
              ),
            }}
            tableHooks={[
              useUserHeaderStatePersist,
              usePagination,
              useRowSelect,
            ]}
            initialState={{ pageSize: 16, storageKey: "groupDispatchColumns" }}
            onChangeColumnsSelection={props.onChangeColumnsSelection}
            getSelectedRows={props.setSelectedAddresses}
            selectable
            isVisiblePopover
            hideSelectionInPopover
            stretchColumnWidth={stretchColumnWidth}
            autoSelectAllRows
            useHookPagination
          ></DndTable>
        </Col>
      </Row>
    </ShipmentCard>
  );
};

GroupsCard.propTypes = {
  onGroupsChange: PropTypes.func,
  setSelectedAddresses: PropTypes.func,
  onChangeColumnsSelection: PropTypes.func,
  addressbookGroups: PropTypes.array,
  profiles: PropTypes.array,
  selectedColumnNames: PropTypes.array,
  groupAddresses: PropTypes.array,
  title: PropTypes.string,
};

export default GroupsCard;
