import moment from "moment";

import { Lock, Pen } from "~/components/Icon";
import { DEFAULT_DATE_FORMAT } from "~/constants/dateFormats";
import { getValue } from "~/utils/object";

import { FAILED_IMPORTS_COLUMN_ACCESSORS } from "./constants";

export const ALWAYS_VISIBLE_FAILED_IMPORTS_COLUMNS = [
  "selection",
  "view",
  "edit",
];

export const getFailedImportsColumns = onEditShipmentClick => [
  {
    Header: "Date",
    accessor: FAILED_IMPORTS_COLUMN_ACCESSORS.SHIPMENT_DATE,
    Cell: ({ row: { original } }) =>
      moment(getValue(original, "shipmentDetails.shipmentDate", "")).format(
        DEFAULT_DATE_FORMAT
      ),
    minWidth: 75,
    width: 75,
    defaultCanSort: true,
  },
  {
    Header: "Postcode",
    accessor: FAILED_IMPORTS_COLUMN_ACCESSORS.POSTCODE,
    minWidth: 90,
    width: 90,
    defaultCanSort: true,
  },
  {
    Header: "Reference 1",
    accessor: FAILED_IMPORTS_COLUMN_ACCESSORS.SHIPPING_REF_1,
    minWidth: 100,
    defaultCanSort: true,
  },
  {
    Header: "Reference 4",
    accessor: FAILED_IMPORTS_COLUMN_ACCESSORS.SHIPPING_REF_4,
    minWidth: 100,
  },
  {
    Header: "Failure Reason",
    accessor: FAILED_IMPORTS_COLUMN_ACCESSORS.FAILURE_REASON,
    minWidth: 175,
    defaultCanSort: true,
  },
  {
    Header: "Edit",
    accessor: "edit",
    minWidth: 75,
    width: 90,
    // eslint-disable-next-line react/prop-types
    Cell: ({ row: { original } }) => {
      const isShipmentEditable = () => true;
      return isShipmentEditable(original) ? (
        <button
          onClick={onEditShipmentClick(getValue(original, "stagingShipmentId"))}
        >
          <Pen />
        </button>
      ) : (
        <Lock />
      );
    },
  },
];
