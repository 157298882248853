import Joi from "joi";

import {
  contactDetails,
  countryCode,
  notificationDetails,
  postcode,
  shippingRef1,
  shippingRef2,
  shippingRef3,
  street,
  town,
} from "./commonSchema";

const deliveryDetails = Joi.object({
  address: Joi.object({
    countryCode,
    street,
    town,
    postcode,
  }),
  contactDetails,
  notificationDetails,
});

const outboundConsignment = Joi.object({
  deliveryDetails,
  shippingRef1,
  shippingRef2,
  shippingRef3,
});

export const addressBookSchema = Joi.object().keys({
  outboundConsignment,
});
