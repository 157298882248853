import PropTypes from "prop-types";

import { Button, Modal } from "@dpdgroupuk/mydpd-ui";

import * as S from "~/constants/strings";

const ShipmentReviewModal = ({
  open,
  title,
  children,
  onCancel,
  onSave,
  className,
  saveButton,
  cancelButton,
  size,
}) => (
  <Modal
    id={`shipment-review-modal-${title}`}
    dialogClassName={className}
    show={open}
    onHide={onCancel}
    size={size}
  >
    <Modal.Header onHide={onCancel}>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>{children}</Modal.Body>
    <Modal.Footer>
      {cancelButton.visible && (
        <Button variant="dark" onClick={onCancel}>
          {cancelButton.text}
        </Button>
      )}
      {saveButton.visible && (
        <Button variant="primary" onClick={onSave}>
          {saveButton.text}
        </Button>
      )}
    </Modal.Footer>
  </Modal>
);

ShipmentReviewModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  title: PropTypes.string,
  size: PropTypes.string,
  cancelButton: PropTypes.shape({
    text: PropTypes.string,
    visible: PropTypes.bool,
  }),
  saveButton: PropTypes.shape({
    text: PropTypes.string,
    visible: PropTypes.bool,
  }),
  children: PropTypes.node,
  className: PropTypes.string,
};

ShipmentReviewModal.defaultProps = {
  cancelButton: {
    text: S.CANCEL,
    visible: true,
  },
  saveButton: {
    text: S.SAVE,
    visible: true,
  },
  size: "sm",
};

export default ShipmentReviewModal;
