import classNames from "classnames";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import parseHtml from "react-html-parser";

import ReviewCard from "~/components/ReviewCard";
import styles from "~/components/ReviewCard/cards/Card.module.scss";

import { CustomsNoticeTitle } from "../../Title";

const CustomsDataCard = ({ message, className }) => (
  <ReviewCard
    title={<CustomsNoticeTitle />}
    classes={{ card: classNames(className) }}
    rounded
  >
    <Row>
      <Col md={12}>
        <p className={classNames([styles.text, styles.uppercase])}>
          {parseHtml(message)}
        </p>
      </Col>
    </Row>
  </ReviewCard>
);

CustomsDataCard.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
};

export default CustomsDataCard;
