import isEqual from "lodash/isEqual";
import { compose, lifecycle } from "recompose";

import { BANNERS_TYPES } from "@dpdgroupuk/mydpd-enums";

export default compose(
  lifecycle({
    componentDidUpdate(prevProps) {
      if (
        (!prevProps.hasSubmitFailed && this.props.hasSubmitFailed) ||
        (!isEqual(prevProps.parcelsErrors, this.props.parcelsErrors) &&
          !isEqual(
            this.props.pageFormState.initial,
            this.props.pageFormState.values
          )) // TODO: check this case
      ) {
        this.props.banner.hideByType(BANNERS_TYPES.ALERT);

        if (this.props.parcelsErrors.length) {
          // TODO: get banner id and hide if no errors
          // add white-space: pre;
          // hide banner on unmount
          this.props.banner.showByType(BANNERS_TYPES.ALERT, {
            message: this.props.parcelsErrors.join("\n"),
            closable: true,
            showIcon: true,
            actions: [],
          });
        }
      }
    },
  })
);
