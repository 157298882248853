import * as S from "~/constants/strings";

export const GROUP_DISPATCH_PATH = "/tools/group-dispatch";
export const BULK_PATH = "/tools/bulk-reverse";
export const TOOLS_MENU_PATH = "/tools/tools";
export const MENU_ITEMS = [
  {
    href: TOOLS_MENU_PATH,
    name: S.TOOLS,
  },
  {
    href: GROUP_DISPATCH_PATH,
    name: S.GROUP_DISPATCH,
  },
  {
    href: BULK_PATH,
    name: S.BULK_REVERSE_IT,
  },
];
