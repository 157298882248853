import { useMemo } from "react";

import { compose } from "redux";
import { propTypes } from "redux-form";

import { Step } from "@dpdgroupuk/mydpd-ui";

import BaseAddressForm from "~/components/BaseAddressForm";
import {
  INBOUND_CONSIGNMENT_UPPERCASE,
  RETURN_DETAILS,
  RETURN_DETAILS_SEARCH_FORM,
} from "~/constants/forms";
import help from "~/constants/info";
import * as S from "~/constants/strings";
import { ShipmentModels } from "~/models";

const ReturnDetails = props => {
  const { createShipmentSyncErrors } = props;
  const isValidDeliveryDetailsSection = useMemo(
    () => ShipmentModels.isValidReturnDetailsSection(createShipmentSyncErrors),
    [createShipmentSyncErrors]
  );

  return (
    <Step
      withStepCounter
      totalSteps={props.totalSteps}
      step={props.step}
      complete={!props.pristine && isValidDeliveryDetailsSection}
      help={help[RETURN_DETAILS]}
      title={S.RETURN_DETAILS_TITLE}
      helpModalTitle={S.RETURN_DETAILS_TITLE}
    >
      <BaseAddressForm
        {...props}
        selectedCountry={props.selectedReturnCountry}
        countries={props.returnCountries}
        shipmentSection={INBOUND_CONSIGNMENT_UPPERCASE}
        searchForm={RETURN_DETAILS_SEARCH_FORM}
        addressbookType={S.ADDRESS_BOOK_TYPES.RETURN}
      />
    </Step>
  );
};

ReturnDetails.propTypes = {
  ...propTypes,
};

export default HOC => compose(HOC)(ReturnDetails);
