import Joi from "joi";

import { joiExtension } from "@dpdgroupuk/mydpd-express-helpers/utils";

import { CONTACT_DETAILS, Fields } from "~/constants/forms";
import * as S from "~/constants/strings";
import { formatMessage } from "~/utils/string";

import {
  contactName,
  countryCode,
  county,
  email,
  importerEoriNumber,
  importerTelephone,
  isBusiness,
  locality,
  organisation,
  pidNumber,
  postcode,
  street,
  town,
  vatNumber,
} from "./commonSchema";

export default Joi.object().keys({
  address: Joi.object({
    organisation,
    street,
    locality,
    town,
    county,
    postcode,
    countryCode: countryCode.label(S.IMPORTER_COUNTRY),
  }),
  [Fields.VAT_NUMBER]: vatNumber.label(
    formatMessage(S.$_$_NUMBER, S.IMPORTERS, S.VAT)
  ),
  [CONTACT_DETAILS]: Joi.object({
    telephone: importerTelephone,
    contactName,
    email: email.error(
      joiExtension.messages({
        "any.required": formatMessage(
          S.$_MUST_BE_ENTERED,
          S.IMPORTER_EMAIL_OR_IMPORTER_TELEPHONE
        ),
      })
    ),
  }),
  [Fields.PID_NUMBER]: pidNumber,
  [Fields.EORI_NUMBER]: importerEoriNumber,
  [Fields.IS_BUSINESS]: isBusiness,
});
