/* eslint-disable react/prop-types */
import classNames from "classnames";
import moment from "moment";
import PropTypes from "prop-types";
import { useFlexLayout } from "react-table";

import { Button, Card, DndTable, usePrompt } from "@dpdgroupuk/mydpd-ui";

import { COLOR_LEVEL } from "~/constants/importActivity";
import * as S from "~/constants/strings";

import styles from "./ImportActivityTable.module.scss";

const ImportActivityTable = props => {
  const fieldOrderColumns = [
    {
      accessor: "message",
      minWidth: 500,
      width: 700,
      Cell: ({ row: { original } }) => (
        <div className={classNames("h-100 px-3 d-flex align-items-center")}>
          {original.message}
        </div>
      ),
    },
    {
      accessor: "timestamp",
      minWidth: 130,
      width: 167,
      Cell: ({ row: { original } }) => (
        <div
          className={classNames(
            "h-100 px-3 d-flex align-items-center justify-content-end"
          )}
        >
          {moment(new Date(original.timestamp)).format("DD/MM/YYYY - HH:mm:ss")}
        </div>
      ),
    },
  ];

  const { showConfirmation } = usePrompt();

  return (
    <Card>
      <Card.Title
        className={classNames(
          "mb-4 d-flex justify-content-between",
          props.cardTitleClassName
        )}
      >
        {S.IMPORT_ACTIVITY}
      </Card.Title>

      <DndTable
        data={props.messages}
        columns={fieldOrderColumns}
        classes={{
          table: styles.table,
          container: styles.tableContainer,
        }}
        getRowProps={row => {
          const original = row.original;
          return {
            style: {
              background: COLOR_LEVEL[original.level],
            },
          };
        }}
        tableHooks={[useFlexLayout]}
      />
      <div className={styles.footer}>
        <Button
          variant="dark"
          className="align-self-end"
          disabled={!props.messages.length}
          onClick={() =>
            showConfirmation({
              header: S.ARE_YOU_SURE,
              message: S.CLEAR_IMPORT_ACTIVITY_MESSAGE,
              closeButtonText: S.CANCEL,
              confirmButtonText: S.YES,
              onConfirm: () => props.onClear(),
            })
          }
        >
          {S.CLEAR_ACTIVITY}
        </Button>
      </div>
    </Card>
  );
};

ImportActivityTable.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.object),
  onClear: PropTypes.func,
};

export default ImportActivityTable;
