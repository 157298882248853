import { EventLogger, LOG_LEVEL } from "@dpdgroupuk/mydpd-app";
import { fill } from "lodash";

export const getEntryRow = entry => entry.row + 1;

export const processImportResults = (
  jobId,
  result,
  { shipmentTemplate, shippingSettings }
) => {
  const importIgnoreHeader = shipmentTemplate.ignoreHeader;
  const importHeaderLines = shipmentTemplate.headerLines;

  let errorLineNumber =
    (importIgnoreHeader && importHeaderLines) || importHeaderLines
      ? 1 + (importHeaderLines || 1)
      : 1;

  return result.entries.reduce(
    (acc, entry) => {
      const warning = entry.shipmentId && entry.failureReasons.length;
      const failed = !entry.shipmentId && !entry.success;
      entry.failureReasons.forEach(failureReason => {
        if (warning) {
          acc.warnings.push(
            EventLogger.logEntry(
              LOG_LEVEL.WARNING,
              `${jobId}: ${failureReason} on line ${getEntryRow(entry)}`,
              {
                entry,
                failureReason,
              }
            )
          );
        } else if (failed) {
          let errorMessage = `${jobId}: ${failureReason} on line ${getEntryRow(
            entry
          )}`;
          if (
            !failureReason.includes("Discarded") &&
            !shippingSettings.onScreenImportFix
          ) {
            errorMessage += " (line " + errorLineNumber + " in error file)";
          }
          acc.errors.push(
            EventLogger.logEntry(LOG_LEVEL.ERROR, errorMessage, {
              entry,
              failureReason,
            })
          );
          errorLineNumber++;
        }
      });
      return acc;
    },
    {
      warnings: [],
      errors: [],
    }
  );
};
const regCheckPRD = new RegExp(/^[\t \"\']*PRD/, "i");
const isPRDLine = str => regCheckPRD.test(str);

const getChunk = (lines, pos, chunkSize) => {
  const len = lines.length;
  const chunk = [];

  while (pos < len) {
    const preparedStr = lines[pos].trim();

    if (preparedStr) {
      if (chunk.length >= chunkSize && !isPRDLine(preparedStr)) {
        break;
      }

      chunk.push(preparedStr);
    }

    pos++;
  }

  return [chunk, pos];
};

export const groupImportLines = (lines, options = {}) => {
  options = {
    limit: 100,
    ...options,
  };

  const groups = [];
  let pos = 0;

  while (pos < lines.length) {
    const [chunk, currentPos] = getChunk(lines, pos, options.limit);

    if (chunk.length) {
      groups.push(chunk);
    }

    pos = currentPos;
  }

  return groups;
};

export const createHeaderFooterArray = (data, count) => {
  const res = [];

  if (!count) {
    return res;
  }

  return fill(Array(count), data);
};
