import { GroupDispatchModels } from "~/pages/Tools/pages/GroupDispatch/models";
import { BulkReverseModels } from "~/pages/Tools/pages/BulkReverse/models";
import { uniq } from "lodash/array";
import { TOOLS_FIELDS } from "~/constants/forms";

export const duplicateDataByCount = (data, count = 1) => {
  const result = [];
  for (let i = 0; i < parseInt(count); i++) {
    result.push(data);
  }

  return result;
};

export const getLabelQuery = (
  formValues,
  address,
  isGroupsField,
  returnAddresses
) => {
  const lines = isGroupsField
    ? GroupDispatchModels.createLines(address, formValues)
    : BulkReverseModels.createLines(address, formValues, returnAddresses);

  return { lines, linePos: 0 };
};

export const mapErrorsByAddresses = (data, address) => {
  const allErrors = data.reduce((acc, item) => {
    if (!item.success) {
      acc.push(...item.failureReasons);
    }

    return acc;
  }, []);

  return { shortName: address.shortName, errors: uniq(allErrors) };
};

export const getGroupStartLabelQuery = ({
  groups,
  formValues,
  selectedAddresses,
}) => {
  const groupName = groups.results.find(
    data => data.groupId === formValues[TOOLS_FIELDS.GROUP]
  ).groupName;
  const totalAddresses = selectedAddresses.length;
  const totalParcels =
    totalAddresses * parseInt(formValues[TOOLS_FIELDS.SHIPMENTS_PER_LINE]);

  return { groupName, totalAddresses, totalParcels };
};

export const updateEndLabelQuery = ({ query, success, failure }) => {
  query.successfulPrintedAddress = query.successfulPrintedAddress + success;
  query.successfulPrintedLabels = query.successfulPrintedLabels + success;
  query.failedAddress = query.failedAddress + failure;
  query.failedParcels = query.failedParcels + failure;

  return query;
};
