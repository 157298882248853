import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import { shipmentApi } from "~/apis";

export const ActionTypes = createActionTypes("FAILED_IMPORTS", {
  FETCH: createAsyncActionTypes("FETCH"),
  FETCH_BY_ID: createAsyncActionTypes("FETCH_BY_ID"),
  DELETE: createAsyncActionTypes("DELETE"),
  CLEAR: "CLEAR",
});

export const fetchFailedShipments = createAsyncAction(
  query => shipmentApi.getFailedShipments(query),
  ActionTypes.FETCH
);

export const fetchFailedShipmentById = createAsyncAction(
  query => shipmentApi.getFailedShipmentById(query).then(({ data }) => data),
  ActionTypes.FETCH_BY_ID
);

export const deleteFailedShipment = createAsyncAction(
  stagingShipmentId => shipmentApi.deleteFailedShipment(stagingShipmentId),
  ActionTypes.DELETE
);
