import { findIndex, get, isEmpty, set, values } from "lodash";

import { REQUIRED_TYPE } from "@dpdgroupuk/mydpd-enums";

import { InvoiceEntity, ShipmentEntity } from "~/constants/forms";
import {
  defaultReasons,
  deliveredAtPlace,
  deliveredDutyPaid,
  dutiesTaxesPrepaid,
  excludeReasons,
  INVOICE_TYPE,
  INVOICE_TYPE_STRING,
} from "~/constants/invoice";
import * as S from "~/constants/strings";
import { AddressModels } from "~/models/address";
import { ServiceModels } from "~/models/service";
import { ShipmentModels } from "~/models/shipment";
import { roundToDecimal } from "~/utils/number";
import { getValue, toUppercaseValues } from "~/utils/object";

export const getReasons = (
  exportReasons = [],
  selectedCountry = {},
  selectedService = {}
) => {
  if (
    excludeReasons.includes(selectedService.networkKey) ||
    (selectedCountry.countryKey === S.IE &&
      ServiceModels.getNetworkCode(selectedService) === "11")
  ) {
    return defaultReasons;
  }

  return exportReasons.map(({ code: value, description: label }) => ({
    label,
    value,
  }));
};

export const isProformaInvoiceType = type =>
  type === INVOICE_TYPE_STRING.PROFORMA;

export const isCommercialInvoiceType = type =>
  type === INVOICE_TYPE_STRING.COMMERCIAL;

// @see: https://it.dpduk.live/it/diagram/diag_1vnf3D6GAqAAhRU_.html
export const getInternationalBillings = (
  selectedService = {},
  customerPrefs = {}
) => {
  const billings = [deliveredAtPlace];

  // NOTE: order is required (see comments at https://geopost.jira.com/browse/CSHIP-7314)
  if (
    selectedService.ddpAvailable === REQUIRED_TYPE.OPTIONAL &&
    customerPrefs.allowDdp
  ) {
    billings.unshift(deliveredDutyPaid);
  }

  if (
    selectedService.dt1Available === REQUIRED_TYPE.OPTIONAL &&
    customerPrefs.allowDt1
  ) {
    billings.unshift(dutiesTaxesPrepaid);
  }

  return billings;
};

const parseInvoiceTypeValue = value => {
  switch (value) {
    case INVOICE_TYPE_STRING.PROFORMA:
      return INVOICE_TYPE_STRING.PROFORMA;
    case INVOICE_TYPE_STRING.COMMERCIAL:
      return INVOICE_TYPE_STRING.COMMERCIAL;
    default:
      return null;
  }
};

// @see: https://it.dpduk.live/it/diagram/diag_zEmhGl6GAqAADHXl.html
export const getDefaultInvoiceType = (
  profileInvoiceType,
  preferenceInvoiceType
) => {
  const profileValue = parseInvoiceTypeValue(profileInvoiceType);
  const preferenceValue = parseInvoiceTypeValue(preferenceInvoiceType);
  const invoiceType = profileValue === null ? preferenceValue : profileValue;

  return isProformaInvoiceType(invoiceType)
    ? INVOICE_TYPE.PROFORMA
    : INVOICE_TYPE.COMMERCIAL;
};

export const getInvoiceType = (
  preferenceInvoiceType,
  profileInvoiceType,
  shipmentInvoiceType
) => {
  if (shipmentInvoiceType) {
    const isAvailableInOption =
      values(INVOICE_TYPE).includes(shipmentInvoiceType);

    if (isAvailableInOption) {
      return shipmentInvoiceType;
    }
    return shipmentInvoiceType;
  }
  return getDefaultInvoiceType(profileInvoiceType, preferenceInvoiceType);
};

// @see: see comments at https://geopost.jira.com/browse/CSHIP-7314
export const getDefaultInternationalBilling = billings => billings[0].value;

export const setupInitialInvoice = data => {
  const {
    shipment,
    selectedOutboundNetwork,
    customer,
    preferences,
    profile,
    customerPrefs,
  } = data;
  const invoice = getValue(shipment, "invoice", {});
  const generateCustomsData = getValue(
    shipment,
    ShipmentEntity.GENERATE_CUSTOMS_DATA
  );

  if (isEmpty(invoice) && !generateCustomsData) {
    return {
      ...data,
      shipment,
    };
  }

  const isBusiness = get(
    shipment,
    ShipmentEntity.INVOICE.IMPORTER_DETAILS.IS_BUSINESS
  )
    ? S.IMPORTER_BUSINESS
    : S.IMPORTER_CONSUMER;

  // for failed shipment set default values
  if (isEmpty(invoice) && generateCustomsData) {
    const { totalWeight, numberOfParcels } =
      ShipmentModels.getPackageDetails(shipment);
    set(invoice, InvoiceEntity.IMPORTER_DETAILS.IS_BUSINESS, isBusiness);
    set(invoice, InvoiceEntity.TOTAL_WEIGHT, totalWeight);
    set(invoice, InvoiceEntity.NUMBER_OF_PARCELS, numberOfParcels);

    return {
      ...data,
      shipment: {
        ...shipment,
        invoice,
      },
    };
  }

  // if international set default if absent () 4 section
  const exporterOrganisation = getValue(
    invoice,
    InvoiceEntity.EXPORTER_DETAILS.ADDRESS.ORGANISATION
  );
  const importerOrganisation = get(
    invoice,
    InvoiceEntity.IMPORTER_DETAILS.ADDRESS.ORGANISATION
  );
  const internationalBillings = getInternationalBillings(
    selectedOutboundNetwork,
    customerPrefs
  );

  if (!exporterOrganisation) {
    set(
      invoice,
      InvoiceEntity.EXPORTER_DETAILS.ADDRESS.ORGANISATION,
      getValue(customer, "customername")?.toUpperCase()
    );
  }

  if (!importerOrganisation) {
    const contactName = get(
      shipment,
      ShipmentEntity.INVOICE.IMPORTER_DETAILS.CONTACT_DETAILS.CONTACT_NAME
    );
    set(
      invoice,
      InvoiceEntity.IMPORTER_DETAILS.ADDRESS.ORGANISATION,
      contactName
    );
  }

  set(
    invoice,
    InvoiceEntity.TOTAL_WEIGHT,
    ServiceModels.roundTotalWeight(
      get(shipment, ShipmentEntity.OUTBOUND_CONSIGNMENT.TOTAL_WEIGHT),
      get(
        shipment,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
          .COUNTRY_CODE
      ),
      get(selectedOutboundNetwork, "networkKey"),
      true
    )
  );
  set(
    invoice,
    InvoiceEntity.NUMBER_OF_PARCELS,
    ServiceModels.roundNumberOfParcels(
      get(shipment, ShipmentEntity.OUTBOUND_CONSIGNMENT.NUMBER_OF_PARCELS)
    )
  );
  set(invoice, InvoiceEntity.IMPORTER_DETAILS.IS_BUSINESS, isBusiness);
  set(
    invoice,
    InvoiceEntity.SHIPPING_COST,
    roundToDecimal(get(shipment, ShipmentEntity.INVOICE.SHIPPING_COST))
  );

  if (!getValue(shipment, ShipmentEntity.INVOICE.INVOICE_REFERENCE)) {
    set(
      invoice,
      InvoiceEntity.INVOICE_REFERENCE,
      getValue(shipment, "outboundConsignment.consignmentNumber")
    );
  }

  set(
    invoice,
    InvoiceEntity.INVOICE_TYPE,
    getInvoiceType(
      preferences?.prefsInvoiceSettings?.invoiceType,
      profile?.invInvoiceType,
      getValue(shipment, ShipmentEntity.INVOICE.INVOICE_TYPE)
    )
  );

  if (
    internationalBillings.findIndex(
      ({ value }) =>
        value ===
        getValue(shipment, ShipmentEntity.INVOICE.INTERNATIONAL_BILLING_TERMS)
    ) === -1
  ) {
    set(
      invoice,
      InvoiceEntity.INTERNATIONAL_BILLING_TERMS,
      getDefaultInternationalBilling(internationalBillings)
    );
  }

  if (isBusiness === S.IMPORTER_BUSINESS) {
    delete invoice.importerDetails.pidNumber;
  }

  if (isBusiness === S.IMPORTER_CONSUMER) {
    delete invoice.importerDetails.eoriNumber;
    delete invoice.importerDetails.vatNumber;
  }

  if (selectedOutboundNetwork?.taxRequired === REQUIRED_TYPE.NEEDLESS) {
    delete invoice.exporterDetails.destinationTaxId;
    delete invoice.exporterDetails.gstVatPaid;
  }

  if (!ServiceModels.isVisibleFdaNumber(selectedOutboundNetwork)) {
    delete invoice.exporterDetails.fdaRegistrationNumber;
  }

  return {
    ...data,
    shipment: {
      ...shipment,
      invoice,
    },
  };
};

export const isExportReasonRequired = (selectedService = {}) =>
  selectedService.exportReasonRequired === REQUIRED_TYPE.MANDATORY;

export const shouldResetShipmentExportReason = (shipment, exportReasons) => {
  const currentExportReason = get(
    shipment,
    ShipmentEntity.INVOICE.EXPORT_REASON
  );
  const currentExportReasonIndex = findIndex(
    exportReasons,
    ({ value }) => value === currentExportReason
  );

  return (
    currentExportReason &&
    exportReasons?.length &&
    currentExportReasonIndex === -1
  );
};

// @see https://it.dpduk.live/version/customer-shipping/sprint-2.6/diag_F_RVHF6GAqAADOKk.html?id=1650536806775
export const getShipmentInfoDefault = ({
  selectedCountry,
  customerPrefs,
  shipment,
  selectedService,
  profile,
  prefsInvoiceSettings,
  exportReasons,
}) => {
  const { totalWeight, numberOfParcels } =
    ShipmentModels.getPackageDetails(shipment);

  const havePostcode = !isEmpty(profile?.invDetailsPostcode);

  return {
    defaultInvoiceType: getDefaultInvoiceType(
      profile?.invInvoiceType,
      prefsInvoiceSettings.invoiceType
    ),
    eoriNumber: havePostcode
      ? profile?.invShippersEoriNumber
      : prefsInvoiceSettings.eoriNumber,
    vatNumber: havePostcode
      ? profile?.invShippersVatNumber
      : prefsInvoiceSettings.vatNumber,
    fdaRegistrationNumber: ServiceModels.isVisibleFdaNumber(selectedService)
      ? havePostcode
        ? profile?.invFdaRegistrationNumber
        : prefsInvoiceSettings.fdaRegistrationNumber
      : null,
    exportReasons: getReasons(exportReasons, selectedCountry, selectedService),
    internationalBillings: getInternationalBillings(
      selectedService,
      customerPrefs
    ),
    totalWeight,
    numberOfParcels,
  };
};

export const getInitialInvoiceData = ({
  preferences,
  customer,
  selectedCountry,
  shipment,
  selectedService,
  profile,
  exportReasons,
  userCustomerAddress,
  customerPrefs,
}) => {
  const { prefsInvoiceSettings } = preferences;
  const {
    totalWeight,
    numberOfParcels,
    defaultInvoiceType,
    internationalBillings,
    eoriNumber,
    vatNumber,
    fdaRegistrationNumber,
  } = toUppercaseValues(
    getShipmentInfoDefault({
      selectedCountry,
      customerPrefs,
      shipment,
      selectedService,
      profile,
      prefsInvoiceSettings,
      exportReasons,
    })
  );

  const defaultExporterAddress = AddressModels.getDefaultExporterAddress({
    customer,
    userCustomerAddress,
    profile,
    prefsInvoiceSettings,
  });
  const { destinationTaxId, gstVatPaid } =
    ShipmentModels.getInitialDestinationGstValues(profile, preferences);
  const isVisibleFields =
    selectedService?.taxRequired !== REQUIRED_TYPE.NEEDLESS;

  return toUppercaseValues({
    [ShipmentEntity.INVOICE.TOTAL_WEIGHT]: totalWeight,
    [ShipmentEntity.INVOICE.NUMBER_OF_PARCELS]: numberOfParcels,
    [ShipmentEntity.INVOICE.INVOICE_TYPE]: defaultInvoiceType,
    [ShipmentEntity.INVOICE.INTERNATIONAL_BILLING_TERMS]:
      getDefaultInternationalBilling(internationalBillings),
    ...defaultExporterAddress,
    [ShipmentEntity.INVOICE.EXPORTER_DETAILS.EORI_NUMBER]: eoriNumber,
    [ShipmentEntity.INVOICE.EXPORTER_DETAILS.VAT_NUMBER]: vatNumber,
    ...(fdaRegistrationNumber !== null && {
      [ShipmentEntity.INVOICE.EXPORTER_DETAILS.FDA_NUMBER]:
        fdaRegistrationNumber,
    }),
    ...(isVisibleFields && {
      [ShipmentEntity.INVOICE.EXPORTER_DETAILS.DESTINATION_TAX_ID_REG_NO]:
        destinationTaxId,
      [ShipmentEntity.INVOICE.EXPORTER_DETAILS.GST_VAT_PAID]: gstVatPaid,
    }),

    [ShipmentEntity.INVOICE.SHIPPING_COST]: "0.00",
    [ShipmentEntity.INVOICE.IMPORTER_DETAILS.ADDRESS.COUNTRY_CODE]: get(
      shipment,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTRY_CODE
    ),
    [ShipmentEntity.INVOICE.IMPORTER_DETAILS.ADDRESS.POSTCODE]: get(
      shipment,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE
    ),
    [ShipmentEntity.INVOICE.IMPORTER_DETAILS.ADDRESS.ORGANISATION]: get(
      shipment,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.ORGANISATION
    ),
    [ShipmentEntity.INVOICE.IMPORTER_DETAILS.ADDRESS.TOWN]: get(
      shipment,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.TOWN
    ),
    [ShipmentEntity.INVOICE.IMPORTER_DETAILS.ADDRESS.COUNTY]: get(
      shipment,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTY
    ),
    [ShipmentEntity.INVOICE.IMPORTER_DETAILS.ADDRESS.STREET]: get(
      shipment,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.STREET
    ),
    [ShipmentEntity.INVOICE.IMPORTER_DETAILS.ADDRESS.LOCALITY]: get(
      shipment,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.LOCALITY
    ),

    [ShipmentEntity.INVOICE.IMPORTER_DETAILS.CONTACT_DETAILS.CONTACT_NAME]: get(
      shipment,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.CONTACT_DETAILS
        .CONTACT_NAME
    ),
    [ShipmentEntity.INVOICE.IMPORTER_DETAILS.CONTACT_DETAILS.TELEPHONE]: get(
      shipment,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.CONTACT_DETAILS
        .TELEPHONE
    ),
    [ShipmentEntity.INVOICE.IMPORTER_DETAILS.CONTACT_DETAILS.EMAIL]: get(
      shipment,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.NOTIFICATION_DETAILS
        .EMAIL
    ),
  });
};

export const setupDefaultValuesForInvalidInvoice = ({
  exportReasons,
  shipment,
}) => {
  const invoice = getValue(shipment, "invoice", {});

  if (isEmpty(invoice)) {
    return shipment;
  }

  const isAvailableExportReason = exportReasons.some(
    item =>
      item.code === getValue(shipment, ShipmentEntity.INVOICE.EXPORT_REASON)
  );

  if (!isAvailableExportReason) {
    set(invoice, InvoiceEntity.EXPORT_REASON, null);
  }

  return {
    ...shipment,
    invoice,
  };
};
