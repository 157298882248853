import moment from "moment";
import PropTypes from "prop-types";
import { Col, Container, Row } from "react-bootstrap";
import { compose } from "recompose";
import { Field, reduxForm } from "redux-form";

import { Button, FormControl } from "@dpdgroupuk/mydpd-ui";

import { DEFAULT_DATE_FORMAT } from "~/constants/dateFormats";
import {
  SHIPMENT_REVIEW_CHANGE_DATE_FORM,
  ShipmentEntity,
} from "~/constants/forms";
import * as S from "~/constants/strings";

import Modal from "../../../../components/ShipmentReviewModal";
import styles from "./ChangeDateModal.module.scss";
import { connect } from "react-redux";
import { ModalSelectors } from "~/redux/modals";
import withUpdateDate from "~/pages/Shipment/hocs/withUpdateDate";

const ChangeDateModal = ({
  onSubmit,
  getDatesRange,
  filterDate,
  handleSubmit,
  open,
  onCancel,
}) => (
  <Modal
    open={open}
    onCancel={onCancel}
    title={S.CHANGE_DATE}
    dialogClassName={styles.changeDateModal}
    saveButton={{ visible: false }}
  >
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container className="p-0">
        <Row>
          <Col>
            <Field
              readOnly
              component={FormControl.DatePicker}
              label={S.SHIPMENT_DATE}
              name={ShipmentEntity.SHIPMENT_DATE}
              helperText={S.FORWARD_SHIPMENT_DATE}
              dateFormat={DEFAULT_DATE_FORMAT}
              getDatesRange={getDatesRange}
              filterDate={filterDate}
              required
            />
          </Col>
          <Col xs="auto" className={styles.buttonContainer}>
            <Button
              variant="primary"
              type="submit"
              className={styles.button}
              onClick={handleSubmit(onSubmit)}
            >
              {S.CHANGE_DATE}
            </Button>
          </Col>
        </Row>
      </Container>
    </form>
  </Modal>
);

ChangeDateModal.propTypes = {
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func,
  filterDate: PropTypes.func,
  getDatesRange: PropTypes.func,
  open: PropTypes.bool,
  onCancel: PropTypes.func,
};

export default compose(
  connect(state => ({
    formValues: ModalSelectors.getModalFormValues(
      state,
      SHIPMENT_REVIEW_CHANGE_DATE_FORM
    ),
  })),
  reduxForm({
    form: SHIPMENT_REVIEW_CHANGE_DATE_FORM,
    initialValues: {
      [ShipmentEntity.SHIPMENT_DATE]: moment().format(DEFAULT_DATE_FORMAT),
    },
    destroyOnUnmount: false,
    enableReinitialize: true,
  }),
  withUpdateDate({
    formName: SHIPMENT_REVIEW_CHANGE_DATE_FORM,
    dateFormat: DEFAULT_DATE_FORMAT,
    fieldPath: ShipmentEntity.SHIPMENT_DATE,
  })
)(ChangeDateModal);
