import PropTypes from "prop-types";
import { propTypes } from "redux-form";

import { Step } from "@dpdgroupuk/mydpd-ui";

import BaseContactForm from "~/components/BaseContactForm";
import {
  INBOUND_CONSIGNMENT_UPPERCASE,
  RETURN_CONTACT,
} from "~/constants/forms";
import help from "~/constants/info";
import * as S from "~/constants/strings";

const ReturnContact = props => (
  <Step
    totalSteps={props.totalSteps}
    step={props.step}
    withStepCounter
    complete={!props.pristine && props.isValidReturnContactSection}
    help={help[RETURN_CONTACT]}
    title={S.RETURN_CONTACT_TITLE}
    helpModalTitle={S.RETURN_CONTACT_TITLE}
  >
    <BaseContactForm
      {...props}
      shipmentSection={INBOUND_CONSIGNMENT_UPPERCASE}
    />
  </Step>
);

ReturnContact.propTypes = {
  isValidReturnContactSection: PropTypes.bool,
  ...propTypes,
};

export default ReturnContact;
