import { LOG_LEVEL } from "@dpdgroupuk/mydpd-app";

export const NOTICE_LEVEL = "notice";

export const COLOR_LEVEL = {
  [LOG_LEVEL.INFO]: "#FFFFFF",
  [LOG_LEVEL.SUCCESS]: "#E3FAD9",
  [LOG_LEVEL.WARNING]: "#FFEACC",
  [NOTICE_LEVEL]: "#FFEACC",
  [LOG_LEVEL.ERROR]: "#FAD9E0",
};
