import { Card, Container } from "react-bootstrap";

import { Info } from "~/components/Icon";
import * as S from "~/constants/strings";

import styles from "./InfoCard.module.scss";

const InfoCard = () => (
  <>
    <Card className={styles.infoCard}>
      <Container className={styles.infoCardContainer}>
        <Info />
        <p>{S.INFO_CARD_MESSAGE}</p>
      </Container>
    </Card>
  </>
);

export default InfoCard;
