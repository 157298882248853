import { noop } from "lodash/fp";
import { connect } from "react-redux";
import { compose, withHandlers, withProps } from "recompose";

import { printJS, withNotifier } from "@dpdgroupuk/mydpd-app";
import { withOverlay, withPrompt, withSnackbar } from "@dpdgroupuk/mydpd-ui";

import { SHOW_ALERT_DISPLAY_TIME } from "~/constants/snackbar";
import * as S from "~/constants/strings";
import { getErrorMessage } from "~/utils/error";

import { ManifestActions } from "~/features";

export default compose(
  withSnackbar,
  withPrompt,
  withOverlay,
  withNotifier,
  connect(null, dispatch => ({
    fetchManifests: shipmentDate =>
      dispatch(ManifestActions.fetchManifests({ shipmentDate })),
    createManifest: async shipmentDate => {
      try {
        const manifest = await dispatch(
          ManifestActions.createManifest({ shipmentDate })
        );

        if (manifest) {
          const data = await dispatch(
            ManifestActions.generateManifestLabelById(manifest.manifestId)
          );
          return data?.printString;
        }
      } finally {
        dispatch(ManifestActions.clearManifestAll());
      }
    },
    getManifest: async manifestId => {
      const data = await dispatch(
        ManifestActions.generateManifestLabelById(manifestId)
      );

      return data?.printString;
    },
  })),
  withProps(() => ({
    printManifest: htmlString => {
      printJS({
        printable: htmlString.join(""),
        type: "raw-html",
      });
    },
  })),
  withHandlers({
    onClickGenerateManifest: ({
      prompt,
      overlay,
      notifier,
      createManifest,
      printManifest,
    }) =>
      notifier.runAsync(
        async shipmentDate => {
          try {
            overlay.show();
            const manifestLabels = await createManifest(shipmentDate);

            if (manifestLabels) {
              printManifest(manifestLabels);
              return true;
            } else {
              prompt.showInfo({
                header: S.NO_SHIPMENTS_TO_MANIFEST,
                message: S.MANIFEST_NOTHING_TO_PROCESS,
              });
            }
          } finally {
            overlay.hide();
          }
        },
        { entityName: S.MANIFEST }
      ),
    onClickRePrintManifest: ({
      overlay,
      notifier,
      getManifest,
      printManifest,
    }) =>
      notifier.runAsync(
        async (manifestId, onFinishProcess = noop) => {
          try {
            overlay.show();
            const manifestLabel = await getManifest(manifestId);
            printManifest(manifestLabel);
          } finally {
            overlay.hide();
            onFinishProcess();
          }
        },
        { entityName: S.MANIFEST }
      ),
    onFetchManifests: ({ notifier, snackbar, overlay, fetchManifests }) =>
      notifier.runAsync(
        async (shipmentDate = []) => {
          try {
            overlay.show();
            const res = await fetchManifests(shipmentDate);
            overlay.hide();
            return res.data.results;
          } catch (e) {
            overlay.hide();

            snackbar.showAlert({
              message: getErrorMessage(e, S.MANIFEST),
              displayTime: SHOW_ALERT_DISPLAY_TIME,
            });
            return [];
          }
        },
        { entityName: S.MANIFEST }
      ),
  })
);
