import { isEmpty, sortBy } from "lodash";

import { TEMPLATE_TYPE } from "@dpdgroupuk/mydpd-enums";
import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
  createCacheAsyncAction,
  createPayloadAction,
} from "@dpdgroupuk/redux-action-creator";

import { productBookApi, shipmentApi, templatesApi } from "~/apis";
import { ImportsFields } from "~/constants/forms";
import * as S from "~/constants/strings";
import { ImportsModels } from "~/pages/Imports/models";
import { ImportsSelectors } from "~/pages/Imports/redux";
import { TemplateSelectors } from "~/redux";
import {
  getShipmentImportTemplates,
  getTemplateByType,
} from "~/redux/template/selectors";
import { getValue } from "~/utils/object";

export const ActionTypes = createActionTypes("TEMPLATES", {
  GET_TEMPLATE_TYPES: createAsyncActionTypes("GET_TEMPLATE_TYPES"),
  GET_DPD_DIRECT_TEMPLATE: createAsyncActionTypes("GET_DPD_DIRECT_TEMPLATE"),
  GET_TEMPLATE_PRODUCT_DETAILS: createAsyncActionTypes(
    "GET_TEMPLATE_PRODUCT_DETAILS"
  ),
  GET_TEMPLATE_SHIPMENT_IMPORT_META: createAsyncActionTypes(
    "GET_TEMPLATE_SHIPMENT_IMPORT_META"
  ),
  GET_TEMPLATE_RECEIPT_TEMPLATE: createAsyncActionTypes(
    "GET_TEMPLATE_RECEIPT_TEMPLATE"
  ),
  GET_TEMPLATE_SHIPMENT_EXPORT: createAsyncActionTypes(
    "GET_TEMPLATE_SHIPMENT_EXPORT"
  ),
  GET_TEMPLATE_SHIPMENT_IMPORT: createAsyncActionTypes(
    "GET_TEMPLATE_SHIPMENT_IMPORT"
  ),
  GET_RETURN_ADDRESS_BOOK_TEMPLATE: createAsyncActionTypes(
    "GET_RETURN_ADDRESS_BOOK_TEMPLATE"
  ),
  GET_TEMPLATE_DELIVERY_ADDRESS_BOOK: createAsyncActionTypes(
    "GET_TEMPLATE_DELIVERY_ADDRESS_BOOK"
  ),
  GET_TEMPLATE_IMPORT_BY_ID: createAsyncActionTypes(
    "GET_TEMPLATE_IMPORT_BY_ID"
  ),
  GET_DELIMITERS: createAsyncActionTypes("GET_DELIMITERS"),
  GET_ADDRESSBOOK_DELIMITERS: createAsyncActionTypes(
    "GET_ADDRESSBOOK_DELIMITERS"
  ),
  GET_SHIPMENT_IMPORT_TEMPLATES: createAsyncActionTypes(
    "GET_SHIPMENT_IMPORT_TEMPLATES"
  ),
  SAVE_DPD_DIRECT_TEMPLATE: createAsyncActionTypes("SAVE_DPD_DIRECT_TEMPLATE"),
  SAVE_PRODUCT_TEMPLATE: createAsyncActionTypes("SAVE_PRODUCT_TEMPLATE"),
  SAVE_SHIPMENT_RECEIPT_TEMPLATE: createAsyncActionTypes(
    "SAVE_SHIPMENT_RECEIPT_TEMPLATE"
  ),
  SAVE_SHIPMENT_EXPORT_TEMPLATE: createAsyncActionTypes(
    "SAVE_SHIPMENT_EXPORT_TEMPLATE"
  ),
  SAVE_ADDRESSBOOK_TEMPLATE: createAsyncActionTypes(
    "SAVE_ADDRESSBOOK_TEMPLATE"
  ),
  CREATE_SHIPMENT_IMPORT_TEMPLATE: createAsyncActionTypes(
    "CREATE_SHIPMENT_IMPORT_TEMPLATE"
  ),
  UPDATE_SHIPMENT_IMPORT_TEMPLATE: createAsyncActionTypes(
    "UPDATE_SHIPMENT_IMPORT_TEMPLATE"
  ),
  UPDATE_TEMPLATE: "UPDATE_TEMPLATE",
  DELETE: createAsyncActionTypes("DELETE"),
  IMPORT_TEMPLATE: createAsyncActionTypes("IMPORT_TEMPLATE"),
  FETCH_TEMPLATE: createAsyncActionTypes("FETCH_TEMPLATE"),
  TEST_IMPORT_FILE: createAsyncActionTypes("TEST_IMPORT_FILE"),
  GET_TEST_IMPORT_FILE_RESULT: createAsyncActionTypes(
    "GET_TEST_IMPORT_FILE_RESULT"
  ),
  GET_TEMPLATE_PRODUCTBOOK: createAsyncActionTypes("GET_TEMPLATE_PRODUCTBOOK"),
  GET_PRODUCTBOOK_DELIMITERS: createAsyncActionTypes(
    "GET_PRODUCTBOOK_DELIMITERS"
  ),
  SAVE_PRODUCTBOOK_TEMPLATE: createAsyncActionTypes(
    "SAVE_PRODUCTBOOK_TEMPLATE"
  ),
});

export const fetchDpdDirectTemplate = createAsyncAction(
  () => templatesApi.fetchDpdDirectTemplate().then(({ data }) => data),
  ActionTypes.GET_DPD_DIRECT_TEMPLATE
);

export const fetchCacheableDpdDirectTemplate = createCacheAsyncAction(
  fetchDpdDirectTemplate,
  state => !!getTemplateByType(state, TEMPLATE_TYPE.DPD_DIRECT_PRODUCT)
);

export const fetchReturnAddressBookTemplate = createAsyncAction(
  () =>
    templatesApi
      .fetchReturnAddressbookTemplate({
        type: S.ADDRESSBOOK_TYPE_CODES.returnAddressbook,
      })
      .then(({ data }) => data),
  ActionTypes.GET_RETURN_ADDRESS_BOOK_TEMPLATE
);

export const fetchCacheableReturnAddressBookTemplate = createCacheAsyncAction(
  fetchReturnAddressBookTemplate,
  state => !!getTemplateByType(state, TEMPLATE_TYPE.RETURN_ADDRESS_BOOK)
);

export const fetchProductDetailsTemplate = createAsyncAction(
  () => templatesApi.fetchShipmentProductTemplate().then(({ data }) => data),
  ActionTypes.GET_TEMPLATE_PRODUCT_DETAILS
);

export const fetchCacheableProductDetailsTemplate = createCacheAsyncAction(
  fetchProductDetailsTemplate,
  state => !!getTemplateByType(state, TEMPLATE_TYPE.PRODUCT)
);

export const fetchShipmentImportMetaTemplate = createAsyncAction(
  () => templatesApi.fetchShipmentImportMetaTemplate().then(({ data }) => data),
  ActionTypes.GET_TEMPLATE_SHIPMENT_IMPORT_META
);

export const fetchShipmentReceiptTemplate = createAsyncAction(
  () => templatesApi.fetchShipmentReceiptTemplate().then(({ data }) => data),
  ActionTypes.GET_TEMPLATE_RECEIPT_TEMPLATE
);

export const fetchCacheableShipmentReceiptTemplate = createCacheAsyncAction(
  fetchShipmentReceiptTemplate,
  state => !!getTemplateByType(state, TEMPLATE_TYPE.SHIPMENT_RECEIPT)
);

export const fetchShipmentExportTemplate = createAsyncAction(
  () => templatesApi.fetchShipmentExportTemplate().then(({ data }) => data),
  ActionTypes.GET_TEMPLATE_SHIPMENT_EXPORT
);

export const fetchCacheableShipmentExportTemplate = createCacheAsyncAction(
  fetchShipmentExportTemplate,
  state => !!getTemplateByType(state, TEMPLATE_TYPE.SHIPMENT_EXPORT)
);

// @see https://it.dpduk.live/version/customer-shipping/sprint-2.1/diag_fh.kfb6GAqAAhTnL.html?id=1644404235662
export const fetchShipmentTemplate = createAsyncAction(
  () => (dispatch, getState) => {
    const shipmentImportTemplates =
      TemplateSelectors.getShipmentImportTemplates(getState());

    return isEmpty(shipmentImportTemplates)
      ? dispatch(fetchShipmentImportMetaTemplate())
      : dispatch(
          fetchShipmentImportTemplateById(
            ImportsModels.getDefaultTemplateId(shipmentImportTemplates)
          )
        );
  },
  ActionTypes.GET_TEMPLATE_SHIPMENT_IMPORT
);

export const fetchDeliveryAddressBookTemplate = createAsyncAction(
  () =>
    templatesApi
      .fetchReturnAddressbookTemplate({
        type: S.ADDRESSBOOK_TYPE_CODES.deliveryAddressbook,
      })
      .then(({ data }) => data),
  ActionTypes.GET_TEMPLATE_DELIVERY_ADDRESS_BOOK
);

export const fetchCacheableDeliveryAddressBookTemplate = createCacheAsyncAction(
  fetchDeliveryAddressBookTemplate,
  state => !!getTemplateByType(state, TEMPLATE_TYPE.DELIVERY_ADDRESS_BOOK)
);

export const fetchProductBookTemplate = createAsyncAction(
  () => productBookApi.fetchProductBookTemplate().then(({ data }) => data),
  ActionTypes.GET_TEMPLATE_PRODUCTBOOK
);

export const fetchCacheableProductBookTemplate = createCacheAsyncAction(
  fetchProductBookTemplate,
  state => !!getTemplateByType(state, TEMPLATE_TYPE.PRODUCT_BOOK)
);

export const fetchTemplate = createAsyncAction(
  templateType => dispatch => {
    switch (parseInt(templateType)) {
      case TEMPLATE_TYPE.DPD_DIRECT_PRODUCT: {
        return dispatch(fetchDpdDirectTemplate());
      }
      case TEMPLATE_TYPE.PRODUCT: {
        return dispatch(fetchProductDetailsTemplate());
      }
      case TEMPLATE_TYPE.SHIPMENT: {
        return dispatch(fetchShipmentTemplate());
      }
      case TEMPLATE_TYPE.SHIPMENT_RECEIPT: {
        return dispatch(fetchShipmentReceiptTemplate());
      }
      case TEMPLATE_TYPE.SHIPMENT_EXPORT: {
        return dispatch(fetchShipmentExportTemplate());
      }
      case TEMPLATE_TYPE.RETURN_ADDRESS_BOOK: {
        return dispatch(fetchReturnAddressBookTemplate());
      }
      case TEMPLATE_TYPE.DELIVERY_ADDRESS_BOOK: {
        return dispatch(fetchDeliveryAddressBookTemplate());
      }
      case TEMPLATE_TYPE.PRODUCT_BOOK: {
        return dispatch(fetchProductBookTemplate());
      }
      // TODO: case TEMPLATE_TYPE.RELEASE_FILE

      default:
        return Promise.resolve();
    }
  },
  ActionTypes.FETCH_TEMPLATE
);

export const fetchTemplateTypes = createAsyncAction(
  () =>
    templatesApi.fetchTemplateTypes().then(({ data }) => ({
      data: sortBy(
        [
          ...data,
          {
            typeCode: 8,
            typeDescription: "Product Book",
          },
        ],
        "typeCode"
      ),
    })),
  ActionTypes.GET_TEMPLATE_TYPES
);

export const fetchDelimiters = createAsyncAction(
  templatesApi.fetchDelimiters,
  ActionTypes.GET_DELIMITERS
);

export const fetchAddressbookDelimiters = createAsyncAction(
  templatesApi.fetchAddressbookDelimiters,
  ActionTypes.GET_ADDRESSBOOK_DELIMITERS
);

export const fetchProductbookDelimiters = createAsyncAction(
  productBookApi.fetchProductbookDelimiters,
  ActionTypes.GET_PRODUCTBOOK_DELIMITERS
);

export const fetchShipmentImportTemplates = createAsyncAction(
  () =>
    templatesApi.fetchShipmentImportTemplates().then(({ data }) => ({
      data: sortBy(data, ({ templateName }) => templateName.toUpperCase()),
    })),
  ActionTypes.GET_SHIPMENT_IMPORT_TEMPLATES
);

export const saveDpdDirectTemplate = createAsyncAction(
  body => templatesApi.saveDpdDirectTemplate(body).then(({ data }) => data),
  ActionTypes.SAVE_DPD_DIRECT_TEMPLATE
);

export const saveProductTemplate = createAsyncAction(
  body =>
    templatesApi.saveShipmentProductTemplate(body).then(({ data }) => data),
  ActionTypes.SAVE_PRODUCT_TEMPLATE
);

export const updateShipmentImportTemplate = createAsyncAction(
  (id, body) =>
    templatesApi
      .updateShipmentImportTemplate(id, body)
      .then(({ data }) => data),
  ActionTypes.UPDATE_SHIPMENT_IMPORT_TEMPLATE
);

export const createShipmentImportTemplate = createAsyncAction(
  body =>
    templatesApi.createShipmentImportTemplate(body).then(({ data }) => data),
  ActionTypes.CREATE_SHIPMENT_IMPORT_TEMPLATE
);

export const saveShipmentReceiptTemplate = createAsyncAction(
  body =>
    templatesApi.saveShipmentReceiptTemplate(body).then(({ data }) => data),
  ActionTypes.SAVE_SHIPMENT_RECEIPT_TEMPLATE
);

export const saveShipmentExportTemplate = createAsyncAction(
  body =>
    templatesApi.saveShipmentExportTemplate(body).then(({ data }) => data),
  ActionTypes.SAVE_SHIPMENT_EXPORT_TEMPLATE
);
export const fetchCacheableShipmentImportTemplates = createCacheAsyncAction(
  fetchShipmentImportTemplates,
  state => getShipmentImportTemplates(state).length > 0
);

export const saveAddressbookTemplate = createAsyncAction(
  body => templatesApi.saveAddressbookTemplate(body).then(({ data }) => data),
  ActionTypes.SAVE_ADDRESSBOOK_TEMPLATE
);

export const saveProductbookTemplate = createAsyncAction(
  body => productBookApi.saveProductbookTemplate(body).then(({ data }) => data),
  ActionTypes.SAVE_PRODUCTBOOK_TEMPLATE
);

export const saveTemplate = (body, id, definition) => dispatch => {
  switch (parseInt(definition)) {
    case TEMPLATE_TYPE.DPD_DIRECT_PRODUCT: {
      return dispatch(saveDpdDirectTemplate(body));
    }
    case TEMPLATE_TYPE.PRODUCT: {
      return dispatch(saveProductTemplate(body));
    }
    case TEMPLATE_TYPE.SHIPMENT: {
      if (id) {
        return dispatch(updateShipmentImportTemplate(id, body));
      } else {
        return dispatch(createShipmentImportTemplate(body));
      }
    }
    case TEMPLATE_TYPE.SHIPMENT_RECEIPT: {
      return dispatch(saveShipmentReceiptTemplate(body));
    }
    case TEMPLATE_TYPE.SHIPMENT_EXPORT: {
      return dispatch(saveShipmentExportTemplate(body));
    }
    case TEMPLATE_TYPE.RETURN_ADDRESS_BOOK:
    case TEMPLATE_TYPE.DELIVERY_ADDRESS_BOOK: {
      return dispatch(saveAddressbookTemplate(body));
    }
    case TEMPLATE_TYPE.PRODUCT_BOOK: {
      return dispatch(saveProductbookTemplate(body));
    }

    default:
      break;
  }
};

export const fetchShipmentImportTemplateById = createAsyncAction(
  id =>
    templatesApi.fetchShipmentImportTemplateById(id).then(({ data }) => data),
  ActionTypes.GET_TEMPLATE_IMPORT_BY_ID
);

export const fetchCacheableShipmentImportTemplateById = createCacheAsyncAction(
  fetchShipmentImportTemplateById,
  (state, templateId) =>
    !!getTemplateByType(state, TEMPLATE_TYPE.SHIPMENT)?.[templateId]
);

export const deleteShipmentImportTemplateById = createAsyncAction(
  () => (dispatch, getState) =>
    templatesApi.deleteShipmentImportTemplateById(
      getValue(
        ImportsSelectors.getImportsFormValues(getState()),
        ImportsFields.TEMPLATE_NAME,
        ""
      )
    ),
  ActionTypes.DELETE
);

export const importTemplate = createAsyncAction(
  (data, formValues) => () => {
    switch (formValues[ImportsFields.DEFINITION]) {
      case TEMPLATE_TYPE.SHIPMENT.toString():
        return templatesApi
          .uploadShipmentTemplate(data, {
            profileCode: formValues[ImportsFields.PROFILE_CODE],
          })
          .then(({ data }) => data);
      case TEMPLATE_TYPE.SHIPMENT_RECEIPT.toString():
        return templatesApi
          .uploadShipmentReceiptTemplate(data)
          .then(({ data }) => data);
      default:
        break;
    }
  },
  ActionTypes.IMPORT_TEMPLATE
);

export const updateTemplate = template =>
  createPayloadAction(ActionTypes.UPDATE_TEMPLATE, template);

export const testImportFile = createAsyncAction(
  (data, templateId) =>
    shipmentApi
      .testImportFile(data, {
        templateId,
      })
      .then(({ data }) => {
        if (!isEmpty(data.entries)) {
          return data.entries.reduce((acc, item) => {
            if (item.failureReasons.length === 1) {
              item.failureReasons = `${item.failureReasons[0]} on line ${item.row}`;
              item.level = item.success === true ? "warn" : "error";
              acc.push(item);
              return acc;
            }
            // We need to show each failure in separate line so we need to map
            // multiple failures to multiple entries with single failure
            if (item.failureReasons.length > 1) {
              item.failureReasons.forEach(reason => {
                const newItem = { ...item };
                newItem.failureReasons = `${reason} on line ${item.row}`;
                newItem.level = item.success === true ? "warn" : "error";
                acc.push(newItem);
                return acc;
              });
            }

            return acc;
          }, []);
        } else {
          return [];
        }
      }),
  ActionTypes.TEST_IMPORT_FILE
);
