import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import { profilesApi } from "~/apis";

export const ActionTypes = createActionTypes("PROFILES", {
  FETCH: createAsyncActionTypes("FETCH"),
  CLEAR_PROFILES: "CLEAR_PROFILES",
});

export const fetchProfiles = createAsyncAction(
  profilesApi.fetchProfiles,
  ActionTypes.FETCH
);

export const clearProfiles = () => ({
  type: ActionTypes.CLEAR_PROFILES,
});
