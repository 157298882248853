import { isNil } from "lodash/lang";

export const roundToDecimal = value => {
  const parsedValue = Number.parseFloat(value);

  return !isNil(value) && value !== "" && !isNaN(parsedValue)
    ? parsedValue.toFixed(2)
    : value;
};

export const separateThousandWithComma = value => {
  if (typeof Number(value) === "number" && !isNaN(Number(value)) && !!value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return value;
};
