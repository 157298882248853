import React from "react";

import PropTypes from "prop-types";
import { connect, ReactReduxContext } from "react-redux";
import { matchPath, withRouter } from "react-router";
import { compose } from "recompose";

import { EventLogger, withLocalService } from "@dpdgroupuk/mydpd-app";

import BatchImport from "~/features/Importer/BatchImport";
import PrintWorkstation from "~/features/Importer/PrintWorkstation";
import * as ImporterSelectors from "~/features/Importer/redux/selectors";
import ReceiptController from "~/features/Receipt/ReceiptController";
import { IMPORT_ACTIVITY, IMPORTS } from "~/router/constants";

import { Context } from "./Context";
import { ImporterActions } from "./redux";

class Provider extends React.Component {
  static contextType = ReactReduxContext;

  constructor(props, context) {
    super(props, context);
    this.store = this.context.store;
    this.logger = new EventLogger();
    this.manager = props.localService.manager;
    this.printer = props.localService.printer;
    this.localApis = props.localService.localApis;
    this.receipt = new ReceiptController(this);
    this.batchImport = new BatchImport(this);
    this.workstation = new PrintWorkstation(this);
  }

  beforeUnloadListener = event => {
    event.preventDefault();
    const confirmationMessage =
      "Import is running. Are you sure you want to exit?";
    (event || window.event).returnValue = confirmationMessage;
    return confirmationMessage;
  };

  // register global listener to keep handle import activity
  registerImportListeners() {
    this.autoImportIntervalId = setInterval(async () => {
      const activeTemplateId = this.props.activeTemplateId;
      const failedRecord = this.props.failedRecord;
      const { isRunning } = this.props.localService.state;
      const isImportsTab = matchPath(this.props.location.pathname, {
        path: IMPORTS,
      });

      if (isRunning && activeTemplateId && !isImportsTab && !failedRecord) {
        const currentState = this.store.getState();
        const runningState = ImporterSelectors.getRunningState(currentState);
        const params = ImporterSelectors.getImportParams(
          currentState,
          activeTemplateId
        );
        if (params.shipmentTemplate?.autoImport && !runningState.importing) {
          try {
            await this.batchImport.runImportJob(params, false);
          } catch (e) {
            // NOTE: ignore error, because logger inside runImportJob handles it
          }
        }
      }
    }, 3000);

    this.removeImportingListener = this.batchImport.addListener(
      "importing",
      ({ detail: running }) => {
        this.props.setRunningState("importing", running);
        if (running) {
          // eslint-disable-next-line no-restricted-globals
          addEventListener("beforeunload", this.beforeUnloadListener, {
            capture: true,
          });
        } else {
          // eslint-disable-next-line no-restricted-globals
          removeEventListener("beforeunload", this.beforeUnloadListener, {
            capture: true,
          });
        }
      }
    );

    this.removeImportMessageListener = this.logger.addListener(
      "log",
      ({ detail }) => {
        this.props.addImportMessage(detail);
      }
    );

    this.removeImportMessageListener = this.logger.addListener(
      "progress",
      ({ detail }) => {
        this.props.addImportProgressMessage(detail);
      }
    );

    this.removePrintingListener = this.workstation.addListener(
      "printing",
      ({ detail }) => {
        this.props.setRunningState("printing", detail);
      }
    );
  }

  registerPrintListeners() {
    this.printIntervalId = setInterval(async () => {
      const { isRunning, appSettings } = this.props.localService.state;
      const activeTemplateId = this.props.activeTemplateId;
      const isImportActivityTab = matchPath(this.props.location.pathname, {
        path: IMPORT_ACTIVITY,
      });
      if (isRunning && isImportActivityTab) {
        const currentState = this.store.getState();
        const runningState = ImporterSelectors.getRunningState(currentState);
        const params = ImporterSelectors.getImportParams(
          currentState,
          activeTemplateId // can be null
        );

        if (!runningState.printing) {
          if (appSettings.workstationId && appSettings.polling) {
            await this.workstation.processPrintJobs(
              appSettings.workstationId,
              params
            );
          }
        }
      }
    }, 4000);

    this.removePrintingListener = this.workstation.addListener(
      "printing",
      ({ detail }) => {
        this.props.setRunningState("printing", detail);
      }
    );
  }

  unregisterImportListeners() {
    this.autoImportIntervalId && clearInterval(this.autoImportIntervalId);
    this.removeImportingListener && this.removeImportingListener();

    this.removePrintingListener && this.removePrintingListener();

    this.removeImportMessageListener && this.removeImportMessageListener();
  }

  unregisterPrintListeners() {
    this.printIntervalId && clearInterval(this.printIntervalId);
    this.removePrintingListener && this.removePrintingListener();
  }

  componentDidMount() {
    this.registerImportListeners();
    this.registerPrintListeners();
  }

  componentWillUnmount() {
    this.unregisterImportListeners();
    this.unregisterPrintListeners();
  }

  render() {
    return (
      <Context.Provider
        value={{
          logger: this.logger,
          batchImport: this.batchImport,
          workstation: this.workstation,
        }}
      >
        {this.props.children}
      </Context.Provider>
    );
  }
}
Provider.propTypes = {
  children: PropTypes.node,
  activeTemplateId: PropTypes.string,
  failedRecord: PropTypes.string,
  addImportMessage: PropTypes.func,
  addImportProgressMessage: PropTypes.func,
  setRunningState: PropTypes.func,
  localService: PropTypes.object,
  location: PropTypes.object,
};

export default compose(
  connect(
    state => ({
      activeTemplateId: ImporterSelectors.getActiveTemplateId(state),
      failedRecord: ImporterSelectors.getFailedRecord(state),
    }),
    {
      addImportMessage: ImporterActions.addImportMessage,
      addImportProgressMessage: ImporterActions.addImportProgressMessage,
      setRunningState: ImporterActions.setRunningState,
    }
  ),
  withRouter,
  withLocalService
)(Provider);
