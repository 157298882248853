import { find, get } from "lodash";

import PackageContent from "~/components/PackageContent/PackageContent";
import { ProfileCard } from "~/components/ProfileCard";
import { ShipmentEntity } from "~/constants/forms";
import withReturnDetails from "~/pages/Shipment/hocs/withReturnDetails";
import withShippingInformation from "~/pages/Shipment/hocs/withShippingInformation";

import DeliveryContact from "../../components/DeliveryContact/DeliveryContact";
import DeliveryDetails from "../../components/DeliveryDetails/DeliveryDetails";
import PackageDetails from "../../components/PackageDetails/PackageDetails";
import ReturnContact from "../../components/ReturnContact/ReturnContact";
import ReturnDetails from "../../components/ReturnDetails/ReturnDetails";
import ReturnPackageDetails from "../../components/ReturnPackageDetails/ReturnPackageDetails";
import ShipmentReview from "../../components/ShipmentReview/ShipmentReview";
import ShippingInformation from "../../components/ShippingInformation/ShippingInformation";

export const getSections = ({
  generateCustomsData,
  isSwapItOrReverseItShipmentType,
  isNotifyRequired,
  isProductsRequired,
  allowedFields = {},
  isHiddenProfile,
}) => {
  let views;

  if (generateCustomsData && !isSwapItOrReverseItShipmentType) {
    views = {
      data: [
        { section: DeliveryDetails, order: 1, padding: "pr-md-2 p-0" },
        { section: PackageDetails, order: 2, padding: "pl-md-2 p-0" },
        { section: DeliveryContact, columns: 12, order: 3, padding: "p-0" },
        {
          section: ShippingInformation(withShippingInformation),
          order: 4,
          columns: 12,
          padding: "p-0",
        },
        { section: ShipmentReview, order: 5, columns: 12, padding: "p-0" },
      ],
      totalSteps: 5,
    };
  } else if (
    !generateCustomsData &&
    isSwapItOrReverseItShipmentType &&
    allowedFields[ShipmentEntity.SHIPMENT_TYPE]
  ) {
    views = {
      data: [
        {
          section: DeliveryDetails,
          order: 1,
          padding: "pr-md-2 p-0",
        },
        {
          section: PackageDetails,
          order: 2,
          padding: "pl-md-2 p-0",
        },
        {
          section: DeliveryContact,
          columns: 12,
          order: 3,
          padding: "p-0",
        },
        {
          section: ReturnDetails(withReturnDetails),
          order: 4,
          padding: "pr-md-2 p-0",
        },
        {
          section: [
            {
              section: ReturnPackageDetails,
              order: 5,
            },
            { section: ReturnContact, order: 6 },
          ],
          order: 5,
          padding: "pl-md-2 p-0",
        },
        {
          section: ShipmentReview,
          columns: 12,
          order: 7,
          padding: "p-0",
        },
      ],
      totalSteps: 7,
    };
  } else if (
    generateCustomsData &&
    isSwapItOrReverseItShipmentType &&
    allowedFields[ShipmentEntity.SHIPMENT_TYPE]
  ) {
    views = {
      data: [
        {
          section: DeliveryDetails,
          order: 1,
          padding: "pr-md-2 p-0",
        },
        {
          section: PackageDetails,
          order: 2,
          padding: "pl-md-2 p-0",
        },
        {
          section: DeliveryContact,
          columns: 12,
          order: 3,
          padding: "p-0",
        },
        {
          section: ShippingInformation(withShippingInformation),
          columns: 12,
          order: 7,
          padding: "p-0",
        },
        {
          section: ReturnDetails(withReturnDetails),
          order: 4,
          padding: "pr-md-2 p-0",
        },
        {
          section: [
            {
              section: ReturnPackageDetails,
              order: 5,
            },
            { section: ReturnContact, order: 6 },
          ],
          order: 5,
          padding: "pl-md-2 p-0",
        },
        {
          section: ShipmentReview,
          columns: 12,
          order: 8,
          padding: "p-0",
        },
      ],
      totalSteps: 8,
    };
  } else {
    views = {
      data: [
        { section: DeliveryDetails, order: 1, padding: "pr-md-2 p-0" },
        { section: PackageDetails, order: 2, padding: "pl-md-2 p-0" },
        {
          section: DeliveryContact,
          order: 3,
          columns: isNotifyRequired || isProductsRequired ? 12 : 6,
          padding:
            isNotifyRequired || isProductsRequired ? "p-0" : "pr-md-2 p-0",
        },
        {
          section: ShipmentReview,
          order: 4,
          columns: isNotifyRequired || isProductsRequired ? 12 : 6,
          padding:
            isNotifyRequired || isProductsRequired ? "p-0" : "pl-md-2 p-0",
        },
      ],
      totalSteps: 4,
    };
  }

  if (isProductsRequired || generateCustomsData) {
    views.totalSteps = ++views.totalSteps;
    views.data.splice(views.data.length - 1, 0, {
      section: PackageContent,
      order: views.totalSteps - 1,
      columns: 12,
      padding: "p-0",
    });
    const lastIndex = views.data.length - 1;
    views.data[lastIndex].order = ++views.data[lastIndex].order;
  }

  if (!isHiddenProfile) {
    views.data.unshift({
      section: ProfileCard,
      columns: 12,
      padding: "p-0",
    });
  }

  return views;
};

export const getPackageContentOrder = props => {
  const sections = get(getSections(props), "data");
  const packageContentSection = find(sections, { section: PackageContent });

  return get(packageContentSection, "order");
};
