import { forwardRef } from "react";

import PropTypes from "prop-types";

const Pen = forwardRef(({ color, size, ...rest }, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={size}
    height={size}
    {...rest}
  >
    <g clipPath="url(#clip0_177_9887)">
      <path
        d="M3 17.251V21.001H6.75L17.81 9.941L14.06 6.191L3 17.251ZM20.71 7.041C21.1 6.651 21.1 6.021 20.71 5.631L18.37 3.291C17.98 2.901 17.35 2.901 16.96 3.291L15.13 5.121L18.88 8.871L20.71 7.041Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_177_9887">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 0.00100708)"
        />
      </clipPath>
    </defs>
  </svg>
));

Pen.displayName = "Pen";
Pen.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
Pen.defaultProps = {
  color: "black",
  size: "24",
};

export default Pen;
