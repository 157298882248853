export const createReceiptFile = (
  receipts,
  { oneReceiptPerShipment, useReferenceFive },
  isOneReceiptFile
) =>
  receipts.reduce((acc, receipt, i) => {
    receipt.contents = receipt.contents.join("\r\n");
    receipt.contents = receipt.contents.replace(/\\t/g, String.fromCharCode(9));
    receipt.contents += "\r\n"; // CR00003231 - Add CRLF at the end of the receipt file
    if (oneReceiptPerShipment) {
      if (useReferenceFive) {
        acc.push({
          filename: receipt.filename,
          contents: receipt.contents,
        });
      } else {
        acc.push({
          filename: receipt.oneReceiptFileName,
          contents: receipt.contents,
        });
      }
    } else if (isOneReceiptFile) {
      if (i === 0) {
        acc.push({
          filename: receipt.filename || receipt.oneReceiptFileName,
          contents: "",
        });
      }
      const file = acc[0];
      const newLine = i !== 0 ? "\r\n" : "";
      file.contents += newLine + receipt.contents;
    }
    return acc;
  }, []);
