import { importsApi } from "~/apis";
import PrintController from "~/features/Importer/PrintController";
import { ignoreCatch } from "~/utils/async";

class PrintWorkstation extends PrintController {
  async processPrintJobs(workstationId, params) {
    const jobIds = await this.getWorkstationPrintJobs(workstationId, params);
    this.emit("printing", true);
    if (jobIds && jobIds.length > 0) {
      for (const jobId of jobIds) {
        try {
          await this.processWorkstationPrintJob(jobId, workstationId, params);
        } catch (e) {
          this.logger.error("Error processing print job for workstationId", e);
          // don't propagate error to continue process
        }
      }
    }
    this.emit("printing", false);
  }

  async getShipmentIdsForReceipt(jobId, printId) {
    const { entries } = await importsApi.getImportResultByJobId(jobId, {
      printId,
    });
    return entries
      .filter(entry => !!entry.shipmentId)
      .map(entry => entry.shipmentId);
  }

  async getWorkstationPrintJobs(workstationId) {
    return importsApi.getJobsForWorkstation(workstationId).catch(err => {
      this.logger.error(
        "Error retrieving jobs for workstationId - please try again",
        err
      );
      throw err;
    });
  }

  async processPrintReceipt(jobId, workstationId, params) {
    const { shipmentReceiptTemplate } = params;
    const receiptEnabled = shipmentReceiptTemplate
      ? shipmentReceiptTemplate.createReceipt
      : null;
    const oneReceiptPerShipment = shipmentReceiptTemplate
      ? shipmentReceiptTemplate.oneReceiptPerShipment
      : null;

    if (receiptEnabled && oneReceiptPerShipment) {
      const shipmentIds = await this.getShipmentIdsForReceipt(
        jobId,
        workstationId
      );

      shipmentIds.length &&
        (await this.receipt.generateReceiptsPerShipments(shipmentIds, params));
    } else if (receiptEnabled && !oneReceiptPerShipment) {
      await this.receipt.generateReceipt(jobId, workstationId, params);
    }
  }

  async processWorkstationPrintJob(jobId, workstationId, params) {
    const printStrings = await this.getImportLabels(
      jobId,
      workstationId,
      params
    ).catch(err => {
      this.logger.error(
        "Error retrieving jobs for workstationId - please try again",
        err
      );
      throw err;
    });
    if (printStrings.length > 0) {
      await Promise.all([
        // if receipt fails printing should work
        ignoreCatch(this.processPrintReceipt(jobId, workstationId, params)),
        this.printImportLabels(printStrings, params),
      ]);
    }
  }
}

export default PrintWorkstation;
