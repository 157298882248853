import { get, isEmpty, isNil } from "lodash";
import { getFormSyncErrors, getFormValues } from "redux-form";
import { createSelector } from "reselect";

import { REQUIRED_TYPE } from "@dpdgroupuk/mydpd-enums";

import {
  EDIT_EXPORTER_FORM,
  ExporterDetailsEntity,
  Fields,
} from "~/constants/forms";
import { ServiceModels } from "~/models";
import { ReferenceSelectors } from "~/redux";
import { getValue } from "~/utils/object";

export const getEditExporterFormState = state =>
  get(state, `form.${EDIT_EXPORTER_FORM}`, {});

export const getEditExporterFormErrors = state =>
  getFormSyncErrors(EDIT_EXPORTER_FORM)(state) || {};

export const getEditExporterFormValues = state =>
  getFormValues(EDIT_EXPORTER_FORM)(state) || {};

export const getExporterSelectedCountry = createSelector(
  ReferenceSelectors.getCountries,
  getEditExporterFormValues,
  (countries, editExporterFormValues) =>
    countries.find(
      ({ countryKey }) =>
        countryKey ===
        get(editExporterFormValues, ExporterDetailsEntity.ADDRESS.COUNTRY_CODE)
    )
);

export const getExporterModalRequiredFields = selectedService =>
  createSelector(
    getEditExporterFormValues,
    getExporterSelectedCountry,
    (editExporterFormValues, selectedCountry) => {
      let destinationTaxIdRegNo = getValue(
        editExporterFormValues,
        ExporterDetailsEntity.DESTINATION_TAX_ID_REG_NO,
        ""
      );

      if (typeof destinationTaxIdRegNo === "string") {
        destinationTaxIdRegNo = destinationTaxIdRegNo.trim();
      }

      const countryCode = get(
        editExporterFormValues,
        ExporterDetailsEntity.ADDRESS.COUNTRY_CODE,
        ""
      );
      const isPostcodeRequired = get(
        selectedCountry,
        "isPostcodeRequired",
        false
      );
      const taxRequired = get(selectedService, "taxRequired", "");
      const fdaRequired = get(selectedService, "fdaRequired", "");

      return {
        [ExporterDetailsEntity.ADDRESS.COUNTRY_CODE]: true,
        [ExporterDetailsEntity.ADDRESS.POSTCODE]:
          !isEmpty(countryCode) && isPostcodeRequired,
        [ExporterDetailsEntity.ADDRESS.STREET]: true,
        [ExporterDetailsEntity.ADDRESS.TOWN]: true,
        [ExporterDetailsEntity.EORI_NUMBER]: true,
        [ExporterDetailsEntity.FDA_NUMBER]:
          fdaRequired === REQUIRED_TYPE.MANDATORY,
        [ExporterDetailsEntity.GST_VAT_PAID]:
          taxRequired !== REQUIRED_TYPE.NEEDLESS &&
          destinationTaxIdRegNo.length > 0,
        [ExporterDetailsEntity.DESTINATION_TAX_ID_REG_NO]:
          taxRequired === REQUIRED_TYPE.MANDATORY,
      };
    }
  );

export const isVisibleTaxRequiredFields = selectedService =>
  createSelector(getEditExporterFormValues, ({ destinationTaxId = "" }) => {
    const isDestinationTaxIdRegNoEmpty =
      typeof destinationTaxId === "string"
        ? isEmpty(destinationTaxId?.trim())
        : isNil(destinationTaxId);

    return {
      [Fields.DESTINATION_TAX_ID_REG_NO]:
        selectedService?.taxRequired !== REQUIRED_TYPE.NEEDLESS,
      [Fields.GST_VAT_PAID]:
        selectedService?.taxRequired !== REQUIRED_TYPE.NEEDLESS &&
        !isDestinationTaxIdRegNoEmpty,
      [Fields.FDA_NUMBER]: ServiceModels.isVisibleFdaNumber(selectedService),
    };
  });
