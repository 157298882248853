import { useCallback, useEffect, useMemo } from "react";

import { get, isEmpty } from "lodash";
import { flow } from "lodash/fp";
import PropTypes from "prop-types";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { lifecycle, withHandlers } from "recompose";
import { compose } from "redux";
import {
  autofill,
  change,
  Field,
  propTypes,
  reduxForm,
  reset,
} from "redux-form";

import {
  Button,
  CoverModalButton,
  FormControl,
  Modal,
} from "@dpdgroupuk/mydpd-ui";

import DoubleAstericksInput from "~/components/DoubleAstericksInput/DoubleAstericksInput";
import { ReduxifiedRadioField } from "~/components/Radio/Radio";
import {
  EDIT_IMPORTER_FORM,
  IMPORTER_DETAILS,
  ImporterDetailsEntity,
} from "~/constants/forms";
import help from "~/constants/info";
import * as S from "~/constants/strings";
import PostcodeFinderInput from "~/features/PostcodeFinder/PostcodeFinderInput";
import withInvoicePostcodeAutocomplete from "~/hocs/withInvoicePostcodeAutocomplete";
import { Normalizers, ServiceModels, ShipmentModels } from "~/models";
import postcodeValidation from "~/models/validators/additionalPostcodeValidation";
import { invoiceImporterCountryValidation } from "~/models/validators/additionalValidations";
import importerDetailsSchema from "~/models/validators/importerDetailsSchema";
import { ShipmentActions } from "~/pages/Shipment/redux";
import createValidator from "~/utils/joiReduxForm";
import { touchFields } from "~/utils/reduxForm";
import { formatMessage, joinStringsWithComma } from "~/utils/string";

import CountriesAutocomplete from "../Autocomplete/CountriesAutocomplete";
import DeliveryContactCard from "../ReviewCard/cards/DeliveryContact";
import styles from "./EditImporterModal.module.scss";
import { isImporterBusiness, isImporterConsumer } from "./models";
import {
  getEditImporterFormValues,
  getImporterModalRequiredFields,
  getImporterSelectedCountry,
} from "./selectors";

const EditImporterModal = ({
  onFieldEntry,
  onPostcodeSelectionChange,
  open,
  onHide,
  onReset,
  countries,
  handleSubmit,
  requiredFields,
  formValues,
  onImporterTypeChange,
  selectedService,
  onCountryChange,
  editImporterSelectedCountry,
  onLoad,
  disabled,
}) => {
  useEffect(() => {
    onLoad();
  }, []);

  const onCancel = useCallback(() => {
    onHide();
    onReset();
  }, [onReset, onHide]);
  const isBusiness = useMemo(
    () =>
      isImporterBusiness(get(formValues, ImporterDetailsEntity.IS_BUSINESS)),
    [formValues]
  );
  const isConsumer = useMemo(
    () =>
      isImporterConsumer(get(formValues, ImporterDetailsEntity.IS_BUSINESS)),
    [formValues]
  );
  const withAutocomplete = useMemo(
    () => ShipmentModels.isCountryGB(editImporterSelectedCountry?.countryKey),
    [editImporterSelectedCountry?.countryKey]
  );
  const hideOptions = useMemo(
    () => ServiceModels.shouldHideBusinessConsumerOptions(selectedService),
    [selectedService]
  );

  return (
    <Modal
      id="edit-importer-modal"
      className={styles.editImporterDialog}
      show={open}
      onHide={onCancel}
    >
      <Modal.Header>
        {S.EDIT_IMPORTER}
        <CoverModalButton
          items={help[IMPORTER_DETAILS]}
          title={S.EDIT_IMPORTER}
        />
      </Modal.Header>
      <Modal.Body>
        <Container className="p-0">
          <Row>
            <Col>
              <Row>
                <Col>
                  <Field
                    component={CountriesAutocomplete}
                    id={ImporterDetailsEntity.ADDRESS.COUNTRY_CODE}
                    name={ImporterDetailsEntity.ADDRESS.COUNTRY_CODE}
                    label={S.IMPORTER_COUNTRY}
                    helperText
                    onCountryChange={onCountryChange}
                    countries={countries}
                    required={
                      requiredFields[ImporterDetailsEntity.ADDRESS.COUNTRY_CODE]
                    }
                    selectedCountry={editImporterSelectedCountry}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Field
                    component={PostcodeFinderInput}
                    label={S.POSTAL_ZIP_CODE}
                    labelKey={option => option.postcode}
                    optionLabelMapper={option =>
                      joinStringsWithComma([
                        option.organisation,
                        option.property,
                        option.street,
                        option.town,
                      ])
                    }
                    onSelectionChange={values => {
                      onPostcodeSelectionChange(values);
                    }}
                    showFindButton={false}
                    name={ImporterDetailsEntity.ADDRESS.POSTCODE}
                    maxLength={8}
                    withAutocomplete={withAutocomplete}
                    helperText={S.DESTINATION}
                    onBlur={onFieldEntry}
                    required={
                      requiredFields[ImporterDetailsEntity.ADDRESS.POSTCODE]
                    }
                    id={ImporterDetailsEntity.ADDRESS.POSTCODE}
                    selectedCountry={editImporterSelectedCountry}
                    disabled={disabled}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Field
                    component={FormControl.Input}
                    label={S.ORGANISATION_OR_NAME}
                    name={ImporterDetailsEntity.ADDRESS.ORGANISATION}
                    maxLength={35}
                    helperText={S.MAX_35_CHARACTERS}
                    onBlur={onFieldEntry}
                    normalize={Normalizers.emptyValueNormalize}
                    disabled={disabled}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Field
                    component={FormControl.Input}
                    label={S.ADDRESS_LINE_1}
                    name={ImporterDetailsEntity.ADDRESS.STREET}
                    maxLength={35}
                    helperText={S.MAX_35_CHARACTERS}
                    onBlur={onFieldEntry}
                    required={
                      requiredFields[ImporterDetailsEntity.ADDRESS.STREET]
                    }
                    normalize={Normalizers.emptyValueNormalize}
                    disabled={disabled}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Field
                    component={FormControl.Input}
                    label={S.ADDRESS_LINE_2}
                    name={ImporterDetailsEntity.ADDRESS.LOCALITY}
                    maxLength={35}
                    helperText={S.MAX_35_CHARACTERS}
                    onBlur={onFieldEntry}
                    normalize={Normalizers.emptyValueNormalize}
                    disabled={disabled}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Field
                    component={FormControl.Input}
                    label={S.CITY}
                    name={ImporterDetailsEntity.ADDRESS.TOWN}
                    maxLength={35}
                    helperText={S.MAX_35_CHARACTERS}
                    onBlur={onFieldEntry}
                    required={
                      requiredFields[ImporterDetailsEntity.ADDRESS.TOWN]
                    }
                    normalize={Normalizers.emptyValueNormalize}
                    disabled={disabled}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Field
                    component={FormControl.Input}
                    label={S.COUNTY_STATE}
                    name={ImporterDetailsEntity.ADDRESS.COUNTY}
                    maxLength={35}
                    helperText={S.MAX_35_CHARACTERS}
                    onBlur={onFieldEntry}
                    normalize={Normalizers.emptyValueNormalize}
                    disabled={disabled}
                  />
                </Col>
              </Row>
              {(requiredFields[ImporterDetailsEntity.CONTACT_DETAILS.EMAIL] ||
                requiredFields[
                  ImporterDetailsEntity.CONTACT_DETAILS.TELEPHONE
                ]) && (
                <Row>
                  <Col>
                    <DeliveryContactCard />
                  </Col>
                </Row>
              )}
            </Col>
            <Col>
              <Row>
                <Col>
                  <Field
                    component={FormControl.Input}
                    label={S.CONTACT_NAME}
                    name={ImporterDetailsEntity.CONTACT_DETAILS.CONTACT_NAME}
                    maxLength={35}
                    helperText={S.MAX_35_CHARACTERS}
                    onBlur={onFieldEntry}
                    required={
                      requiredFields[
                        ImporterDetailsEntity.CONTACT_DETAILS.CONTACT_NAME
                      ]
                    }
                    normalize={Normalizers.emptyValueNormalize}
                    disabled={disabled}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Field
                    component={DoubleAstericksInput}
                    label={S.CONTACT_TELEPHONE}
                    name={ImporterDetailsEntity.CONTACT_DETAILS.TELEPHONE}
                    helperText={S.MAX_15_CHARACTERS_INCLUDING_SPACES}
                    onBlur={onFieldEntry}
                    required={
                      requiredFields[
                        ImporterDetailsEntity.CONTACT_DETAILS.TELEPHONE
                      ]
                    }
                    normalize={flow(
                      Normalizers.emptyValueNormalize,
                      Normalizers.phoneNumberNormalize
                    )}
                    disabled={disabled}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Field
                    component={DoubleAstericksInput}
                    label={S.NOTIFICATION_EMAIL}
                    name={ImporterDetailsEntity.CONTACT_DETAILS.EMAIL}
                    maxLength={100}
                    helperText={S.MAX_100_CHARACTERS}
                    onBlur={onFieldEntry}
                    required={
                      requiredFields[
                        ImporterDetailsEntity.CONTACT_DETAILS.EMAIL
                      ]
                    }
                    normalize={Normalizers.emptyValueNormalize}
                    disabled={disabled}
                  />
                </Col>
              </Row>
              {!hideOptions && (
                <>
                  <Row>
                    <Col>
                      <div className={styles.importerType}>
                        {S.IMPORTER_TYPE}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Field
                        component={ReduxifiedRadioField}
                        type="radio"
                        label={S.BUSINESS}
                        name={ImporterDetailsEntity.IS_BUSINESS}
                        onChange={event => {
                          onImporterTypeChange(event.target.value);
                        }}
                        checked={isBusiness}
                        value={S.IMPORTER_BUSINESS}
                        normalize={Normalizers.emptyValueNormalize}
                      />
                    </Col>
                  </Row>
                  <Row className={styles.typeRelatedField}>
                    <Col>
                      <Field
                        component={FormControl.Input}
                        label={formatMessage(S.$_$_NUMBER, S.IMPORTERS, S.EORI)}
                        name={ImporterDetailsEntity.EORI_NUMBER}
                        onBlur={onFieldEntry}
                        required={
                          requiredFields[ImporterDetailsEntity.EORI_NUMBER]
                        }
                        disabled={isConsumer}
                        maxLength={45}
                        helperText
                        normalize={Normalizers.emptyValueNormalize}
                      />
                    </Col>
                  </Row>
                  <Row className={styles.typeRelatedField}>
                    <Col>
                      <Field
                        component={FormControl.Input}
                        label={formatMessage(S.$_$_NUMBER, S.IMPORTERS, S.VAT)}
                        name={ImporterDetailsEntity.VAT_NUMBER}
                        onBlur={onFieldEntry}
                        required={
                          requiredFields[ImporterDetailsEntity.VAT_NUMBER]
                        }
                        disabled={isConsumer}
                        maxLength={45}
                        helperText
                        normalize={Normalizers.emptyValueNormalize}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Field
                        component={ReduxifiedRadioField}
                        type="radio"
                        label={S.CONSUMER}
                        name={ImporterDetailsEntity.IS_BUSINESS}
                        onChange={event => {
                          onImporterTypeChange(event.target.value);
                        }}
                        checked={isConsumer}
                        value={S.IMPORTER_CONSUMER}
                      />
                    </Col>
                  </Row>
                  <Row className={styles.typeRelatedField}>
                    <Col>
                      <Field
                        component={FormControl.Input}
                        label={formatMessage(S.$_$_NUMBER, S.IMPORTERS, S.PID)}
                        name={ImporterDetailsEntity.PID_NUMBER}
                        onBlur={onFieldEntry}
                        required={
                          requiredFields[ImporterDetailsEntity.PID_NUMBER]
                        }
                        disabled={isBusiness}
                        maxLength={45}
                        helperText
                        normalize={Normalizers.emptyValueNormalize}
                      />
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="dark" onClick={onCancel}>
          {S.CANCEL}
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          {S.SAVE}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

EditImporterModal.propTypes = {
  ...propTypes,
  requiredFields: PropTypes.object,
  countries: PropTypes.array,
  onHide: PropTypes.func,
  onReset: PropTypes.func,
  open: PropTypes.bool,
  values: PropTypes.object,
  selectedService: PropTypes.object,
  onFieldEntry: PropTypes.func,
  onPostcodeSelectionChange: PropTypes.func,
  formValues: PropTypes.object,
  onImporterTypeChange: PropTypes.func,
  onCountryChange: PropTypes.func,
  editImporterSelectedCountry: PropTypes.object,
  disabled: PropTypes.bool,
};

export default compose(
  withInvoicePostcodeAutocomplete(EDIT_IMPORTER_FORM),
  connect(
    (state, { editImporterInitialValues, selectedService }) => ({
      initialValues: editImporterInitialValues,
      requiredFields: getImporterModalRequiredFields(selectedService)(state),
      editImporterSelectedCountry: getImporterSelectedCountry(state),
      formValues: getEditImporterFormValues(state),
    }),
    (dispatch, { selectedAddressBook }) => ({
      onImporterTypeChange: value => {
        if (isImporterBusiness(value)) {
          dispatch(
            change(EDIT_IMPORTER_FORM, ImporterDetailsEntity.PID_NUMBER, "")
          );

          if (!isEmpty(selectedAddressBook?.vatNumber)) {
            dispatch(
              autofill(
                EDIT_IMPORTER_FORM,
                ImporterDetailsEntity.VAT_NUMBER,
                selectedAddressBook.vatNumber
              )
            );
          }
          if (!isEmpty(selectedAddressBook?.eoriNumber)) {
            dispatch(
              autofill(
                EDIT_IMPORTER_FORM,
                ImporterDetailsEntity.EORI_NUMBER,
                selectedAddressBook.eoriNumber
              )
            );
          }
        }
        if (isImporterConsumer(value)) {
          dispatch(
            change(EDIT_IMPORTER_FORM, ImporterDetailsEntity.EORI_NUMBER, "")
          );
          dispatch(
            change(EDIT_IMPORTER_FORM, ImporterDetailsEntity.VAT_NUMBER, "")
          );

          if (!isEmpty(selectedAddressBook?.pid)) {
            dispatch(
              autofill(
                EDIT_IMPORTER_FORM,
                ImporterDetailsEntity.PID_NUMBER,
                selectedAddressBook.pid
              )
            );
          }
        }
      },
      onReset: () => dispatch(reset(EDIT_IMPORTER_FORM)),
      onCountryChange: selection =>
        dispatch(
          ShipmentActions.changeCountry(
            { formName: EDIT_IMPORTER_FORM },
            ImporterDetailsEntity.ADDRESS.COUNTRY_CODE,
            selection.value
          )
        ),
    })
  ),
  withHandlers({
    onSubmit:
      ({ saveInvoiceImporterDetails }) =>
      values =>
        saveInvoiceImporterDetails(values),
    onSubmitSuccess:
      ({ onHide, onReset }) =>
      () => {
        onHide();
        onReset();
      },
  }),
  reduxForm({
    form: EDIT_IMPORTER_FORM,
    shouldError: () => true,
    validate: (values, props) =>
      createValidator(importerDetailsSchema, [
        () =>
          postcodeValidation(
            props,
            props.editImporterSelectedCountry,
            ImporterDetailsEntity.ADDRESS.POSTCODE
          ),
        () =>
          invoiceImporterCountryValidation(
            props,
            ImporterDetailsEntity.ADDRESS.COUNTRY_CODE
          ),
      ])(values, props),
    enableReinitialize: true,
  }),
  lifecycle({
    componentDidMount() {
      const { dispatch, editImporterInitialValues } = this.props;
      dispatch(touchFields(EDIT_IMPORTER_FORM, editImporterInitialValues));
    },
  })
)(EditImporterModal);
