import { cloneDeep, get, isEmpty, isNumber, set } from "lodash";

import {
  ExporterDetailsEntity,
  ImporterDetailsEntity,
  ShipmentEntity,
} from "~/constants/forms";
import { Normalizers } from "~/models";
import { getDeepKeys, getValue } from "~/utils/object";

const phoneFields = [
  ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.CONTACT_DETAILS
    .TELEPHONE,
  ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS.CONTACT_DETAILS.TELEPHONE,
  ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.NOTIFICATION_DETAILS
    .MOBILE,
  ExporterDetailsEntity.CONTACT_DETAILS.TELEPHONE,
  ImporterDetailsEntity.CONTACT_DETAILS.TELEPHONE,
];

export default (state, action) => {
  switch (action.type) {
    case "@@redux-form/CHANGE":
    case "@@redux-form/AUTOFILL": {
      if (!isEmpty(action.payload) || isNumber(action.payload)) {
        const fields = cloneDeep(state.fields || {});
        set(fields, `${action.meta.field}.touched`, true);

        return {
          ...state,
          fields,
        };
      }

      return state;
    }

    case "@@redux-form/TOUCH_FIELDS":
      if (!isEmpty(action.payload)) {
        const allFields = getDeepKeys(action.payload);
        const fields = cloneDeep(state.fields || {});

        allFields.forEach(fieldPath => {
          if (getValue(action.payload, fieldPath, "").toString()) {
            set(fields, `${fieldPath}.touched`, true);
          }
        });

        return {
          ...state,
          fields,
        };
      }
      return state;

    case "@@redux-form/REINITIALIZE":
      if (!isEmpty(action.payload)) {
        const allFields = getDeepKeys(action.payload);
        const values = cloneDeep(state.values || {});
        allFields.forEach(fieldPath => {
          const value = get(action.payload, fieldPath, "");

          if (phoneFields.includes(fieldPath)) {
            set(values, fieldPath, Normalizers.phoneNumberNormalize(value));
          } else set(values, fieldPath, value);
        });

        return {
          ...state,
          values,
        };
      }
      return state;

    case "@@redux-form/UNTOUCH_EMPTY_FIELDS":
      if (!isEmpty(action.payload)) {
        const allFields = getDeepKeys(action.payload);
        const fields = cloneDeep(state.fields || {});

        allFields.forEach(fieldPath => {
          if (getValue(action.payload, fieldPath, "") === "") {
            set(fields, `${fieldPath}.touched`, false);
          }
        });

        return {
          ...state,
          fields,
        };
      }
      return state;

    default:
      return state;
  }
};
