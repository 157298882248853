import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { Field } from "redux-form";

import { FormControl } from "@dpdgroupuk/mydpd-ui";

import { ShipmentEntity } from "~/constants/forms";
import * as S from "~/constants/strings";
import { Normalizers } from "~/models";
import { formatMessage } from "~/utils/string";

const ExtendedLiability = ({
  onFieldEntry,
  selectedCurrency,
  requiredFields,
  allowedFields,
  disabledFields,
  onLiabilityChange,
}) => (
  <Row>
    <Col xs={6}>
      <Field
        component={FormControl.Dropdown}
        label={S.EXTENDED_LIABILITY}
        name={ShipmentEntity.OUTBOUND_CONSIGNMENT.LIABILITY}
        values={S.DEFAULT_BOOLEAN_KEY_VALUE}
        normalize={v => parseInt(v) === 1}
        format={v => (v ? "1" : "0")}
        helperText={S.PROTECT_AGAINST_LOSS_DAMAGE}
        onBlur={onFieldEntry}
        textTransform={S.UPPERCASE}
        disabled={disabledFields[ShipmentEntity.OUTBOUND_CONSIGNMENT.LIABILITY]}
        onChange={onLiabilityChange}
      />
    </Col>
    {allowedFields[ShipmentEntity.OUTBOUND_CONSIGNMENT.LIABILITY_VALUE] && (
      <Col xs={6}>
        <Field
          normalize={Normalizers.floatNormalize}
          component={FormControl.Input}
          label={formatMessage(S.LIABILITY_VALUE_$, selectedCurrency)}
          helperText={S.YOUR_SHIPMENT_VALUE}
          name={ShipmentEntity.OUTBOUND_CONSIGNMENT.LIABILITY_VALUE}
          required={
            requiredFields[ShipmentEntity.OUTBOUND_CONSIGNMENT.LIABILITY_VALUE]
          }
          maxLength={7}
          onBlur={onFieldEntry}
          disabled={
            disabledFields[ShipmentEntity.OUTBOUND_CONSIGNMENT.LIABILITY_VALUE]
          }
        />
      </Col>
    )}
  </Row>
);
ExtendedLiability.propTypes = {
  onFieldEntry: PropTypes.func,
  onLiabilityChange: PropTypes.func.isRequired,
  liability: PropTypes.bool,
  selectedCurrency: PropTypes.string,
  requiredFields: PropTypes.object,
  allowedFields: PropTypes.object,
  disabledFields: PropTypes.object,
};

export default ExtendedLiability;
