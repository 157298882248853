import { useCallback, useMemo } from "react";

import cloneDeep from "lodash/cloneDeep";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { useRowSelect } from "react-table/src/plugin-hooks/useRowSelect";
import { useSortBy } from "react-table/src/plugin-hooks/useSortBy";
import { Field } from "redux-form";

import { TEMPLATE_TYPE } from "@dpdgroupuk/mydpd-enums";
import { Button, DndTable, FormControl } from "@dpdgroupuk/mydpd-ui";

import { ImportsFields } from "~/constants/forms";
import * as S from "~/constants/strings";
import { ImportsModels } from "~/pages/Imports/models";
import { getValue } from "~/utils/object";

import TableContainer from "../TableContainer";
import styles from "./FieldsOptions.module.scss";

const FieldsOptions = ({
  templates,
  excludeFields,
  onClickAdd,
  onClickAddAll,
  setSelectedExcludeFields,
  selectedExcludeRowIds,
  onChangeDefinition,
  includeFields,
  templateTranslations,
  onClickRemoveAll,
  setSelectedIncludeFields,
  onClickRemove,
  onClickMoveUp,
  onClickMoveDown,
  formValues,
  selectedIncludeRowIds,
  onDragEnd,
  selectedExcludeFields,
  selectedIncludeFields,
}) => {
  // eslint-disable-next-line react/display-name
  const getAvailableFieldsFooterActions = noRowsSelected => () => (
    <Row>
      <Col className="pt-3 pl-0">
        <Button variant="dark" onClick={onClickAdd} disabled={noRowsSelected}>
          {S.ADD_FIELD}
        </Button>
        <Button variant="dark" className="ml-3" onClick={onClickAddAll}>
          {S.ADD_ALL_FIELDS}
        </Button>
      </Col>
    </Row>
  );

  const getAvailableFieldsHeaderActions = () => (
    <Col className="px-0">
      <Field
        label={S.FILE_DEFINITION}
        component={FormControl.Dropdown}
        name={ImportsFields.DEFINITION}
        values={templates}
        textTransform={S.UPPERCASE}
        onChange={onChangeDefinition}
      />
    </Col>
  );

  const includeFieldsColumns = useMemo(() => {
    if (
      getValue(formValues, ImportsFields.DEFINITION, "") ===
      TEMPLATE_TYPE.SHIPMENT.toString()
    ) {
      const res = cloneDeep(ImportsModels.FILE_ORDER_COLUMNS);
      res.splice(1, 0, {
        Header: S.TRANSLATION,
        // eslint-disable-next-line react/prop-types
        Cell: ({ row }) =>
          // eslint-disable-next-line react/prop-types
          !row.original.templateFieldId.startsWith("null") && (
            <Field
              component={FormControl.Dropdown}
              // eslint-disable-next-line react/prop-types
              name={`includeFields[${row.id}].templateFieldTranslationCode`}
              values={templateTranslations}
              classes={{
                group: styles.translationGroup,
                input: styles.translationInput,
                dropdown: styles.translationDropdown,
                option: styles.translationOption,
              }}
              textTransform={S.UPPERCASE}
            />
          ),
      });

      return res;
    }

    return ImportsModels.FILE_ORDER_COLUMNS;
  }, [getValue(formValues, ImportsFields.DEFINITION)]);

  const getIncludeFieldsFooterActions =
    noRowsSelected =>
    // eslint-disable-next-line react/prop-types, react/display-name
    ({ containerId }) => (
      <Col className="d-flex flex-wrap flex-md-wrap">
        <Col
          lg={7}
          md={12}
          xs={12}
          className={`d-flex justify-content-end justify-content-md-end justify-content-lg-start pt-3 ${styles.actionSectionShiftStart}`}
        >
          <Button
            variant="dark"
            onClick={onClickRemove}
            disabled={noRowsSelected}
          >
            {S.REMOVE_FIELD}
          </Button>
          <Button variant="dark" className="ml-3" onClick={onClickRemoveAll}>
            {S.REMOVE_ALL_FIELDS}
          </Button>
        </Col>
        <Col
          lg={5}
          md={12}
          xs={12}
          className={`d-flex justify-content-end pt-3 ${styles.actionSectionShiftEnd}`}
        >
          <Button
            variant="dark"
            onClick={() => onClickMoveUp(containerId)}
            disabled={noRowsSelected}
          >
            {S.MOVE_UP}
          </Button>
          <Button
            variant="dark"
            className="ml-3"
            onClick={() => onClickMoveDown(containerId)}
            disabled={noRowsSelected}
          >
            {S.MOVE_DOWN}
          </Button>
        </Col>
      </Col>
    );

  const tableEntities = useMemo(
    () => [
      {
        columns: ImportsModels.AVAILABLE_FIELDS_COLUMNS,
        data: excludeFields,
        droppableId: ImportsFields.EXCLUDE,
        draggableKeyId: "templateFieldId",
        headerActions: getAvailableFieldsHeaderActions,
        footerActions: getAvailableFieldsFooterActions(
          !selectedExcludeFields?.length || selectedExcludeFields?.length === 0
        ),
        getSelectedRows: setSelectedExcludeFields,
        initialState: {
          selectedRowIds: selectedExcludeRowIds,
        },
        importCardValues: {
          step: 1,
          title: S.AVAILABLE_FIELDS,
          columns: 5,
        },
        classes: {
          container: styles.availableTableContainer,
          ghostingRow: styles.ghostingRow,
        },
      },
      {
        columns: includeFieldsColumns,
        data: includeFields,
        droppableId: ImportsFields.INCLUDE,
        draggableKeyId: "templateFieldId",
        footerActions: getIncludeFieldsFooterActions(
          !selectedIncludeFields?.length || selectedIncludeFields?.length === 0
        ),
        getSelectedRows: setSelectedIncludeFields,
        initialState: {
          selectedRowIds: selectedIncludeRowIds,
        },
        importCardValues: {
          step: 2,
          title: S.DATA_FILE_FIELD_ORDER,
          columns: 7,
        },
      },
    ],
    [
      JSON.stringify(excludeFields),
      JSON.stringify(includeFields),
      JSON.stringify(selectedExcludeRowIds),
      JSON.stringify(selectedIncludeRowIds),
      JSON.stringify(selectedExcludeFields),
      JSON.stringify(selectedIncludeFields),
    ]
  );

  const onDragRowEnd = useCallback(result => {
    const { destination, reason, source } = result;
    const dragToSamePlace =
      source.droppableId === destination.droppableId &&
      source.index === destination.index;

    if (!destination || reason === "CANCEL" || dragToSamePlace) {
      return;
    }

    onDragEnd(result);
  }, []);

  return (
    <DndTable
      onDragEnd={onDragRowEnd}
      tableEntities={tableEntities}
      isDraggableTable={true}
      tableHooks={[useSortBy, useRowSelect]}
      container={TableContainer}
      containerId="fields-options-table-container"
      classes={{
        container: styles.tableContainer,
        ghostingRow: styles.ghostingRow,
      }}
    />
  );
};

FieldsOptions.propTypes = {
  onClickRemoveField: PropTypes.func,
  onClickRemoveAllFields: PropTypes.func,
  onClickMoveUp: PropTypes.func,
  onClickMoveDown: PropTypes.func,
  includeFields: PropTypes.array,
  onClickRemoveAll: PropTypes.func,
  templateTranslations: PropTypes.array,
  setSelectedIncludeFields: PropTypes.func,
  onClickRemove: PropTypes.func,
  formValues: PropTypes.object,
  selectedIncludeRowIds: PropTypes.object,
  step: PropTypes.number,
  onClickAddAllFields: PropTypes.func,
  onClickAddField: PropTypes.func,
  templates: PropTypes.array,
  onChangeDefinition: PropTypes.func,
  excludeFields: PropTypes.array,
  onClickAdd: PropTypes.func,
  onClickAddAll: PropTypes.func,
  setSelectedExcludeFields: PropTypes.func,
  onDragEnd: PropTypes.func,
  selectedExcludeRowIds: PropTypes.object,
  selectedExcludeFields: PropTypes.array,
  selectedIncludeFields: PropTypes.array,
};

export default FieldsOptions;
