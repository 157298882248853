import { REGEX } from "@dpdgroupuk/mydpd-joi-validator";

import { GB, IE } from "~/constants/strings";
export const isPostcodeOffshore = (value = "", countryCode) => {
  if (countryCode === GB) {
    const matchResult = value.match(/^BT|JE|GY|IM/i);
    return !!matchResult;
  }

  if (countryCode === IE) {
    return value.toUpperCase().startsWith("ZZ7");
  }

  return false;
};

export const isGBPostCodeFormat = postCode =>
  REGEX.GB_POSTCODE_PATTERN.test(postCode);
