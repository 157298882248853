import api from "./api";
import { PRODUCTBOOK } from "./basePaths";

export const fetchProductBookTemplate = (query, options) =>
  api.get({
    path: `${PRODUCTBOOK}/template`,
    query,
    options,
  });

export const fetchProductbookDelimiters = (query, options) =>
  api.get({
    path: `${PRODUCTBOOK}/delimiters`,
    query,
    options,
  });

export const saveProductbookTemplate = body =>
  api.post({
    path: `${PRODUCTBOOK}/template`,
    body,
  });

export const fetchProductBooks = (query, options) =>
  api.get({
    path: `${PRODUCTBOOK}/filter`,
    query,
    options,
  });

export const fetchProductBookById = (id, query, options) =>
  api.get({
    path: `${PRODUCTBOOK}/${id}`,
    query,
    options,
  });

export const insertProductBook = (body, options) =>
  api.post({
    path: PRODUCTBOOK,
    body,
    options,
  });

export const updateProductBook = (productBookId, body, options) =>
  api.put({
    path: `${PRODUCTBOOK}/${productBookId}`,
    body,
    options,
  });
