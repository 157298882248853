import get from "lodash/get";
import { compose, withHandlers } from "recompose";

import { ERROR_TYPE } from "@dpdgroupuk/mydpd-enums";
import { withSnackbar } from "@dpdgroupuk/mydpd-ui";

import styles from "~/components/ShipmentReview/ShipmentReview.module.scss";
import { SHOW_ALERT_DISPLAY_TIME } from "~/constants/snackbar";
import * as S from "~/constants/strings";
import { getInvisibleFieldErrorMessages } from "~/utils/error";

export default compose(
  withSnackbar,
  withHandlers({
    showInvisibleFieldError:
      ({ snackbar, prompt }) =>
      (func, { uiFields }) =>
      async (...args) => {
        try {
          return await func(...args);
        } catch (error) {
          if (get(error, "errors[0].displayType") === "MODAL") {
            return prompt.showWarning({
              header: S.WARNING,
              message:
                get(error, "errors[0].rule") === "IOSS"
                  ? S.IOSS_ERROR_MESSAGE
                  : error.message,
              bodyClassName: styles.promptStyle,
            });
          }
          const invalidFieldErrorMessages = getInvisibleFieldErrorMessages(
            get(error, "errors"),
            uiFields
          );

          if (
            get(error, "errors[0].code") === ERROR_TYPE.FIELD_VALIDATION &&
            invalidFieldErrorMessages.length
          ) {
            return snackbar.showAlert({
              message: invalidFieldErrorMessages.join(", "),
              displayTime: SHOW_ALERT_DISPLAY_TIME,
            });
          }
          throw error;
        }
      },
  })
);
