import { ActionTypes } from "~/redux/profile/actions";

export const initialState = {
  profiles: [],
  loaded: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH.SUCCESS: {
      return {
        ...state,
        loaded: true,
        profiles: action.payload.data,
      };
    }
    case ActionTypes.CLEAR_PROFILES: {
      return initialState;
    }
    default:
      return state;
  }
};
