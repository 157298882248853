import { createSelector } from "reselect";
import { getValue } from "~/utils/object";
import { AddressBookSelectors, UmsSelectors } from "~/redux";
import { ShipmentModels } from "~/models";
import { getFormValues } from "redux-form";
import { TOOLS_FORM } from "~/constants/forms";
import * as DATE_FORMAT from "~/constants/dateFormats";
import moment from "moment";
import { sortBy } from "lodash";
import { PRINTING_COMPLETED } from "~/constants/strings";

const emptyArray = [];

export const getValues = state => getFormValues(TOOLS_FORM)(state);

export const getAddressbookGroupsKeyValue = createSelector(
  AddressBookSelectors.getAddressBookGroups,
  groups => {
    const mappedGroups = getValue(groups, "results", emptyArray).map(
      ({ groupId: value, groupName: label }) => ({
        label,
        value,
      })
    );

    return sortBy(mappedGroups, ({ label }) => label);
  }
);

export const getTools = state => state.ui.tools;

export const getPrintingProgress = createSelector(getTools, tools =>
  getValue(tools, "printingProgress", 0)
);

export const getPrintingLabel = createSelector(getPrintingProgress, progress =>
  progress === 100 ? PRINTING_COMPLETED : `${progress}%`
);

export const getModalProgress = createSelector(
  getTools,
  tools => tools?.progressModal
);

export const getReturnAddressesKeyValue = createSelector(
  AddressBookSelectors.getAddressBooks,
  list =>
    getValue(list, "results", emptyArray).map(
      ({ addressBookId: value, shortName: label }) => ({
        label,
        value,
      })
    )
);
export const getDefaultDate = createSelector(
  UmsSelectors.getPreferences,
  ({ shippingDefaults }) => {
    const minDate = ShipmentModels.getMinShipmentDate(
      shippingDefaults.enableWeekend
    );
    const defaultDate = ShipmentModels.getWeekendNextMonday(
      moment(
        getValue(shippingDefaults, "defaultShipmentDate", minDate)
      ).toDate(),
      shippingDefaults.enableWeekend
    );

    if (
      moment(defaultDate, DATE_FORMAT.DEFAULT_DATE_FORMAT).isSameOrAfter(
        moment(minDate, DATE_FORMAT.DEFAULT_DATE_FORMAT)
      )
    ) {
      return moment(defaultDate, DATE_FORMAT.DEFAULT_DATE_FORMAT).format(
        DATE_FORMAT.DEFAULT_DATE_FORMAT
      );
    }

    return moment(minDate, DATE_FORMAT.DEFAULT_DATE_FORMAT).format(
      DATE_FORMAT.DEFAULT_DATE_FORMAT
    );
  }
);
