import { useCallback } from "react";

import { PropTypes } from "prop-types";
import { propTypes } from "redux-form";

import { PrinterPropType } from "@dpdgroupuk/mydpd-app";
import { Button } from "@dpdgroupuk/mydpd-ui";

import * as S from "~/constants/strings";

const ShipmentActions = props => {
  const {
    onClickClear,
    handleSubmit,
    allowedFields,
    onBackClick,
    onClickPrint,
    handleSubmitShipment,
    printShipment,
    clearButtonDisabled,
    printButtonsDisabled,
  } = props;

  const handleSubmitShipmentPrinter = useCallback(
    async (...parmas) => {
      try {
        return await handleSubmit(() =>
          handleSubmitShipment(() => onClickPrint(true, printShipment))
        )(...parmas);
      } catch {}
    },
    [handleSubmitShipment, onClickPrint, printShipment]
  );

  const handleSubmitShipmentNonePrinter = useCallback(
    async (...parmas) => {
      try {
        return await handleSubmit(() =>
          handleSubmitShipment(() => onClickPrint(false, printShipment))
        )(...parmas);
      } catch {}
    },
    [handleSubmitShipment, onClickPrint, printShipment]
  );

  return (
    <Button.Toolbar>
      {allowedFields.clearButton ? (
        <Button
          disabled={clearButtonDisabled}
          variant="light"
          onClick={onClickClear}
        >
          {S.CLEAR}
        </Button>
      ) : (
        <Button variant="light" onClick={onBackClick}>
          {S.CANCEL}
        </Button>
      )}
      <Button
        variant="light"
        onClick={handleSubmitShipmentNonePrinter}
        disabled={printButtonsDisabled}
      >
        {S.PRINT_LATER}
      </Button>
      <Button
        variant="light"
        onClick={handleSubmitShipmentPrinter}
        disabled={printButtonsDisabled}
      >
        {S.PRINT_NOW}
      </Button>
    </Button.Toolbar>
  );
};

ShipmentActions.propTypes = {
  printer: PrinterPropType,
  allowedFields: PropTypes.object,
  onBackClick: PropTypes.func,
  onClickPrint: PropTypes.func,
  onClickClear: PropTypes.func,
  printShipment: PropTypes.func,
  disableButton: PropTypes.bool,
  ...propTypes,
};

export default ShipmentActions;
