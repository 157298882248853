export const mapErrorsToReduxForm = err =>
  err.reduce(
    (acc, e) => ({
      ...acc,
      [e.fieldPath]: e.message,
    }),
    {}
  );

export const initializeForm = (form, values) => ({
  type: "@@redux-form/REINITIALIZE",
  meta: { form },
  payload: values,
});

export const touchFields = (form, values) => ({
  type: "@@redux-form/TOUCH_FIELDS",
  meta: { form },
  payload: values,
});

export const untouchEmptyFields = (form, values) => ({
  type: "@@redux-form/UNTOUCH_EMPTY_FIELDS",
  meta: { form },
  payload: values,
});
