import PropTypes from "prop-types";
import { Form } from "react-bootstrap";

import { Form as MyDPDForm } from "@dpdgroupuk/mydpd-ui";

import styles from "./Checkbox.module.scss";

const Checkbox = ({
  label,
  disabled,
  readonly,
  helperText,
  classes,
  input,
  ...rest
}) => (
  <MyDPDForm.MaterialContainer
    helperText={helperText}
    classes={{ ...classes, group: styles.container }}
    disabled={disabled}
    readonly={readonly}
  >
    <Form.Check
      type="checkbox"
      {...input}
      label={label}
      disabled={disabled}
      {...rest}
    />
  </MyDPDForm.MaterialContainer>
);

Checkbox.propTypes = {
  label: PropTypes.string,
  className: PropTypes.any,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  helperText: PropTypes.string,
  classes: PropTypes.object,
  input: PropTypes.object,
};

export const ReduxifiedCheckbox = ({ input, ...inputProps }) => (
  <Checkbox {...input} {...inputProps} />
);

ReduxifiedCheckbox.propTypes = {
  label: PropTypes.string,
  input: PropTypes.object,
};

export default Checkbox;
