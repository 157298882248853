import { get } from "lodash-es";
import withHandlers from "recompose/withHandlers";

import { BANNERS_TYPES } from "@dpdgroupuk/mydpd-enums";

import * as S from "~/constants/strings";
import * as routes from "~/router/constants";

import { ShipmentActions } from "../redux";

export default withHandlers({
  printShipment:
    ({
      dispatch,
      banner,
      shipment: selectedShipment,
      confirmPrintHandler,
      history,
      pageConfig,
      overlay,
    }) =>
    async printNow => {
      let consignmentNumber;
      let shipment;

      try {
        overlay.show();

        shipment = await dispatch(
          ShipmentActions.updateShipment(
            {
              formName: pageConfig.formName,
            },
            selectedShipment?.shipmentId
          )
        );

        consignmentNumber = get(shipment.consignments, "[0].consignmentNumber");

        if (printNow) {
          await confirmPrintHandler(shipment);
        }
      } finally {
        overlay.hide();
      }

      history.push(routes.REVIEW);

      // @see https://dpdgroup.slack.com/archives/G01LEMW2J6L/p1641382360041100
      banner.showByType(BANNERS_TYPES.SUCCESS, {
        message: `${S.SHIPMENT_UPDATED_SUCCESSFULLY} ${consignmentNumber}`,
        closable: true,
        showIcon: true,
        actions: [],
      });

      return shipment;
    },
});
