import { ActionTypes } from "~/pages/Imports/redux/actions";

export const initialState = {
  printSequence: [],
  templateTranslationList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.UPDATE_PRINT_SEQUENCE: {
      return {
        ...state,
        printSequence: action.payload,
      };
    }
    case ActionTypes.GET_TEMPLATE_TRANSLATIONS.SUCCESS: {
      return {
        ...state,
        templateTranslationList: action.payload.data,
      };
    }
    case ActionTypes.CLEAR_IMPORTS: {
      return initialState;
    }

    default:
      return state;
  }
};
