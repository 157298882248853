import classNames from "classnames";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";

import ReviewCard from "~/components/ReviewCard";
import styles from "~/components/ReviewCard/cards/Card.module.scss";
import {
  MANDATORY_INFORMATION_IS_NEEDED,
  MOBILE_NOTIFICATION_TEXT,
  NOTIFICATION_EMAIL,
} from "~/constants/strings";

import Title from "../../Title";

const DeliveryContactCard = ({ className }) => (
  <ReviewCard
    title={<Title />}
    classes={{
      card: classNames(className),
    }}
    rounded
  >
    <Row>
      <Col md={12}>
        <p className={classNames([styles.text, styles.uppercase])}>
          {MANDATORY_INFORMATION_IS_NEEDED}
        </p>
        <div className={classNames([styles.text, styles.uppercase])}>
          <p>** {NOTIFICATION_EMAIL}</p>
          <p>** {MOBILE_NOTIFICATION_TEXT}</p>
        </div>
      </Col>
    </Row>
  </ReviewCard>
);

DeliveryContactCard.propTypes = {
  className: PropTypes.string,
  countries: PropTypes.array,
  deliveryDetails: PropTypes.object,
  deliveryInstructions: PropTypes.string,
  action: PropTypes.node,
};

export default DeliveryContactCard;
