import { useMemo, useState } from "react";

import classNames from "classnames";
import { get } from "lodash";
import { PropTypes } from "prop-types";
import { Col, Row } from "react-bootstrap";
import { compose } from "recompose";
import { Field, propTypes } from "redux-form";

import { FormControl, Step } from "@dpdgroupuk/mydpd-ui";

import ActionLink from "~/components/ActionLink";
import EditExporterModal from "~/components/EditExporterModal";
import EditImporterModal from "~/components/EditImporterModal";
import { Edit } from "~/components/Icon";
import DetailsCard from "~/components/ReviewCard/cards/DetailsCard";
import {
  EXPORTER_DETAILS,
  Fields,
  IMPORTER_DETAILS,
  INVOICE,
  ShipmentEntity,
  SHIPPING_INFORMATION,
} from "~/constants/forms";
import help from "~/constants/info";
import { invoiceTypes } from "~/constants/invoice";
import * as S from "~/constants/strings";
import { InvoiceModels, Normalizers, ShipmentModels } from "~/models";
import { getValue } from "~/utils/object";
import { formatMessage } from "~/utils/string";

import styles from "./ShippingInformation.module.scss";

const ShippingInformation = props => {
  const {
    createShipmentValues,
    countries,
    createShipmentSyncErrors,
    selectedService,
    saveInvoiceExporterDetails,
    saveInvoiceImporterDetails,
    customerPrefs,
    exportReasons,
    selectedCountry,
    disabledFields,
    exporterInvoiceDataCard,
    importerInvoiceDataCard,
    preferences,
    selectedAddressBook,
    profile,
    touchExporterFields,
    touchImporterFields,
    allowedFields,
    selectedDeliveryCountry,
    generateCustomsData,
  } = props;
  const [editExporterModalOpen, setEditExporterModalOpen] = useState();
  const [editImporterModalOpen, setEditImporterModalOpen] = useState();
  const selectedCurrency = get(
    props,
    "createShipmentValues.outboundConsignment.currency",
    S.GBP
  );
  const isValidInvoiceSection = useMemo(
    () => ShipmentModels.isValidInvoiceSection(createShipmentSyncErrors),
    [createShipmentSyncErrors]
  );
  const invoiceImporterDetails = useMemo(
    () => ShipmentModels.getInvoiceImporterDetails(createShipmentValues),
    [createShipmentValues]
  );
  const importerDetailsBusiness = useMemo(
    () => getValue(invoiceImporterDetails, Fields.IS_BUSINESS, ""),
    [invoiceImporterDetails]
  );
  const editExporterInitialValues = useMemo(
    () => ShipmentModels.getInvoiceExporterDetails(createShipmentValues),
    [createShipmentValues]
  );
  const editImporterInitialValues = useMemo(
    () => ShipmentModels.getInvoiceImporterDetails(createShipmentValues),
    [createShipmentValues]
  );
  const shippingInfoDefaults = useMemo(
    () =>
      InvoiceModels.getShipmentInfoDefault({
        customerPrefs,
        exportReasons,
        selectedCountry,
        selectedService,
        prefsInvoiceSettings: preferences.prefsInvoiceSettings,
        shipment: createShipmentValues,
        profile,
      }),
    [
      customerPrefs,
      exportReasons,
      selectedCountry,
      selectedService,
      preferences,
      createShipmentValues,
      profile,
    ]
  );

  return (
    <Step
      totalSteps={props.totalSteps}
      step={props.step}
      withStepCounter
      complete={!props.pristine && isValidInvoiceSection}
      help={help[SHIPPING_INFORMATION]}
      title={S.SHIPPING_INFORMATION_TITLE}
      helpModalTitle={S.SHIPPING_INFORMATION_TITLE}
    >
      <Row>
        <Col md={6}>
          <Col className="px-0 pr-md-4">
            <Row>
              <Col xs={12}>
                <Field
                  component={FormControl.Dropdown}
                  name={ShipmentEntity.INVOICE.INVOICE_TYPE}
                  label={S.INVOICE_TYPE}
                  helperText
                  onBlur={props.onFieldEntry}
                  values={invoiceTypes}
                  required
                  textTransform={S.UPPERCASE}
                  disabled={disabledFields[ShipmentEntity.INVOICE.INVOICE_TYPE]}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Field
                  component={FormControl.Dropdown}
                  name={ShipmentEntity.INVOICE.EXPORT_REASON}
                  label={S.REASON_FOR_EXPORT}
                  helperText
                  onBlur={props.onFieldEntry}
                  values={shippingInfoDefaults.exportReasons}
                  required={
                    props.requiredFields[ShipmentEntity.INVOICE.EXPORT_REASON]
                  }
                  textTransform={S.UPPERCASE}
                  disabled={
                    disabledFields[ShipmentEntity.INVOICE.EXPORT_REASON]
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Field
                  component={FormControl.Dropdown}
                  name={ShipmentEntity.INVOICE.INTERNATIONAL_BILLING_TERMS}
                  label={S.INTERNATIONAL_BILLING_TERMS}
                  helperText
                  onBlur={props.onFieldEntry}
                  values={shippingInfoDefaults.internationalBillings}
                  required
                  textTransform={S.UPPERCASE}
                  disabled={
                    disabledFields[
                      ShipmentEntity.INVOICE.INTERNATIONAL_BILLING_TERMS
                    ]
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Field
                  component={FormControl.Input}
                  name={ShipmentEntity.INVOICE.INVOICE_REFERENCE}
                  label={S.INVOICE_REFERENCE}
                  helperText={S.MAX_30_CHARACTERS}
                  onBlur={props.onFieldEntry}
                  maxLength={30}
                  disabled={
                    disabledFields[ShipmentEntity.INVOICE.INVOICE_REFERENCE]
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <Field
                  component={FormControl.Input}
                  name={ShipmentEntity.INVOICE.NUMBER_OF_PARCELS}
                  label={S.TOTAL_PACKAGES_NUMBER}
                  maxLength={2}
                  normalize={Normalizers.integerNormalize}
                  onBlur={props.onFieldEntry}
                  required
                  disabled={
                    disabledFields[ShipmentEntity.INVOICE.NUMBER_OF_PARCELS]
                  }
                  helperText
                />
              </Col>
              <Col xs={6}>
                <Field
                  component={FormControl.Input}
                  name={ShipmentEntity.INVOICE.TOTAL_WEIGHT}
                  label={S.TOTAL_WEIGHT}
                  normalize={Normalizers.floatNormalize}
                  maxLength={5}
                  onBlur={props.onFieldEntry}
                  required
                  disabled={disabledFields[ShipmentEntity.INVOICE.TOTAL_WEIGHT]}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Field
                  component={FormControl.Input}
                  name={ShipmentEntity.INVOICE.SHIPPING_COST}
                  label={formatMessage(S.SHIPPING_COST_$, selectedCurrency)}
                  helperText
                  onBlur={props.onFieldEntry}
                  normalize={Normalizers.floatNormalize}
                  maxLength={11}
                  required={
                    props.requiredFields[ShipmentEntity.INVOICE.SHIPPING_COST]
                  }
                  disabled={
                    disabledFields[ShipmentEntity.INVOICE.SHIPPING_COST]
                  }
                />
              </Col>
            </Row>
          </Col>
        </Col>
        <Col md={6} className={classNames(styles.card_wrapper)}>
          <Row>
            <Col
              xs={12}
              className={styles.col}
              id={`${INVOICE}.${EXPORTER_DETAILS}`}
            >
              <DetailsCard
                action={
                  allowedFields.editExporter ? (
                    <ActionLink
                      text={S.EDIT}
                      icon={Edit}
                      onClick={() => setEditExporterModalOpen(true)}
                    />
                  ) : null
                }
                title={S.EXPORTER_INVOICE_DETAILS}
                data={exporterInvoiceDataCard}
              />
            </Col>
            <Col
              xs={12}
              className={styles.col}
              id={`${INVOICE}.${IMPORTER_DETAILS}`}
            >
              <DetailsCard
                action={
                  allowedFields.editImporter ? (
                    <ActionLink
                      text={S.EDIT}
                      icon={Edit}
                      onClick={() => setEditImporterModalOpen(true)}
                    />
                  ) : null
                }
                title={S.IMPORTER_INVOICE_DETAILS}
                data={importerInvoiceDataCard}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {editExporterModalOpen && (
        <EditExporterModal
          open={editExporterModalOpen}
          onHide={() => setEditExporterModalOpen(false)}
          saveInvoiceExporterDetails={saveInvoiceExporterDetails}
          editExporterInitialValues={editExporterInitialValues}
          countries={countries}
          importerDetailsBusiness={importerDetailsBusiness}
          selectedService={selectedService}
          onLoad={touchExporterFields}
          disabled={disabledFields.invoiceDetails}
        />
      )}
      {editImporterModalOpen && (
        <EditImporterModal
          open={editImporterModalOpen}
          onHide={() => setEditImporterModalOpen(false)}
          saveInvoiceImporterDetails={saveInvoiceImporterDetails}
          editImporterInitialValues={editImporterInitialValues}
          countries={countries}
          selectedService={selectedService}
          selectedAddressBook={selectedAddressBook}
          onLoad={touchImporterFields}
          selectedDeliveryCountry={selectedDeliveryCountry}
          generateCustomsData={generateCustomsData}
          disabled={disabledFields.invoiceDetails}
        />
      )}
    </Step>
  );
};

ShippingInformation.propTypes = {
  ...propTypes,
  customerPrefs: PropTypes.object,
  disabledFields: PropTypes.object,
  exporterInvoiceDataCard: PropTypes.array,
  importerInvoiceDataCard: PropTypes.array,
  selectedDeliveryCountry: PropTypes.object,
  touchExporterFields: PropTypes.func,
  touchImporterFields: PropTypes.func,
  generateCustomsData: PropTypes.bool,
};

ShippingInformation.defaultProps = {
  touchExporterFields: () => {},
  touchImporterFields: () => {},
  generateCustomsData: false,
};

export default shippingInformationHOC =>
  compose(shippingInformationHOC)(ShippingInformation);
