import { get } from "lodash";
import { createSelector } from "reselect";

import { UmsSelectors } from "../ums";

export const getAuthUser = state => state.app.auth.user;

export const getCustomsPrefsInvoiceSettings = createSelector(
  UmsSelectors.getPreferences,
  ({ prefsInvoiceSettings }) => prefsInvoiceSettings
);

export const getEnableWeekend = createSelector(
  UmsSelectors.getPreferences,
  ({ shippingDefaults }) => get(shippingDefaults, "enableWeekend", false)
);

export const getInvoiceType = createSelector(
  getCustomsPrefsInvoiceSettings,
  ({ invoiceType }) => invoiceType
);

export const getUserDestinationTaxIdRegNo = createSelector(
  getCustomsPrefsInvoiceSettings,
  prefsInvoiceSettings => get(prefsInvoiceSettings, "destinationTaxIdRegNo", "")
);

export const getBusinessUnit = createSelector(
  getAuthUser,
  ({ businessId }) => businessId
);

export const getAppRoles = createSelector(
  getAuthUser,
  ({ appRoles }) => appRoles
);
