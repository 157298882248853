import { createRef, useCallback, useEffect, useMemo, useState } from "react";

import moment from "moment";
import PropTypes from "prop-types";
import { Col, Container, Row } from "react-bootstrap";
import { compose } from "recompose";

import { Button, FormControl } from "@dpdgroupuk/mydpd-ui";

import { DEFAULT_DATE_FORMAT } from "~/constants/dateFormats";
import * as S from "~/constants/strings";
import { formatISODate } from "~/utils/date";

import withManifestActions from "../../hocs/withManifestActions";
import ManifestsTable from "../ManifestsTable";
import Modal from "../../../../components/ShipmentReviewModal";
import { getManifestTableColumns } from "./models";
import { formatManifest } from "./../../models";

const PrintManifestModal = ({
  open,
  onCancel,
  onClickGenerateManifest,
  onFetchManifests,
  onClickRePrintManifest,
}) => {
  const [date, setDate] = useState(moment().format(DEFAULT_DATE_FORMAT));
  const [manifests, setManifests] = useState([]);

  const datePickerInputRef = createRef(null);

  const onGenerateManifest = useCallback(async () => {
    const printed = await onClickGenerateManifest(formatISODate(date));

    if (printed) {
      onCancel(printed);
    }
  }, [date, onCancel]);

  const fetchManifests = useCallback(
    async date => {
      const manifests = await onFetchManifests(formatISODate(date));
      const preparedManifest = manifests.map(formatManifest);

      setManifests(preparedManifest);
    },
    [onFetchManifests]
  );

  const onReprint = useCallback(
    manifestId => onClickRePrintManifest(manifestId, onCancel),
    [onClickRePrintManifest]
  );

  useEffect(() => {
    open && fetchManifests(date);
  }, [open, date]);

  const tableColumns = useMemo(() => getManifestTableColumns(onReprint), []);

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      title={S.PRINT_MANIFEST}
      saveButton={{ visible: false }}
      size="lg"
    >
      <Container className="p-0">
        <Row className="align-items-baseline">
          <Col xs={4}>
            <FormControl.DatePicker
              inputRef={datePickerInputRef}
              label={S.SHIPMENT_DATE}
              input={{
                value: date,
                onChange: setDate,
              }}
              dateFormat={DEFAULT_DATE_FORMAT}
            />
          </Col>
          <Col>
            <Button variant="primary" onClick={onGenerateManifest}>
              {S.GENERATE_MANIFEST}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <ManifestsTable data={manifests} columns={tableColumns} />
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

PrintManifestModal.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  onFetchManifests: PropTypes.func,
  onClickRePrintManifest: PropTypes.func,
  onClickGenerateManifest: PropTypes.func,
};

export default compose(withManifestActions)(PrintManifestModal);
