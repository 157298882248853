import { Form } from "react-bootstrap";
import { propTypes } from "redux-form";

import styles from "./Radio.module.scss";

export const ReduxifiedRadioField = ({ input, ...rest }) => (
  <Form.Check
    className={styles.mydpdRadio}
    {...input}
    {...rest}
    checked={input.checked}
  />
);

ReduxifiedRadioField.propTypes = {
  ...propTypes,
};
