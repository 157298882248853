import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
  createPayloadAction,
} from "@dpdgroupuk/redux-action-creator";

import { manifestApi } from "~/apis";

export const ManifestActionTypes = createActionTypes("MANIFEST", {
  FETCH: createAsyncActionTypes("FETCH"),
  CREATE: createAsyncActionTypes("CREATE"),
  CLEAR_ALL: "CLEAR_ALL",
  REPRINT: createAsyncActionTypes("REPRINT"),
});

export const fetchManifests = createAsyncAction(
  query => manifestApi.getManifests(query),
  ManifestActionTypes.FETCH
);

export const createManifest = createAsyncAction(
  query => manifestApi.createManifest(query),
  ManifestActionTypes.CREATE
);

export const generateManifestLabelById = createAsyncAction(
  manifestId => manifestApi.generateManifestLabelById(manifestId),
  ManifestActionTypes.REPRINT
);

export const clearManifestAll = () =>
  createPayloadAction(ManifestActionTypes.CLEAR_ALL);
