import { useCallback, useEffect, useMemo, useState } from "react";

import classNames from "classnames";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { useColumnOrder } from "react-table/src/plugin-hooks/useColumnOrder";
import { useFlexLayout } from "react-table/src/plugin-hooks/useFlexLayout";
import { useResizeColumns } from "react-table/src/plugin-hooks/useResizeColumns";
import { useRowSelect } from "react-table/src/plugin-hooks/useRowSelect";
import { lifecycle, withProps, withState } from "recompose";
import { compose } from "redux";
import { propTypes } from "redux-form";

import { useUserHeaderStatePersist } from "@dpdgroupuk/mydpd-app";
import { BANNERS_TYPES } from "@dpdgroupuk/mydpd-enums";
import {
  Banner,
  Button,
  DndTable,
  Step,
  withSnackbar,
} from "@dpdgroupuk/mydpd-ui";

import { PackageContentModels } from "~/components/PackageContent/models";
import { PACKAGE_CONTENT, ShipmentEntity } from "~/constants/forms";
import help from "~/constants/info";
import * as S from "~/constants/strings";
import withProductChange from "~/hocs/withProductChange";
import { ParcelModels, ShipmentModels } from "~/models";
import { separateThousandWithComma } from "~/utils/number";
import { getValue } from "~/utils/object";

import EditProduct from "./EditProduct";
import styles from "./PackageContent.module.scss";

const INITIAL_PRODUCT_VALUES = {
  packageNumber: "0",
  countryOfOrigin: S.GB,
};

const PackageContent = ({
  createShipmentValues,
  totalSteps,
  step,
  isParcelsDataValid,
  products,
  setSelectedProduct,
  selectedProduct,
  parcelsTotalValue,
  shippingFreightCost,
  deleteSelectedProduct,
  createShipmentSyncErrors,
  createProduct,
  selectedCountry,
  countries,
  editProductRequiredFields,
  editProductHiddenFields,
  selectedService,
  abortController,
  onFieldEntry,
  preferences,
  pageConfig,
  hasSubmitFailed,
  numberOfParcels,
  productPackagesCount,
  disabledFields,
  selectedServiceCode,
  selectedNetworkCode,
}) => {
  const [selectedRow, setSelectedRow] = useState();

  const selectedCurrency = get(
    createShipmentValues,
    "outboundConsignment.currency",
    S.GBP
  );
  const currencyLabel = useMemo(
    () => ShipmentModels.getCurrencyLabel(selectedCurrency),
    [selectedCurrency]
  );

  const deselectRow = useCallback(() => {
    selectedRow && selectedRow.toggleRowSelected();
    setSelectedRow();
  }, [selectedRow]);

  const addNewProduct = useCallback(() => {
    deselectRow();
    setSelectedProduct(INITIAL_PRODUCT_VALUES);
  }, [setSelectedProduct, deselectRow]);

  const handleSelectedProduct = useCallback(
    (e, row) => {
      if (isEqual(row.original, selectedProduct)) {
        setSelectedProduct();
        setSelectedRow();
      } else {
        setSelectedProduct(row.original);
        setSelectedRow(row);
      }
    },
    [selectedProduct]
  );

  const onCancel = useCallback(() => {
    deselectRow();
    setSelectedProduct();
  }, [deselectRow]);

  const onDeleteSelectedProduct = useCallback(() => {
    deselectRow();
    deleteSelectedProduct();
  }, [deselectRow]);

  const parcelsProductsErrors = useMemo(
    () =>
      PackageContentModels.getParcelsProductsErrors(createShipmentSyncErrors),
    [createShipmentSyncErrors]
  );
  const isAdditionalCommCodeCheckRequired = useMemo(
    () =>
      ShipmentModels.isAdditionalCommCodeCheckRequired(
        selectedCountry,
        selectedService
      ),
    [selectedCountry, selectedService]
  );

  const isInvalidRow = useCallback(
    row =>
      getValue(
        parcelsProductsErrors,
        `[${row.packageNumber}].products[${row.productIndex}]`
      ),
    [parcelsProductsErrors]
  );

  useEffect(() => {
    if (!products?.length) {
      addNewProduct();
    }
  }, []);

  const defaultColumn = useMemo(
    () => ({
      minWidth: 50,
      width: 100,
      maxWidth: 400,
    }),
    []
  );

  const columns = useMemo(
    () => PackageContentModels.getProductTableColumns(selectedCurrency),
    [selectedCurrency]
  );

  const isPackageCountValid = useMemo(
    () => productPackagesCount === parseInt(numberOfParcels),
    [productPackagesCount, numberOfParcels]
  );
  const isValid = useMemo(
    () => isParcelsDataValid && isPackageCountValid,
    [isParcelsDataValid, isPackageCountValid]
  );

  return (
    <Step
      totalSteps={totalSteps}
      step={step}
      withStepCounter
      complete={isValid}
      help={help[PACKAGE_CONTENT]}
      title={S.CUSTOMS_PACKAGE_CONTENTS_PRODUCT_DETAILS_TITLE}
      helpModalTitle={S.CUSTOMS_PACKAGE_CONTENTS_PRODUCT_DETAILS_TITLE}
    >
      <Row>
        <Col md={6} className={["pb-4 pr-md-4 pb-md-0"]}>
          <DndTable
            data={products}
            columns={columns}
            onClickRow={handleSelectedProduct}
            classes={{
              container: styles.tableContainer,
              table: styles.table,
              invalidRow: styles.invalidTableRow,
            }}
            tableHooks={[
              useFlexLayout,
              useRowSelect,
              useResizeColumns,
              useColumnOrder,
              useUserHeaderStatePersist,
            ]}
            initialState={{
              storageKey: "packageContent",
              selectedRowIds: { [selectedRow?.id]: selectedRow?.isSelected },
            }}
            selectOnlyOneRow={true}
            defaultColumn={defaultColumn}
            isInvalidRow={isInvalidRow}
          >
            <Container fluid className={classNames("p-3")}>
              <Row>
                <Col lg={8} xs={12}>
                  <Row>
                    <Col
                      xs={9}
                      className={classNames(styles.text, styles.textFixedWidth)}
                    >
                      {S.TOTAL_PACKAGES}
                    </Col>
                    <Col xs={3} className={styles.text}>
                      {numberOfParcels}
                    </Col>
                  </Row>
                  <Row className="mt-0">
                    <Col
                      xs={9}
                      className={classNames(styles.text, styles.textFixedWidth)}
                    >
                      {S.PACKAGE_CONTENTS_DECLARED}
                    </Col>
                    <Col
                      xs={3}
                      className={classNames(
                        styles.text,
                        hasSubmitFailed &&
                          !isPackageCountValid &&
                          styles.textError
                      )}
                    >
                      {productPackagesCount} of {numberOfParcels}
                    </Col>
                  </Row>
                </Col>
                <Col lg={4} xs={12} className={styles.textAlignEnd}>
                  <Row>
                    <Col
                      xs={7}
                      lg={12}
                      className={classNames(styles.text, styles.textFixedWidth)}
                    >
                      {S.TOTAL_VALUE}
                    </Col>
                    <Col
                      xs={5}
                      lg={12}
                      className={classNames(styles.text, styles.text400)}
                    >
                      {currencyLabel}
                      {separateThousandWithComma(parcelsTotalValue)}
                    </Col>
                  </Row>
                  <Row className="mt-0">
                    <Col
                      xs={7}
                      lg={12}
                      className={classNames(styles.text, styles.textFixedWidth)}
                    >
                      {S.SHIPPING_FREIGHT_COST}
                    </Col>
                    <Col
                      xs={5}
                      lg={12}
                      className={classNames(styles.text, styles.text400)}
                    >
                      {currencyLabel}
                      {separateThousandWithComma(shippingFreightCost)}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-4 justify-content-between">
                <Col xs="auto">
                  <Button
                    disabled={
                      !selectedProduct ||
                      isEqual(selectedProduct, INITIAL_PRODUCT_VALUES) ||
                      disabledFields.packageContent
                    }
                    variant="danger"
                    onClick={onDeleteSelectedProduct}
                  >
                    {S.DELETE}
                  </Button>
                </Col>
                <Col xs="auto">
                  <Button
                    variant="dark"
                    disabled={
                      (selectedProduct &&
                        isNaN(selectedProduct.productIndex)) ||
                      disabledFields.packageContent
                    }
                    onClick={addNewProduct}
                  >
                    {S.ADD_NEW_PRODUCT}
                  </Button>
                </Col>
              </Row>
            </Container>
          </DndTable>
        </Col>
        <Col md={6}>
          <EditProduct
            pageConfig={pageConfig}
            initialValues={selectedProduct}
            currencyLabel={currencyLabel}
            selectedCurrency={selectedCurrency}
            createShipmentValues={createShipmentValues}
            isAdditionalCommCodeCheckRequired={
              isAdditionalCommCodeCheckRequired
            }
            countries={countries}
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
            deleteSelectedProduct={deleteSelectedProduct}
            createProduct={createProduct}
            selectedDeliveryCountry={selectedCountry}
            onCancel={onCancel}
            editProductRequiredFields={editProductRequiredFields}
            editProductHiddenFields={editProductHiddenFields}
            abortController={abortController}
            onFieldEntry={onFieldEntry}
            preferences={preferences}
            disabledFields={disabledFields}
            // NOTE: Do not remove the fields below, needed for the commodity code validation
            selectedServiceCode={selectedServiceCode}
            selectedNetworkCode={selectedNetworkCode}
            selectedService={selectedService}
          />
        </Col>
      </Row>
    </Step>
  );
};

PackageContent.propTypes = {
  ...propTypes,
};

export default compose(
  withState("selectedProduct", "setSelectedProduct"),
  connect(
    null,
    (dispatch, { selectedProduct, setSelectedProduct, deleteProduct }) => ({
      deleteSelectedProduct: () => {
        deleteProduct(selectedProduct);
        setSelectedProduct();
      },
    })
  ),
  withProps(({ createShipmentValues, products }) => ({
    numberOfParcels: get(
      createShipmentValues,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.NUMBER_OF_PARCELS,
      0
    ),
    productPackagesCount: ParcelModels.countProductPackages(products),
  })),
  withSnackbar,
  Banner.withBanner,
  withProductChange,
  lifecycle({
    componentWillUnmount() {
      this.props.banner.hideByType(BANNERS_TYPES.ALERT);
    },
  })
)(PackageContent);
