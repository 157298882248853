import get from "lodash/get";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";
import {
  compose,
  lifecycle,
  withHandlers,
  withProps,
  withState,
} from "recompose";
import { change, propTypes, reduxForm, SubmissionError } from "redux-form";

import {
  UserDataActions,
  UserDataSelectors,
  withAppLoader,
  withAppUserPreferences,
} from "@dpdgroupuk/mydpd-app";
import {
  Button,
  Card,
  Legend,
  Main,
  withOverlay,
  withPrompt,
  withSnackbar,
} from "@dpdgroupuk/mydpd-ui";

import { TOOLS_FIELDS, TOOLS_FORM } from "~/constants/forms";
import * as S from "~/constants/strings";
import EditCard from "~/pages/Tools/components/EditCard";
import GroupsCard from "~/pages/Tools/components/GroupsCard";
import createValidator from "~/utils/joiReduxForm";
import { connect } from "react-redux";
import {
  AddressBookActions,
  AddressBookSelectors,
  ProfilesActions,
  ProfilesSelectors,
  ReferenceActions,
  ReferenceSelectors,
  UmsSelectors,
} from "~/redux";

import { isWeekend } from "~/utils/date";
import { getValue } from "~/utils/object";
import * as DATE_FORMAT from "~/constants/dateFormats";

import { GroupDispatchModels } from "~/pages/Tools/pages/GroupDispatch/models";

import { isEmpty } from "lodash/lang";
import ValidationErrors from "~/pages/Tools/components/ValidationErrors";
import { formatMessage } from "~/utils/string";
import { some } from "lodash/collection";
import { getErrorMessage } from "~/utils/error";
import { SHOW_ALERT_DISPLAY_TIME } from "~/constants/snackbar";
import Checkbox from "~/components/Checkbox";
import { convertToBool } from "~/models/normolizers/normalizers";
import useToggle from "~/hooks/useToggle";
import CreateLabelsModal from "~/pages/Tools/components/CreateLabelsModal";
import { ShipmentModels } from "~/models";
import withUpdateDate from "~/pages/Shipment/hocs/withUpdateDate";
import { ToolsActions, ToolsSelectors } from "~/pages/Tools/redux";
import withPrinterTypeValidator from "~/pages/Tools/hocs/withPrinterTypeValidator";
import {
  getGroupStartLabelQuery,
  mapErrorsByAddresses,
  updateEndLabelQuery,
} from "~/pages/Tools/models";

const BasePage = props => (
  <Main>
    <Main.Body>
      <Legend
        leftMessage={S.PLEASE_COMPLETE_ALL_REQUIRED_FIELDS}
        rightMessage={get(props.authUser, "user.username")}
        classes={{ container: "p-0" }}
      />
      <Card.Stack fluid>
        <Col md={6} className="pr-md-2 p-0">
          <GroupsCard
            addressbookGroups={props.addressbookGroups}
            profiles={props.profiles}
            groupAddresses={props.groupAddresses}
            onGroupsChange={props.onGroupsChange}
            onChangeColumnsSelection={props.onChangeColumnsSelection}
            setSelectedAddresses={props.setSelectedAddresses}
            selectedColumnNames={props.selectedColumnNames}
            title={props.groupsTitle}
            isHiddenProfile={props.isHiddenProfile}
          />
        </Col>
        <Col md={6} className="pl-md-2 p-0">
          <EditCard
            groupDispatchServices={props.groupDispatchServices}
            shippingSettings={props.shippingSettings}
            getDatesRange={props.getDatesRange}
            filterDate={props.filterDate}
            isGroupsField={props.isGroupsField}
            returnAddresses={props.returnAddresses}
          />
        </Col>
      </Card.Stack>
    </Main.Body>
    <Main.Footer className="dark">
      <Button.Toolbar>
        <Button
          variant="light"
          disabled={props.bannerId}
          onClick={props.handleSubmit(props.onClickCreateLabel)}
        >
          {S.CREATE_LABELS}
        </Button>
      </Button.Toolbar>
    </Main.Footer>
    <CreateLabelsModal
      open={props.createLabelsModalToggle.value}
      onCancel={props.onClickCancelPrint}
      onClose={props.onClickClose}
    />
  </Main>
);

BasePage.propTypes = {
  ...propTypes,
  authUser: PropTypes.object,
  shippingSettings: PropTypes.object,
  createLabelsModalToggle: PropTypes.object,
  handleSubmit: PropTypes.func,
  onGroupsChange: PropTypes.func,
  filterDate: PropTypes.func,
  getDatesRange: PropTypes.func,
  onClickCreateLabel: PropTypes.func,
  onClickCancelPrint: PropTypes.func,
  onClickClose: PropTypes.func,
  isHiddenProfile: PropTypes.bool,
  isGroupsField: PropTypes.bool,
  addressbookGroups: PropTypes.array,
  returnAddresses: PropTypes.array,
  groupDispatchServices: PropTypes.array,
  groupAddresses: PropTypes.array,
  bannerId: PropTypes.string,
  groupsTitle: PropTypes.string,
};

export default compose(
  withState("selectedAddresses", "setSelectedAddresses", []),
  withAppUserPreferences,
  withOverlay,
  withPrompt,
  withSnackbar,
  withPrinterTypeValidator,
  withProps(() => ({
    createLabelsModalToggle: useToggle(),
  })),
  connect(
    state => ({
      profiles: ProfilesSelectors.getProfilesKeyValue(state),
      isHiddenProfile: ProfilesSelectors.isHiddenProfile(state),
      formValues: ToolsSelectors.getValues(state),
      addressbookGroups: ToolsSelectors.getAddressbookGroupsKeyValue(state),
      groups: AddressBookSelectors.getAddressBookGroups(state),
      groupDispatchServices: ReferenceSelectors.getGroupDispatchServices(state),
      groupAddresses: AddressBookSelectors.getGroupAddresses(state),
      shippingSettings: UmsSelectors.getShippingSettings(state),
      preferences: UmsSelectors.getPreferences(state),
      advancedCustomerConfiguration:
        UmsSelectors.getAdvancedCustomerConfiguration(state),
      selectedColumnNames:
        UserDataSelectors.getItem(state.app, "groupDispatchColumns") ||
        GroupDispatchModels.DEFAULT_GROUP_DISPATCH_COLUMNS,
    }),
    (
      dispatch,
      {
        notifier,
        overlay,
        appUser: { user },
        selectedAddresses,
        abortController,
        setAbortController,
        setIsCanceled,
        createLabelsModalToggle,
        isGroupsField,
      }
    ) => ({
      fetchProfiles: notifier.runAsync(
        () => dispatch(ProfilesActions.fetchProfiles()),
        { entityName: S.PROFILES }
      ),
      fetchAddressbookGroups: notifier.runAsync(
        () => dispatch(AddressBookActions.fetchGroups()),
        { entityName: S.ADDRESS_BOOK_GROUPS }
      ),
      fetchGroupDispatchServices: notifier.runAsync(
        () =>
          dispatch(
            ReferenceActions.fetchGroupDispatchServices({
              businessUnit: user.businessId,
            })
          ),
        { entityName: S.GROUP_DISPATCH_SERVICES }
      ),
      fetchAddressesByAddressBookGroupId: notifier.runAsync(
        id =>
          dispatch(AddressBookActions.fetchAddressesByAddressBookGroupId(id)),
        { entityName: S.GROUP_ADDRESSES }
      ),
      fetchStartLabel: query =>
        dispatch(
          ToolsActions.fetchStartLabel(query, isGroupsField, {
            signal: abortController.signal,
          })
        ),
      fetchEndLabel: query =>
        dispatch(
          ToolsActions.fetchEndLabel(query, isGroupsField, {
            signal: abortController.signal,
          })
        ),
      createJobId: async () => {
        try {
          return await dispatch(
            ToolsActions.createJobId(isGroupsField, {
              signal: abortController.signal,
            })
          );
        } catch (error) {
          createLabelsModalToggle.switchOff();
          throw error;
        }
      },
      runJobId: async (jobId, address) => {
        try {
          return await dispatch(
            ToolsActions.runJobId(jobId, address, isGroupsField, {
              signal: abortController.signal,
            })
          );
        } catch (error) {
          createLabelsModalToggle.switchOff();
          throw error;
        }
      },
      getResultByJobId: jobId =>
        dispatch(
          ToolsActions.getResultByJobId(jobId, isGroupsField, {
            signal: abortController.signal,
          })
        ),
      fetchLabelsByJobId: (jobId, query) =>
        dispatch(
          ToolsActions.fetchLabelsByJobId(jobId, query, {
            signal: abortController.signal,
          })
        ),
      validateAddresses: () => {
        if (isEmpty(selectedAddresses)) {
          throw new SubmissionError({
            addresses: S.SELECT_ADDRESSES,
          });
        }
      },
      onChangeDisableErrorMessages: event =>
        localStorage.setItem("disableErrorMessages", event.target.checked),
      onChangeColumnsSelection: columns =>
        dispatch(UserDataActions.setItem("groupDispatchColumns", columns)),
      clearPage: () => {
        dispatch(AddressBookActions.clearAddressBookGroups());
        dispatch(ReferenceActions.clearReference());
        dispatch(ProfilesActions.clearProfiles());
      },
      onClickCancelPrint: () => {
        setAbortController(abortController.abort());
        localStorage.setItem("isCanceledPrinting", "true");
        createLabelsModalToggle.switchOff();
        setAbortController(new AbortController());
      },
      onClickClose: () => createLabelsModalToggle.switchOff(),
    })
  ),
  reduxForm({
    form: TOOLS_FORM,
    validate: (
      values,
      { appUser: { user }, shippingSettings, validationSchema }
    ) =>
      createValidator(validationSchema(values, { user, shippingSettings }))(
        values
      ),
    shouldError: () => true,
    onSubmitFail: (errors, dispatch, submitError, props) => {
      const addressesError = getValue(errors, "addresses", "");

      if (getValue(errors, "printerType", "")) {
        props.showPrinterTypeBanner();
      }

      if (addressesError) {
        props.snackbar.showWarning({
          message: addressesError,
        });
      }

      props.createLabelsModalToggle.switchOff();
    },
  }),
  withHandlers(() => {
    const handlers = {
      printLabelsByJobId:
        ({
          currentPrinterType,
          printer,
          fetchLabelsByJobId,
          isGroupsField,
          createLabelsModalToggle,
        }) =>
        async jobId => {
          try {
            const { printString } = await fetchLabelsByJobId(jobId, {
              printerType: currentPrinterType,
              printSequence: 0,
              disableBadLabels: false,
              fileImportMode: isGroupsField
                ? S.GROUP_DISPATCH_MODE
                : S.BULK_REVERSE_MODE,
            });
            await printer.printLabels(printString);
          } catch (error) {
            createLabelsModalToggle.switchOff();
            throw error;
          }
        },
      printConfirmation: props => mappedErrors =>
        new Promise((resolve, reject) =>
          props.prompt.showConfirmation({
            header: formatMessage(
              S.VALIDATION_ERROR_$,
              mappedErrors.errors.length
            ),
            message: <ValidationErrors data={mappedErrors} />,
            footer: (
              <Checkbox
                label={S.DISABLE_ERROR_MESSAGES}
                onChange={props.onChangeDisableErrorMessages}
              />
            ),
            closeButtonText: S.CANCEL,
            confirmButtonText: S.CONTINUE,
            footerClassName: "mt-5 p-0",
            onConfirm: resolve,
            onReject: reject,
          })
        ),
      printStartLabel:
        ({
          groups,
          formValues,
          selectedAddresses,
          printer,
          fetchStartLabel,
          createLabelsModalToggle,
        }) =>
        async () => {
          const startLabelQuery = getGroupStartLabelQuery({
            groups,
            formValues,
            selectedAddresses,
          });

          try {
            const { data } = await fetchStartLabel(startLabelQuery);
            await printer.printLabels(data);
          } catch (error) {
            createLabelsModalToggle.switchOff();
            throw error;
          }
        },
      printEndLabel:
        ({ printer, fetchEndLabel, createLabelsModalToggle }) =>
        async query => {
          try {
            const { data } = await fetchEndLabel(query);
            await printer.printLabels(data);
          } catch (error) {
            createLabelsModalToggle.switchOff();
            throw error;
          }
        },
      onGroupsChange: ({
        dispatch,
        fetchAddressesByAddressBookGroupId,
        overlay,
        groups,
        groupDispatchServices,
        isGroupsField,
      }) =>
        overlay.showWhile(
          async (event, newValue) => {
            await fetchAddressesByAddressBookGroupId(newValue);

            if (isGroupsField) {
              const selectedGroup = groups.results.find(
                data => data.groupId === newValue
              );
              const isServiceAvailable = some(groupDispatchServices, [
                "value",
                selectedGroup.networkCode,
              ]);
              dispatch(
                change(
                  TOOLS_FORM,
                  TOOLS_FIELDS.REQUIRED_SERVICE,
                  isServiceAvailable ? selectedGroup.networkCode : null
                )
              );
            }
          },
          { entityName: S.GROUP_ADDRESSES }
        ),
      filterDate:
        ({ preferences }) =>
        date =>
          getValue(preferences, "shippingDefaults.enableWeekend", false) ||
          !isWeekend(date),
      getDatesRange:
        ({ preferences, advancedCustomerConfiguration }) =>
        () =>
          ShipmentModels.getAvailableDateRange(
            getValue(preferences, "shippingDefaults.enableWeekend", false),
            getValue(
              advancedCustomerConfiguration,
              "forwardDateOver7Days",
              false
            )
          ),
      onClickCreateLabel: props =>
        props.notifier.runAsync(
          async () => {
            const {
              dispatch,
              validatePrinterType,
              validateAddresses,
              formValues,
              selectedAddresses,
              snackbar,
              groups,
              createJobId,
              runJobId,
              getResultByJobId,
              createLabelsModalToggle,
            } = props;

            validateAddresses();
            validatePrinterType();

            localStorage.setItem("disableErrorMessages", "false");
            localStorage.setItem("isCanceledPrinting", "false");
            dispatch(ToolsActions.resetPrintingProgress());
            let shouldPrintEndLabel = false;
            const isCanceled = convertToBool(
              localStorage.getItem("isCanceledPrinting")
            );
            const groupName = groups.results.find(
              data => data.groupId === formValues[TOOLS_FIELDS.GROUP]
            ).groupName;
            const endLabelQuery = {
              groupName,
              successfulPrintedAddress: 0,
              successfulPrintedLabels: 0,
              failedAddress: 0,
              failedParcels: 0,
            };
            createLabelsModalToggle.switchOn();
            const totalLabels = selectedAddresses.length + 2; // includes start and finish label
            const unit = parseInt((1 / totalLabels) * 100);
            let progress;

            await handlers.printStartLabel(props)();
            progress = unit;
            dispatch(ToolsActions.setPrintingProgress(progress));

            for (let i = 0; i < selectedAddresses.length; i++) {
              // NOTE: check before new iteration
              if (isCanceled) {
                break;
              }

              const address = selectedAddresses[i];
              const {
                data: { jobId },
              } = await createJobId();
              await runJobId(jobId, address);
              const { success, failure, entries } =
                await getResultByJobId(jobId);

              if (
                failure &&
                !convertToBool(localStorage.getItem("disableErrorMessages"))
              ) {
                const mappedErrors = mapErrorsByAddresses(entries, address);

                try {
                  await handlers.printConfirmation(props)(mappedErrors);
                  await handlers.printLabelsByJobId(props)(jobId);
                  // NOTE: must change shouldPrintEndLabel after last printed label
                  shouldPrintEndLabel = !isCanceled;
                } catch (error) {
                  shouldPrintEndLabel = false;
                  createLabelsModalToggle.switchOff();

                  if (error) {
                    snackbar.showAlert({
                      message: getErrorMessage(error, S.LABELS),
                      displayTime: SHOW_ALERT_DISPLAY_TIME,
                    });
                  }
                  // stop printing rest labels
                  break;
                }
              } else {
                if (success) {
                  await handlers.printLabelsByJobId(props)(jobId);
                  // NOTE: must change shouldPrintEndLabel after last printed label
                  shouldPrintEndLabel = !isCanceled;
                }
              }

              updateEndLabelQuery({
                query: endLabelQuery,
                success,
                failure,
              });

              progress = unit + progress;
              dispatch(ToolsActions.setPrintingProgress(progress));
            }

            if (shouldPrintEndLabel) {
              await handlers.printEndLabel(props)(endLabelQuery);
              dispatch(ToolsActions.setPrintingProgress(100));
            }
          },
          {
            entityName: S.LABELS,
          }
        ),
    };

    return handlers;
  }),
  withAppLoader({
    loadFn: async ({
      fetchProfiles,
      fetchAddressbookGroups,
      fetchGroupDispatchServices,
      currentPrinterType,
      showPrinterTypeBanner,
      initializeForm,
      fetchBulkData,
      setAbortController,
    }) => {
      setAbortController(new AbortController());
      // @see https://it.dpduk.live/version/customer-shipping/mydpd-last-5/sprint-1/diag_DV9T4uGFYFxiR6qf.html
      await Promise.all([
        fetchProfiles(),
        fetchAddressbookGroups(),
        fetchGroupDispatchServices(),
      ]);
      fetchBulkData && (await fetchBulkData());

      if (currentPrinterType === 0) {
        showPrinterTypeBanner();
      }

      initializeForm();
    },
  }),
  withUpdateDate({
    formName: TOOLS_FORM,
    dateFormat: DATE_FORMAT.DAY_DEFAULT_DATE_FORMAT,
    fieldPath: TOOLS_FIELDS.SHIPMENT_DATE,
  }),
  lifecycle({
    componentWillUnmount() {
      this.props.bannerId && this.props.banner.hideById(this.props.bannerId);
      this.props.clearPage();
    },
  })
)(BasePage);
