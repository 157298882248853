import { connect } from "react-redux";
import { compose, lifecycle, withHandlers, withState } from "recompose";
import { autofill } from "redux-form";

import { ShipmentSelectors } from "~/pages/Shipment/redux";
import { initializeForm } from "~/utils/reduxForm";

export default compose(
  withState("isBlurred", "setIsBlurred", false),
  withState("prevFormValues", "setPrevFormValues", {}),
  connect((state, { pageConfig }) => ({
    activeField: ShipmentSelectors.getFormActiveField(state, pageConfig),
  })),
  withHandlers({
    reInitializeForm:
      ({ dispatch, pageConfig }) =>
      ({ activeField, ...data }) => {
        !activeField &&
          dispatch(autofill(pageConfig.formName, "activeField", "initialize"));
        const autofillFields = () =>
          new Promise(resolve => {
            dispatch(initializeForm(pageConfig.formName, data));
            resolve();
          });
        autofillFields().then(() => {
          !activeField &&
            dispatch(autofill(pageConfig.formName, "activeField", undefined));
        });
      },
    setPrevValues: props => prevFormValues =>
      props.setPrevFormValues(prevFormValues),
    getPrevValues: props => () => props.prevFormValues,
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (this.props.activeField !== prevProps.activeField) {
        this.props.dispatch(
          autofill(
            this.props.pageConfig.formName,
            "activeField",
            this.props.activeField
          )
        );
      }
    },
  })
);
