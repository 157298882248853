import { ActionTypes } from "./actions";

export const initialState = {
  shipment: {
    data: {},
    isLoading: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_BY_SCAN_REFERENCE.REQUEST: {
      return {
        ...state,
        shipment: {
          ...state.shipment,
          isLoading: true,
        },
      };
    }
    case ActionTypes.FETCH_BY_SCAN_REFERENCE.FAILURE: {
      return {
        ...state,
        shipment: {
          isLoading: false,
        },
      };
    }
    case ActionTypes.FETCH_BY_SCAN_REFERENCE.SUCCESS: {
      return {
        ...state,
        shipment: {
          data: action.payload,
          isLoading: false,
        },
      };
    }
    case ActionTypes.CLEAR: {
      return initialState;
    }
    default:
      return state;
  }
};
