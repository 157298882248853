import PropTypes from "prop-types";
import { Col, Container, Row } from "react-bootstrap";
import { compose } from "redux";
import { Field, propTypes, reduxForm } from "redux-form";

import { Button, FormControl, Modal } from "@dpdgroupuk/mydpd-ui";

import { ImportsFields, SAVE_TEMPLATE_FORM } from "~/constants/forms";
import * as S from "~/constants/strings";
import saveTemplateSchema from "~/models/validators/saveTemplateSchema";
import createValidator from "~/utils/joiReduxForm";
import { formatMessage } from "~/utils/string";

const SaveTemplateModal = ({ open, onHide, handleSubmit }) => (
  <Modal show={open} centered size="sm" onHide={onHide}>
    <Modal.Header>{S.SAVE_TEMPLATE}</Modal.Header>
    <Modal.Body>
      <Container className="p-0">
        <Row>
          <Col>
            <Field
              component={FormControl.Input}
              label={S.TEMPLATE_NAME}
              name={ImportsFields.TEMPLATE_NAME}
              maxLength={50}
              helperText={formatMessage(S.MAX_$_CHARACTERS, 50)}
              required
            />
          </Col>
        </Row>
      </Container>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="dark" onClick={onHide}>
        {S.CANCEL}
      </Button>
      <Button variant="primary" onClick={handleSubmit}>
        {S.SAVE}
      </Button>
    </Modal.Footer>
  </Modal>
);

SaveTemplateModal.propTypes = {
  ...propTypes,
  onHide: PropTypes.func,
  onReset: PropTypes.func,
  open: PropTypes.bool,
  values: PropTypes.object,
  onFieldEntry: PropTypes.func,
};

export default compose(
  reduxForm({
    form: SAVE_TEMPLATE_FORM,
    validate: createValidator(saveTemplateSchema),
    onSubmit: ({ templateName }, dispatch, { saveTemplate }) =>
      saveTemplate(templateName),
    onSubmitSuccess: (values, dispatch, { onHide }) => onHide(),
  })
)(SaveTemplateModal);
