import { useRef } from "react";

import PropTypes from "prop-types";

import { Table } from "@dpdgroupuk/mydpd-ui";

import styles from "./ManifestsTable.module.scss";

const ManifestsTable = ({ data, columns }) => {
  const tableRef = useRef({});
  return (
    <Table
      columns={columns}
      data={data}
      rowId="manifestId"
      ref={tableRef}
      tableContainerClassName={styles.tableContainer}
    />
  );
};

ManifestsTable.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
};

export default ManifestsTable;
