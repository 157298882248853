import { UmsActionTypes } from "./actions";

export const initialState = {
  preferences: {
    prefsConfigSettings: {},
    prefsInvoiceSettings: {},
  },
  customer: {},
  customerPrefs: {},
  customerServices: {},
  advancedConfiguration: {},
  shippingSettings: {},
  customerAddress: {},
  securitySettings: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UmsActionTypes.FETCH_PREFERENCES.SUCCESS: {
      return {
        ...state,
        preferences: action.payload.data,
      };
    }
    case UmsActionTypes.FETCH_CUSTOMER.SUCCESS: {
      return {
        ...state,
        customer: action.payload.data,
      };
    }
    case UmsActionTypes.FETCH_CUSTOMER_PREFS.SUCCESS: {
      return {
        ...state,
        customerPrefs: action.payload.data,
      };
    }
    case UmsActionTypes.FETCH_CUSTOMER_SERVICES.SUCCESS: {
      return {
        ...state,
        customerServices: action.payload.data,
      };
    }
    case UmsActionTypes.FETCH_ADVANCED_CONFIGURATION.SUCCESS: {
      return {
        ...state,
        advancedConfiguration: action.payload.data,
      };
    }
    case UmsActionTypes.FETCH_SHIPPING_SETTINGS.SUCCESS: {
      return {
        ...state,
        shippingSettings: action.payload.data,
      };
    }
    case UmsActionTypes.FETCH_CUSTOMER_ADDRESS.SUCCESS: {
      return {
        ...state,
        customerAddress: action.payload.data,
      };
    }
    case UmsActionTypes.FETCH_SECURITY_SETTINGS.SUCCESS: {
      return {
        ...state,
        securitySettings: action.payload.data,
      };
    }
    default:
      return state;
  }
};
