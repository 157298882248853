import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import { searchAddresses } from "~/apis/reference";

export const ActionTypes = createActionTypes("ADDRESS_LOOKUP", {
  SEARCH: createAsyncActionTypes("SEARCH"),
});

export const search = createAsyncAction(
  (data, options) => searchAddresses(data, options).then(({ data }) => data),
  ActionTypes.SEARCH
);
