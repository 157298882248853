import { useMemo } from "react";

import { PropTypes } from "prop-types";
import { Col, Row } from "react-bootstrap";
import { Field, propTypes } from "redux-form";

import { FormControl, Step } from "@dpdgroupuk/mydpd-ui";

import DomesticService from "~/components/DomesticService";
import {
  INBOUND_CONSIGNMENT_UPPERCASE,
  PACKAGE_RETURN_DETAILS,
  ShipmentEntity,
} from "~/constants/forms";
import help from "~/constants/info";
import * as S from "~/constants/strings";
import { Normalizers, ShipmentModels } from "~/models";

const ReturnPackageDetails = props => {
  const { createShipmentSyncErrors, disabledFields, allowedFields } = props;
  const isValidReturnPackageDetailsSection = useMemo(
    () =>
      ShipmentModels.isValidReturnPackageDetailsSection(
        createShipmentSyncErrors
      ),
    [createShipmentSyncErrors]
  );

  return (
    <Step
      totalSteps={props.totalSteps}
      step={props.step}
      withStepCounter
      isLoading={props.isLoadingInboundServices}
      complete={!props.pristine && isValidReturnPackageDetailsSection}
      title={S.PACKAGE_RETURN_DETAILS}
      help={help[PACKAGE_RETURN_DETAILS]}
      helpModalTitle={S.PACKAGE_RETURN_DETAILS}
    >
      {allowedFields[
        ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DESCRIPTION
      ] && (
        <Row>
          <Col>
            <Field
              component={FormControl.Input}
              label={S.ITEM_DESCRIPTION}
              name={ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DESCRIPTION}
              helperText={S.MAX_30_CHARACTERS}
              maxLength={30}
              onBlur={props.onFieldEntry}
              disabled={
                disabledFields[
                  ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DESCRIPTION
                ]
              }
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col xs={6}>
          <Field
            maxLength={2}
            normalize={Normalizers.integerNormalize}
            component={FormControl.Input}
            label={S.TOTAL_PACKAGES_NUMBER}
            name={ShipmentEntity.INBOUND_CONSIGNMENT.NUMBER_OF_PARCELS}
            onBlur={props.onFieldEntry}
            disabled={
              disabledFields[
                ShipmentEntity.INBOUND_CONSIGNMENT.NUMBER_OF_PARCELS
              ]
            }
            required
            helperText
          />
        </Col>
        <Col xs={6}>
          <Field
            normalize={Normalizers.floatNormalize}
            maxLength={5}
            component={FormControl.Input}
            helperText={ShipmentModels.getRoundedHelperText(
              props.selectedReturnCountry
            )}
            label={S.TOTAL_WEIGHT}
            name={ShipmentEntity.INBOUND_CONSIGNMENT.TOTAL_WEIGHT}
            onBlur={props.onFieldEntry}
            disabled={
              disabledFields[ShipmentEntity.INBOUND_CONSIGNMENT.TOTAL_WEIGHT]
            }
            required
          />
        </Col>
      </Row>
      <Field
        component={DomesticService}
        name={ShipmentEntity.INBOUND_CONSIGNMENT.NETWORK_CODE}
        shipmentSection={INBOUND_CONSIGNMENT_UPPERCASE}
        values={props.inboundServices}
        productDisabled={
          disabledFields[ShipmentEntity.INBOUND_CONSIGNMENT.PRODUCT]
        }
        serviceDisabled={
          disabledFields[ShipmentEntity.INBOUND_CONSIGNMENT.NETWORK_CODE]
        }
        onBlur={props.onFieldEntry}
        required={
          props.requiredFields[ShipmentEntity.INBOUND_CONSIGNMENT.NETWORK_CODE]
        }
        textTransform={S.UPPERCASE}
        onChange={props.onInboundNetworkChange}
      />
      <Row>
        <Col>
          <Field
            component={FormControl.Input}
            label={S.YOUR_REFERENCE_1}
            name={ShipmentEntity.INBOUND_CONSIGNMENT.SHIPPING_REF_1}
            helperText={S.MAX_25_CHARACTERS}
            maxLength={25}
            onBlur={props.onFieldEntry}
            disabled={
              disabledFields[ShipmentEntity.INBOUND_CONSIGNMENT.SHIPPING_REF_1]
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            component={FormControl.Input}
            label={S.YOUR_REFERENCE_2}
            name={ShipmentEntity.INBOUND_CONSIGNMENT.SHIPPING_REF_2}
            helperText={S.MAX_25_CHARACTERS}
            maxLength={25}
            onBlur={props.onFieldEntry}
            disabled={
              disabledFields[ShipmentEntity.INBOUND_CONSIGNMENT.SHIPPING_REF_2]
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            component={FormControl.Input}
            label={S.YOUR_REFERENCE_3}
            name={ShipmentEntity.INBOUND_CONSIGNMENT.SHIPPING_REF_3}
            helperText={S.MAX_25_CHARACTERS}
            maxLength={25}
            onBlur={props.onFieldEntry}
            disabled={
              disabledFields[ShipmentEntity.INBOUND_CONSIGNMENT.SHIPPING_REF_3]
            }
          />
        </Col>
      </Row>
    </Step>
  );
};

ReturnPackageDetails.propTypes = {
  onFieldEntry: PropTypes.func,
  returnServiceValues: PropTypes.array,
  returnProductValues: PropTypes.array,
  disabledFields: PropTypes.object,
  allowedFields: PropTypes.object,
  ...propTypes,
};

export default ReturnPackageDetails;
