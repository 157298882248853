import classNames from "classnames";
import PropTypes from "prop-types";
import { useFlexLayout } from "react-table";

import { Button, DndTable, Modal } from "@dpdgroupuk/mydpd-ui";

import { COLOR_LEVEL } from "~/constants/importActivity";
import * as S from "~/constants/strings";

import styles from "./TestImportFileModal.module.scss";

const TestImportFileModal = ({
  open,
  title,
  onCancel,
  className,
  okButton,
  size,
  testImportFileResult,
}) => {
  const fieldOrderColumns = [
    {
      Header: S.ISSUES,
      accessor: "failureReasons",
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original } }) => (
        <div className={classNames("h-100 px-3 d-flex align-items-center")}>
          {/* eslint-disable-next-line react/prop-types */}
          {original.failureReasons}
        </div>
      ),
    },
  ];

  return (
    <Modal
      id={`test-import-file-modal-${title}`}
      dialogClassName={className}
      show={open}
      onHide={onCancel}
      size={size}
    >
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DndTable
          data={testImportFileResult}
          columns={fieldOrderColumns}
          classes={{
            table: styles.table,
            container: styles.tableContainer,
          }}
          getRowProps={row => {
            const original = row.original;
            return {
              style: {
                background: COLOR_LEVEL[original.level],
              },
            };
          }}
          tableHooks={[useFlexLayout]}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button.ButtonGroup>
          {okButton.visible && (
            <Button variant="primary" onClick={onCancel}>
              {okButton.text}
            </Button>
          )}
        </Button.ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
};

TestImportFileModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  title: PropTypes.string,
  size: PropTypes.string,
  okButton: PropTypes.shape({
    text: PropTypes.string,
    visible: PropTypes.bool,
  }),
  className: PropTypes.string,
  testImportFileResult: PropTypes.array,
};

TestImportFileModal.defaultProps = {
  okButton: {
    text: S.OK,
    visible: true,
  },
  size: "md",
};

export default TestImportFileModal;
