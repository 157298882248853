import { get } from "lodash-es";
import { createSelector } from "reselect";

export const getData = state => state.ui.failedImports;

export const getFailedImportsList = createSelector(
  getData,
  ({ failedImportsList }) => failedImportsList.data
);

export const getSelectedFailedShipment = state =>
  get(state, "ui.failedImports.selectedShipment.data", {});

export const getIsSelectedFailedShipmentLoading = state =>
  get(state, "ui.failedImports.selectedShipment.isLoading", false);
