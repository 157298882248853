import noop from "lodash/noop";

import { Button } from "@dpdgroupuk/mydpd-ui";

import * as S from "~/constants/strings";

import { ShipmentListPropTypes } from "../../models";

const ActionButtons = props => (
  <Button.Toolbar>
    <Button
      disabled={!props.actionPermissions.allowDelete}
      variant="danger"
      onClick={props.onClickDelete}
    >
      {S.DELETE}
    </Button>
    <Button
      variant="light"
      disabled={!props.actionPermissions.allowVoid}
      onClick={props.onClickVoid}
    >
      {S.VOID}
    </Button>
    <Button
      variant="light"
      disabled={!props.actionPermissions.allowUnvoid}
      onClick={props.onClickUnvoid}
    >
      {S.UNVOID}
    </Button>
    <Button
      variant="light"
      disabled={!props.actionPermissions.allowChangeDate}
      onClick={props.onClickChangeDate}
    >
      {S.CHANGE_DATE}
    </Button>
    <Button
      variant="light"
      disabled={!props.actionPermissions.allowPrintShipment}
      onClick={props.onClickPrintShipment}
    >
      {S.PRINT_SHIPMENT}
    </Button>
    {props.eodUser ? (
      <>
        <Button
          variant="light"
          disabled={!props.actionPermissions.allowHold}
          onClick={props.onClickHold}
        >
          {S.HOLD}
        </Button>
        <Button
          variant="light"
          disabled={!props.actionPermissions.allowUnhold}
          onClick={props.onClickUnhold}
        >
          {S.UNHOLD}
        </Button>
      </>
    ) : (
      <>
        <Button variant="light" onClick={props.onClickPrintUnprinted}>
          {S.PRINT_UNPRINTED}
        </Button>
        <Button variant="light" onClick={props.onClickPrintManifest}>
          {S.PRINT_MANIFEST}
        </Button>
      </>
    )}
    <Button
      variant="light"
      href={props.sendToFileHref}
      disabled={false}
      onClick={props.onClickSendToFile}
    >
      {S.SEND_TO_FILE}
    </Button>
  </Button.Toolbar>
);

ActionButtons.defaultProps = {
  onClickDelete: noop,
  onClickVoid: noop,
  onClickUnvoid: noop,
  onClickChangeDate: noop,
  onClickPrintShipment: noop,
  onClickHold: noop,
  onClickSendToFile: noop,
  onClickUnhold: noop,
  actionPermissions: {},
  sendToFileHref: "",
};

ActionButtons.propTypes = ShipmentListPropTypes;

export default ActionButtons;
