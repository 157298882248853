import classNames from "classnames";
import { Col, Row } from "react-bootstrap";
import { Field, reduxForm, propTypes as reduxFormPropTypes } from "redux-form";

import { Button, FormControl } from "@dpdgroupuk/mydpd-ui";

import { DEFAULT_DATE_FORMAT } from "~/constants/dateFormats";
import { ShipmentReviewFilterFields } from "~/constants/forms";
import * as S from "~/constants/strings";
import { Normalizers } from "~/models";
import shipmentReview from "~/models/validators/shipmentReview";
import createValidator from "~/utils/joiReduxForm";

import { ShipmentListPropTypes } from "../../models";
import styles from "./Filters.module.scss";

const Filters = props => (
  <form onSubmit={props.handleSubmit(props.onSubmit)}>
    <div className={classNames(styles.searchForm)}>
      <Row>
        <Col lg={10} md={9}>
          <Row>
            <Col>
              <Field
                component={FormControl.Input}
                label={S.SEARCH_TERMS}
                name={ShipmentReviewFilterFields.SEARCH_CRITERIA}
                maxLength={150}
                helperText={S.MAX_150_CHARACTERS}
                onBlur={props.onFieldEntry}
                type="search"
                normalize={Normalizers.toUppercase}
                classes={{ input: styles.searchInput }}
              />
            </Col>
            <Col>
              <Field
                readOnly
                component={FormControl.DatePicker}
                label={S.DATE}
                name={ShipmentReviewFilterFields.SEARCH_DATE}
                dateFormat={DEFAULT_DATE_FORMAT}
                onClear={props.handleSubmit(props.onClearShipmentDate)}
                clearable
              />
            </Col>
          </Row>
        </Col>
        <Col lg={2} md={3}>
          <Button.Toolbar className={classNames(styles.searchFormButtons)}>
            <Button
              variant="primary"
              disabled={props.submitting || props.invalid || !props.dirty}
              type="submit"
            >
              {S.SEARCH}
            </Button>
            <Button
              variant="dark"
              disabled={props.isPristine}
              onClick={props.onClear}
            >
              {S.CLEAR}
            </Button>
          </Button.Toolbar>
        </Col>
      </Row>
    </div>
  </form>
);

Filters.propTypes = {
  ...reduxFormPropTypes,
  ...ShipmentListPropTypes,
};

export default reduxForm({
  enableReinitialize: true,
  validate: createValidator(shipmentReview),
})(Filters);
