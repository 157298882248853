import { ActionTypes } from "./actions";

export const initialState = {
  printingProgress: 0,
  progressModal: {
    open: false,
    title: null,
    label: null,
    progress: 0,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_PROGRESS_MODAL: {
      return {
        ...state,
        progressModal: {
          ...state.progressModal,
          open: true,
          ...action.payload,
        },
      };
    }
    case ActionTypes.RESET_PROGRESS_MODAL: {
      return {
        ...state,
        progressModal: initialState.progressModal,
      };
    }
    case ActionTypes.SET_PRINTING_PROGRESS: {
      return {
        ...state,
        printingProgress: action.payload,
      };
    }
    case ActionTypes.RESET_PRINTING_PROGRESS: {
      return {
        ...state,
        printingProgress: initialState.printingProgress,
      };
    }
    default:
      return state;
  }
};
