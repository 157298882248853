import { forwardRef } from "react";

import PropTypes from "prop-types";

const Photo = forwardRef(({ color, size, ...rest }, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={size}
    height={size}
    fill={color}
    {...rest}
  >
    <path
      d="M21 3.00101H3C2 3.00101 1 4.00101 1 5.00101V19.001C1 20.101 1.9 21.001 3 21.001H21C22 21.001 23 20.001 23 19.001V5.00101C23 4.00101 22 3.00101 21 3.00101ZM21 18.921C20.98 18.951 20.94 18.981 20.92 19.001H3V5.08101L3.08 5.00101H20.91C20.94 5.02101 20.97 5.06101 20.99 5.08101V18.921H21ZM11 15.511L8.5 12.501L5 17.001H19L14.5 11.001L11 15.511Z"
      fill={color}
    />
  </svg>
));

Photo.displayName = "Photo";
Photo.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
Photo.defaultProps = {
  color: "black",
  size: "24",
};

export default Photo;
