import api from "~/apis/api";
import { ADDRESSBOOK, SHIPPING_TEMPLATES } from "~/apis/basePaths";

export const fetchTemplateTypes = (query, options) =>
  api.get({
    path: `${SHIPPING_TEMPLATES}/types`,
    query,
    options,
  });

export const fetchDelimiters = (query, options) =>
  api.get({
    path: `${SHIPPING_TEMPLATES}/delimiters`,
    query,
    options,
  });

export const fetchAddressbookDelimiters = (query, options) =>
  api.get({
    path: `${ADDRESSBOOK}/import/delimiters`,
    query,
    options,
  });

export const saveDpdDirectTemplate = body =>
  api.post({
    path: `${SHIPPING_TEMPLATES}/dpddirectproduct`,
    body,
  });

export const saveShipmentProductTemplate = body =>
  api.post({
    path: `${SHIPPING_TEMPLATES}/product`,
    body,
  });

export const createShipmentImportTemplate = body =>
  api.post({
    path: `${SHIPPING_TEMPLATES}/shipment`,
    body,
  });

export const updateShipmentImportTemplate = (templateId, body) =>
  api.put({
    path: `${SHIPPING_TEMPLATES}/${templateId}`,
    body,
  });

export const saveShipmentReceiptTemplate = body =>
  api.post({
    path: `${SHIPPING_TEMPLATES}/shipment-receipt`,
    body,
  });

export const fetchDpdDirectTemplate = (query, options) =>
  api.get({
    path: `${SHIPPING_TEMPLATES}/dpddirectproduct`,
    query,
    options,
  });

export const fetchShipmentProductTemplate = (query, options) =>
  api.get({
    path: `${SHIPPING_TEMPLATES}/product`,
    query,
    options,
  });

export const fetchShipmentImportTemplates = (query, options) =>
  api.get({
    path: `${SHIPPING_TEMPLATES}/shipment`,
    query,
    options,
  });

export const fetchShipmentTemplateTranslations = (query, options) =>
  api.get({
    path: `${SHIPPING_TEMPLATES}/translations`,
    query,
    options,
  });

export const fetchShipmentImportMetaTemplate = (query, options) =>
  api.get({
    path: `${SHIPPING_TEMPLATES}/shipment/meta`,
    query,
    options,
  });

export const fetchShipmentImportTemplateById = (id, query, options) =>
  api.get({
    path: `${SHIPPING_TEMPLATES}/${id}`,
    query,
    options,
  });

export const deleteShipmentImportTemplateById = (id, options) =>
  api.delete({
    path: `${SHIPPING_TEMPLATES}/${id}`,
    options,
  });

export const fetchShipmentReceiptTemplate = (query, options) =>
  api.get({
    path: `${SHIPPING_TEMPLATES}/shipment-receipt`,
    query,
    options,
  });

export const fetchShipmentExportTemplate = (query, options) =>
  api.get({
    path: `${SHIPPING_TEMPLATES}/shipment-export`,
    query,
    options,
  });

export const fetchReturnAddressbookTemplate = (query, options) =>
  api.get({
    path: `${ADDRESSBOOK}/import/template`,
    query,
    options,
  });

export const saveShipmentExportTemplate = body =>
  api.post({
    path: `${SHIPPING_TEMPLATES}/shipment-export`,
    body,
  });

export const saveAddressbookTemplate = body =>
  api.post({
    path: `${ADDRESSBOOK}/import/template`,
    body,
  });

export const uploadShipmentTemplate = (body, query) =>
  api.sendFormData({
    path: `${SHIPPING_TEMPLATES}/shipment/import`,
    body,
    query,
  });

export const uploadShipmentReceiptTemplate = body =>
  api.sendFormData({
    path: `${SHIPPING_TEMPLATES}/shipment-receipt/csv`,
    body,
  });
