import classNames from "classnames";
import PropTypes from "prop-types";
import { Card, Col, Row } from "react-bootstrap";

import styles from "./ReviewCard.module.scss";

const ReviewCard = ({ action, title, classes, children, rounded }) => (
  <Card
    className={classNames(
      styles.reviewCard,
      rounded && styles.rounded,
      classes.card
    )}
  >
    <Card.Title
      className={classNames(classes.title && classes.title, styles.title)}
    >
      <Row>
        <Col md={action ? 7 : 12}>{title}</Col>
        {action && (
          <Col md={5} className={styles.label}>
            {action}
          </Col>
        )}
      </Row>
    </Card.Title>
    <Card.Body
      className={classNames(classes.body && classes.body, styles.body)}
    >
      {children}
    </Card.Body>
  </Card>
);

ReviewCard.defaultProps = {
  classes: {},
};

ReviewCard.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  action: PropTypes.node,
  rounded: PropTypes.bool,
  classes: PropTypes.object,
};

export default ReviewCard;
