import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import { connect } from "react-redux";
import { matchPath, withRouter } from "react-router";
import withHandlers from "recompose/withHandlers";
import withProps from "recompose/withProps";
import { compose } from "redux";

import {
  UserDataSelectors,
  withAppLoader,
  withNotifier,
} from "@dpdgroupuk/mydpd-app";
import { Banner } from "@dpdgroupuk/mydpd-ui";

import { EDIT_SHIPMENT_FORM, Fields, ShipmentEntity } from "~/constants/forms";
import * as S from "~/constants/strings";
import { ShipmentModels } from "~/models";
import { editTotalNumberOfPackages } from "~/models/validators/additionalValidations";
import withConsignmentNumberBanner from "~/pages/Shipment/hocs/withConsignmentNumberBanner";
import withEditShipmentLoad from "~/pages/Shipment/hocs/withEditShipmentLoad";
import withEditShipmentPrint from "~/pages/Shipment/hocs/withEditShipmentPrint";
import withShipmentData from "~/pages/Shipment/hocs/withShipmentData";
import { ShipmentActions, ShipmentSelectors } from "~/pages/Shipment/redux";
import Shipment from "~/pages/Shipment/Shipment";
import { ReferenceActions } from "~/redux";
import * as routes from "~/router/constants";
import { toUppercaseValues } from "~/utils/object";

import { ShipmentReviewSelectors } from "../../redux";
import * as EditShipmentActions from "./actions";
import * as EditShipmentSelectors from "./selectors";

export default compose(
  withRouter,
  Banner.withBanner,
  withNotifier,
  withProps(() => ({
    pageConfig: {
      formName: EDIT_SHIPMENT_FORM,
      pageTitle: S.EDIT_SHIPMENT,
      pageMiddleText: S.ALL_FIELDS_MARKED_REQUIRED,
    },
  })),
  connect(
    (state, { pageConfig }) => ({
      disabledFields: EditShipmentSelectors.getEditShipmentDisabledFields(
        state,
        pageConfig
      ),
      isShipmentLoading:
        ShipmentReviewSelectors.getIsSelectedShipmentLoading(state),
      shipment: ShipmentReviewSelectors.getSelectedShipment(state),
      storageDate: UserDataSelectors?.getItem(state.app, "date"),
      requiredFields: ShipmentSelectors.getShipmentRequiredFields(
        state,
        pageConfig
      ),
      allowedFields: ShipmentSelectors.getAllowedFields(state, pageConfig),
    }),
    (dispatch, { notifier }) => ({
      createProduct: (product, packageNumber) =>
        dispatch(
          EditShipmentActions.createParcelProduct(product, packageNumber)
        ),
      fetchShipmentById: notifier.runAsync(
        shipmentId => dispatch(ShipmentActions.getShipmentById(shipmentId)),
        { entityName: S.SHIPMENT_ENTITY }
      ),
      fetchNetworks: notifier.runAsync(
        query => dispatch(ReferenceActions.fetchNetworks(query)),
        { entityName: S.NETWORKS }
      ),
    })
  ),
  withHandlers({
    shipmentAdditionalValidation: () => props => [
      () => editTotalNumberOfPackages(props),
      () =>
        editTotalNumberOfPackages(props, {
          fieldPath: ShipmentEntity.INBOUND_CONSIGNMENT.NUMBER_OF_PARCELS,
          direction: S.CONSIGNMENT_DIRECTION.INBOUND,
          parcelsPath: `${ShipmentEntity.INBOUND_CONSIGNMENT}.${Fields.PARCELS}`,
        }),
    ],
  }),
  withShipmentData,
  withEditShipmentLoad,
  withAppLoader({
    loadFn: async ({
      loadEditShipment,
      customerPrefs,
      fetchShipmentById,
      setAbortController,
      history,
    }) => {
      setAbortController(new AbortController());

      let shipment;

      const match = matchPath(history.location.pathname, {
        path: routes.SHIPMENT_EDIT,
        exact: true,
        strict: false,
      });

      try {
        shipment = await fetchShipmentById(match?.params?.shipmentId);
      } catch (e) {}

      shipment = toUppercaseValues(shipment);

      if (isEmpty(shipment) || isNil(shipment)) {
        return history.replace({
          pathname: routes.REVIEW,
        });
      }

      if (!ShipmentModels.isShipmentEditable(shipment, customerPrefs)) {
        return history.replace({
          pathname: `${routes.REVIEW}/${match.params.shipmentId}`,
          state: {
            error: S.NO_PERMISSIONS_TO_CREATE_UPDATE_SHIPMENT,
          },
        });
      }

      await loadEditShipment(shipment);
    },
  }),
  withHandlers({
    onBackClick:
      ({ banner, history }) =>
      () => {
        banner.hideAll();
        history.push(routes.REVIEW);
      },
  }),
  withEditShipmentPrint,
  withConsignmentNumberBanner
)(Shipment);
