import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  AppLoader,
  LocalProvider,
  NotifierProvider,
  PrinterConfigurator,
  PROJECT_NAME,
  Provider,
} from "@dpdgroupuk/mydpd-app";
import { Layout, Main } from "@dpdgroupuk/mydpd-ui";

import { apisClient, localApi } from "~/apis";
import { Importer } from "~/features/Importer";
import { UmsSelectors } from "~/redux";
import * as AppActions from "~/redux/actions";
import { Routes } from "~/router";

import "./sentry";
import Header from "./Header";
import tracker from "./tracker";
import { compose, withState } from "recompose";

function App({
  configSettings,
  fetchInitialData,
  thirdMenuItems,
  setThirdMenuItems,
}) {
  return (
    <Provider
      enableHelperTextTooltip
      apis={apisClient}
      projectName={PROJECT_NAME.SHIPPING}
      tracker={tracker}
    >
      <AppLoader loadFn={fetchInitialData}>
        <Layout header={<Header thirdMenuItems={thirdMenuItems} />}>
          <Main>
            <LocalProvider localApis={localApi}>
              <Importer>
                <NotifierProvider>
                  <Routes setThirdMenuItems={setThirdMenuItems} />
                </NotifierProvider>
              </Importer>
              <PrinterConfigurator initialConfig={configSettings} />
            </LocalProvider>
          </Main>
        </Layout>
      </AppLoader>
    </Provider>
  );
}

App.propTypes = {
  configSettings: PropTypes.object,
  fetchInitialData: PropTypes.func,
  setThirdMenuItems: PropTypes.func,
  thirdMenuItems: PropTypes.array,
};

const mapStateToProps = state => ({
  configSettings: UmsSelectors.getConfigSettings(state),
});

const mapDispatchToProps = {
  fetchInitialData: AppActions.fetchInitialAppData,
};

export default compose(
  withState("thirdMenuItems", "setThirdMenuItems", []),
  connect(mapStateToProps, mapDispatchToProps)
)(App);
