import { cloneDeep, set } from "lodash";

import {
  Fields,
  getInboundCountry,
  getOutboundCountry,
  INBOUND_CONSIGNMENT,
  INVOICE,
  ShipmentEntity,
} from "~/constants/forms";
import { ServiceModels, ShipmentModels } from "~/models";
import { getValue } from "~/utils/object";

export default (state, action) => {
  switch (action.type) {
    case "@@redux-form/BLUR": {
      if (ShipmentModels.isOutboundTotalWeight(action.meta.field)) {
        return {
          ...state,
          values: ShipmentModels.updateTotalWeight(
            cloneDeep(state.values),
            ServiceModels.roundTotalWeight(
              action.payload,
              getOutboundCountry(state.values),
              getValue(
                state.values,
                ShipmentEntity.OUTBOUND_CONSIGNMENT.NETWORK_CODE
              )
            )
          ),
        };
      }

      if (ShipmentModels.isInboundTotalWeight(action.meta.field)) {
        const totalWeight = ServiceModels.roundTotalWeight(
          action.payload,
          getInboundCountry(state.values)
          // unnecessary networkСode because return available only for UK
        );

        return {
          ...state,
          values: ShipmentModels.updateReturnTotalWeight(
            cloneDeep(state.values),
            totalWeight
          ),
        };
      }

      if (ShipmentModels.isOutboundNumberOfParcels(action.meta.field)) {
        const currentState = cloneDeep(state);

        const fields = cloneDeep(state.fields || {});
        set(
          fields,
          `${ShipmentEntity.INBOUND_CONSIGNMENT.NUMBER_OF_PARCELS}.touched`,
          true
        );

        if (
          ShipmentModels.isSwapItShipmentType(currentState.values.shipmentType)
        ) {
          currentState.values[INBOUND_CONSIGNMENT][Fields.NUMBER_OF_PARCELS] =
            action.payload;
        }

        if (currentState.values[INVOICE]) {
          currentState.values[INVOICE][Fields.NUMBER_OF_PARCELS] =
            action.payload;
        }

        return { ...currentState, values: currentState.values, fields };
      }

      return state;
    }
    default:
      return state;
  }
};
