import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import { addressBookApi } from "~/apis";
import { ADDRESS_BOOK_TYPES } from "~/constants/strings";
import { GroupDispatchModels } from "~/pages/Tools/pages/GroupDispatch/models";
import { sortBy } from "lodash";

export const AddressBookActionTypes = createActionTypes("ADDRESS_BOOK", {
  FETCH_ADDRESS_BOOKS: createAsyncActionTypes("FETCH_ADDRESS_BOOKS"),
  FETCH_FILTERED_ADDRESS_BOOKS: createAsyncActionTypes(
    "FETCH_FILTERED_ADDRESS_BOOKS"
  ),
  FETCH_DEFAULT_ADDRESS_BOOK: createAsyncActionTypes(
    "FETCH_DEFAULT_ADDRESS_BOOK"
  ),
  FETCH_ADDRESS_BOOK_GROUPS: createAsyncActionTypes(
    "FETCH_ADDRESS_BOOK_GROUPS"
  ),
  FETCH_ADDRESSES_BY_ADDRESS_BOOK_GROUPS_ID: createAsyncActionTypes(
    "FETCH_ADDRESSES_BY_ADDRESS_BOOK_GROUPS_ID"
  ),
  CLEAR_ADDRESS_BOOK: "CLEAR_ADDRESS_BOOK",
  CLEAR_ADDRESS_BOOK_GROUPS: "CLEAR_ADDRESS_BOOK_GROUPS",
  CLEAR_GROUP_ADDRESSES: "CLEAR_GROUP_ADDRESSES",
  FETCH_ADDRESS_BOOK_BY_ID: createAsyncActionTypes("FETCH_ADDRESS_BOOK_BY_ID"),
});

export const fetchFilteredAddressBooks = createAsyncAction(
  (query, fetchOptions) =>
    addressBookApi.fetchFilteredAddressBooks(query, fetchOptions),
  AddressBookActionTypes.FETCH_FILTERED_ADDRESS_BOOKS
);

export const fetchDefaultReturnAddressBook = createAsyncAction(
  async fetchOptions => {
    const { data } = await addressBookApi.fetchDefaultReturnAddressBook(
      { type: ADDRESS_BOOK_TYPES.RETURN },
      fetchOptions
    );
    return data;
  },
  AddressBookActionTypes.FETCH_DEFAULT_ADDRESS_BOOK
);

export const fetchAddressBookById = createAsyncAction(
  (id, query, options) =>
    addressBookApi.fetchAddressBookById(id, query, options),
  AddressBookActionTypes.FETCH_ADDRESS_BOOK_BY_ID
);

export const fetchGroups = createAsyncAction(
  fetchOptions => addressBookApi.fetchAddressBookGroups(fetchOptions),
  AddressBookActionTypes.FETCH_ADDRESS_BOOK_GROUPS
);

export const clearAddressBook = () => ({
  type: AddressBookActionTypes.CLEAR_ADDRESS_BOOK,
});
export const clearAddressBookGroups = () => ({
  type: AddressBookActionTypes.CLEAR_ADDRESS_BOOK_GROUPS,
});
export const clearGroupAddresses = () => ({
  type: AddressBookActionTypes.CLEAR_GROUP_ADDRESSES,
});

export const fetchAddressesByAddressBookGroupId = createAsyncAction(
  id =>
    addressBookApi.fetchAddressesByAddressBookGroupId(
      id,
      GroupDispatchModels.INITIAL_SEARCH_QUERY
    ),
  AddressBookActionTypes.FETCH_ADDRESSES_BY_ADDRESS_BOOK_GROUPS_ID
);

export const fetchAddressBooks = createAsyncAction(async (query, options) => {
  const {
    data: { results, totalResults },
  } = await addressBookApi.fetchAddressBooks(query, options);

  return {
    results: sortBy(results, ({ shortName }) => shortName),
    totalResults,
  };
}, AddressBookActionTypes.FETCH_ADDRESS_BOOKS);
