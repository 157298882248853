import React, { useMemo } from "react";
import { Button, Modal } from "@dpdgroupuk/mydpd-ui";
import { ProgressBar } from "react-bootstrap";
import PropTypes from "prop-types";
import * as S from "~/constants/strings";
import { connect } from "react-redux";
import { ToolsSelectors } from "~/pages/Tools/redux";

const CreateLabelsModal = ({ open, onCancel, onClose, progress, label }) => {
  const footer = useMemo(
    () => (
      <Modal.Footer>
        {progress === 100 ? (
          <Button variant="primary" onClick={onClose}>
            {S.CLOSE}
          </Button>
        ) : (
          <Button variant="dark" onClick={onCancel}>
            {S.CANCEL}
          </Button>
        )}
      </Modal.Footer>
    ),
    [progress]
  );

  return (
    <Modal show={open}>
      <Modal.Header>
        <Modal.Title>{S.CREATE_LABELS}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ProgressBar now={progress} label={label} variant="danger" />
      </Modal.Body>
      {footer}
    </Modal>
  );
};

CreateLabelsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  progress: PropTypes.number,
  label: PropTypes.string,
};

export default connect(state => ({
  progress: ToolsSelectors.getPrintingProgress(state),
  label: ToolsSelectors.getPrintingLabel(state),
}))(CreateLabelsModal);
