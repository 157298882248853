import { createSelector } from "reselect";

import { AuthSelectors, TemplateSelectors, UmsSelectors } from "~/redux";

export const getData = state => state.app.importer;

export const getImportMessages = createSelector(getData, ({ importMessages }) =>
  importMessages.sort((a, b) => b.timestamp - a.timestamp)
);

export const getActiveTemplateId = createSelector(
  getData,
  ({ templateId }) => templateId
);

export const getRunningState = createSelector(
  getData,
  ({ runningState }) => runningState
);

export const getImportParams = createSelector(
  TemplateSelectors.getShipmentTemplateById,
  TemplateSelectors.getShipmentReceiptTemplate,
  UmsSelectors.getPreferences,
  UmsSelectors.getShippingSettings,
  UmsSelectors.getSecuritySettings,
  AuthSelectors.getAppRoles,
  (
    shipmentTemplate,
    shipmentReceiptTemplate,
    preferences,
    shippingSettings,
    securitySettings,
    appRoles
  ) => ({
    shipmentTemplate,
    shipmentReceiptTemplate,
    ...preferences,
    shippingSettings,
    securitySettings,
    appRoles,
  })
);

export const getFailedRecord = createSelector(
  getData,
  ({ failedRecord }) => failedRecord
);
