import { EventEmitter } from "@dpdgroupuk/mydpd-app";
import { PRINTER_TYPE } from "@dpdgroupuk/mydpd-enums";

import { importsApi } from "~/apis";

class PrintController extends EventEmitter {
  constructor(provider) {
    super();
    this.store = provider.store;
    this.receipt = provider.receipt;
    this.localApis = provider.localApis;
    this.printer = provider.printer;
    this.logger = provider.logger;
    this.manager = provider.manager;
  }

  async getImportLabels(jobId, printId, params) {
    // possible case when there is no shipmentTemplate
    const { shipmentTemplate, prefsConfigSettings } = params;

    const disableBadLabels = shipmentTemplate?.disableBadLabels;
    const printingSequence = +(shipmentTemplate?.printSequence || 0);

    const printerType = prefsConfigSettings.printToThermal
      ? +prefsConfigSettings.thermalPrinterType
      : PRINTER_TYPE.LASER;

    const { printString } = await importsApi.getImportLabelsByJobId(jobId, {
      printId,
      printerType,
      printSequence: printingSequence,
      disableBadLabels,
    });
    return printString;
  }

  async printImportLabels(printStrings) {
    this.logger.info(`Printing ${printStrings.length} label(s)`, {
      numberOfLabels: printStrings.length,
    });
    return this.printer.printLabels(printStrings).catch(err => {
      this.logger.error("Failed to print label(s)", err);
      throw err;
    });
  }
}

export default PrintController;
