import classNames from "classnames";
import PropTypes from "prop-types";

import styles from "./KeyValueList.module.scss";

const KeyValueList = ({ data = [], variant, className }) => (
  <ul
    className={classNames(
      className,
      styles.list,
      variant === "vertical" && styles.vertical,
      variant === "twoColumns" && styles.twoColumns
    )}
  >
    {data.map(({ name, value, invalid }, index) => (
      <li key={index}>
        {name && <span className={styles.name}>{name}</span>}
        <span className={classNames(styles.value, !!invalid && styles.invalid)}>
          {value || (invalid ? "Required" : "")}
        </span>
      </li>
    ))}
  </ul>
);

KeyValueList.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(["vertical", "twoColumns"]),
  data: PropTypes.array,
};

export default KeyValueList;
