export const TIME_FORMAT = "H:mm";
export const HH_MM_TIME_FORMAT = "HH:mm";
export const DAY_DATE_FORMAT = "dddd";
export const DEFAULT_DATE_FORMAT = "DD/MM/YYYY";
export const ISO_DATE_FORMAT = "YYYY-MM-DD";
export const FULL_TIME_FORMAT = "HH:mm:ss";
export const DATE_TIME_FORMAT = `${ISO_DATE_FORMAT} HH:mm:ss`;
export const EUROPE_LONDON_TIME_ZONE = "Europe/London";
export const DAY_DEFAULT_DATE_FORMAT = `${DAY_DATE_FORMAT} ${DEFAULT_DATE_FORMAT}`;
export const DMY_12_HOUR_TIME_FORMAT = "DD-MM-YYYY hh:mm a";
