import { cloneDeep } from "lodash";

import { ServiceModels, ShipmentModels } from "~/models";

export default (state, action) => {
  switch (action.type) {
    case "@@redux-form/BLUR": {
      if (ShipmentModels.isLabelExtraWeight(action.meta.field)) {
        const totalWeightOfNewParcels = ServiceModels.roundTotalWeight(
          action.payload,
          state.values?.countryCode
          // unnecessary networkСode because action available only for shipment without product (for serviceCode="58" product is required)
          // TODO: pass networkСode from shipment to the initialValues for ADD_EXTRA_LABEL_FORM if it will be necessary in future
        );

        return {
          ...state,
          values: ShipmentModels.updateTotalExtraWeight(
            cloneDeep(state.values),
            totalWeightOfNewParcels
          ),
        };
      }
      return state;
    }
    default:
      return state;
  }
};
