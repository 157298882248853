import Joi from "joi";

import { CommonValidators } from "@dpdgroupuk/mydpd-joi-validator";

import * as S from "~/constants/strings";
import { postcode, street, town } from "~/models/validators/commonSchema";

export default Joi.object({
  countryCode: CommonValidators.countryCode().label(S.DESTINATION_COUNTRY),
  street,
  town,
  postcode,
});
