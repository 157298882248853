import { useCallback, useEffect, useRef, useState } from "react";

import PropTypes from "prop-types";

import { Form } from "@dpdgroupuk/mydpd-ui";
import "react-bootstrap-typeahead/css/Typeahead.css";

const Autocomplete = ({
  label,
  helperText,
  input,
  meta,
  disabled,
  readonly,
  required,
  id,
  onSearch,
  optionLabelMapper,
  labelKey,
  paginationText,
  pageSize,
  onSelectionChange,
  maxLength,
  onChange,
  type,
  minLength,
  formatBeforeSearch,
  getSearchQuery,
  withAutocomplete,
  shouldSearch,
}) => {
  const inputValueRef = useRef(input.value);
  const promiseRef = useRef();
  const getSearchQueryRef = useRef(getSearchQuery);
  const shouldSearchRef = useRef(shouldSearch);

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [query, setQuery] = useState({ searchPage: 1 });

  const abortIfPending = useCallback(() => {
    if (promiseRef.current && typeof promiseRef.current.abort === "function") {
      promiseRef.current.abort();
    }
  }, [promiseRef.current, setIsLoading, setOptions]);

  const searchWithStatusHandler = useCallback(
    params => {
      abortIfPending();
      const searching = onSearch(params);
      searching.finally(() => {
        promiseRef.current = null;
      });
      promiseRef.current = searching;

      return searching;
    },
    [abortIfPending]
  );

  useEffect(() => abortIfPending, []);

  useEffect(() => {
    inputValueRef.current = input.value;

    if (!input.value || input.value === "") {
      setIsLoading(false);
      setOptions([]);
      abortIfPending();
    } else if (!isLoading && !meta.active && withAutocomplete) {
      // Triggers search for external change on not active aucocomplete
      handleSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input.value, abortIfPending, withAutocomplete]);

  useEffect(() => {
    shouldSearchRef.current = shouldSearch;
  }, [shouldSearch]);

  useEffect(() => {
    getSearchQueryRef.current = getSearchQuery;

    abortIfPending();
    handleSearch();
  }, [getSearchQuery]);

  const handleSearch = useCallback(() => {
    if (!isLoading) {
      setIsLoading(true);
      setOptions([]);
    }

    const value = formatBeforeSearch(inputValueRef.current.toUpperCase());

    setQuery({
      ...getSearchQueryRef.current(value),
      searchPage: 1,
    });

    // Searching only by valid value
    if (value && shouldSearchRef.current(value)) {
      searchWithStatusHandler({
        ...getSearchQueryRef.current(value),
        searchPage: 1,
        searchPageSize: pageSize + 1,
      })
        .then(results => {
          setOptions(results);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [pageSize]);

  const handlePagination = useCallback(
    () =>
      searchWithStatusHandler({
        ...query,
        searchPage: query.searchPage + 1,
        searchPageSize: pageSize + 1,
      }).then(results => {
        setOptions(options.concat(results));
        setQuery({ ...query, searchPage: query.searchPage + 1 });
      }),
    [options, query, onSearch, pageSize]
  );

  const handleInputChange = useCallback(
    value => {
      abortIfPending();

      if (value.length >= minLength) {
        setIsLoading(true);
      }

      setOptions([]);

      input.onChange(value);

      if (onChange) {
        onChange(value);
      }
    },
    [input, onChange, promiseRef]
  );

  const handleSelectionChange = useCallback(
    values => {
      onSelectionChange(values);
    },
    [onSelectionChange]
  );

  return withAutocomplete ? (
    <Form.Autocomplete
      withoutHint
      label={label}
      helperText={helperText}
      meta={meta}
      input={{
        ...input,
        onBlur: () => {
          input.onBlur(inputValueRef.current.toUpperCase());
        },
      }}
      required={required}
      disabled={disabled}
      readonly={readonly}
      id={id}
      isLoading={isLoading}
      delay={1000}
      onSearch={handleSearch}
      onInputChange={handleInputChange}
      options={options}
      onSelectionChange={handleSelectionChange}
      paginationText={paginationText}
      pageSize={pageSize}
      onPaginate={handlePagination}
      labelKey={labelKey}
      optionLabelMapper={optionLabelMapper}
      maxLength={maxLength}
      type={type}
      minLength={minLength}
    />
  ) : (
    <Form.MaterialTextInput
      label={label}
      helperText={helperText}
      meta={meta}
      input={input}
      required={required}
      disabled={disabled}
      readonly={readonly}
      maxLength={maxLength}
    />
  );
};

export const propTypes = {
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  label: PropTypes.string,
  helperText: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.object,
  required: PropTypes.bool,
  optionLabelMapper: PropTypes.func,
  labelKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  paginationText: PropTypes.string,
  pageSize: PropTypes.number,
  onSelectionChange: PropTypes.func,
  onSearch: PropTypes.func,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  onChange: PropTypes.func,
  type: PropTypes.string,
  formatBeforeSearch: PropTypes.func,
  getSearchQuery: PropTypes.func,
  withAutocomplete: PropTypes.bool,
  shouldSearch: PropTypes.func,
};

Autocomplete.propTypes = propTypes;

Autocomplete.defaultProps = {
  paginationText: "Show more",
  pageSize: 10,
  formatBeforeSearch: value => value,
  minLength: 1,
};

export default Autocomplete;
