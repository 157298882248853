import fp from "lodash/fp";

export const onlyStrings = fp.compose(
  fp.filter(fp.isString),
  fp.filter(fp.negate(fp.isEmpty))
);

export const joinStringsWithComma = fp.compose(fp.join(", "), onlyStrings);

export const replaceLinebreak = (str, replaceWith = " ") =>
  str.replace(/(?:\r\n|\r|\n)/g, replaceWith);

export const formatMessage = (template, ...args) =>
  template.replace(/{(\d)}/g, (s, num) => args[num]);

export const formatString = (value, def = "", removeSpaces) => {
  let s = typeof value === "string" ? value.trim().toUpperCase() : def;
  if (s && removeSpaces) s = s.replace(/\s/g, "");
  return s;
};

export const truncateString = (string, maxVal) =>
  string?.length > maxVal ? string.substring(0, maxVal) : string;
