import { ActionTypes as ShipmentActionTypes } from "~/pages/Shipment/redux/actions";

import { ActionTypes } from "./actions";

export const initialState = {
  shipmentList: [],
  selectedShipment: {
    data: {},
    isLoading: false,
  },
  shipmentsStats: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH.SUCCESS: {
      return {
        ...state,
        shipmentList: action.payload,
      };
    }
    case ActionTypes.CLEAR: {
      return {
        ...state,
        shipmentList: initialState.shipmentList,
      };
    }
    case ShipmentActionTypes.FETCH_BY_ID.REQUEST: {
      return {
        ...state,
        selectedShipment: {
          ...state.selectedShipment,
          isLoading: true,
        },
      };
    }
    case ShipmentActionTypes.FETCH_BY_ID.FAILURE: {
      return {
        ...state,
        selectedShipment: {
          isLoading: false,
        },
      };
    }
    case ShipmentActionTypes.FETCH_BY_ID.SUCCESS: {
      return {
        ...state,
        selectedShipment: {
          data: action.payload,
          isLoading: false,
        },
      };
    }
    case ActionTypes.GET_SHIPMENTS_STATS.SUCCESS: {
      return {
        ...state,
        shipmentsStats: action.payload.data,
      };
    }

    default:
      return state;
  }
};
