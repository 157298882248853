import { createSelector } from "reselect";

import { TEMPLATE_TYPE } from "@dpdgroupuk/mydpd-enums";

import { UmsSelectors } from "~/redux";
import { getValue } from "~/utils/object";

const emptyArray = [];
const emptyObj = {};

export const getData = state => state.app.template;

export const getTemplates = createSelector(
  getData,
  ({ templates }) => templates
);

export const getTemplatesBySettings = createSelector(
  getTemplates,
  UmsSelectors.getShippingSettings,
  (templates, shippingSettings) => {
    if (!shippingSettings?.enableReleaseFile) {
      return templates.filter(
        ({ typeCode }) => typeCode !== TEMPLATE_TYPE.RELEASE_FILE
      );
    }

    return templates;
  }
);

export const getDelimiters = createSelector(
  getData,
  ({ delimiters }) => delimiters
);

export const getAddressbookDelimiters = createSelector(
  getData,
  ({ addressbookDelimiters }) => addressbookDelimiters
);

export const getProductBookDelimiters = createSelector(
  getData,
  ({ productBookDelimiters }) => productBookDelimiters
);

export const getTemplatesKeyValue = createSelector(
  getTemplatesBySettings,
  templates =>
    templates.map(({ typeCode: value, typeDescription: label }) => ({
      label,
      value,
    }))
);

export const getShipmentImportTemplates = createSelector(getData, imports =>
  getValue(imports, "shipmentImportTemplates", [])
);

export const getShipmentImportTemplatesKeyValue = createSelector(
  getData,
  values =>
    getValue(values, "shipmentImportTemplates", emptyArray).map(
      ({ templateId: value, templateName: label }) => ({
        label,
        value,
      })
    )
);

export const getTemplate = createSelector(getData, data =>
  getValue(data, "template", emptyObj)
);

export const getTemplateByType = createSelector(
  getData,
  (state, type) => type,
  ({ templatesByType }, type) => templatesByType[type]
);

export const getShipmentTemplateById = createSelector(
  getData,
  (state, templateId) => templateId,
  ({ templatesByType }, templateId) =>
    templatesByType[TEMPLATE_TYPE.SHIPMENT]?.[templateId]
);

export const getTestImportFileResult = createSelector(getData, data =>
  getValue(data, "testImportFileResult", emptyArray)
);

export const getShipmentReceiptTemplate = createSelector(
  getData,
  ({ templatesByType }) => templatesByType[TEMPLATE_TYPE.SHIPMENT_RECEIPT]
);
