export const DEFAULT_FAILED_IMPORTS_COLUMNS = [
  "selection",
  "shipmentDetails.shipmentDate",
  "shipmentDetails.outboundConsignment.deliveryDetails.address.postcode",
  "shipmentDetails.outboundConsignment.shippingRef1",
  "failureDetails.failureReasons[0]",
  "edit",
];

export const FAILED_IMPORTS_COLUMN_ACCESSORS = {
  SHIPMENT_DATE: "shipmentDetails.shipmentDate",
  POSTCODE:
    "shipmentDetails.outboundConsignment.deliveryDetails.address.postcode",
  SHIPPING_REF_1: "shipmentDetails.outboundConsignment.shippingRef1",
  SHIPPING_REF_4: "shipmentDetails.outboundConsignment.shippingRef4",
  FAILURE_REASON: "failureDetails.failureReasons[0]",
};

export const DEFAULT_SORT_BY = "created_date,created_time";

export const COLUMN_ID_TO_SORT_CRITERIA = {
  [FAILED_IMPORTS_COLUMN_ACCESSORS.SHIPMENT_DATE]: DEFAULT_SORT_BY,
  [FAILED_IMPORTS_COLUMN_ACCESSORS.POSTCODE]: `postcode,${DEFAULT_SORT_BY}`,
  [FAILED_IMPORTS_COLUMN_ACCESSORS.SHIPPING_REF_1]: `customer_reference,${DEFAULT_SORT_BY}`,
  [FAILED_IMPORTS_COLUMN_ACCESSORS.FAILURE_REASON]: `failure_reason,${DEFAULT_SORT_BY}`,
};

export const SORTING_FIELDS = {
  SEARCH_SORT_BY: "searchSortBy",
  SEARCH_SORT_ORDER: "searchSortOrder",
};

export const SORTING_ORDER = {
  ASC: "ASC",
  DESC: "DESC",
};
