import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import omit from "lodash/omit";
import omitBy from "lodash/omitBy";
import pick from "lodash/pick";
import { getFormSyncErrors, getFormValues } from "redux-form";
import { createSelector } from "reselect";

import {
  EDIT_PRODUCT_FORM,
  PRODUCT_BOOK_SEARCH_FORM,
  ProductEntity,
} from "~/constants/forms";
import { ParcelModels } from "~/models";
import { ShipmentSelectors } from "~/pages/Shipment/redux";
import { flattenEntityRoutes } from "~/utils/object";

import { PackageContentModels } from "../../components/PackageContent/models";
import { ReferenceSelectors } from "../references";
import { UmsSelectors } from "../ums";

export const getData = state => state.app.productBook;

export const isProductBookSubmitting = createSelector(
  getData,
  ({ isProductBookBookSubmitting }) => isProductBookBookSubmitting
);

export const getFormState = state =>
  get(state, `form.${EDIT_PRODUCT_FORM}`, {});

export const getFormActiveField = createSelector(
  getFormState,
  ({ active }) => active
);

export const getCommodityError = createSelector(
  getFormSyncErrors(EDIT_PRODUCT_FORM),
  syncErrors => get(syncErrors, ProductEntity.COMMODITY_CODE)
);

export const getEditProductFormValues = state =>
  getFormValues(EDIT_PRODUCT_FORM)(state);

export const getProductBookSearchFormValues = state =>
  getFormValues(PRODUCT_BOOK_SEARCH_FORM)(state);

export const getProductTotalValue = createSelector(
  getEditProductFormValues,
  product => ParcelModels.calculateProductTotalValue(product).toFixed(2)
);

export const getEditProductSelectedCountry = createSelector(
  ReferenceSelectors.getCountries,
  getEditProductFormValues,
  (countries, editProductFormValues) =>
    countries.find(
      ({ countryKey }) =>
        countryKey ===
        get(editProductFormValues, ProductEntity.COUNTRY_OF_ORIGIN)
    )
);

export const getProductBookQuery = createSelector(
  getEditProductFormValues,
  getProductBookSearchFormValues,
  UmsSelectors.getAdvancedCustomerConfiguration,
  ShipmentSelectors.getSelectedShipmentCurrency,
  ReferenceSelectors.getCurrencies,
  (
    values,
    search,
    advancedCustomerConfiguration,
    selectedCurrency,
    currencies
  ) => {
    if (
      isEmpty(values) ||
      PackageContentModels.isProductBookReadonly(
        values,
        advancedCustomerConfiguration
      )
    ) {
      return null;
    }

    if (
      search?.searchCriteriaField !== "shortName" ||
      !search?.searchCriteriaValue
    ) {
      return null;
    }

    let currency = values.currency;

    if (
      !currency ||
      !currencies.find(({ currencyCode }) => currencyCode === values.currency)
    ) {
      currency = selectedCurrency;
    }

    return {
      ...omit(
        omitBy(
          pick(values, flattenEntityRoutes(ProductEntity)),
          value => value === null || value === undefined || value === ""
        ),
        ProductEntity.PRODUCT_QUANTITY
      ),
      shortName: search?.searchCriteriaValue,
      productBookId: values.productBookId,
      currency,
    };
  }
);
