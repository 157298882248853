export const debounce = (() => {
  let timeout;

  return fn =>
    (...args) => {
      clearTimeout(timeout);

      timeout = setTimeout(() => {
        clearTimeout(timeout);
        fn(...args);
      }, 500);
    };
})();

export const pipe =
  (...functions) =>
  data =>
    functions.reduce((acc, fn) => fn(acc), data);
