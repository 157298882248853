import React from "react";
import { Modal } from "@dpdgroupuk/mydpd-ui";
import { ProgressBar } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ToolsSelectors } from "~/pages/Tools/redux";

import styles from "./ProgressModal.module.scss";

const ProgressModal = ({ open, progress, label, title }) => (
  <Modal show={open}>
    <Modal.Header>
      <Modal.Title className={styles.title}>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <ProgressBar
        animated
        striped
        now={progress}
        label={label}
        variant="danger"
      />
    </Modal.Body>
  </Modal>
);

ProgressModal.propTypes = {
  open: PropTypes.bool.isRequired,
  progress: PropTypes.number,
  label: PropTypes.string,
  title: PropTypes.string,
};

export default connect(state => ({
  ...ToolsSelectors.getModalProgress(state),
}))(ProgressModal);
