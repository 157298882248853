import { getFormValues } from "redux-form";
import { createSelector } from "reselect";

import { ShipmentEntity } from "~/constants/forms";

export const getData = state => state.app.profiles;

export const getProfiles = createSelector(getData, ({ profiles }) => profiles);

export const isDataLoaded = createSelector(getData, ({ loaded }) => loaded);

export const getProfilesKeyValue = createSelector(getProfiles, profiles => {
  const sortedProfiles = profiles.sort((a, b) =>
    a.profileName.localeCompare(b.profileName)
  );
  return sortedProfiles.map(({ profileCode, profileName }) => ({
    value: profileCode,
    label: profileName,
  }));
});

const getShipmentFormValues = (state, { formName }) =>
  getFormValues(formName)(state);

export const getActiveProfile = createSelector(
  getProfiles,
  getShipmentFormValues,
  (profiles, formValues = {}) =>
    profiles.find(
      ({ profileCode }) =>
        profileCode === formValues[ShipmentEntity.PROFILE_CODE]
    )
);

export const isHiddenProfile = createSelector(
  isDataLoaded,
  getProfilesKeyValue,
  (isDataLoaded, profiles) => !isDataLoaded || profiles.length === 1
);
