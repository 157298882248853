import { compose, withProps } from "recompose";

import { referenceApi } from "~/apis";
import * as S from "~/constants/strings";
import { PostcodeModels } from "~/models";
import noop from "~/utils/noop";
import { formatMessage } from "~/utils/string";

export default compose(
  withProps(({ prompt, notifier = noop, abortController }) => ({
    checkPostcodeIssue: notifier?.runAsync(
      async (postcode, type, valid = () => true) => {
        if (PostcodeModels.isGBPostCodeFormat(postcode)) {
          const { data } = await referenceApi.fetchPostcodeIssues(postcode, {
            signal: abortController.signal,
          });

          if (data.postcodeIssue && data.postcodeIssue.length && valid(data)) {
            const { postcodeDelay, postcodeDelayDescription } =
              data.postcodeIssue[0];

            return new Promise(resolve => {
              if (prompt) {
                prompt.showInfo({
                  header: formatMessage(S.DELAY_IN_$_POSTCODE_AREA, type),
                  message: formatMessage(
                    S.DELAY_POSTCODE_AREA_MESSAGE_$_$_$,
                    postcodeDelayDescription,
                    postcodeDelay,
                    type === S.COLLECTION ? S.COLLECT : S.DELIVER
                  ),
                  onConfirm: resolve,
                });
              } else {
                resolve();
              }
            });
          }
        }
      },
      { entityName: S.POSTCODE_ISSUES }
    ),
  }))
);
