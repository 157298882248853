import { get } from "lodash";
import withHandlers from "recompose/withHandlers";

import { BANNERS_TYPES } from "@dpdgroupuk/mydpd-enums";

import * as S from "~/constants/strings";
import * as routes from "~/router/constants";

import { ShipmentActions } from "../redux";

export default withHandlers({
  printShipment:
    ({
      dispatch,
      banner,
      onClickCopyNumber,
      confirmPrintHandler,
      pageConfig,
      history,
      overlay,
    }) =>
    async printNow => {
      let consignmentNumber;
      let shipment;

      try {
        overlay.show();

        shipment = await dispatch(
          ShipmentActions.createShipment({
            formName: pageConfig.formName,
          })
        );
        consignmentNumber = get(shipment.consignments, "[0].consignmentNumber");

        if (printNow) {
          await confirmPrintHandler(shipment);
        }
      } finally {
        overlay.hide();
      }

      history.push(routes.FAILED_IMPORTS);

      banner.showByType(BANNERS_TYPES.INFO, {
        message: `${S.SHIPMENT_SAVED_SUCCESSFULLY} ${consignmentNumber}`,
        closable: true,
        showIcon: true,
        actions: [
          {
            text: S.COPY_NUMBER,
            handleClick: () => onClickCopyNumber(consignmentNumber),
          },
        ],
      });

      return shipment;
    },
});
