import classNames from "classnames";
import PropTypes from "prop-types";

import * as S from "~/constants/strings";

import styles from "./PackageDetailsTitle.module.scss";

const PackageDetailsTitle = ({ numberOfParcels, totalWeight }) => (
  <div className={classNames(styles.flexRow, styles.container)}>
    <div className={styles.title}>{S.PACKAGE_DETAILS}</div>
    <div className={classNames(styles.flexRow, styles.info)}>
      <div className={styles.parsels}>
        {S.PACKAGES}
        <span>{numberOfParcels}</span>
      </div>
      <div>
        {S.TOTAL_WEIGHT}
        <span>{totalWeight}</span>
      </div>
    </div>
  </div>
);

PackageDetailsTitle.propTypes = {
  numberOfParcels: PropTypes.number,
  totalWeight: PropTypes.number,
  className: PropTypes.string,
};

export default PackageDetailsTitle;
