export const scrollToSelected = (containerId, down) => {
  setTimeout(() => {
    const selectedRows = document.querySelectorAll(
      `#${containerId} .mydpd-dndtable-row-selected`
    );
    const element = down
      ? selectedRows?.[selectedRows?.length - 1]
      : selectedRows?.[0];

    if (element) {
      const container = element.closest(".mydpd-dndtable-container");
      const tableHeader = container.querySelector("thead");
      const containerTop = container.scrollTop;
      const containerHeight = container.offsetHeight;

      const elementTop = element.offsetTop;
      const elementHeight = element.offsetHeight;
      const tableHeaderHeight = tableHeader.offsetHeight;

      if (elementTop < containerTop + tableHeaderHeight) {
        container.scrollTop = elementTop - tableHeaderHeight;
      }

      if (elementTop > containerTop + containerHeight - elementHeight) {
        container.scrollTop = elementTop + elementHeight - containerHeight;
      }
    }
  });
};
