import { ProductBookActionTypes } from "./actions";

export const initialState = {
  isProductBookBookSubmitting: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ProductBookActionTypes.CREATE_UPDATE.REQUEST: {
      return {
        ...state,
        isProductBookBookSubmitting: true,
      };
    }
    case ProductBookActionTypes.CREATE_UPDATE.FAILURE:
    case ProductBookActionTypes.CREATE_UPDATE.SUCCESS: {
      return {
        ...state,
        isProductBookBookSubmitting: false,
      };
    }
    default:
      return state;
  }
};
