export const AIR_EXPRESS_PARCEL_NETWORKS = [
  "1^10",
  "1^30",
  "1^50",
  "1^70",
  "2^10",
  "2^30",
  "2^50",
  "2^70",
];
export const AIR_CLASSIC_PARCEL_NETWORKS = ["1^60", "2^60"];
export const DPD_DIRECT_PARCEL_NETWORKS = ["1^58", "1^80", "2^58", "2^80"];
export const DPD_CLASSIC_PARCEL_NETWORKS = ["1^19", "2^19"];
export const DPD_SMALL_PARCEL_NETWORKS = ["1^39", "2^39"];
export const DPD_CLASSIC_FREIGHT_NETWORKS = ["1^89", "2^89"];
export const DPD_CLASSIC_PALLET_NETWORKS = ["1^79", "2^79"];
export const DPD_DIRECT_LITE = ["1^20", "2^20"];
export const SHIP_TO_SHOP = ["1^91", "2^91"];
