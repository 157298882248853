import { get } from "lodash";
import moment from "moment/moment";
import { Col, Container, Row } from "react-bootstrap";
import { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { compose, withHandlers, withState } from "recompose";

import { Button, FormControl } from "@dpdgroupuk/mydpd-ui";

import { END_DAY_FORM, ShipmentEntity } from "~/constants/forms";
import { DEFAULT_DATE_FORMAT } from "~/constants/dateFormats";
import * as S from "~/constants/strings";
import Modal from "~/components/ShipmentReviewModal/ShipmentReviewModal";
import { formatISODate } from "~/utils/date";
import { getValue } from "~/utils/object";
import { isCustomerError } from "~/utils/error";
import { formatMessage } from "~/utils/string";
import { UmsSelectors } from "~/redux";
import { ModalSelectors } from "~/redux/modals";
import * as EndOfDayModelSelector from "./selectors";
import { ManifestActions } from "~/features";
import { ToolsActions } from "~/pages/Tools/redux";
import withManifestActions from "~/pages/ShipmentReview/hocs/withManifestActions";
import ManifestsTable from "~/pages/ShipmentReview/components/ManifestsTable";
import { formatManifest } from "../../models";

const getColumns = onReprint => [
  {
    text: S.CREATED_DATE,
    dataIndex: "createdDate",
    style: {
      width: "161px",
    },
  },
  {
    text: S.CONSIGNMENTS,
    dataIndex: "numberOfConsignments",
    style: {
      width: "161px",
    },
  },
  {
    text: S.PARCELS,
    dataIndex: "numberOfParcels",
    style: {
      width: "161px",
    },
  },
  {
    text: S.REPRINT,
    render: ({ rowData }) => (
      <Button onClick={() => onReprint(get(rowData, "manifestId"))}>
        {S.PRINT}
      </Button>
    ),
    style: {
      width: "161px",
    },
  },
];

const EndDayModal = ({
  open,
  onCancel,
  onClickRunEndOfDay,
  handleSubmit,
  onFetchManifests,
  formValues,
  onClickRePrintEodMaster,
  disabledRunOfDay,
  manifests,
  setManifests,
}) => {
  const tableColumns = useMemo(() => getColumns(onClickRePrintEodMaster), []);

  useEffect(async () => {
    if (open && getValue(formValues, ShipmentEntity.SHIPMENT_DATE)) {
      const manifests = await onFetchManifests(
        formatISODate(getValue(formValues, ShipmentEntity.SHIPMENT_DATE))
      );
      const preparedManifest = manifests.map(formatManifest);

      setManifests(preparedManifest);
    }
  }, [open, getValue(formValues, ShipmentEntity.SHIPMENT_DATE)]);

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      title={S.RUN_END_DAY_RELEASE_FILE}
      saveButton={{ visible: false }}
      size="lg"
    >
      <Container className="p-0">
        <Row className="align-items-baseline">
          <Col xs={4}>
            <Field
              component={FormControl.DatePicker}
              label={S.SHIPMENT_DATE}
              name={ShipmentEntity.SHIPMENT_DATE}
              dateFormat={DEFAULT_DATE_FORMAT}
              required
            />
          </Col>
          <Col>
            <Button
              onClick={handleSubmit(onClickRunEndOfDay)}
              disabled={disabledRunOfDay}
            >
              {S.RUN_END_OF_DAY}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <ManifestsTable data={manifests} columns={tableColumns} />
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

EndDayModal.propTypes = {
  open: PropTypes.bool,
  disabledRunOfDay: PropTypes.bool,
  onCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
  onClickRunEndOfDay: PropTypes.func,
  onClickRePrintManifest: PropTypes.func,
  onFetchManifests: PropTypes.func,
  onClickRePrintEodMaster: PropTypes.func,
  setManifests: PropTypes.func,
  manifests: PropTypes.array,
  formValues: PropTypes.object,
};
export default compose(
  withState("manifests", "setManifests", []),
  withManifestActions,
  connect(state => ({
    disabledRunOfDay: EndOfDayModelSelector.isCurrentDateInThePast(
      state,
      END_DAY_FORM
    ),
    formValues: ModalSelectors.getModalFormValues(state, END_DAY_FORM),
    shippingSettings: UmsSelectors.getShippingSettings(state),
  })),
  reduxForm({
    form: END_DAY_FORM,
    initialValues: {
      [ShipmentEntity.SHIPMENT_DATE]: moment().format(DEFAULT_DATE_FORMAT),
    },
    destroyOnUnmount: false,
    enableReinitialize: true,
  }),
  withHandlers({
    onClickRePrintEodMaster:
      ({ onCancel, prompt, shippingSettings, onClickRePrintManifest }) =>
      async manifestId => {
        if (
          getValue(shippingSettings, "eodMaster", false) ||
          getValue(shippingSettings, "disablePrintManifest", false)
        ) {
          const errorMessage = getValue(shippingSettings, "eodMaster", false)
            ? S.DISABLED_AS_MASTER_EOD_USER
            : S.DISABLED_FOR_MANIFEST;

          return prompt.showError({
            header: S.PRINT_MANIFEST,
            message: errorMessage,
          });
        }

        await onClickRePrintManifest(manifestId, onCancel);
      },
    onClickRunEndOfDay: ({
      dispatch,
      notifier,
      prompt,
      onCancel,
      printManifest,
      shippingSettings,
      onFetchManifests,
      setManifests,
      formValues,
    }) =>
      notifier.runAsync(
        async () => {
          try {
            const manifest = await dispatch(ToolsActions.runEodOfDay());

            if (!manifest) {
              return prompt.showInfo({
                header: S.NO_SHIPMENTS_TO_MANIFEST,
                message: S.MANIFEST_NOTHING_TO_PROCESS,
              });
            }

            if (getValue(shippingSettings, "disablePrintManifest", false)) {
              const errorMessage = getValue(
                shippingSettings,
                "eodMaster",
                false
              )
                ? S.DISABLED_AS_MASTER_EOD_USER
                : S.DISABLED_FOR_MANIFEST;

              return prompt.showError({
                header: S.PRINT_MANIFEST,
                message: errorMessage,
                onConfirm: async () => {
                  const manifests = await onFetchManifests(
                    formatISODate(
                      getValue(formValues, ShipmentEntity.SHIPMENT_DATE)
                    )
                  );
                  const preparedManifest = manifests.map(formatManifest);
                  setManifests(preparedManifest);
                },
              });
            }

            if (manifest.filePath) {
              prompt.showInfo({
                header: S.EXPORT_COMPLETED_SUCCESSFULLY,
                message: formatMessage(
                  S.GENERATED_EXPORT_FILE_$,
                  manifest.filePath
                ),
              });
            }
            printManifest(manifest.label);
          } catch (err) {
            dispatch(ToolsActions.resetModalProgress());

            const message = isCustomerError(err)
              ? err.message
              : S.UNABLE_TO_RETRIEVE_EXPORT_DATA;

            prompt.showError({
              header: S.ERROR_DURING_EXPORT,
              message,
            });
          } finally {
            dispatch(ManifestActions.clearManifestAll());
          }

          return onCancel(true);
        },
        {
          entityName: S.RUN_END_OF_DAY,
        }
      ),
  })
)(EndDayModal);
