const SHIPPING_PREFIX = "/shipping";

export const SHIPPING_SHIPMENTS = SHIPPING_PREFIX + "/shipments";
export const SHIPPING_MANIFESTS = SHIPPING_PREFIX + "/manifests";
export const SHIPPING_TEMPLATES = SHIPPING_PREFIX + "/templates";
export const SHIPPING_LABELS = SHIPPING_PREFIX + "/labels";
export const SHIPPING_IMPORTS = SHIPPING_PREFIX + "/imports";
export const UMS = "/ums";
export const ADDRESSBOOK = "/addressbook";
export const REFERENCE = "/reference";
export const CONTAINER = "/container";
export const PRODUCTBOOK = "/productbook";
export const IMPORTS = "/imports";
