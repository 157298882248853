import moment from "moment/moment";
import { createSelector } from "reselect";
import { getFormValues } from "redux-form";

import { DEFAULT_DATE_FORMAT } from "~/constants/dateFormats";

export const getFormState = (state, formName) => getFormValues(formName)(state);

export const isCurrentDateInThePast = createSelector(getFormState, params => {
  if (!params?.shipmentDate) {
    return false;
  }
  return moment(params?.shipmentDate, DEFAULT_DATE_FORMAT).isBefore(
    moment(),
    "days"
  );
});
