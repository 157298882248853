import { get } from "lodash-es";
import lifecycle from "recompose/lifecycle";

import { BANNERS_TYPES } from "@dpdgroupuk/mydpd-enums";

import * as S from "~/constants/strings";

export default lifecycle({
  componentDidMount() {
    const consignmentNumber = get(
      this.props.shipment,
      "outboundConsignment.consignmentNumber"
    );

    if (consignmentNumber) {
      // @see https://dpdgroup.slack.com/archives/G01LEMW2J6L/p1641382360041100
      this.props.banner.showByType(BANNERS_TYPES.WARNING, {
        message: `${S.SHIPMENT_CURRENTLY_EDITING} ${consignmentNumber}`,
        closable: true,
        showIcon: true,
        actions: [],
      });
    }
  },
});
