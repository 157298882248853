import api from "./api";
import { SHIPPING_SHIPMENTS } from "./basePaths";

export const createShipmentDomestic = body =>
  api.post({
    path: `${SHIPPING_SHIPMENTS}/domestic`,
    body,
  });

export const createShipmentInternational = body =>
  api.post({
    path: `${SHIPPING_SHIPMENTS}/international`,
    body,
  });

export const createShipmentCollection = body =>
  api.post({
    path: `${SHIPPING_SHIPMENTS}/collectionondelivery`,
    body,
  });

export const getLabel = (shipmentId, { printerType, parcelNumbers }) =>
  api.get({
    path: `${SHIPPING_SHIPMENTS}/${shipmentId}/labels`,
    query: {
      printerType,
      parcelNumbers,
    },
  });

export const getInvoiceLabel = (shipmentId, query) =>
  api.get({
    path: `${SHIPPING_SHIPMENTS}/${shipmentId}/invoice`,
    query,
  });

export const getShipmentList = query =>
  api.get({
    path: `${SHIPPING_SHIPMENTS}`,
    query,
  });

export const updateShipmentDate = (shipmentId, body) =>
  api.put({
    path: `${SHIPPING_SHIPMENTS}/${shipmentId}/actions/changedate`,
    body,
  });

export const voidShipment = (shipmentId, options) =>
  api.put({
    path: `${SHIPPING_SHIPMENTS}/${shipmentId}/actions/void`,
    options,
  });

export const voidParcels = (shipmentId, body) =>
  api.put({
    path: `${SHIPPING_SHIPMENTS}/${shipmentId}/parcels/actions/void`,
    body,
  });

export const unvoidParcels = (shipmentId, body) =>
  api.put({
    path: `${SHIPPING_SHIPMENTS}/${shipmentId}/parcels/actions/unvoid`,
    body,
  });

export const unvoidShipment = (shipmentId, options) =>
  api.put({
    path: `${SHIPPING_SHIPMENTS}/${shipmentId}/actions/unvoid`,
    options,
  });

export const generateUniqueReference1 = () =>
  api.post({
    path: `${SHIPPING_SHIPMENTS}/actions/generate-reference`,
  });

export const validateUniqueReference1 = (customerRef, options) =>
  api.post({
    path: `${SHIPPING_SHIPMENTS}/actions/validate-reference`,
    body: { customerRef },
    options,
  });

export const deleteShipment = (shipmentId, options) =>
  api.delete({
    path: `${SHIPPING_SHIPMENTS}/${shipmentId}`,
    options,
  });

export const updateShipmentCollection = (shipmentId, body) =>
  api.put({
    path: `${SHIPPING_SHIPMENTS}/${shipmentId}/collectionondelivery`,
    body,
  });

export const updateShipmentDomestic = (shipmentId, body) =>
  api.put({
    path: `${SHIPPING_SHIPMENTS}/${shipmentId}/domestic`,
    body,
  });

export const updateShipmentInternational = (shipmentId, body) =>
  api.put({
    path: `${SHIPPING_SHIPMENTS}/${shipmentId}/international`,
    body,
  });

export const getShipmentById = shipmentId =>
  api.get({
    path: `${SHIPPING_SHIPMENTS}/${shipmentId}`,
  });

export const getFailedShipments = query =>
  api.get({
    path: `${SHIPPING_SHIPMENTS}/failed`,
    query,
  });

export const getFailedShipmentById = stagingShipmentId =>
  api.get({
    path: `${SHIPPING_SHIPMENTS}/failed/${stagingShipmentId}`,
  });

export const deleteFailedShipment = stagingShipmentId =>
  api.delete({
    path: `${SHIPPING_SHIPMENTS}/failed/${stagingShipmentId}`,
  });

export const updateShipmentParcels = (shipmentId, query) =>
  api.put({
    path: `${SHIPPING_SHIPMENTS}/${shipmentId}/parcels`,
    query,
  });

export const importShipments = (templateId, body) =>
  api.sendFormData({
    path: `${SHIPPING_SHIPMENTS}/import`,
    query: {
      templateId,
    },
    body,
  });

export const testImportFile = (body, query) =>
  api.sendFormData({
    path: `${SHIPPING_SHIPMENTS}/import-test`,
    body,
    query,
  });

export const getShipmentByScanReference = query =>
  api
    .get({
      path: `${SHIPPING_SHIPMENTS}/scan`,
      query,
    })
    .then(({ data }) => data);

export const deleteUnprintedShipments = options =>
  api.delete({
    path: `${SHIPPING_SHIPMENTS}/clear-unprinted`,
    options,
  });

export const fetchShipmentsStats = (query, options) =>
  api.get({
    path: `${SHIPPING_SHIPMENTS}/stats`,
    query,
    options,
  });

export const holdShipment = (shipmentId, options) =>
  api.put({
    path: `${SHIPPING_SHIPMENTS}/${shipmentId}/actions/hold`,
    options,
  });

export const unholdShipment = (shipmentId, options) =>
  api.put({
    path: `${SHIPPING_SHIPMENTS}/${shipmentId}/actions/unhold`,
    options,
  });

export const postShipmentEod = (query, options) =>
  api.post({
    path: `${SHIPPING_SHIPMENTS}/eod`,
    query,
    options,
  });
