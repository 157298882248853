import { EventEmitter } from "@dpdgroupuk/mydpd-app";
import { getFileAttributes } from "@dpdgroupuk/mydpd-ui";

import * as FsUtils from "~/utils/fs";

import { localApi } from "../../apis";

// eslint-disable-next-line
const prdRegex = new RegExp(`^\\s*(["']{0,1})\\s*PRD`);

class FileReader extends EventEmitter {
  static MAX_CHUNK_SIZE = 128 * 1024; // 128kb

  static parseLines(content) {
    // CR = \r;  CRLF = \r\n LF = \n;
    return (content || "").toString().split(/\r\n|\n/);
  }

  constructor(filePath, options = {}) {
    super();
    this.path = filePath;
    const baseName = FsUtils.basename(this.path);
    this.name = getFileAttributes(baseName)?.fileName;
    this.options = {
      ...options,
    };
    this.headers = [];
    this.totalRows = 0;
    this.tmpBeginChunk = "";
    this.tmpEndChunk = "";
    this.footers = [];
    this.startPointer = 0;
    this.endPointer = null;
    this.size = 0;

    this.statsPromise = localApi.fs.stat(this.path).then(stats => {
      this.endPointer = stats.size;
      this.size = stats.size;
      return stats;
    });
  }

  async validate() {
    if (this.name.length > 100) {
      const error = new Error(
        `File name ${this.name} length must be less than or equal to 100 characters long`
      );
      this.emit("error", error);
      throw error;
    }
    const { size } = await this.statsPromise;
    if (!size) {
      const error = new Error(`Nothing to process in file ${this.name}`);
      this.emit("error", error);
      throw error;
    }
    return this;
  }

  async read(params) {
    const { shipmentTemplate } = params;
    const importHeaderLines =
      shipmentTemplate.headerLines || (shipmentTemplate.ignoreHeader && 1);
    const importFooterLines = shipmentTemplate.footerLines;

    await this.statsPromise;

    const floatChunks = this.size / FileReader.MAX_CHUNK_SIZE;
    const chunks = Math.ceil(floatChunks);
    if (chunks > 1) {
      if (importHeaderLines) {
        this.headers = await this._readHeader(importHeaderLines);
      }
      if (importFooterLines) {
        this.footers = await this._readFooter(importFooterLines);
      }
      this._emitMetadata();
      await this._readChunks();
    } else {
      const { chunk } = await this._readChunk(
        this.startPointer,
        this.endPointer
      );
      const lines = FileReader.parseLines(chunk).filter(line => line.trim());

      if (importHeaderLines) {
        this.headers = lines.splice(0, importHeaderLines);
      }
      if (importFooterLines) {
        this.footers = lines.splice(
          lines.length - importFooterLines,
          importFooterLines
        );
      }
      if (!lines.length) {
        throw new Error(`Nothing to process in file ${this.name}`);
      }
      this._emitMetadata();
      this._emitRows(lines);
    }

    return {
      totalRows: this.totalRows,
      headers: this.headers,
      footers: this.footers,
    };
  }

  _emitRows(rows) {
    const startAt = this.totalRows;
    this.totalRows += rows.length;
    // this.rows = this.rows.concat(rows);
    this.emit("data", {
      lines: rows,
      startAt,
      total: this.totalRows,
    });
  }

  _emitMetadata() {
    this.emit("metadata", {
      path: this.path,
      name: this.name,
      size: this.size,
      headers: this.headers,
      footers: this.footers,
    });
  }

  async _readHeader(headerLines) {
    let headerChunk = "";
    let numberOfHeaderLines = 0;
    let endHeaderBytes = FileReader.MAX_CHUNK_SIZE;

    do {
      const { chunk, end } = await this._readChunk(
        this.startPointer,
        endHeaderBytes
      );
      this.startPointer = end + 1;
      endHeaderBytes = end + FileReader.MAX_CHUNK_SIZE;

      headerChunk += chunk;
      const dividers = headerChunk.match(/\r\n|\n/g);
      numberOfHeaderLines += (dividers && dividers.length) || 0;
    } while (numberOfHeaderLines < headerLines);

    const lines = FileReader.parseLines(headerChunk.trimStart());

    const headers = lines.splice(0, headerLines);
    this.tmpBeginChunk = lines.join("\n");
    return headers;
  }

  async _readFooter(footerLines) {
    let footerChunk = "";
    let numberOfFooterLines = 0;
    let startFooterBytes = this.size - FileReader.MAX_CHUNK_SIZE;
    do {
      const { chunk, start } = await this._readChunk(
        startFooterBytes,
        this.endPointer
      );
      this.endPointer = start - 1;
      startFooterBytes = start - FileReader.MAX_CHUNK_SIZE;

      // append always at start
      footerChunk = chunk + footerChunk;
      const dividers = footerChunk.match(/\r\n|\n/g);
      numberOfFooterLines += (dividers && dividers.length) || 0;
    } while (numberOfFooterLines < footerLines);
    const lines = FileReader.parseLines(footerChunk.trimEnd());
    const footers = lines.splice(lines.length - footerLines, lines.length);
    // leave rest footer chunk for later process
    this.tmpEndChunk = lines.join("\n");
    return footers;
  }

  async _readChunks() {
    while (this.startPointer < this.endPointer) {
      const { chunk, end } = await this._readChunk(
        this.startPointer,
        this.startPointer + FileReader.MAX_CHUNK_SIZE
      );
      this.startPointer = end + 1;
      const data = this.tmpBeginChunk + chunk;
      const lines = FileReader.parseLines(data);
      const cutIndex = lines.findLastIndex(
        line => line.length > 4 && !prdRegex.test(line)
      );
      this.tmpBeginChunk = lines.splice(cutIndex).join("\n");

      this._emitRows(lines);
    }
    // last chunk
    const lines = FileReader.parseLines(this.tmpBeginChunk + this.tmpEndChunk);

    return this._emitRows(lines);
  }

  async _readChunk(start, end) {
    const startBytes = start < this.startPointer ? this.startPointer : start;
    const endBytes = end > this.endPointer ? this.endPointer : end;
    const chunk = await localApi.fs.read(this.path, {
      start: startBytes,
      end: endBytes,
    });
    return {
      chunk,
      start: startBytes,
      end: endBytes,
    };
  }
}

export default FileReader;
