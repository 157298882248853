import PropTypes from "prop-types";
import { Col, InputGroup, Row } from "react-bootstrap";
import { Field } from "redux-form";

import { FormControl } from "@dpdgroupuk/mydpd-ui";

import { ShipmentEntity } from "~/constants/forms";
import * as S from "~/constants/strings";
import { Normalizers } from "~/models";

import styles from "./Customs.module.scss";

const Customs = ({
  requiredFields,
  currencies,
  onFieldEntry,
  disabledFields,
  allowedFields,
}) => (
  <>
    <Row className={styles.customs}>
      <Col xs={6}>
        <InputGroup>
          <Field
            component={FormControl.Dropdown}
            name={ShipmentEntity.OUTBOUND_CONSIGNMENT.CURRENCY}
            values={currencies}
            onBlur={onFieldEntry}
            textTransform={S.UPPERCASE}
            classes={{ group: styles.currencyDropdown }}
            disabled={
              disabledFields[ShipmentEntity.OUTBOUND_CONSIGNMENT.CURRENCY]
            }
          />
          <Field
            component={FormControl.Input}
            label={S.CUSTOMS_VALUE}
            name={ShipmentEntity.OUTBOUND_CONSIGNMENT.CUSTOMS_VALUE}
            helperText={
              disabledFields[ShipmentEntity.OUTBOUND_CONSIGNMENT.CUSTOMS_VALUE]
                ? S.AUTO_CALCULATED_VALUE
                : true
            }
            onBlur={onFieldEntry}
            normalize={Normalizers.floatNormalize}
            disabled={
              disabledFields[ShipmentEntity.OUTBOUND_CONSIGNMENT.CUSTOMS_VALUE]
            }
            maxLength={11}
            required={
              requiredFields[ShipmentEntity.OUTBOUND_CONSIGNMENT.CUSTOMS_VALUE]
            }
            classes={{ group: styles.customsValueInput }}
          />
        </InputGroup>
      </Col>
      {allowedFields[
        ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DESCRIPTION
      ] && (
        <Col xs={6}>
          <Field
            component={FormControl.Input}
            label={S.CONTENTS_DESCRIPTION}
            helperText
            name={ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DESCRIPTION}
            onBlur={onFieldEntry}
            maxLength={45}
            required={
              requiredFields[
                ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DESCRIPTION
              ]
            }
            disabled={
              disabledFields[
                ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DESCRIPTION
              ]
            }
          />
        </Col>
      )}
    </Row>
  </>
);

Customs.propTypes = {
  requiredFields: PropTypes.object,
  currencies: PropTypes.array,
  onFieldEntry: PropTypes.func,
  disabledFields: PropTypes.object,
  allowedFields: PropTypes.object,
};

export default Customs;
