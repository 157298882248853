/**
 * This method adds namespaces for string nested fields
 * @param definitions - Object of namespace definitions
 * @param fields - Object with all fieldNames
 * @param namespace
 * @returns {{}}
 */
export const createNamespace = (definitions, fields, namespace = "") => {
  const modules = {};
  for (const key in definitions) {
    const definition = definitions[key];
    const space = namespace ? `${namespace}.${fields[key]}` : fields[key];
    if (typeof definition === "string") {
      modules[key] = space;
    } else if (typeof definition === "object") {
      modules[key] = createNamespace(definition, fields, space);
    }
  }
  return modules;
};
