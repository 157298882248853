import { ManifestActionTypes } from "./actions";

export const initialState = {
  manifest: null,
  manifests: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ManifestActionTypes.FETCH.SUCCESS: {
      return {
        ...state,
        manifests: action.payload,
      };
    }
    case ManifestActionTypes.CREATE.SUCCESS: {
      return {
        ...state,
        manifest: action.payload,
      };
    }
    case ManifestActionTypes.CLEAR_ALL: {
      return {
        ...state,
        manifest: null,
        manifests: [],
      };
    }
    default:
      return state;
  }
};
