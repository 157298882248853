import {
  compose,
  lifecycle,
  withHandlers,
  withProps,
  withState,
} from "recompose";
import * as S from "~/constants/strings";
import { connect } from "react-redux";
import { AddressBookActions } from "~/redux";
import { withNotifier } from "@dpdgroupuk/mydpd-app";
import { BulkReverseModels } from "~/pages/Tools/pages/BulkReverse/models";
import { getValue } from "~/utils/object";
import { bulkReverseSchema } from "~/models/validators/bulkReverseSchema";
import { ToolsActions, ToolsSelectors } from "~/pages/Tools/redux";
import BasePage from "~/pages/Tools/pages/BasePage";

export default compose(
  withState("abortController", "setAbortController", new AbortController()),
  withNotifier,
  withProps(() => ({
    groupsTitle: S.BULK_REVERSE_IT,
    isGroupsField: false,
    validationSchema: () => bulkReverseSchema(),
  })),
  connect(
    state => ({
      returnAddresses: ToolsSelectors.getReturnAddressesKeyValue(state),
    }),
    (dispatch, { notifier, abortController, setAbortController }) => ({
      fetchReturnAddressBooks: notifier.runAsync(
        query =>
          dispatch(
            AddressBookActions.fetchAddressBooks(query, {
              signal: abortController.signal,
            })
          ),
        {
          entityName: S.ADDRESS_BOOKS,
        }
      ),
      initializeForm: () => dispatch(ToolsActions.initializeBulkReverse()),
    })
  ),
  withHandlers({
    fetchBulkData:
      ({ fetchReturnAddressBooks }) =>
      async () => {
        const addressBooks = await fetchReturnAddressBooks(
          BulkReverseModels.INITIAL_SEARCH_ADDRESS_QUERY
        );
        const totalReturnAddressBooks = getValue(
          addressBooks,
          "totalResults",
          0
        );

        // NOTE: get all addresses because response returns only 10 (default length)
        if (totalReturnAddressBooks > 10) {
          await fetchReturnAddressBooks({
            ...BulkReverseModels.INITIAL_SEARCH_ADDRESS_QUERY,
            searchPageSize: totalReturnAddressBooks,
          });
        }
      },
  }),
  lifecycle({
    componentWillUnmount() {
      const { abortController, setAbortController } = this.props;

      setAbortController(abortController.abort());
    },
  })
)(BasePage);
