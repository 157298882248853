import { reset } from "redux-form";

import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import { containerApi } from "~/apis";
import { CONTAINER_FORM } from "~/constants/forms";

export const ActionTypes = createActionTypes("SHIPMENT", {
  GET_LABELS: createAsyncActionTypes("GET_LABELS"),
});

export const fetchLabels = createAsyncAction(
  query =>
    containerApi
      .getLabels(query)
      .then(({ data: { printString } }) => printString),
  ActionTypes.GET_LABELS
);

export const clearContainerForm = dispatch => {
  dispatch(reset(CONTAINER_FORM));
};
