import { compose, lifecycle, withHandlers, withState } from "recompose";
import * as S from "~/constants/strings";
import { BANNERS_TYPES } from "@dpdgroupuk/mydpd-enums";
import { Banner } from "@dpdgroupuk/mydpd-ui";
import { SubmissionError } from "redux-form";
import { withPrinter } from "@dpdgroupuk/mydpd-app";
import { connect } from "react-redux";
import { UmsSelectors } from "~/redux";
import { formatMessage } from "~/utils/string";

export default compose(
  withState("bannerId", "setBannerId", null),
  withPrinter,
  Banner.withBanner,
  connect(
    state => ({
      currentPrinterType: UmsSelectors.getPrinterType(state),
    }),
    (dispatch, { bannerId, setBannerId, banner, isGroupsField }) => ({
      showPrinterTypeBanner: () => {
        bannerId && banner.hideById(bannerId);
        setBannerId(
          banner.showByType(BANNERS_TYPES.ALERT, {
            message: formatMessage(
              S.SWITCH_TO_THERMAL_PRINTER_TO_USE_$,
              isGroupsField ? S.GROUP_DISPATCH : S.BULK_REVERSE_IT
            ),
            closable: true,
            showIcon: true,
            actions: [],
          })
        );
      },
    })
  ),
  withHandlers({
    validatePrinterType:
      ({ currentPrinterType, isGroupsField }) =>
      () => {
        if (currentPrinterType === 0) {
          throw new SubmissionError({
            printerType: formatMessage(
              S.SWITCH_TO_THERMAL_PRINTER_TO_USE_$,
              isGroupsField ? S.GROUP_DISPATCH : S.BULK_REVERSE_IT
            ),
          });
        }
      },
  }),
  lifecycle({
    componentWillUnmount() {
      this.props.bannerId && this.props.banner.hideById(this.props.bannerId);
    },
  })
);
