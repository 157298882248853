import { AddressBookEntity, TOOLS_FIELDS } from "~/constants/forms";
import { ShipmentModels } from "~/models";
import { GB } from "~/constants/strings";
import { getValue } from "~/utils/object";
import { duplicateDataByCount } from "~/pages/Tools/models";
import { transformAddressValues } from "~/models/address/address";
import { find } from "lodash";

export const getInitialValues = (profile, preferences, addressBook) => ({
  [TOOLS_FIELDS.PROFILE_CODE]: profile?.profileCode,
  [TOOLS_FIELDS.SHIPMENTS_PER_LINE]: "1",
  [TOOLS_FIELDS.WEIGHT_PER_SHIPMENT]: ShipmentModels.getDefaultTotalWeight(
    null,
    preferences,
    GB
  ),
  [TOOLS_FIELDS.RETURN_ADDRESS]: addressBook?.addressBookId,
});

export const INITIAL_SEARCH_ADDRESS_QUERY = {
  type: 1,
  searchPage: 1,
};

// @see:https://it.dpduk.live/version/customer-shipping/mydpd-last-5/sprint-3/diag_3TpN576GAqAADQHZ.html
export const createLines = (data, formValues, returnAddresses) => {
  const result = [];
  const count = getValue(formValues, TOOLS_FIELDS.SHIPMENTS_PER_LINE, "");
  const returnAddressBook = find(returnAddresses, [
    "value",
    getValue(formValues, TOOLS_FIELDS.RETURN_ADDRESS, ""),
  ]);
  const transformedAddress = transformAddressValues(data.address);
  const line = `"${getValue(
    data,
    AddressBookEntity.ADDRESS.ORGANISATION,
    ""
  )}"|"${getValue(transformedAddress, "addressLine1", "")}"|"${getValue(
    transformedAddress,
    "addressLine2",
    ""
  )}"|"${getValue(data, AddressBookEntity.ADDRESS.TOWN, "")}"|"${getValue(
    data,
    AddressBookEntity.ADDRESS.COUNTY,
    ""
  )}"|"${getValue(data, AddressBookEntity.ADDRESS.POSTCODE, "")}"|"${getValue(
    data,
    AddressBookEntity.ADDRESS.COUNTRY_CODE,
    ""
  )}"|""|"1"|"${getValue(
    formValues,
    TOOLS_FIELDS.WEIGHT_PER_SHIPMENT,
    ""
  )}"|"${getValue(
    data,
    AddressBookEntity.CONTACT_DETAILS.CONTACT_NAME,
    ""
  )}"|"${getValue(
    data,
    AddressBookEntity.CONTACT_DETAILS.TELEPHONE,
    ""
  )}"|"${getValue(
    data,
    AddressBookEntity.NOTIFICATION_DETAILS.MOBILE,
    ""
  )}"|"${getValue(data, AddressBookEntity.NOTIFICATION_DETAILS.EMAIL, "")}"|"${
    returnAddressBook.label
  }"`;

  result.push(...duplicateDataByCount(line, count));

  return result;
};
