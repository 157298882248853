import { get, isEmpty, set } from "lodash";

import { REQUIRED_TYPE } from "@dpdgroupuk/mydpd-enums";

import { Fields, ShipmentEntity } from "~/constants/forms";
import * as S from "~/constants/strings";
import { ParcelModels } from "~/models";
import { InvoiceModels } from "~/models/invoice";
import { roundToDecimal } from "~/utils/number";
import { getValue } from "~/utils/object";

export const getDefaultCurrency = (preferences, profile) =>
  get(profile, "invDefaultCurrency") ||
  get(preferences, "shippingDefaults.international.intCurrency") ||
  S.GBP;

export const getShipmentCustomsFieldsConfig = (
  service,
  country,
  generateCustomsData
) => {
  if (isEmpty(service)) return {};

  if (service.proforma === REQUIRED_TYPE.MANDATORY) {
    return { deliveryDescription: true, customsValueDisabled: true };
  }

  if (service.proforma === REQUIRED_TYPE.OPTIONAL) {
    if (generateCustomsData) {
      return { deliveryDescription: true, customsValueDisabled: true };
    }

    return { customsValue: true, deliveryDescription: true };
  }

  if (country.countryKey === S.GB) {
    if (
      service.prodRequired === REQUIRED_TYPE.MANDATORY ||
      service.prodRequired === REQUIRED_TYPE.OPTIONAL
    ) {
      return { customsValueDisabled: true, deliveryDescriptionHidden: true };
    }

    return {};
  }

  if (service.prodRequired === REQUIRED_TYPE.MANDATORY) {
    return { customsValueDisabled: true, deliveryDescriptionHidden: true };
  }

  return { customsValue: true, deliveryDescription: true };
};

export const isCustomsVisible = (service, country, generateCustomsData) =>
  !isEmpty(
    getShipmentCustomsFieldsConfig(service, country, generateCustomsData)
  );

// @see https://it.dpduk.live/version/customer-shipping/sprint-1.19/diag_aBI0iN6GAqAAhaeB.html?id=1639416352165
export const isCustomsFieldsVisible = (service, country, formValues) => {
  const generateCustomsData = get(
    formValues,
    ShipmentEntity.GENERATE_CUSTOMS_DATA
  );

  return isCustomsVisible(service, country, generateCustomsData);
};

export const isCostsRequired = (selectedService = {}) =>
  selectedService.costsRequired === REQUIRED_TYPE.MANDATORY;

// @see https://it.dpduk.live/it/diagram/diag_1jl2ul6GAqAADNn6.html
export const getCustomsFieldsConfig = (service, country, formValues) => {
  const generateCustomsData = get(
    formValues,
    ShipmentEntity.GENERATE_CUSTOMS_DATA
  );
  const exportReasonRequired = InvoiceModels.isExportReasonRequired(service);
  const costsRequired = isCostsRequired(service);

  return {
    ...getShipmentCustomsFieldsConfig(service, country, generateCustomsData),
    [Fields.EXPORT_REASON]: generateCustomsData && exportReasonRequired,
    [Fields.SHIPPING_COST]: generateCustomsData && costsRequired,
  };
};

export const setupInitialCustomsFields = data => {
  const { shipment, selectedOutboundNetwork } = data;
  const country = {
    countryKey: get(
      shipment,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTRY_CODE
    ),
  };
  const visibleFields = isCustomsFieldsVisible(
    selectedOutboundNetwork,
    country,
    shipment
  );
  const outboundConsignment = shipment.outboundConsignment;
  const generateCustomsData = getValue(
    shipment,
    ShipmentEntity.GENERATE_CUSTOMS_DATA
  );
  const { customsValueDisabled } = getShipmentCustomsFieldsConfig(
    selectedOutboundNetwork,
    country,
    generateCustomsData
  );
  const shipmentCustomsValue = getValue(
    shipment,
    ShipmentEntity.OUTBOUND_CONSIGNMENT.CUSTOMS_VALUE
  );

  if (!shipmentCustomsValue && customsValueDisabled) {
    const calculatedCustomsValue = parseFloat(
      ParcelModels.calculateParcelsTotalValues(
        get(shipment, "outboundConsignment.parcels", [])
      )
    ).toFixed(2);
    set(outboundConsignment, Fields.CUSTOMS_VALUE, calculatedCustomsValue);
  } else {
    set(
      outboundConsignment,
      Fields.CUSTOMS_VALUE,
      roundToDecimal(shipmentCustomsValue)
    );
  }

  if (!visibleFields) {
    delete outboundConsignment.customsValue;
    delete outboundConsignment.currency;
    delete outboundConsignment.deliveryDescription;
  }

  return {
    ...data,
    shipment: {
      ...shipment,
      outboundConsignment,
    },
  };
};

export const isCustomsValue = field =>
  field === ShipmentEntity.OUTBOUND_CONSIGNMENT.CUSTOMS_VALUE;
