import { lifecycle } from "recompose";
import moment from "moment/moment";
import { change } from "redux-form";
import { checkMinDate } from "~/redux";

const updateDate = ({ formName, dateFormat, fieldPath }) =>
  lifecycle({
    componentDidUpdate(prevProps) {
      const { dispatch } = this.props;
      dispatch(checkMinDate({ formName, dateFormat, fieldPath }));

      // NOTE: only if modal was closed
      if (!this.props.open && prevProps.open) {
        dispatch(change(formName, fieldPath, moment().format(dateFormat)));
      }
    },
  });

export default updateDate;
