import { compose, lifecycle, withHandlers, withState } from "recompose";

import { BANNERS_TYPES } from "@dpdgroupuk/mydpd-enums";
import { Banner } from "@dpdgroupuk/mydpd-ui";

import { withImportLogger } from "./Context";

export default compose(
  Banner.withBanner,
  withState("bannerId", "setBannerId", null),
  withHandlers({
    showImportBanner:
      ({ bannerId, setBannerId, banner }) =>
      e => {
        const event = e.detail;

        bannerId && banner.hideById(bannerId);

        setBannerId(
          banner.showByType(BANNERS_TYPES.INFO, {
            message: event.message,
            closable: true,
            showIcon: true,
            actions: [],
          })
        );
      },
  }),
  withImportLogger,
  lifecycle({
    componentDidMount() {
      this.removeImportBannerListener = this.props.logger.addListener(
        "log",
        this.props.showImportBanner
      );
    },

    componentWillUnmount() {
      this.props.bannerId && this.props.banner.hideById(this.props.bannerId);
      this.removeImportBannerListener && this.removeImportBannerListener();
    },
  })
);
