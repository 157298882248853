import Joi from "joi";

import { ImportsFields } from "~/constants/forms";
import { TEMPLATE_NAME } from "~/constants/strings";

// @see https://it.dpduk.live/version/customer-shipping/sprint-2.1/diag_bLvEE76GAqAAhV1p.html?id=1649407337165
// @see https://dpdgroup.slack.com/archives/G01LEMW2J6L/p1652449556780229
export default Joi.object().keys({
  [ImportsFields.TEMPLATE_NAME]: Joi.string()
    .max(50)
    .label(TEMPLATE_NAME)
    .required(),
});
