import {
  EDIT_FAILED_SHIPMENT_FORM,
  OUTBOUND_CONSIGNMENT,
  ShipmentEntity,
} from "~/constants/forms";
import { ShipmentSelectors } from "~/pages/Shipment/redux";
import { getValue } from "~/utils/object";
import { initializeForm } from "~/utils/reduxForm";

import { getSelectedFailedShipment } from "../../redux/selectors";

export const createParcelProduct =
  (product, packageNumber) => (dispatch, getState) => {
    const parcelId = getValue(
      getSelectedFailedShipment(getState()),
      `${ShipmentEntity.OUTBOUND_CONSIGNMENT.PARCELS}.${packageNumber}.parcelId`,
      null
    );
    const formProducts = getValue(
      ShipmentSelectors.getShipmentFormValues(getState(), {
        formName: EDIT_FAILED_SHIPMENT_FORM,
      }),
      `${ShipmentEntity.OUTBOUND_CONSIGNMENT.PARCELS}.${packageNumber}.products`,
      []
    );
    dispatch(
      initializeForm(EDIT_FAILED_SHIPMENT_FORM, {
        [OUTBOUND_CONSIGNMENT]: {
          parcels: {
            [packageNumber]: {
              products: [...formProducts, product],
              ...(parcelId && {
                parcelId,
              }),
            },
          },
        },
      })
    );
  };
