import React from "react";

import PropTypes from "prop-types";

import ReceiptController from "./ReceiptController";

const withController = BaseComponent =>
  class WrapReceiptController extends React.Component {
    static propTypes = {
      children: PropTypes.node,
      localService: PropTypes.object,
    };

    constructor(props, context) {
      super(props, context);
      this.receiptController = new ReceiptController({
        localApis: this.props.localService.localApis,
      });
    }

    render() {
      return (
        <BaseComponent
          {...this.props}
          receiptController={this.receiptController}
        >
          {this.props.children}
        </BaseComponent>
      );
    }
  };

export default withController;
