import { connect } from "react-redux";
import { compose, withHandlers } from "recompose";

import { GB } from "~/constants/strings";
import { AddressModels } from "~/models";
import { toUppercaseValues } from "~/utils/object";
import { initializeForm } from "~/utils/reduxForm";

export default formName =>
  compose(
    connect(null, dispatch => ({
      updateForm: values => dispatch(initializeForm(formName, values)),
    })),
    withHandlers({
      onPostcodeSelectionChange:
        ({ updateForm }) =>
        address => {
          const updatedFormValues = toUppercaseValues({
            ...AddressModels.updateEditInvoiceFormFields({
              address: { countryCode: GB, ...address },
            }),
          });

          updateForm(updatedFormValues);
        },
    })
  );
