import { useState } from "react";

const useToggle = (initial = false) => {
  const [state, setState] = useState(initial);

  return {
    value: state,
    set: value => setState(!!value),
    toggle: () => setState(!state),
    switchOn: () => setState(true),
    switchOff: () => setState(false),
  };
};

export default useToggle;
