import { connect } from "react-redux";
import { compose } from "recompose";

import { createCancelableRequest } from "@dpdgroupuk/fetch-client";

import { ReferenceActions } from "~/redux";

export default compose(
  connect(null, dispatch => ({
    onCommoditySearch: createCancelableRequest(async (query, options) =>
      dispatch(ReferenceActions.fetchCommodities(query, options))
    ),
    onCommodityCodeSearch: createCancelableRequest(async (query, options) =>
      dispatch(ReferenceActions.fetchCommoditiesByCode(query, options))
    ),
  }))
);
