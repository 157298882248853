import { get } from "lodash";
import withHandlers from "recompose/withHandlers";

import { BANNERS_TYPES } from "@dpdgroupuk/mydpd-enums";

import * as S from "~/constants/strings";
import { clearCreateShipmentPage } from "~/pages/CreateShipment/actions";

import { ShipmentActions } from "../redux";

export default withHandlers({
  printShipment:
    ({
      dispatch,
      banner,
      onClickCopyNumber,
      confirmPrintHandler,
      pageConfig,
      setConsignmentBannerId,
      overlay,
      shippingSettings,
      generateUniqueSenderRef1,
    }) =>
    async printNow => {
      let consignmentNumber;
      let shipment;

      try {
        overlay.show();

        shipment = await dispatch(
          ShipmentActions.createShipment({
            formName: pageConfig.formName,
          })
        );
        consignmentNumber = get(shipment.consignments, "[0].consignmentNumber");

        if (printNow) {
          await confirmPrintHandler(shipment);
        }

        // NOTE: need it in scope clearCreateShipmentPage
        if (shippingSettings.allocateSenders) {
          await generateUniqueSenderRef1();
        }

        dispatch(clearCreateShipmentPage(pageConfig.formName));

        setConsignmentBannerId(
          banner.showByType(BANNERS_TYPES.INFO, {
            message: `${S.SHIPMENT_SAVED_SUCCESSFULLY} ${consignmentNumber}`,
            closable: true,
            showIcon: true,
            actions: [
              {
                text: S.COPY_NUMBER,
                handleClick: () => onClickCopyNumber(consignmentNumber),
              },
            ],
          })
        );
      } finally {
        overlay.hide();
      }

      return shipment;
    },
});
