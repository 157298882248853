const Lock = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
    <mask
      id="lockMask0"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="14"
      height="14"
    >
      <path d="M0 0H14V14H0V0Z" fill="#C4C4C4" />
    </mask>
    <g mask="url(#lockMask0)">
      <path
        d="M11.0833 4.66667H10.4167V3.33333C10.4167 1.49333 8.92333 0 7.08333 0C5.24333 0 3.75 1.49333 3.75 3.33333V4.66667H3.08333C2.35 4.66667 1.75 5.26667 1.75 6V12.6667C1.75 13.4 2.35 14 3.08333 14H11.0833C11.8167 14 12.4167 13.4 12.4167 12.6667V6C12.4167 5.26667 11.8167 4.66667 11.0833 4.66667ZM7.08333 10.6667C6.35 10.6667 5.75 10.0667 5.75 9.33333C5.75 8.6 6.35 8 7.08333 8C7.81667 8 8.41667 8.6 8.41667 9.33333C8.41667 10.0667 7.81667 10.6667 7.08333 10.6667ZM9.15 4.66667H5.01667V3.33333C5.01667 2.19333 5.94333 1.26667 7.08333 1.26667C8.22333 1.26667 9.15 2.19333 9.15 3.33333V4.66667Z"
        fill="#DC0032"
      />
    </g>
  </svg>
);

export default Lock;
