import Joi from "joi";

import { ShipmentReviewFilterFields } from "~/constants/forms";
import * as S from "~/constants/strings";

export default Joi.object({
  [ShipmentReviewFilterFields.SEARCH_CRITERIA]: Joi.string()
    .empty("")
    .max(150)
    .label(S.SEARCH),
});
