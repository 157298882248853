import classNames from "classnames";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { Field } from "redux-form";

import { Card, FormControl } from "@dpdgroupuk/mydpd-ui";

import { Fields } from "~/constants/forms";
import * as S from "~/constants/strings";

const SelectTemplateCard = ({ data, cardTitleClassName }) => (
  <Card className="h-auto">
    <Row>
      <Card.Title className={classNames("mb-4 col-md-4", cardTitleClassName)}>
        {S.TEMPLATE}
      </Card.Title>
      <Col className="mt-1 col-md-8 pl-1 pr-2">
        <Field
          name={Fields.SHIPMENT_TEMPLATE_ID}
          component={FormControl.Dropdown}
          label={S.TEMPLATE}
          values={data}
          disabled={!data.length}
        />
      </Col>
    </Row>
  </Card>
);

SelectTemplateCard.propTypes = {
  data: PropTypes.array,
  cardTitleClassName: PropTypes.string,
};

export default SelectTemplateCard;
