import { get, isEmpty, pick, valuesIn } from "lodash";
import isEqual from "lodash/isEqual";
import moment from "moment";
import queryString from "query-string";

import { DEFAULT_DATE_FORMAT } from "~/constants/dateFormats";
import { FilterFields } from "~/constants/forms";

import { omitNilValues } from "./object";

export const getInitialPageQuery = () => ({
  page: 1,
  pageSize: 15,
  searchCriteria: "",
  searchDate: moment().format(DEFAULT_DATE_FORMAT),
});

export const parseQuery = (searchQuery, parseNumbers) => {
  const query = queryString.parse(searchQuery, {
    parseNumbers,
  });

  return {
    ...getInitialPageQuery(),
    ...query,
  };
};
export const stringifyQuery = (query, encode = true, omitNil = true) =>
  queryString.stringify(omitNil ? omitNilValues(query) : query, {
    encode,
  });

export const getLocationState = location => {
  const locationState = get(location, "state", {}) || {};

  if (locationState.expiresIn && locationState.expiresIn > Date.now()) {
    return locationState;
  }

  return {};
};

export const createLocationState = (state = {}) => omitNilValues(state);

export const isPristineForm = (searchQuery, filterFields, omitNil) => {
  const { searchCriteria, searchDate } = getInitialPageQuery();

  return isEqual(getQueryFilters(searchQuery, filterFields, omitNil), {
    searchCriteria,
    searchDate,
  });
};

export const getQueryFilters = (
  searchQuery,
  filterFields = FilterFields,
  omitNil = true
) => {
  const query = parseQuery(searchQuery, false);
  const res = pick(query, valuesIn(filterFields));

  return isEmpty(res) ? {} : omitNil ? omitNilValues(res) : res;
};

export const getQueryPagination = searchQuery => {
  const query = parseQuery(searchQuery, true);
  const res = pick(query, ["page", "pageSize"]);
  const { page, pageSize } = getInitialPageQuery();

  if (isNaN(res.page) || res.page < 1) {
    res.page = page;
  }

  if (isNaN(res.pageSize)) {
    res.page = pageSize;
  }

  return res;
};

export const getQueryPaginationByTotal = (searchQuery, total) => {
  const res = getQueryPagination(searchQuery);

  if (typeof total === "number") {
    const lastPage = Math.ceil(total / res.pageSize);

    if (res.page > lastPage) {
      res.page = lastPage;
    }
  }

  return res;
};
