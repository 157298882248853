import classNames from "classnames";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { Field } from "redux-form";

import { Card, FormControl } from "@dpdgroupuk/mydpd-ui";

import { ShipmentEntity } from "~/constants/forms";
import { PROFILE, UPPERCASE } from "~/constants/strings";

import styles from "./ProfileCard.module.scss";

const ProfileCard = props => (
  <Col xs={12} className="p-0">
    <Card className={styles.profiles}>
      <Row className={styles.row}>
        <Col sm={6} className={classNames("m-0")}>
          <p className={styles.title}>Shipping Profile</p>
        </Col>
        <Col sm={6} className={classNames("m-0")}>
          <Field
            component={FormControl.Dropdown}
            label={PROFILE}
            name={ShipmentEntity.PROFILE_CODE}
            values={props.profiles}
            onBlur={props.onFieldEntry}
            textTransform={UPPERCASE}
            disabled={props.disabledFields[ShipmentEntity.PROFILE_CODE]}
            onChange={props.onProfileChange}
          />
        </Col>
      </Row>
    </Card>
  </Col>
);

ProfileCard.propTypes = {
  profiles: PropTypes.array,
  onFieldEntry: PropTypes.func,
  onProfileChange: PropTypes.func,
  disableProfile: PropTypes.bool,
  disabledFields: PropTypes.object,
};

export default ProfileCard;
