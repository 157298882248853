import isEmpty from "lodash/isEmpty";
import withHandlers from "recompose/withHandlers";

import { ShipmentEntity } from "~/constants/forms";
import { getEditShipmentInitialDataValues } from "~/pages/ShipmentReview/pages/ShipmentEdit/models";
import { set } from "lodash";
import { getValue } from "~/utils/object";

export default withHandlers({
  loadEditShipment:
    ({
      customerPrefs,
      fetchCountriesProfilesCurrenciesExportReasonsUniqueSenderRef,
      fetchAndProcessServices,
      initializeShipment,
      runCheckPostcodeIssues,
      initWithShipmentDate,
      fetchNetworks,
      customer,
      preferences,
      securitySettings,
      storageDate,
      authUser,
    }) =>
    async shipment => {
      try {
        await fetchNetworks({
          networkCode: shipment.outboundConsignment.networkCode,
        });
      } catch (e) {}

      const {
        countries,
        currencies,
        exportReasons,
        deliveryCountry,
        activeProfile,
      } = await fetchCountriesProfilesCurrenciesExportReasonsUniqueSenderRef({
        isAvailableInvoice: !isEmpty(shipment.invoice),
        shipmentCountryCode: getValue(
          shipment,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
            .COUNTRY_CODE,
          ""
        ),
        shipmentProfileCode: getValue(
          shipment,
          ShipmentEntity.PROFILE_CODE,
          ""
        ),
      });

      let outboundNetworks;
      let inboundNetworks;
      let selectedOutboundNetwork;
      let outboundErrors;
      let inboundErrors;

      // NOTE: Prevents service calls for voided shipment on Scanning page
      if (!shipment.isVoided) {
        const {
          outboundNetworks: outboundNetworksData,
          inboundNetworks: inboundNetworksData,
          selectedOutboundNetwork: selectedOutboundNetworkData,
          outboundErrors: outboundErrorsData,
          inboundErrors: inboundErrorsData,
        } = await fetchAndProcessServices({
          shipment,
          profile: activeProfile,
          selectedCountry: deliveryCountry,
        });

        outboundNetworks = outboundNetworksData;
        inboundNetworks = inboundNetworksData;
        selectedOutboundNetwork = selectedOutboundNetworkData;
        outboundErrors = outboundErrorsData;
        inboundErrors = inboundErrorsData;
      } else {
        const {
          outboundNetworks: outboundNetworksData,
          selectedOutboundNetwork: selectedOutboundNetworkData,
        } = await fetchAndProcessServices({
          shipment: {
            ...shipment,
            outboundConsignment: {
              ...shipment.outboundConsignment,
              // NOTE: pass 1 to fetch all available services
              numberOfParcels: 1,
              totalWeight: 1,
            },
          },
          profile: activeProfile,
          selectedCountry: deliveryCountry,
        });

        outboundNetworks = outboundNetworksData;
        selectedOutboundNetwork = selectedOutboundNetworkData;
        // NOTE: set proper weight only for Scan page
        set(
          shipment,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.TOTAL_WEIGHT,
          getValue(shipment, ShipmentEntity.OUTBOUND_CONSIGNMENT.DISPLAY_WEIGHT)
        );
      }

      const initialValues = getEditShipmentInitialDataValues({
        exportReasons,
        outboundNetworks,
        selectedOutboundNetwork,
        inboundNetworks,
        customer,
        preferences,
        customerPrefs,
        profile: activeProfile,
        shipment,
        securitySettings,
        currencies,
        countries,
        storageDate,
        authUser,
      });

      await initializeShipment({
        shipment,
        inboundNetworks,
        selectedOutboundNetwork,
        outboundErrors,
        inboundErrors,
        initialValues,
      });

      runCheckPostcodeIssues();
      initWithShipmentDate();
    },
});
