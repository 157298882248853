import { lifecycle } from "recompose";
import { Redirect, Switch } from "react-router";
import PropTypes from "prop-types";
import ProtectedRoute from "~/router/ProtectedRoute";
import * as routes from "~/router";
import { isRestrictedTab } from "~/router/model";
import {
  BULK_PATH,
  GROUP_DISPATCH_PATH,
  MENU_ITEMS,
  TOOLS_MENU_PATH,
} from "~/pages/Tools/constants";
import GroupDispatch from "~/pages/Tools/pages/GroupDispatch";
import BulkReverse from "~/pages/Tools/pages/BulkReverse/BulkReverse";
import ToolsMenu from "~/pages/Tools/pages/ToolsMenu";

const Tools = () => (
  <Switch>
    <Redirect exact strict from={routes.TOOLS} to={TOOLS_MENU_PATH} />
    <ProtectedRoute
      exact
      strict
      path={TOOLS_MENU_PATH}
      component={ToolsMenu}
      isEnabled={settings => !isRestrictedTab(settings, TOOLS_MENU_PATH)}
    />
    <ProtectedRoute
      exact
      strict
      path={GROUP_DISPATCH_PATH}
      component={GroupDispatch}
      isEnabled={settings => !isRestrictedTab(settings, GROUP_DISPATCH_PATH)}
    />
    <ProtectedRoute
      exact
      strict
      path={BULK_PATH}
      component={BulkReverse}
      isEnabled={settings => !isRestrictedTab(settings, BULK_PATH)}
      redirectTo={GROUP_DISPATCH_PATH}
    />
  </Switch>
);

Tools.defaultProps = {
  setThirdMenuItems: () => null,
};

Tools.propTypes = {
  setThirdMenuItems: PropTypes.func,
};

export default lifecycle({
  componentDidMount() {
    this.props.setThirdMenuItems(MENU_ITEMS);
  },
  componentWillUnmount() {
    this.props.setThirdMenuItems([]);
  },
})(Tools);
