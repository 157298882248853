import { isBoolean } from "lodash";
import { isNil } from "lodash/lang";

export const integerNormalize = v =>
  v.replace(/[^0-9]/g, "").replace(/^0/g, "");

export const numbersNormalize = v => v.replace(/[^0-9]/g, "");

export const floatNormalize = value =>
  value
    .replace(/[^0-9.,]/g, "") // replace non number chars
    .replace(/,/g, ".");

export const phoneNumberNormalize = value => {
  if (!value) {
    return value;
  }

  let phoneValue = value?.replace(/[^+\d]/g, "");
  const plusCharacterIndex = phoneValue?.lastIndexOf("+");

  if (plusCharacterIndex !== -1 && plusCharacterIndex !== 0) {
    phoneValue =
      phoneValue?.slice(0, plusCharacterIndex) +
      phoneValue?.slice(plusCharacterIndex + 1);
  }

  return phoneValue.substring(0, 15);
};

const TRUTHY = ["true", "y", 1, "1", "a"];
const FALSY = ["false", "n", 0, "0", "b"];

export const convertToBool = (value, forceCust = false) => {
  const originValue = value;
  if (typeof value !== "boolean") {
    if (typeof value === "string") {
      value = value.toLowerCase();
    }
    return TRUTHY.includes(value)
      ? true
      : FALSY.includes(value)
        ? false
        : forceCust
          ? null
          : originValue;
  }
  return forceCust ? null : originValue;
};

export const booleanOrNullValueFormat = value => {
  const castValue = convertToBool(value, false);
  if (isBoolean(castValue)) {
    return castValue ? "1" : "0";
  }
  return "";
};

export const booleanOrNullValueNormalize = value => convertToBool(value, true);

export const toUppercase = value => value.toUpperCase();

export const emptyValueNormalize = (value, prevValue) =>
  value === "" && isNil(prevValue) ? prevValue : value;
