import { get, has, merge, reduce, set, values } from "lodash";

import * as StringUtil from "@dpdgroupuk/mydpd-app/lib/utils/string";

import { isImporterBusiness } from "~/components/EditImporterModal/models";
import {
  DELIVERY_DETAILS,
  Fields,
  IMPORTER_DETAILS,
  INVOICE,
  OUTBOUND_CONSIGNMENT,
  ShipmentEntity,
} from "~/constants/forms";
import * as S from "~/constants/strings";
import { getValue } from "~/utils/object";

export const isUserAbleToAddOrEditAddressbook = advancedCustomerConfiguration =>
  !get(advancedCustomerConfiguration, "readOnlyAddressBook", false);

export const isAddressbookReadonly = (addressBook, user) => {
  if (!addressBook) return false;

  if (get(addressBook, "readOnly")) return true;

  const ownerRecord = get(addressBook, "userId");
  const uid = get(user, "userId");

  return ownerRecord !== uid;
};

const dummyAddressbook = {
  groups: [],
  isValid: true,
  isDefault: false,
  readOnly: false,
};

const replaceShipmentPathToAddressbook = field =>
  field
    .replace(`${OUTBOUND_CONSIGNMENT}.${DELIVERY_DETAILS}.`, "")
    .replace(`${OUTBOUND_CONSIGNMENT}.`, "")
    .replace(`${INVOICE}.${IMPORTER_DETAILS}.`, "")
    .replace("pidNumber", "pid");

export const getAddressBookQuery = (
  addressBook,
  formValues,
  searchAddressDetailsValues
) => {
  const isBusiness = isImporterBusiness(
    get(formValues, ShipmentEntity.INVOICE.IMPORTER_DETAILS.IS_BUSINESS)
  );
  const isVatNumberAvailable = has(
    formValues,
    ShipmentEntity.INVOICE.IMPORTER_DETAILS.VAT_NUMBER
  );
  const isEoriNumberAvailable = has(
    formValues,
    ShipmentEntity.INVOICE.IMPORTER_DETAILS.EORI_NUMBER
  );
  const isPidNumberAvailable = has(
    formValues,
    ShipmentEntity.INVOICE.IMPORTER_DETAILS.PID_NUMBER
  );

  const addressBookFields = [
    ...values(ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS),
    ...values(
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.CONTACT_DETAILS
    ),
    ...values(
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.NOTIFICATION_DETAILS
    ),
    ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_INSTRUCTION,
    ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_1,
    ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_2,
    ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_3,
    ...(isBusiness && isVatNumberAvailable
      ? [ShipmentEntity.INVOICE.IMPORTER_DETAILS.VAT_NUMBER]
      : []),
    ...(isBusiness && isEoriNumberAvailable
      ? [ShipmentEntity.INVOICE.IMPORTER_DETAILS.EORI_NUMBER]
      : []),
    ...(!isBusiness && isPidNumberAvailable
      ? [ShipmentEntity.INVOICE.IMPORTER_DETAILS.PID_NUMBER]
      : []),
  ];

  const newRecord = reduce(
    addressBookFields,
    (acc, field) => {
      set(
        acc,
        replaceShipmentPathToAddressbook(field),
        get(formValues, field, "")
      );
      return acc;
    },
    { addressBookType: getValue(addressBook, Fields.ADDRESS_TYPE, "0") }
  );

  if (newRecord.address.countryCode === S.GB) {
    set(
      newRecord,
      "address.postcode",
      StringUtil.addSpaceToUKPostcode(
        getValue(newRecord, "address.postcode", "")
      )
    );
  }

  if (
    searchAddressDetailsValues.searchCriteriaField === "shortName" &&
    searchAddressDetailsValues.searchCriteriaValue
  ) {
    set(newRecord, "shortName", searchAddressDetailsValues.searchCriteriaValue);
  }

  if (!addressBook) {
    merge(newRecord, dummyAddressbook);
  }

  return newRecord;
};
