import * as S from "~/constants/strings";
import { AddressBookEntity, Fields, TOOLS_FIELDS } from "~/constants/forms";
import { getValue } from "~/utils/object";
import { formatISODate } from "~/utils/date";
import { ShipmentModels } from "~/models";
import { duplicateDataByCount } from "~/pages/Tools/models";

export const INITIAL_SEARCH_QUERY = {
  // TODO: remove searchPage and searchPageSize
  searchPage: 1,
  searchPageSize: 10,
  isGroupDispatch: true,
  fetchAll: true,
  searchSort: Fields.SHORT_NAME,
};
export const createLines = (data, formValues) => {
  const result = [];
  const count = getValue(formValues, TOOLS_FIELDS.SHIPMENTS_PER_LINE, "");
  const formInstruction = getValue(
    formValues,
    TOOLS_FIELDS.DELIVERY_INSTRUCTION,
    ""
  );
  const instruction = formInstruction.length
    ? formInstruction
    : getValue(data, AddressBookEntity.DELIVERY_INSTRUCTION, "");

  const line = `"${getValue(
    data,
    AddressBookEntity.ADDRESS.STREET,
    ""
  )}"|"${getValue(data, AddressBookEntity.ADDRESS.TOWN, "")}"|"${getValue(
    data,
    AddressBookEntity.ADDRESS.POSTCODE,
    ""
  )}"|"${getValue(
    data,
    AddressBookEntity.ADDRESS.COUNTRY_CODE,
    ""
  )}"|"${getValue(
    formValues,
    TOOLS_FIELDS.REQUIRED_SERVICE,
    ""
  )}"|"1"|"${getValue(
    formValues,
    TOOLS_FIELDS.WEIGHT_PER_SHIPMENT,
    ""
  )}"|"${getValue(formValues, TOOLS_FIELDS.REF_2, "")}"|"${getValue(
    formValues,
    TOOLS_FIELDS.REF_3,
    ""
  )}"|"${getValue(
    data,
    Fields.ADDRESS_BOOK_ID,
    ""
  )}"|"${instruction}"|"${formatISODate(
    getValue(formValues, TOOLS_FIELDS.SHIPMENT_DATE, "")
  )}"|"${getValue(
    data,
    AddressBookEntity.CONTACT_DETAILS.CONTACT_NAME,
    ""
  )}"|"${getValue(
    data,
    AddressBookEntity.CONTACT_DETAILS.TELEPHONE,
    ""
  )}"|"${getValue(
    data,
    AddressBookEntity.NOTIFICATION_DETAILS.EMAIL,
    ""
  )}"|"${getValue(data, AddressBookEntity.NOTIFICATION_DETAILS.MOBILE, "")}"`;

  result.push(...duplicateDataByCount(line, count));

  return result;
};

export const DEFAULT_GROUP_DISPATCH_COLUMNS = [
  Fields.SHORT_NAME,
  AddressBookEntity.CONTACT_DETAILS.CONTACT_NAME,
  AddressBookEntity.ADDRESS.POSTCODE,
];

export const GROUP_DISPATCH_COLUMNS = [
  {
    Header: S.SHORT_NAME,
    accessor: Fields.SHORT_NAME,
    width: 140,
  },
  {
    Header: S.NAME,
    accessor: AddressBookEntity.CONTACT_DETAILS.CONTACT_NAME,
    width: 140,
  },

  {
    Header: S.POSTCODE,
    accessor: AddressBookEntity.ADDRESS.POSTCODE,
    width: 104,
  },
];

export const getInitialValues = (profile, preferences) => ({
  [TOOLS_FIELDS.PROFILE_CODE]: profile?.profileCode,
  [TOOLS_FIELDS.SHIPMENTS_PER_LINE]: "1",
  [TOOLS_FIELDS.WEIGHT_PER_SHIPMENT]: ShipmentModels.getDefaultTotalWeight(
    null,
    preferences,
    S.GB
  ),
  [TOOLS_FIELDS.SHIPMENT_DATE]: ShipmentModels.getPreferredShipmentDate(
    preferences.shippingDefaults
  ),
});
