import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";
import { manifestApi } from "~/apis";

export const ActionTypes = createActionTypes("MANIFEST", {
  GET_GENERATED_MANIFEST: createAsyncActionTypes("GET_GENERATED_MANIFEST"),
});

export const fetchGeneratedManifest = createAsyncAction(
  manifestApi.getGeneratedManifest,
  ActionTypes.GET_GENERATED_MANIFEST
);
