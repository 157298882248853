import { get, isArray, set } from "lodash";

import { joiMessages } from "~/constants/strings";

import {
  getAllObjectKeysWhereValueIsTrue,
  setUndefinedToEmptyRequiredFields,
} from "./object";

// take a joi schema and create a validator function for redux form
export default function createValidator(
  schema,
  additionalValidationFunctions = []
) {
  return (values, props = {}) => {
    const requiredKeys = getAllObjectKeysWhereValueIsTrue(
      props.requiredFields ?? {}
    );
    const preparedValuesForValidate = setUndefinedToEmptyRequiredFields(
      values,
      requiredKeys
    );
    const validateOptions = {
      context: { ...props, values: preparedValuesForValidate },
      abortEarly: false,
      allowUnknown: true,
      language: joiMessages,
    };
    let result;

    if (requiredKeys.length > 0) {
      result = schema
        .requiredKeys(...requiredKeys)
        .validate(preparedValuesForValidate, validateOptions);
    } else {
      result = schema.validate(preparedValuesForValidate, validateOptions);
    }
    const errors = result.error
      ? result.error.details.reduce((all, cur) => {
          const allErrors = Object.assign({}, all);
          set(allErrors, cur.path.join("."), cur.message);

          return allErrors;
        }, {})
      : {};

    // additional validation
    for (const func of additionalValidationFunctions) {
      let validationErrors = [];
      const error = func(errors);

      if (error) {
        if (isArray(error)) {
          validationErrors = error;
        } else {
          validationErrors.push(error);
        }
      }

      validationErrors.forEach(err => {
        if (err && !get(errors, err.path)) {
          set(errors, err.path, err.message);
        }
      });
    }

    return errors;
  };
}
