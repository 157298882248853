import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import omit from "lodash/omit";
import {
  arrayRemove,
  autofill,
  change,
  destroy,
  resetSection,
  SubmissionError,
  touch,
} from "redux-form";

import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";
import { createPayloadAction } from "@dpdgroupuk/redux-action-creator/lib/actionTypes";

import { addressBookApi, labelsApi, shipmentApi } from "~/apis";
import {
  ADDRESS,
  CONTACT_DETAILS,
  DELIVERY_CONTACT,
  DELIVERY_DETAILS,
  DELIVERY_DETAILS_SEARCH_FORM,
  EXPORTER_DETAILS,
  Fields,
  FilterFields,
  IMPORTER_DETAILS,
  INBOUND_CONSIGNMENT,
  INVOICE,
  OUTBOUND_CONSIGNMENT,
  RETURN_DETAILS_SEARCH_FORM,
  ShipmentEntity,
} from "~/constants/forms";
import * as S from "~/constants/strings";
import {
  CustomsModels,
  LiabilityModels,
  ServiceModels,
  ShipmentModels,
} from "~/models";
import {
  AddressBookActions,
  ProfilesSelectors,
  ReferenceActions,
  ReferenceSelectors,
  UmsSelectors,
} from "~/redux";
import { getDeepKeys, getValue } from "~/utils/object";
import { initializeForm } from "~/utils/reduxForm";

import { ShipmentActions, ShipmentSelectors } from ".";

export const ActionTypes = createActionTypes("SHIPMENT", {
  CREATE_SHIPMENT: createAsyncActionTypes("CREATE_SHIPMENT"),
  UPDATE_SHIPMENT: createAsyncActionTypes("UPDATE_SHIPMENT"),
  FETCH_BY_ID: createAsyncActionTypes("FETCH_BY_ID"),
  GET_LABEL: createAsyncActionTypes("GET_LABEL"),
  GET_LABELS_BY_SHIPMENT_IDS: createAsyncActionTypes(
    "GET_LABELS_BY_SHIPMENT_IDS"
  ),
  GET_INVOICE_LABEL: createAsyncActionTypes("GET_INVOICE_LABEL"),
  GET_UNPRINTED_LABELS: createAsyncActionTypes("GET_UNPRINTED_LABELS"),
  GENERATE_UNIQUE_REFERENCE_1: createAsyncActionTypes(
    "GENERATE_UNIQUE_REFERENCE_1"
  ),
  VALIDATE_UNIQUE_REFERENCE_1: createAsyncActionTypes(
    "VALIDATE_UNIQUE_REFERENCE_1"
  ),
  SET_SELECTED_ADDRESS_BOOK: "SET_SELECTED_ADDRESS_BOOK",
  SET_SELECTED_RETURN_ADDRESS_BOOK: "SET_SELECTED_RETURN_ADDRESS_BOOK",
  CREATE_UPDATE: createAsyncActionTypes("CREATE_UPDATE"),
  CLEAR_SELECTED_RETURN_ADDRESSBOOK: "CLEAR_SELECTED_RETURN_ADDRESSBOOK",
  CLEAR_SELECTED_ADDRESSBOOK: "CLEAR_SELECTED_ADDRESSBOOK",
  CLEAR_PAGE: "CLEAR_PAGE",
});

export const clearContactDetails = formName => dispatch =>
  dispatch(resetSection(formName, DELIVERY_CONTACT));

export const resetInboundConsignment = formName => dispatch =>
  dispatch(resetSection(formName, INBOUND_CONSIGNMENT));

export const clearReturnItemDescription = formName => dispatch =>
  dispatch(
    autofill(formName, ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DESCRIPTION)
  );

export const updateReturnShortName = shortName => dispatch =>
  dispatch(
    autofill(
      RETURN_DETAILS_SEARCH_FORM,
      FilterFields.SEARCH_CRITERIA_VALUE,
      shortName
    )
  );

export const touchShipmentFormFields =
  (formName, fields, formValues) => dispatch => {
    fields.forEach(
      field =>
        isEmpty(get(formValues, field)) && dispatch(touch(formName, field))
    );
  };

export const initializeShippingInformation =
  pageConfig => (dispatch, getState) => {
    const data = ShipmentSelectors.getInitialInvoiceData(
      getState(),
      pageConfig
    );

    return dispatch(initializeForm(pageConfig.formName, data));
  };

export const updateImporterDetails = pageConfig => (dispatch, getState) => {
  const createShipmentValues = ShipmentSelectors.getShipmentFormValues(
    getState(),
    pageConfig
  );

  return dispatch(
    initializeForm(pageConfig.formName, {
      [INVOICE]: {
        [IMPORTER_DETAILS]: {
          [ADDRESS]: {
            ...get(
              createShipmentValues,
              `${OUTBOUND_CONSIGNMENT}.${DELIVERY_DETAILS}.${ADDRESS}`
            ),
          },
          [CONTACT_DETAILS]: {
            ...get(
              createShipmentValues,
              `${OUTBOUND_CONSIGNMENT}.${DELIVERY_DETAILS}.${CONTACT_DETAILS}`
            ),
            [Fields.EMAIL]: get(
              createShipmentValues,
              ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS
                .NOTIFICATION_DETAILS.EMAIL
            ),
          },
        },
      },
    })
  );
};

export const updateImporterVatEoriPid = pageConfig => (dispatch, getState) => {
  const selectedService =
    ReferenceSelectors.getActiveOutboundService(getState());
  const selectedAddressBook =
    ShipmentSelectors.getSelectedAddressBook(getState());
  const hideOptions =
    ServiceModels.shouldHideBusinessConsumerOptions(selectedService);
  const isBusinessValue =
    selectedAddressBook &&
    (selectedAddressBook.vatNumber || selectedAddressBook.eoriNumber)
      ? S.IMPORTER_BUSINESS
      : S.IMPORTER_CONSUMER;
  const isBusinessRelatedFields =
    isBusinessValue === S.IMPORTER_BUSINESS
      ? {
          [Fields.VAT_NUMBER]: selectedAddressBook.vatNumber,
          [Fields.EORI_NUMBER]: selectedAddressBook.eoriNumber,
          [Fields.PID_NUMBER]: null,
        }
      : {
          [Fields.VAT_NUMBER]: null,
          [Fields.EORI_NUMBER]: null,
          [Fields.PID_NUMBER]: selectedAddressBook?.pid,
        };

  return dispatch(
    initializeForm(pageConfig.formName, {
      [INVOICE]: {
        [IMPORTER_DETAILS]: {
          [Fields.IS_BUSINESS]: isBusinessValue,
          ...(!hideOptions && isBusinessRelatedFields),
        },
      },
    })
  );
};

export const createShipment = createAsyncAction(
  pageConfig => (dispatch, getState) => {
    const state = getState();
    const query = ShipmentSelectors.getShipmentQuery(state, pageConfig);
    const selectedCountry = ShipmentSelectors.getSelectedCountry(
      state,
      pageConfig
    );
    const isSwapItOrReverseIt =
      ShipmentSelectors.isSwapItOrReverseItShipmentType(state, pageConfig);
    const isDomestic = ShipmentModels.isCountryDomestic(
      selectedCountry.countryKey
    );

    if (isSwapItOrReverseIt) {
      return shipmentApi
        .createShipmentCollection(query)
        .then(({ data }) => data);
    }

    if (isDomestic) {
      return shipmentApi.createShipmentDomestic(query).then(({ data }) => data);
    }

    return shipmentApi
      .createShipmentInternational(query)
      .then(({ data }) => data);
  },
  ActionTypes.CREATE_SHIPMENT
);

// @see  https://it.dpduk.live/version/customer-shipping/sprint-1.18/diag_fGPa5D6FYFxijSYL.html
export const updateShipment = createAsyncAction(
  (pageConfig, shipmentId, isScanned = false) =>
    (dispatch, getState) => {
      const state = getState();
      const query = omit(
        ShipmentSelectors.getShipmentQuery(state, pageConfig),
        ["outboundConsignment.collectionDetails"]
      );

      // NOTE: passed isScanned it only from Scan page (allow edit ref2/ref3 fields)
      if (isScanned) {
        query.isScanned = isScanned;
      }

      const selectedCountry = ShipmentSelectors.getSelectedCountry(
        state,
        pageConfig
      );
      const isSwapItOrReverseIt =
        ShipmentSelectors.isSwapItOrReverseItShipmentType(state, pageConfig);
      const isDomestic = ShipmentModels.isCountryDomestic(
        selectedCountry.countryKey
      );

      if (isSwapItOrReverseIt) {
        return shipmentApi
          .updateShipmentCollection(shipmentId, query)
          .then(({ data }) => data);
      }

      if (isDomestic) {
        return shipmentApi
          .updateShipmentDomestic(shipmentId, query)
          .then(({ data }) => data);
      }

      return shipmentApi
        .updateShipmentInternational(shipmentId, query)
        .then(({ data }) => data);
    },
  ActionTypes.UPDATE_SHIPMENT
);

export const fetchLabel = createAsyncAction(
  (shipmentId, query) =>
    shipmentApi
      .getLabel(shipmentId, query)
      .then(({ data: { printString } }) => printString),
  ActionTypes.GET_LABEL
);

export const fetchLabels = createAsyncAction(
  query =>
    labelsApi
      .getLabelsByShipmentIds(query)
      .then(({ data: { printString } }) => printString),
  ActionTypes.GET_LABELS_BY_SHIPMENT_IDS
);

export const fetchInvoiceLabel = createAsyncAction(
  (shipmentId, query) =>
    shipmentApi
      .getInvoiceLabel(shipmentId, query)
      .then(({ data: { printString } }) => printString),
  ActionTypes.GET_INVOICE_LABEL
);

export const fetchUnprintedLabels = createAsyncAction(
  query =>
    labelsApi
      .getUnprintedLabels(query)
      .then(({ data: { printString } }) => printString),
  ActionTypes.GET_UNPRINTED_LABELS
);

export const generateUniqueReference1 = createAsyncAction(
  () =>
    shipmentApi
      .generateUniqueReference1()
      .then(({ data: { reference } }) => reference),
  ActionTypes.GENERATE_UNIQUE_REFERENCE_1
);

export const validateUniqueReference1 = createAsyncAction(
  (customerRef, options) =>
    shipmentApi
      .validateUniqueReference1(customerRef, options)
      .then(({ data }) => data),
  ActionTypes.VALIDATE_UNIQUE_REFERENCE_1
);

export const setSelectedAddressBook = addressBook =>
  createPayloadAction(ActionTypes.SET_SELECTED_ADDRESS_BOOK, addressBook);

export const setSelectedReturnAddressBook = addressBook =>
  createPayloadAction(
    ActionTypes.SET_SELECTED_RETURN_ADDRESS_BOOK,
    addressBook
  );

export const handleSubmitShipment =
  ({ pageConfig, notifier }) =>
  (dispatch, getState) =>
  onSuccess => {
    const state = getState();

    if (ShipmentSelectors.isEditProductFormActive(state)) {
      throw new SubmissionError({
        [Fields.PACKAGE_NUMBER]: Fields.PACKAGE_NUMBER,
      });
    }

    const isLoadingOutboundServices =
      ShipmentSelectors.isLoadingOutboundServices(state, pageConfig);
    const isLoadingInboundServices = ShipmentSelectors.isLoadingInboundServices(
      state,
      pageConfig
    );
    const disableButton = ShipmentSelectors.shouldDisablePrintButtons(
      state,
      pageConfig
    );

    if (isLoadingOutboundServices || isLoadingInboundServices) return;

    if (!disableButton) {
      return onSuccess();
    }

    // Test for form errors at the page, display and scroll if any
    const errors = ShipmentSelectors.getShipmentFormSyncErrors(
      state,
      pageConfig
    );

    const flat = getDeepKeys(errors);
    dispatch(touch(pageConfig.formName, ...flat));
    const isErrorVisible = notifier.scrollToError(flat);

    if (isErrorVisible) return;

    // Otherwise, look for hidden errors (Parcel, Invoice) at the page
    const isValidInvoiceSection = ShipmentModels.isValidInvoiceSection(errors);
    const isOnlyInvoiceImporterSectionInvalid =
      ShipmentModels.isOnlyInvoiceImporterSectionInvalid(errors);
    const isParcelsDataValid = ShipmentSelectors.isParcelsDataValid(
      state,
      pageConfig
    );

    if (!isValidInvoiceSection || !isParcelsDataValid) {
      const invalidSection = isOnlyInvoiceImporterSectionInvalid
        ? IMPORTER_DETAILS
        : EXPORTER_DETAILS;

      const errorField = isParcelsDataValid
        ? `${INVOICE}.${invalidSection}`
        : Fields.PACKAGE_NUMBER;

      throw new SubmissionError({ [errorField]: errorField });
    } else {
      return onSuccess();
    }
  };

export const handleSubmitAddressbook =
  ({ pageConfig, notifier }) =>
  (dispatch, getState) =>
  onSuccess => {
    const errors = ShipmentSelectors.getAddressBookErrors(
      getState(),
      pageConfig
    );

    if (!isEmpty(errors)) {
      const flat = getDeepKeys(errors);
      dispatch(touch(pageConfig.formName, ...flat));
      notifier.scrollToError(flat);
    } else {
      onSuccess();
    }
  };

export const createOrUpdateAddressBook = createAsyncAction(
  (addressbookId, body, abortController) => async () => {
    const updateObject = {
      addressBookType: body.addressBookType,
    };

    let result;

    if (addressbookId) {
      result = await addressBookApi.updateAddressBook(
        addressbookId,
        updateObject,
        body,
        {
          signal: abortController.signal,
        }
      );
    } else {
      result = await addressBookApi.insertAddressBook(body, {
        signal: abortController.signal,
      });
    }

    const { data } = await addressBookApi.fetchAddressBookById(
      result.data.addressbookId,
      updateObject,
      {
        signal: abortController.signal,
      }
    );

    return data;
  },
  ActionTypes.CREATE_UPDATE
);

export const clearAddressBook = form => ({
  type:
    form === DELIVERY_DETAILS_SEARCH_FORM
      ? ActionTypes.CLEAR_SELECTED_ADDRESSBOOK
      : ActionTypes.CLEAR_SELECTED_RETURN_ADDRESSBOOK,
});

export const clearCustoms = formName => dispatch =>
  dispatch(
    resetSection(
      formName,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.CUSTOMS_VALUE,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DESCRIPTION,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.CURRENCY
    )
  );

export const setDefaultCurrency = pageConfig => (dispatch, getState) => {
  const userCurrency = CustomsModels.getDefaultCurrency(
    UmsSelectors.getPreferences(getState()),
    ProfilesSelectors.getActiveProfile(getState(), pageConfig)
  );
  dispatch(
    autofill(
      pageConfig.formName,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.CURRENCY,
      userCurrency
    )
  );
};

// @see: comments from https://geopost.jira.com/browse/CSHIP-6395
export const setDefaultDeliveryDescription =
  (pageConfig, allowedFields) => (dispatch, getState) => {
    const state = getState();
    const isDeliveryDescriptionVisible =
      allowedFields[ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DESCRIPTION];
    const userDescription = getValue(
      UmsSelectors.getPreferences(getState()),
      "shippingDefaults.international.intContentDescription",
      ""
    ).toUpperCase();
    const createShipmentValues = ShipmentSelectors.getShipmentFormValues(
      state,
      pageConfig
    );

    if (
      isDeliveryDescriptionVisible &&
      userDescription &&
      !getValue(
        createShipmentValues,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DESCRIPTION,
        ""
      )
    ) {
      dispatch(
        change(
          pageConfig.formName,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DESCRIPTION,
          userDescription
        )
      );
    }
  };

export const resetShippingInformation = pageConfig => dispatch => {
  dispatch(resetSection(pageConfig.formName, INVOICE));
  dispatch(ShipmentActions.initializeShippingInformation(pageConfig));
  dispatch(ShipmentActions.updateImporterVatEoriPid(pageConfig));
};

export const resetCustoms = pageConfig => (dispatch, getState) => {
  const isCustomsFieldsVisible = ShipmentSelectors.isCustomsFieldsVisible(
    getState(),
    pageConfig
  );

  if (!isCustomsFieldsVisible) {
    dispatch(clearCustoms(pageConfig.formName));
  } else {
    dispatch(setDefaultCurrency(pageConfig));
    dispatch(
      resetSection(
        pageConfig.formName,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.CUSTOMS_VALUE
      )
    );
  }
};

export const deleteParcelProduct = (formName, selectedProduct) => dispatch => {
  if (selectedProduct.productIndex !== undefined) {
    dispatch(
      arrayRemove(
        formName,
        `outboundConsignment.parcels[${selectedProduct.packageNumber}].products`,
        selectedProduct.productIndex
      )
    );
  }
};

export const clearShipment = () => ({
  type: ActionTypes.CLEAR_PAGE,
});

export const changeCountry = (pageConfig, path, country) => dispatch => {
  dispatch(autofill(pageConfig.formName, path, country));
};

export const clearServices = pageConfig => dispatch => {
  dispatch(
    resetSection(
      pageConfig.formName,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.NETWORK_CODE
    )
  );
  dispatch(ReferenceActions.clearServices());
};

export const setupDefaultExtendedLiability =
  pageConfig => (dispatch, getState) => {
    const preferences = UmsSelectors.getPreferences(getState());
    const formValues = ShipmentSelectors.getShipmentFormValues(
      getState(),
      pageConfig
    );
    const countryCode = getValue(
      formValues,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTRY_CODE,
      ""
    );
    const liability = LiabilityModels.getDefaultLiability(
      preferences,
      countryCode
    );
    dispatch(
      change(
        pageConfig.formName,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.LIABILITY,
        liability
      )
    );
    liability &&
      dispatch(
        change(
          pageConfig.formName,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.LIABILITY_VALUE,
          LiabilityModels.getDefaultLiabilityValue(preferences, countryCode)
        )
      );
  };

export const setupDefaultAdditionalDataRequirements =
  pageConfig => (dispatch, getState) => {
    const { destinationTaxId, gstVatPaid } =
      ShipmentModels.getInitialDestinationGstValues(
        ProfilesSelectors.getActiveProfile(getState(), pageConfig),
        UmsSelectors.getPreferences(getState())
      );

    dispatch(
      change(
        pageConfig.formName,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.DESTINATION_TAX_ID_REG_NO,
        destinationTaxId
      )
    );
    destinationTaxId &&
      dispatch(
        change(
          pageConfig.formName,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.GST_VAT_PAID,
          gstVatPaid
        )
      );
  };

export const resetExtendedLiability = pageConfig => dispatch => {
  dispatch(
    change(
      pageConfig.formName,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.LIABILITY,
      false
    )
  );
  dispatch(
    change(
      pageConfig.formName,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.LIABILITY_VALUE,
      undefined
    )
  );
};

export const resetAdditionalDataRequirements = pageConfig => dispatch => {
  dispatch(
    change(
      pageConfig.formName,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DESTINATION_TAX_ID_REG_NO,
      undefined
    )
  );
  dispatch(
    change(
      pageConfig.formName,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.GST_VAT_PAID,
      undefined
    )
  );
};

export const resetInvoiceAdditionalDataRequirements =
  pageConfig => dispatch => {
    dispatch(
      change(
        pageConfig.formName,
        ShipmentEntity.INVOICE.EXPORTER_DETAILS.DESTINATION_TAX_ID_REG_NO,
        undefined
      )
    );
    dispatch(
      change(
        pageConfig.formName,
        ShipmentEntity.INVOICE.EXPORTER_DETAILS.GST_VAT_PAID,
        undefined
      )
    );
  };

export const setupExtendedLiability =
  (allowedLiability, pageConfig) => dispatch =>
    allowedLiability
      ? dispatch(setupDefaultExtendedLiability(pageConfig))
      : dispatch(resetExtendedLiability(pageConfig));

// @see:https://it.dpduk.live/it/diagram/diag_GCJhVN6GAqAAhVnC.html?id=1633609607280
export const setupAdditionalDataRequirements =
  (isVisibleTaxId, pageConfig) => dispatch =>
    isVisibleTaxId
      ? dispatch(setupDefaultAdditionalDataRequirements(pageConfig))
      : dispatch(resetAdditionalDataRequirements(pageConfig));

export const unmountShipmentPage = () => dispatch => {
  dispatch(ShipmentActions.clearShipment());
  dispatch(AddressBookActions.clearAddressBook());
  dispatch(ReferenceActions.clearReference());
  dispatch(destroy(DELIVERY_DETAILS_SEARCH_FORM));
  dispatch(destroy(RETURN_DETAILS_SEARCH_FORM));
};

export const getShipmentById = createAsyncAction(
  shipmentId =>
    shipmentApi.getShipmentById(shipmentId).then(({ data }) => data),
  ActionTypes.FETCH_BY_ID
);

export const resetReference1 = pageConfig => (dispatch, getState) => {
  const state = getState();
  dispatch(
    initializeForm(pageConfig.formName, {
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_1]:
        ShipmentModels.getDefaultUniqueShippingRef1(
          get(
            ShipmentSelectors.getShipmentFormValues(state, pageConfig),
            ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
              .COUNTRY_CODE
          ),
          ShipmentSelectors.getUniqueSenderRef1(state),
          {
            preferences: UmsSelectors.getPreferences(state),
            shippingSettings: UmsSelectors.getShippingSettings(state),
          }
        ),
    })
  );
};

// manage only manual changing liability, dynamic changing is managed in the handleLiability
// @see https://it.dpduk.live/it/diagram/diag_VLeqsR6C48kIGApK.html?id=1639737191640
export const onLiabilityChange =
  (liability, pageConfig) => (dispatch, getState) => {
    const preferences = UmsSelectors.getPreferences(getState());
    const formValues = ShipmentSelectors.getShipmentFormValues(
      getState(),
      pageConfig
    );
    const liabilityValue = liability
      ? LiabilityModels.getDefaultLiabilityValue(
          preferences,
          getValue(
            formValues,
            ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
              .COUNTRY_CODE,
            ""
          )
        )
      : undefined;
    dispatch(
      change(
        pageConfig.formName,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.LIABILITY_VALUE,
        liabilityValue
      )
    );
  };
