import { withProps } from "recompose";

import { ShipmentEntity } from "~/constants/forms";
import { getValue } from "~/utils/object";

// extends redux form validation by passing new props
export default withProps(() => ({
  asyncValidate: async (values, dispatch, { validateOnUniqueShipmentRef1 }) => {
    const senderRef1Value = getValue(
      values,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_1
    );
    // @see: https://it.dpduk.live/version/customer-shipping/sprint-2.9/diag_qKAP2EGGAqCIfLZH.html
    await validateOnUniqueShipmentRef1(senderRef1Value);
  },
  asyncBlurFields: [ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_1],
}));
