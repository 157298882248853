import { get } from "lodash";
import { getFormSyncErrors, getFormValues } from "redux-form";
import { createSelector } from "reselect";

import { REQUIRED_TYPE } from "@dpdgroupuk/mydpd-enums";

import {
  isImporterBusiness,
  isImporterConsumer,
} from "~/components/EditImporterModal/models";
import { EDIT_IMPORTER_FORM, ImporterDetailsEntity } from "~/constants/forms";
import { ServiceModels } from "~/models";
import { ReferenceSelectors } from "~/redux";

export const getEditImporterFormState = state =>
  get(state, `form.${EDIT_IMPORTER_FORM}`, {});

export const getEditImporterFormErrors = state =>
  getFormSyncErrors(EDIT_IMPORTER_FORM)(state) || {};

export const getEditImporterFormValues = state =>
  getFormValues(EDIT_IMPORTER_FORM)(state);

export const getImporterSelectedCountry = createSelector(
  ReferenceSelectors.getCountries,
  getEditImporterFormValues,
  (countries, editImporterFormValues) =>
    countries.find(
      ({ countryKey }) =>
        countryKey ===
        get(editImporterFormValues, ImporterDetailsEntity.ADDRESS.COUNTRY_CODE)
    )
);

export const getImporterModalRequiredFields = selectedService =>
  createSelector(
    getEditImporterFormValues,
    getImporterSelectedCountry,
    (editImporterFormValues, selectedCountry) => {
      const countryCode = get(
        editImporterFormValues,
        ImporterDetailsEntity.ADDRESS.COUNTRY_CODE,
        ""
      );
      const notifyRequired = ServiceModels.isNotifyRequired(selectedService);
      const emailValue = get(
        editImporterFormValues,
        ImporterDetailsEntity.CONTACT_DETAILS.EMAIL,
        ""
      );
      const telephoneValue = get(
        editImporterFormValues,
        ImporterDetailsEntity.CONTACT_DETAILS.TELEPHONE,
        ""
      );
      const isPostcodeRequired = get(
        selectedCountry,
        "isPostcodeRequired",
        false
      );
      const isBusiness = isImporterBusiness(
        get(editImporterFormValues, ImporterDetailsEntity.IS_BUSINESS)
      );
      const isConsumer = isImporterConsumer(
        get(editImporterFormValues, ImporterDetailsEntity.IS_BUSINESS)
      );
      const eoriRequired = get(selectedService, "eoriRequired");
      const vatRequired = get(selectedService, "vatRequired");
      const pidRequired = get(selectedService, "pidRequired");
      const hideOptions =
        ServiceModels.shouldHideBusinessConsumerOptions(selectedService);

      return {
        [ImporterDetailsEntity.ADDRESS.STREET]: true,
        [ImporterDetailsEntity.ADDRESS.COUNTRY_CODE]: true,
        // @see https://it.dpduk.live/it/diagram/diag_z4h9WV6GAqAADGAL.html?id=1627037965015
        [ImporterDetailsEntity.IS_BUSINESS]:
          eoriRequired !== REQUIRED_TYPE.NEEDLESS &&
          pidRequired !== REQUIRED_TYPE.NEEDLESS &&
          vatRequired !== REQUIRED_TYPE.NEEDLESS,
        [ImporterDetailsEntity.ADDRESS.TOWN]: true,
        [ImporterDetailsEntity.CONTACT_DETAILS.TELEPHONE]:
          selectedService && notifyRequired && !(!telephoneValue && emailValue),
        [ImporterDetailsEntity.CONTACT_DETAILS.CONTACT_NAME]:
          selectedService && notifyRequired,
        [ImporterDetailsEntity.CONTACT_DETAILS.EMAIL]:
          selectedService && notifyRequired && !(!emailValue && telephoneValue),
        [ImporterDetailsEntity.ADDRESS.POSTCODE]:
          countryCode && isPostcodeRequired,
        [ImporterDetailsEntity.PID_NUMBER]:
          !hideOptions && isConsumer && pidRequired === REQUIRED_TYPE.MANDATORY,
        [ImporterDetailsEntity.VAT_NUMBER]:
          !hideOptions && isBusiness && vatRequired === REQUIRED_TYPE.MANDATORY,
        [ImporterDetailsEntity.EORI_NUMBER]:
          !hideOptions &&
          isBusiness &&
          eoriRequired === REQUIRED_TYPE.MANDATORY,
      };
    }
  );
