import { useCallback, useMemo, useState } from "react";

import Joi from "joi";
import { get, isEmpty } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import queryString from "query-string";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { useColumnOrder } from "react-table/src/plugin-hooks/useColumnOrder";
import { useFlexLayout } from "react-table/src/plugin-hooks/useFlexLayout";
import { useResizeColumns } from "react-table/src/plugin-hooks/useResizeColumns";
import { useRowSelect } from "react-table/src/plugin-hooks/useRowSelect";
import { useSortBy } from "react-table/src/plugin-hooks/useSortBy";
import { lifecycle, withHandlers, withState } from "recompose";
import compose from "recompose/compose";
import { change, touch } from "redux-form";

import {
  UserSessionDataActions,
  UserSessionDataSelectors,
  useUserHeaderStatePersist,
  withAppState,
  withAppUserPreferences,
  withNotifier,
} from "@dpdgroupuk/mydpd-app";
import { BANNERS_TYPES } from "@dpdgroupuk/mydpd-enums";
import {
  Banner,
  Button,
  Card,
  DndTable,
  Legend,
  Main,
  withOverlay,
  withPrompt,
  withSnackbar,
} from "@dpdgroupuk/mydpd-ui";

import { DEFAULT_DATE_FORMAT, ISO_DATE_FORMAT } from "~/constants/dateFormats";
import {
  FAILED_IMPORTS_SEARCH_FORM,
  FAILURE_DETAILS,
  REIMPORT_FORM,
  SHIPMENT_REVIEW_CHANGE_DATE_FORM,
  ShipmentEntity,
  ShipmentReviewFilterFields,
  STAGING_SHIPMENT_ID,
} from "~/constants/forms";
import { SHOW_ALERT_DISPLAY_TIME } from "~/constants/snackbar";
import * as S from "~/constants/strings";
import shipmentReview from "~/models/validators/shipmentReview";
import {
  FailedImportsActions,
  FailedImportsSelectors,
} from "~/pages/FailedImports/redux";
import Filters from "~/pages/ShipmentReview/components/Filters";
import { ShipmentReviewSelectors } from "~/pages/ShipmentReview/redux";
import { UmsSelectors } from "~/redux";
import * as routes from "~/router/constants";
import { getErrorMessage } from "~/utils/error";
import { getValue, toUppercaseValues } from "~/utils/object";
import {
  getInitialPageQuery,
  getQueryFilters,
  getQueryPagination,
  getQueryPaginationByTotal,
  isPristineForm,
  parseQuery,
  stringifyQuery,
} from "~/utils/query";

import {
  COLUMN_ID_TO_SORT_CRITERIA,
  DEFAULT_FAILED_IMPORTS_COLUMNS,
  DEFAULT_SORT_BY,
  SORTING_FIELDS,
  SORTING_ORDER,
} from "./constants";
import styles from "./FailedImportsList.module.scss";
import {
  ALWAYS_VISIBLE_FAILED_IMPORTS_COLUMNS,
  getFailedImportsColumns,
} from "./models";
import { SHIP_TO_SHOP } from "~/constants/services";
import useToggle from "~/hooks/useToggle";
import ReimportModal from "~/pages/FailedImports/components/reimportModal";

const FailedImportsList = props => {
  const [selectedFailedImports, setSelectedFailedImports] = useState([]);

  const { page, pageSize, filters, sorting, isPristine } = useMemo(
    () => ({
      ...getQueryPagination(props.location.search),
      filters: {
        [ShipmentReviewFilterFields.SEARCH_DATE]:
          getInitialPageQuery().searchDate,
        ...toUppercaseValues(
          getQueryFilters(
            props.location.search,
            ShipmentReviewFilterFields,
            false
          )
        ),
      },
      sorting: {
        ...getQueryFilters(props.location.search, SORTING_FIELDS),
      },
      isPristine: isPristineForm(
        props.location.search,
        ShipmentReviewFilterFields,
        false
      ),
    }),
    [props.location]
  );

  const [data, totalCount] = useMemo(
    () => [
      get(props, "failedImports.results", []),
      get(props, "failedImports.totalResults", 0),
    ],
    [props.failedImports]
  );

  const reimportToggle = useToggle();

  const onEditShipmentClick = useCallback(
    stagingShipmentId => () => {
      const selectedShipment = data.find(
        shipment => shipment.stagingShipmentId === stagingShipmentId
      );

      if (
        !SHIP_TO_SHOP.includes(
          getValue(
            selectedShipment.shipmentDetails,
            ShipmentEntity.OUTBOUND_CONSIGNMENT.NETWORK_CODE,
            ""
          )
        )
      ) {
        return props.history.push(
          `${routes.FAILED_IMPORTS}/${stagingShipmentId}/edit`
        );
      }

      reimportToggle.switchOn();
      props.dispatch(
        change(
          REIMPORT_FORM,
          FAILURE_DETAILS,
          selectedShipment.failureDetails.lineData
        )
      );
      props.dispatch(
        change(REIMPORT_FORM, STAGING_SHIPMENT_ID, stagingShipmentId)
      );
    },
    [props.history, data]
  );
  const onChangeColumnsSelection = useCallback(
    value => {
      if (value.length) {
        props.setSelectedColumnNames(value);
      }
    },
    [props.setSelectedColumnNames]
  );

  const columns = useMemo(
    () => getFailedImportsColumns(onEditShipmentClick),
    [onEditShipmentClick]
  );
  const hiddenColumns = useMemo(() =>
    columns.reduce((acc, column) => {
      if (
        !props.selectedColumnNames.includes(column.accessor) &&
        !ALWAYS_VISIBLE_FAILED_IMPORTS_COLUMNS.includes(column.accessor)
      ) {
        acc.push(column.accessor);
      }
      return acc;
    }, [])
  );

  const handleClickDelete = useCallback(() => {
    props.onClickDelete(selectedFailedImports);
  }, [selectedFailedImports]);

  const getSortBy = useCallback(
    sortBy => {
      let sortOrder = sortBy[0]?.desc ? SORTING_ORDER.DESC : SORTING_ORDER.ASC;

      if (!sortBy[0]?.id) {
        sortOrder = undefined;
      }

      if (sorting.searchSortBy || sortBy[0]?.id) {
        props.onSort(sortBy[0]?.id, sortOrder);
      }
    },
    [props.onSort, sorting]
  );

  return (
    <>
      <Main.Body>
        <Legend
          rightMessage={get(props.authUser, "user.username")}
          classes={{ container: "p-0" }}
        />
        <Card.Stack>
          <Col className="p-0">
            <Card>
              <Card.Title className="mb-4">{S.FAILED_IMPORTS}</Card.Title>
              <Row>
                <Col sm={12}>
                  <Filters
                    form={FAILED_IMPORTS_SEARCH_FORM}
                    initialValues={filters}
                    isPristine={isPristine}
                    onClear={props.onClear}
                    onSubmit={props.onSearch}
                    onClearShipmentDate={props.onClearShipmentDate}
                  />
                </Col>
                <Col sm={12}>
                  <DndTable
                    data={data}
                    columns={columns}
                    classes={{
                      container: styles.tableContainer,
                      table: styles.table,
                      popover: styles.popover,
                    }}
                    tableHooks={[
                      useFlexLayout,
                      useSortBy,
                      useRowSelect,
                      useResizeColumns,
                      useColumnOrder,
                      useUserHeaderStatePersist,
                    ]}
                    unhiddenColumns={ALWAYS_VISIBLE_FAILED_IMPORTS_COLUMNS}
                    initialState={{
                      hiddenColumns,
                      columnOrder: props.selectedColumnNames,
                      storageKey: "failedImportsColumns",
                    }}
                    onChangeColumnsSelection={onChangeColumnsSelection}
                    onColumnOrderChanged={onChangeColumnsSelection}
                    isVisiblePopover
                    stretchColumnWidth={false}
                    onDoubleClickRow={(e, row) =>
                      onEditShipmentClick(
                        row.original[S.ROW_ID_STAGING_FIELD_NAME]
                      )()
                    }
                    getSelectedRows={setSelectedFailedImports}
                    selectable
                    manualSortBy
                    getSortBy={getSortBy}
                  >
                    <DndTable.DndPaginator
                      page={page}
                      totalCount={totalCount}
                      pageSize={pageSize}
                      statusText={S.SHOWING}
                      onPrevious={props.onPrevious}
                      onNext={props.onNext}
                      onFirst={props.onFirst}
                      onLast={props.onLast}
                    />
                  </DndTable>
                </Col>
              </Row>
            </Card>
          </Col>
        </Card.Stack>
      </Main.Body>
      <Main.Footer className="dark">
        <Button.Toolbar>
          <Button
            disabled={isEmpty(selectedFailedImports)}
            variant="danger"
            onClick={handleClickDelete}
          >
            {S.DELETE}
          </Button>
        </Button.Toolbar>
      </Main.Footer>
      <ReimportModal
        open={reimportToggle.value}
        onCancel={reimportToggle.switchOff}
        history={props.history}
      />
    </>
  );
};

FailedImportsList.propTypes = {
  location: PropTypes.object,
  onClear: PropTypes.func,
  onSearch: PropTypes.func,
  failedImports: PropTypes.object,
  onPrevious: PropTypes.func,
  onNext: PropTypes.func,
  onFirst: PropTypes.func,
  onLast: PropTypes.func,
  setSelectedImports: PropTypes.func,
  history: PropTypes.object,
  onClickDelete: PropTypes.func,
  onClearShipmentDate: PropTypes.func,
  selectedColumnNames: PropTypes.array,
  setSelectedColumnNames: PropTypes.func,
  authUser: PropTypes.object,
  onSort: PropTypes.func,
};

export default compose(
  withState("selectedImports", "setSelectedImports", []),
  withState(
    "selectedColumnNames",
    "setSelectedColumnNames",
    () => DEFAULT_FAILED_IMPORTS_COLUMNS
  ),
  withAppState,
  withAppUserPreferences,
  withSnackbar,
  Banner.withBanner,
  withPrompt,
  withOverlay,
  withNotifier,
  connect(
    state => ({
      uiFields: [
        ...ShipmentReviewSelectors.getChangeDateField(state, {
          formName: SHIPMENT_REVIEW_CHANGE_DATE_FORM,
        }),
      ],
      failedImports: FailedImportsSelectors.getFailedImportsList(state),
      customer: UmsSelectors.getCustomer(state),
      securitySettings: UmsSelectors.getSecuritySettings(state),
      queryFromStorage: UserSessionDataSelectors.getDataItem(
        "failedShipmentListSearchQuery"
      )(state),
    }),
    (dispatch, { history, location }) => {
      const getModifiedQueryParams = newQuery => {
        const page = getQueryPagination(location.search);
        const query = getQueryFilters(
          location.search,
          ShipmentReviewFilterFields,
          false
        );
        const sorting = getQueryFilters(location.search, SORTING_FIELDS);

        return stringifyQuery(
          {
            ...page,
            ...query,
            ...sorting,
            ...newQuery,
          },
          true,
          false
        );
      };

      const changeFilter = (newQuery, reload = true) => {
        history.push({
          pathname: routes.FAILED_IMPORTS,
          search: getModifiedQueryParams(newQuery),
          state: {
            reload,
          },
        });
      };

      const replaceFilter = (newQuery, reload = true) => {
        history.replace({
          pathname: routes.FAILED_IMPORTS,
          search: getModifiedQueryParams(newQuery),
          state: {
            reload,
          },
        });
      };

      const onNextOrPrev = page => {
        changeFilter({ page });
      };

      return {
        onNext: onNextOrPrev,
        onPrevious: onNextOrPrev,
        onFirst: onNextOrPrev,
        onLast: onNextOrPrev,
        dispatch,
        changeFilter,
        replaceFilter,
      };
    }
  ),
  connect(
    null,
    (
      dispatch,
      {
        snackbar,
        setSelectedShipments,
        overlay,
        replaceFilter,
        location,
        queryFromStorage,
      }
    ) => {
      const fetchShipmentList = (pageParams, query, sorting) =>
        dispatch(
          FailedImportsActions.fetchFailedShipments({
            searchPage: pageParams.page,
            searchPageSize: pageParams.pageSize,
            ...(query.searchCriteria && {
              searchCriteria: query.searchCriteria,
            }),
            ...(query.searchDate && {
              searchDate: moment(query.searchDate, DEFAULT_DATE_FORMAT).format(
                ISO_DATE_FORMAT
              ),
            }),
            searchSortBy: sorting.searchSortBy
              ? sorting.searchSortBy
              : DEFAULT_SORT_BY,
            ...(sorting.searchSortOrder && {
              searchSortOrder: sorting.searchSortOrder,
            }),
          })
        );

      const reloadFn = async userSearchQuery => {
        const locationSearchQuery = location.search;

        try {
          overlay.show();

          if (setSelectedShipments) {
            setSelectedShipments([]);
          }

          const searchQuery =
            userSearchQuery || locationSearchQuery || queryFromStorage;

          const pagination = getQueryPagination(searchQuery);
          const query = getQueryFilters(
            searchQuery,
            ShipmentReviewFilterFields
          );
          const sorting = getQueryFilters(searchQuery, SORTING_FIELDS);

          const res = await fetchShipmentList(pagination, query, sorting);

          if (!res.data.results.length && !!res.data?.totalResults) {
            const newPagination = getQueryPaginationByTotal(
              searchQuery,
              res.data.totalResults
            );
            replaceFilter(newPagination);
          }
        } catch (e) {
          snackbar.showAlert({
            message: getErrorMessage(e, S.SHIPMENT),
            displayTime: SHOW_ALERT_DISPLAY_TIME,
          });
        } finally {
          overlay.hide();
        }
      };

      const touchErrorFields = (formName, fields) => {
        dispatch(touch(formName, fields));
      };

      return {
        fetchShipmentList,
        reloadFn,
        touchErrorFields,
        deleteFailedShipment: stagingShipmentId =>
          dispatch(
            FailedImportsActions.deleteFailedShipment(stagingShipmentId)
          ),
      };
    }
  ),
  withHandlers({
    onClear:
      ({ changeFilter }) =>
      () =>
        changeFilter(getInitialPageQuery()),
    onSearch:
      ({ changeFilter }) =>
      formValues =>
        changeFilter({ ...getInitialPageQuery(), ...formValues }),
    onSort:
      ({ changeFilter }) =>
      (columnId, searchSortOrder) => {
        changeFilter({
          searchSortBy: COLUMN_ID_TO_SORT_CRITERIA[columnId],
          searchSortOrder,
        });
      },
    onClickDelete: ({
      notifier,
      prompt,
      reloadFn,
      overlay,
      deleteFailedShipment,
    }) =>
      notifier.runAsync(
        async stagingShipments => {
          const isMultiple = stagingShipments.length > 1;
          try {
            overlay.show();

            for await (const stagingShipment of stagingShipments) {
              await deleteFailedShipment(stagingShipment.stagingShipmentId);
            }

            await reloadFn();

            prompt.showInfo({
              header: isMultiple
                ? S.FAILED_SHIPMENTS_DELETED
                : S.FAILED_SHIPMENT_DELETED,
              message: isMultiple
                ? S.FAILED_SHIPMENTS_DELETED_MESSAGE
                : S.FAILED_SHIPMENT_DELETED_MESSAGE,
            });
          } finally {
            overlay.hide();
          }
        },
        { entityName: S.FAILED_SHIPMENT }
      ),
    onClearShipmentDate:
      ({ changeFilter }) =>
      formValues =>
        changeFilter({
          ...getInitialPageQuery(),
          ...formValues,
          searchDate: null,
        }),
  }),
  lifecycle({
    async componentDidMount() {
      const {
        location,
        queryFromStorage,
        dispatch,
        touchErrorFields,
        reloadFn,
      } = this.props;
      // TODO: Refactor to reduce code duplication: src\pages\ShipmentReview\pages\ShipmentList\ShipmentList.js
      // Validate searchCriteria against schema and in case of an error
      // do not reload the page as Server errors are received
      const currentSearch = location.search;
      const storageSearchQuery = parseQuery(queryFromStorage);
      const { searchCriteria = "" } = parseQuery(currentSearch);
      const errors = Joi.validate({ searchCriteria }, shipmentReview);
      const details = errors?.error?.details;

      if (!storageSearchQuery?.searchDate && !currentSearch) {
        storageSearchQuery.searchDate = getInitialPageQuery().searchDate;
        await dispatch(
          UserSessionDataActions.setItem(
            "failedShipmentListSearchQuery",
            stringifyQuery(storageSearchQuery)
          )
        );
      }

      // In case of errors, grab all fields and touch them
      if (Array.isArray(details) && details.length) {
        const fields = details
          .map(f => f.path)
          .filter(Boolean)
          .flat(Infinity);
        touchErrorFields(FAILED_IMPORTS_SEARCH_FORM, fields);
      } else {
        // otherwise fetch data
        reloadFn(currentSearch || stringifyQuery(storageSearchQuery));
      }

      if (currentSearch) {
        dispatch(
          UserSessionDataActions.setItem(
            "failedShipmentListSearchQuery",
            currentSearch
          )
        );
      }
    },
    componentDidUpdate(prevProps) {
      const { location, queryFromStorage, dispatch, reloadFn, replaceFilter } =
        this.props;

      const currentSearch = location.search;

      if (prevProps.location.search !== currentSearch) {
        if (currentSearch && queryFromStorage !== currentSearch) {
          dispatch(
            UserSessionDataActions.setItem(
              "failedShipmentListSearchQuery",
              currentSearch
            )
          );
        }

        if (get(location, "state.reload", true)) {
          reloadFn();
        }
      }

      if (!currentSearch?.length && queryFromStorage) {
        replaceFilter(queryString.parse(queryFromStorage), false);
      }
    },
    componentWillUnmount() {
      this.props.banner.hideByType(BANNERS_TYPES.INFO);
    },
  })
)(FailedImportsList);
