import { connect } from "react-redux";
import { compose } from "recompose";
import { autofill, stopSubmit } from "redux-form";

import {
  createCancelableRequest,
  isAbortError,
} from "@dpdgroupuk/fetch-client";

import { EDIT_PRODUCT_FORM, ProductEntity } from "~/constants/forms";
import { ReferenceActions } from "~/redux";
export default compose(
  connect(null, dispatch => ({
    onSearch: createCancelableRequest(async (query, options) => {
      try {
        const {
          data: { commodities },
        } = await dispatch(
          ReferenceActions.fetchCommoditiesByFilter(query, options)
        );
        return commodities;
      } catch (err) {
        if (!isAbortError(err)) {
          const fieldError = err?.errors?.[0]?.message;

          if (fieldError) {
            dispatch(
              stopSubmit(EDIT_PRODUCT_FORM, {
                [ProductEntity.COMMODITY_CODE]: fieldError,
              })
            );
          } else {
            throw err;
          }
        }
      }
    }),
    onSelectionChange: values => {
      dispatch(
        autofill(
          EDIT_PRODUCT_FORM,
          ProductEntity.COMMODITY_CODE,
          values.commodityCode
        )
      );
    },
  }))
);
