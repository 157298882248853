import { ImportsModels } from "~/pages/Imports/models";
import { TemplateActions, TemplateSelectors } from "~/redux/template";
import { UmsActions, UmsSelectors } from "~/redux/ums";
import { ModalSelectors } from "~/redux/modals";
import moment from "moment";
import { ShipmentModels } from "~/models";
import { getValue } from "~/utils/object";
import { change } from "redux-form";

export const fetchInitialAppData = () => (dispatch, getState) =>
  Promise.all([
    dispatch(UmsActions.fetchPreferences()),
    dispatch(UmsActions.fetchCustomer()),
    dispatch(UmsActions.fetchCustomerPrefs()),
    dispatch(UmsActions.fetchCustomerServices()),
    dispatch(UmsActions.fetchAdvanceConfiguration()),
    dispatch(UmsActions.fetchShippingSettings()),
    dispatch(UmsActions.fetchCustomerAddress()),
    dispatch(UmsActions.fetchSecuritySettings()),
    dispatch(TemplateActions.fetchCacheableShipmentImportTemplates()).then(
      () => {
        const shipmentImportTemplates =
          TemplateSelectors.getShipmentImportTemplates(getState());
        const shipmentTemplateId = ImportsModels.getDefaultTemplateId(
          shipmentImportTemplates
        );
        if (shipmentTemplateId) {
          return dispatch(
            TemplateActions.fetchCacheableShipmentImportTemplateById(
              shipmentTemplateId
            )
          );
        }
      }
    ),
    dispatch(TemplateActions.fetchCacheableShipmentReceiptTemplate()),
  ]);

export const checkMinDate =
  ({ formName, dateFormat, fieldPath }) =>
  (dispatch, getState) => {
    const state = getState();
    const formValues = ModalSelectors.getModalFormValues(state, formName);
    const shipmentDate = getValue(formValues, fieldPath, null);
    const preferences = UmsSelectors.getPreferences(state);
    const minDate = moment(
      ShipmentModels.getMinShipmentDate(
        getValue(preferences, "shippingDefaults.enableWeekend", false)
      ),
      dateFormat
    ).format(dateFormat);

    if (
      moment(shipmentDate, dateFormat).isBefore(
        moment(minDate, dateFormat),
        "day"
      )
    ) {
      dispatch(change(formName, fieldPath, minDate));

      return minDate;
    }

    return shipmentDate;
  };
