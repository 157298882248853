import { compose, withProps, withState } from "recompose";

import { withNotifier } from "@dpdgroupuk/mydpd-app";

import * as S from "~/constants/strings";

import { connect } from "react-redux";

import BasePage from "~/pages/Tools/pages/BasePage";
import { groupDispatchSchema } from "~/models/validators/groupDispatchSchema";
import { ToolsActions } from "~/pages/Tools/redux";

export default compose(
  withState("abortController", "setAbortController", new AbortController()),
  withNotifier,
  withProps(() => ({
    groupsTitle: S.GROUP_DISPATCH,
    isGroupsField: true,
    validationSchema: (values, { user, shippingSettings }) =>
      groupDispatchSchema(values, { user, shippingSettings }),
  })),
  connect(null, (dispatch, { abortController, setAbortController }) => ({
    initializeForm: () => dispatch(ToolsActions.initializeGroupDispatch()),
  }))
)(BasePage);
