import Joi from "joi";

import { Fields, SEARCH_CRITERIA_FIELD } from "~/constants/forms";

import { organisation, postcode, shortName } from "./commonSchema";

export default Joi.object({
  searchCriteriaField: Joi.string(),
  searchCriteriaValue: Joi.when(Joi.ref(SEARCH_CRITERIA_FIELD), {
    is: Fields.POSTCODE,
    then: postcode,
    otherwise: Joi.when(Joi.ref(SEARCH_CRITERIA_FIELD), {
      is: Fields.ORGANISATION,
      then: organisation,
      otherwise: Joi.when(Joi.ref(SEARCH_CRITERIA_FIELD), {
        is: Fields.SHORT_NAME,
        then: shortName,
      }),
    }),
  }),
});
