import { useMemo } from "react";

import PropTypes from "prop-types";
import { Col, Container, Row } from "react-bootstrap";
import { Field, propTypes } from "redux-form";

import { Step } from "@dpdgroupuk/mydpd-ui";

import BaseContactForm from "~/components/BaseContactForm";
import DoubleAstericksInput from "~/components/DoubleAstericksInput/DoubleAstericksInput";
import DeliveryContactCard from "~/components/ReviewCard/cards/DeliveryContact";
import {
  DELIVERY_CONTACT,
  OUTBOUND_CONSIGNMENT_UPPERCASE,
  ShipmentEntity,
} from "~/constants/forms";
import help from "~/constants/info";
import * as S from "~/constants/strings";
import { Normalizers, ShipmentModels } from "~/models";

const DeliveryContact = props => {
  const {
    createShipmentSyncErrors,
    disabledFields,
    requiredFields,
    allowedFields,
  } = props;
  const isValidDeliveryContactSection = useMemo(
    () =>
      ShipmentModels.isValidDeliveryContactSection(createShipmentSyncErrors),
    [createShipmentSyncErrors]
  );

  return (
    <Step
      totalSteps={props.totalSteps}
      step={props.step}
      withStepCounter
      complete={!props.pristine && isValidDeliveryContactSection}
      help={help[DELIVERY_CONTACT]}
      title={S.DELIVERY_CONTACT_TITLE}
      helpModalTitle={S.DELIVERY_CONTACT_TITLE}
    >
      <Container fluid className="p-0">
        <Row>
          <Col md={props.isNotifyRequired ? 6 : 12}>
            <Col className={`px-0 ${props.isNotifyRequired && "pr-md-4"}`}>
              <BaseContactForm
                {...props}
                shipmentSection={OUTBOUND_CONSIGNMENT_UPPERCASE}
              />
              <Row>
                <Col>
                  <Field
                    component={DoubleAstericksInput}
                    label={S.NOTIFICATION_EMAIL}
                    name={
                      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS
                        .NOTIFICATION_DETAILS.EMAIL
                    }
                    maxLength={100}
                    helperText={S.MAX_100_CHARACTERS}
                    onBlur={props.onFieldEntry}
                    disabled={
                      disabledFields[
                        ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS
                          .NOTIFICATION_DETAILS.EMAIL
                      ]
                    }
                    required={
                      requiredFields[
                        ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS
                          .NOTIFICATION_DETAILS.EMAIL
                      ]
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Field
                    component={DoubleAstericksInput}
                    label={S.MOBILE_NOTIFICATION_TEXT}
                    name={
                      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS
                        .NOTIFICATION_DETAILS.MOBILE
                    }
                    normalize={Normalizers.phoneNumberNormalize}
                    helperText={S.MAX_15_CHARACTERS}
                    onBlur={props.onFieldEntry}
                    disabled={
                      disabledFields[
                        ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS
                          .NOTIFICATION_DETAILS.MOBILE
                      ]
                    }
                    required={
                      requiredFields[
                        ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS
                          .NOTIFICATION_DETAILS.MOBILE
                      ]
                    }
                  />
                </Col>
              </Row>
            </Col>
          </Col>

          {allowedFields.deliveryContactCard && (
            <Col md={6}>
              <DeliveryContactCard />
            </Col>
          )}
        </Row>
      </Container>
    </Step>
  );
};

DeliveryContact.propTypes = {
  requiredFields: PropTypes.object,
  totalSteps: PropTypes.number,
  step: PropTypes.number,
  isNotifyRequired: PropTypes.bool,
  disabledFields: PropTypes.object,
  allowedFields: PropTypes.object,
  ...propTypes,
};

export default DeliveryContact;
