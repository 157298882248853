import Joi from "joi";

import { ProductEntity, SEARCH_CRITERIA_FIELD } from "~/constants/forms";

import {
  commodityCode,
  countryOfOrigin,
  productCode,
  productComposition,
  productDescription,
  productType,
  productUnitValue,
  productUrl,
  productWeight,
  shortName,
} from "./commonSchema";

export default Joi.object({
  searchCriteriaField: Joi.string(),
  searchCriteriaValue: Joi.alternatives()
    .when(SEARCH_CRITERIA_FIELD, {
      is: ProductEntity.SHORT_NAME,
      then: shortName,
    })
    .when(SEARCH_CRITERIA_FIELD, {
      is: ProductEntity.PRODUCT_CODE,
      then: productCode,
    })
    .when(SEARCH_CRITERIA_FIELD, {
      is: ProductEntity.PRODUCT_TYPE,
      then: productType,
    })
    .when(SEARCH_CRITERIA_FIELD, {
      is: ProductEntity.PRODUCT_DESCRIPTION,
      then: productDescription,
    })
    .when(SEARCH_CRITERIA_FIELD, {
      is: ProductEntity.PRODUCT_COMPOSITION,
      then: productComposition,
    })
    .when(SEARCH_CRITERIA_FIELD, {
      is: ProductEntity.COUNTRY_OF_ORIGIN,
      then: countryOfOrigin,
    })
    .when(SEARCH_CRITERIA_FIELD, {
      is: ProductEntity.COMMODITY_CODE,
      then: commodityCode,
    })
    .when(SEARCH_CRITERIA_FIELD, {
      is: ProductEntity.UNIT_WEIGHT,
      then: productWeight,
    })
    .when(SEARCH_CRITERIA_FIELD, {
      is: ProductEntity.UNIT_VALUE,
      then: productUnitValue,
    })
    .when(SEARCH_CRITERIA_FIELD, {
      is: ProductEntity.PRODUCT_URL,
      then: productUrl,
    }),
});
