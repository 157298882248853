import { useCallback, useEffect, useMemo } from "react";

import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import isNull from "lodash/isNull";
import omit from "lodash/omit";
import omitBy from "lodash/omitBy";
import pick from "lodash/pick";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { lifecycle, withHandlers, withState } from "recompose";
import { compose } from "redux";
import { change, Field, propTypes, reduxForm, reset } from "redux-form";

import { withNotifier } from "@dpdgroupuk/mydpd-app";
import CommodityCodeFinder from "@dpdgroupuk/mydpd-commodity-finder";
import {
  Button,
  FormControl,
  withPrompt,
  withSnackbar,
} from "@dpdgroupuk/mydpd-ui";

import { validateCommodity } from "~/apis/reference";
import { PackageContentModels } from "~/components/PackageContent/models";
import SearchProduct from "~/components/SearchProduct";
import {
  EDIT_PRODUCT_FORM,
  Fields,
  PRODUCT_BOOK_SEARCH_FORM,
  ProductEntity,
  SEARCH_CRITERIA_FIELD,
  SEARCH_CRITERIA_VALUE,
  ShipmentEntity,
} from "~/constants/forms";
import { SHOW_ALERT_DISPLAY_TIME } from "~/constants/snackbar";
import * as S from "~/constants/strings";
import withCommodityCodeAutocomplete from "~/hocs/withCommodityCodeAutocomplete";
import withCommodityCodeSearch from "~/hocs/withCommodityCodeSearch";
import { Normalizers } from "~/models";
import {
  countryValidation,
  packageNumberValidation,
  validateCommodityStatic,
} from "~/models/validators/additionalValidations";
import productSchema from "~/models/validators/productSchema";
import { ShipmentActions } from "~/pages/Shipment/redux";
import { ProductBookActions } from "~/redux";
import {
  getCommodityError,
  getEditProductFormValues,
  getEditProductSelectedCountry,
  getFormActiveField,
  getProductBookQuery,
  getProductBookSearchFormValues,
  getProductTotalValue,
  isProductBookSubmitting,
} from "~/redux/productBook/selectors";
import { isIgnoredError } from "~/utils/error";
import createValidator from "~/utils/joiReduxForm";
import { roundToDecimal, separateThousandWithComma } from "~/utils/number";
import { flattenEntityRoutes, getDeepKeys } from "~/utils/object";
import { initializeForm } from "~/utils/reduxForm";
import { formatMessage } from "~/utils/string";

import Autocomplete from "../Autocomplete";
import CountriesAutocomplete from "../Autocomplete/CountriesAutocomplete";
import styles from "./PackageContent.module.scss";

const EditProduct = ({
  createShipmentValues,
  selectedDeliveryCountry,
  selectedProduct,
  onFieldEntry,
  requiredFields,
  hiddenFields,
  countries,
  onSearch,
  productTotalValue,
  handleSubmit,
  onSelectionChange,
  onProductBookClear,
  isAdditionalCommCodeCheckRequired,
  setIsAsyncCommodityValidated,
  onClickCancel,
  currencyLabel,
  onCountryChange,
  editProductSelectedCountry,
  onClickSaveToProductBook,
  onProductBookSelectionChange,
  productBookQuery,
  onCommoditySearch,
  onCommodityCodeSearch,
  change,
  isProductBookSubmitting,
  disabledFields,
}) => {
  useEffect(
    () => setIsAsyncCommodityValidated(!isAdditionalCommCodeCheckRequired),
    [isAdditionalCommCodeCheckRequired]
  );
  const getSearchQuery = useCallback(
    value => ({
      searchString: value,
      deliveryCountryCode: selectedDeliveryCountry.countryKey,
    }),
    [selectedDeliveryCountry]
  );

  const shouldSearchCommodityCode = useCallback(
    () => isAdditionalCommCodeCheckRequired,
    [isAdditionalCommCodeCheckRequired]
  );

  const onCommodityChange = useCallback(() => {
    setIsAsyncCommodityValidated(!isAdditionalCommCodeCheckRequired);
  }, [isAdditionalCommCodeCheckRequired]);

  const packageNumberOptions = useMemo(
    () =>
      PackageContentModels.getShipmentPackageNumberOptions(
        createShipmentValues
      ),
    [createShipmentValues]
  );

  return (
    <>
      <Row>
        <Col>
          <Field
            component={FormControl.Dropdown}
            label={S.PACKAGE_NUMBER}
            name={Fields.PACKAGE_NUMBER}
            helperText={S.SELECT_PACKAGE}
            onBlur={onFieldEntry}
            disabled={!selectedProduct || disabledFields.packageContent}
            values={packageNumberOptions}
            textTransform={S.UPPERCASE}
            required
          />
        </Col>
      </Row>
      <SearchProduct
        disabled={!selectedProduct || disabledFields.packageContent}
        onFieldEntry={onFieldEntry}
        form={PRODUCT_BOOK_SEARCH_FORM}
        onClear={onProductBookClear}
        onSelectionChange={values => {
          onProductBookSelectionChange(values);
        }}
      />
      {!hiddenFields[ProductEntity.PRODUCT_CODE] && (
        <Row>
          <Col>
            <Field
              component={FormControl.Input}
              label={S.PRODUCT_CODE}
              name={ProductEntity.PRODUCT_CODE}
              helperText={S.ENTER_PRODUCT_CODE_IDENTIFIER}
              onBlur={onFieldEntry}
              disabled={!selectedProduct || disabledFields.packageContent}
              required={requiredFields[ProductEntity.PRODUCT_CODE]}
              maxLength={255}
            />
          </Col>
        </Row>
      )}
      {!hiddenFields[ProductEntity.PRODUCT_TYPE] && (
        <Row>
          <Col>
            <Field
              component={FormControl.Input}
              label={S.PRODUCT_TYPE}
              name={ProductEntity.PRODUCT_TYPE}
              helperText={S.PRODUCT_TYPE_EXAMPLE}
              onBlur={onFieldEntry}
              disabled={!selectedProduct || disabledFields.packageContent}
              required={requiredFields[ProductEntity.PRODUCT_TYPE]}
              maxLength={40}
            />
          </Col>
        </Row>
      )}
      {!hiddenFields[ProductEntity.PRODUCT_URL] && (
        <Row>
          <Col>
            <Field
              component={FormControl.Input}
              label={S.PRODUCT_URL}
              name={ProductEntity.PRODUCT_URL}
              helperText={S.ENTER_PRODUCT_URL}
              onBlur={onFieldEntry}
              disabled={!selectedProduct || disabledFields.packageContent}
              required={requiredFields[ProductEntity.PRODUCT_URL]}
              maxLength={255}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <Field
            component={FormControl.Input}
            label={S.DETAILED_PRODUCT_DESCRIPTION}
            name={ProductEntity.PRODUCT_DESCRIPTION}
            helperText={S.DETAILED_PRODUCT_DESCRIPTION_EXAMPLE}
            onBlur={onFieldEntry}
            disabled={!selectedProduct || disabledFields.packageContent}
            required={requiredFields[ProductEntity.PRODUCT_DESCRIPTION]}
            maxLength={200}
          />
        </Col>
      </Row>
      {!hiddenFields[ProductEntity.PRODUCT_COMPOSITION] && (
        <Row>
          <Col>
            <Field
              component={FormControl.Input}
              label={S.PRODUCT_COMPOSITION}
              name={ProductEntity.PRODUCT_COMPOSITION}
              helperText={S.PRODUCT_COMPOSITION_EXAMPLE}
              onBlur={onFieldEntry}
              disabled={!selectedProduct || disabledFields.packageContent}
              required={requiredFields[ProductEntity.PRODUCT_COMPOSITION]}
              maxLength={200}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <Field
            component={CountriesAutocomplete}
            id={ProductEntity.COUNTRY_OF_ORIGIN}
            name={ProductEntity.COUNTRY_OF_ORIGIN}
            label={S.COUNTRY_OF_ORIGIN}
            helperText={S.ENTER_OR_SELECT_DESTINATION_COUNTRY}
            onCountryChange={onCountryChange}
            countries={countries}
            disabled={!selectedProduct || disabledFields.packageContent}
            required
            selectedCountry={editProductSelectedCountry}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="d-flex align-items-center">
            <Field
              component={Autocomplete}
              label={S.COMMODITY_CODE}
              id={ProductEntity.COMMODITY_CODE}
              name={ProductEntity.COMMODITY_CODE}
              helperText={S.CLARIFY_YOUR_GOODS}
              disabled={!selectedProduct || disabledFields.packageContent}
              required={requiredFields[ProductEntity.COMMODITY_CODE]}
              key={requiredFields[ProductEntity.COMMODITY_CODE]}
              minLength={3}
              maxLength={10}
              onSearch={onSearch}
              labelKey="commodityCode"
              optionLabelMapper={PackageContentModels.getCommodityDescription}
              onSelectionChange={onSelectionChange}
              withAutocomplete={isAdditionalCommCodeCheckRequired}
              type="search"
              getSearchQuery={getSearchQuery}
              shouldSearch={shouldSearchCommodityCode}
              pageSize={25}
              onChange={onCommodityChange}
            />
            <CommodityCodeFinder
              buttonClassName={styles.findCommodityButton}
              disabled={!selectedProduct || disabledFields.packageContent}
              onSearch={onCommoditySearch}
              onCommodityCodeSearch={onCommodityCodeSearch}
              onSubmit={value => change(ProductEntity.COMMODITY_CODE, value)}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            component={FormControl.Input}
            label={S.PRODUCT_UNIT_WEIGHT}
            name={ProductEntity.UNIT_WEIGHT}
            helperText={S.MAXIMUM_TOTAL_WEIGHT_PER_PACKAGE}
            onBlur={onFieldEntry}
            disabled={!selectedProduct || disabledFields.packageContent}
            normalize={Normalizers.floatNormalize}
            required={requiredFields[ProductEntity.UNIT_WEIGHT]}
            maxLength={8}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            component={FormControl.Input}
            label={S.PRODUCT_QUANTITY}
            name={ProductEntity.PRODUCT_QUANTITY}
            helperText={S.TOTAL_PRODUCT_QUANTITY_ITEMS_PER_PARCEL}
            onBlur={onFieldEntry}
            disabled={!selectedProduct || disabledFields.packageContent}
            normalize={Normalizers.integerNormalize}
            required={requiredFields[ProductEntity.PRODUCT_QUANTITY]}
            maxLength={5}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            component={FormControl.Input}
            label={formatMessage(S.PRODUCT_UNIT_VALUE_$, currencyLabel)}
            name={ProductEntity.UNIT_VALUE}
            helperText={S.INDIVIDUAL_VALUE_FOR_EACH_PRODUCT_IN_PARCEL}
            onBlur={onFieldEntry}
            disabled={!selectedProduct || disabledFields.packageContent}
            normalize={Normalizers.floatNormalize}
            required={requiredFields[ProductEntity.UNIT_VALUE]}
            maxLength={8}
          />
        </Col>
      </Row>
      <Row className="mt-3 justify-content-end">
        <Col xs="auto" className={styles.text16}>
          {S.TOTAL_PRODUCT_VALUE}
        </Col>
        <Col xs="auto" className={styles.text16}>
          {currencyLabel}
          {separateThousandWithComma(productTotalValue)}
        </Col>
      </Row>
      <Row className={`mt-3 pt-3 ${styles.buttonsWrapper}`}>
        <Col className={`d-flex justify-content-start ${styles.buttonsStart}`}>
          <Button
            disabled={!selectedProduct || disabledFields.packageContent}
            variant="danger"
            onClick={onClickCancel}
          >
            {S.CANCEL}
          </Button>
          <Button
            className="ml-3"
            disabled={
              !productBookQuery ||
              isProductBookSubmitting ||
              disabledFields.packageContent
            }
            variant="primary"
            onClick={onClickSaveToProductBook}
          >
            {S.SAVE_TO_PRODUCT_BOOK}
          </Button>
        </Col>
        <Col className="d-flex justify-content-end">
          <Button
            disabled={!selectedProduct || disabledFields.packageContent}
            variant="dark"
            onClick={handleSubmit}
          >
            {S.SAVE_PRODUCT}
          </Button>
        </Col>
      </Row>
    </>
  );
};

EditProduct.propTypes = {
  ...propTypes,
  createShipmentValues: PropTypes.object,
  selectedDeliveryCountry: PropTypes.object,
  selectedProduct: PropTypes.object,
  onFieldEntry: PropTypes.func,
  requiredFields: PropTypes.object,
  countries: PropTypes.array,
  onSearch: PropTypes.func,
  productTotalValue: PropTypes.number,
  onSelectionChange: PropTypes.func,
  isAdditionalCommCodeCheckRequired: PropTypes.bool,
  isAsyncCommodityValidated: PropTypes.bool,
  setIsAsyncCommodityValidated: PropTypes.func,
  onCancel: PropTypes.func,
  currencyLabel: PropTypes.string,
  onCountryChange: PropTypes.func,
  editProductSelectedCountry: PropTypes.object,
  onClickSaveToProductBook: PropTypes.func,
  hiddenFields: PropTypes.object,
  onClickCancel: PropTypes.func,
  onProductBookSelectionChange: PropTypes.func,
  preferences: PropTypes.object,
  isProductBookSubmitting: PropTypes.bool,
};

export default compose(
  withPrompt,
  withNotifier,
  withSnackbar,
  withState("isAsyncCommodityValidated", "setIsAsyncCommodityValidated", true),
  connect(
    (
      state,
      { editProductRequiredFields, editProductHiddenFields, pageConfig }
    ) => ({
      editProductFormValues: getEditProductFormValues(state),
      editProductFormActiveField: getFormActiveField(state),
      commodityError: getCommodityError(state),
      requiredFields: editProductRequiredFields,
      hiddenFields: editProductHiddenFields,
      productTotalValue: getProductTotalValue(state),
      editProductSelectedCountry: getEditProductSelectedCountry(state),
      productBookQuery: getProductBookQuery(state, pageConfig),
      productBookSearchQuery: getProductBookSearchFormValues(state),
      isProductBookSubmitting: isProductBookSubmitting(state),
    }),
    (dispatch, { onCancel }) => ({
      onCountryChange: selection =>
        dispatch(
          ShipmentActions.changeCountry(
            { formName: EDIT_PRODUCT_FORM },
            ProductEntity.COUNTRY_OF_ORIGIN,
            selection.value
          )
        ),

      updateForm: values => dispatch(initializeForm(EDIT_PRODUCT_FORM, values)),
      updateSearchForm: (field, value) =>
        dispatch(change(PRODUCT_BOOK_SEARCH_FORM, field, value)),
      createOrUpdateProductBook: (productBookId, productBook) =>
        dispatch(
          ProductBookActions.createOrUpdateProductBook(
            productBookId,
            productBook
          )
        ),
      onClickCancel: () => {
        dispatch(reset(EDIT_PRODUCT_FORM));
        onCancel();
      },
      onProductBookClear: () => {
        dispatch(change(EDIT_PRODUCT_FORM, "productBookId"));
      },
      fetchProductBookById: productBookId =>
        dispatch(ProductBookActions.fetchProductBookById(productBookId)),
    })
  ),
  withCommodityCodeAutocomplete,
  withCommodityCodeSearch,
  withHandlers({
    onProductBookSelectionChange: ({
      notifier,
      fetchProductBookById,
      selectedCurrency,
      productBookSearchQuery,
      updateForm,
      updateSearchForm,
      prompt,
    }) =>
      notifier.runAsync(async ({ productBookId }) => {
        const { data } = await fetchProductBookById(productBookId);

        if (data.currency && data.currency !== selectedCurrency) {
          prompt.showInfo({
            header: S.PRODUCT_ERROR_MESSAGE,
            message: S.PRODUCT_CURRENCY_MISMATCH,
          });
        }
        updateForm({
          ...data,
          unitValue: roundToDecimal(data.unitValue),
          unitWeight: roundToDecimal(data.unitWeight),
        });
        updateSearchForm(
          SEARCH_CRITERIA_VALUE,
          data[productBookSearchQuery[SEARCH_CRITERIA_FIELD]]
        );
      }),
    onClickSaveToProductBook: ({
      productBookQuery,
      snackbar,
      notifier,
      createOrUpdateProductBook,
      updateForm,
    }) =>
      notifier.runAsync(
        async () => {
          const { productBookId, ...productBook } = productBookQuery;

          try {
            const { data } = await createOrUpdateProductBook(
              productBookId,
              productBook
            );

            updateForm({
              productBookId: data.productbookId,
              [ProductEntity.CURRENCY]: productBook[ProductEntity.CURRENCY],
            });

            snackbar.showSuccess({
              message: productBookId
                ? S.EDIT_PRODUCT_BOOK
                : S.CREATE_PRODUCT_BOOK,
            });
          } catch (error) {
            if (isIgnoredError(error)) {
              return;
            }

            const errors = error?.errors
              ?.filter(({ message }) => message)
              .map(({ message }) => message)
              .join("\n");

            if (errors) {
              snackbar.showAlert({ message: errors });
            } else {
              throw error;
            }
          }
        },
        { entityName: S.PRODUCT_BOOK }
      ),
    onSubmit:
      ({ createProduct, deleteSelectedProduct, setSelectedProduct }) =>
      ({ productIndex, packageNumber, ...values }) => {
        if (productIndex !== undefined) {
          deleteSelectedProduct();
        }

        createProduct(
          omit(
            omitBy(pick(values, flattenEntityRoutes(ProductEntity)), isNull),
            ProductEntity.SHORT_NAME,
            ProductEntity.CURRENCY
          ),
          packageNumber
        );
        setSelectedProduct();
      },
  }),
  reduxForm({
    form: EDIT_PRODUCT_FORM,
    shouldError: () => true,
    validate: (values, props) =>
      createValidator(productSchema, [
        () =>
          countryValidation(
            { ...props, values: props.editProductFormValues },
            ProductEntity.COUNTRY_OF_ORIGIN
          ),
        () =>
          packageNumberValidation(
            values?.packageNumber,
            get(
              props.createShipmentValues,
              ShipmentEntity.OUTBOUND_CONSIGNMENT.NUMBER_OF_PARCELS
            ) - 1,
            Fields.PACKAGE_NUMBER
          ),
        () =>
          validateCommodityStatic({
            ...props,
            values: props.editProductFormValues,
          }),
      ])(values, props),
    asyncValidate: async (
      { commodityCode },
      _,
      {
        prompt,
        isAdditionalCommCodeCheckRequired,
        selectedDeliveryCountry,
        setIsAsyncCommodityValidated,
        snackbar,
        abortController,
      }
    ) => {
      if (isAdditionalCommCodeCheckRequired && !isEmpty(commodityCode)) {
        try {
          await validateCommodity(
            {
              commodityCode,
              deliveryCountryCode: selectedDeliveryCountry.countryKey,
            },
            {
              signal: abortController.signal,
            }
          );
          setIsAsyncCommodityValidated(true);
        } catch (error) {
          const errorCode = error?.errors?.[0]?.code;
          const errorMessage = error?.errors?.[0]?.message;
          const fieldPath = error?.errors?.[0]?.fieldPath;

          if (errorMessage) {
            const message =
              fieldPath === "deliveryCountryCode" || errorCode === 100
                ? errorMessage
                : S.COMMODITY_CODE_ERROR_MESSAGE(errorMessage);

            prompt.showInfo({
              header: S.COMMODITY_CODE_ERROR,
              message,
            });

            // eslint-disable-next-line no-throw-literal
            throw {
              commodityCode: errorMessage,
            };
          } else if (!isIgnoredError(error)) {
            snackbar.showAlert({
              message: S.FAILED_TO_VALIDATE_COMMODITY,
              displayTime: SHOW_ALERT_DISPLAY_TIME,
            });
            throw error;
          }
        }
      }

      return Promise.resolve();
    },
    asyncBlurFields: [ProductEntity.COMMODITY_CODE],
    enableReinitialize: true,
    onSubmitFail: (errors, dispatch, _, props) => {
      const mappedErrors = getDeepKeys(errors);
      props?.notifier.scrollToError(mappedErrors);
    },
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      const { selectedProduct, updateForm } = this.props;

      if (
        !isEqual(prevProps.selectedProduct, selectedProduct) &&
        !isEmpty(selectedProduct)
      ) {
        updateForm(selectedProduct);
      }

      if (prevProps.selectedProduct && !this.props.selectedProduct) {
        this.props.dispatch(reset(PRODUCT_BOOK_SEARCH_FORM));
      }
    },
  })
)(EditProduct);
