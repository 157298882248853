import get from "lodash/get";
import omit from "lodash/omit";
import { connect } from "react-redux";
import { compose, withHandlers } from "recompose";

import { DEFAULT_ADDRESS_BOOK, GB } from "~/constants/strings";
import { ShipmentActions } from "~/pages/Shipment/redux";
import { AddressBookActions } from "~/redux";
import { touchFields, untouchEmptyFields } from "~/utils/reduxForm";

export default compose(
  connect(
    null,
    (
      dispatch,
      { notifier, reInitializeForm, abortController, pageConfig }
    ) => ({
      fetchDefaultReturnAddressBook: notifier.runAsync(
        async () => {
          try {
            return await dispatch(
              AddressBookActions.fetchDefaultReturnAddressBook({
                signal: abortController.signal,
              })
            );
          } catch (e) {
            if (e.statusCode !== 404) {
              throw e;
            }
          }
        },
        { entityName: DEFAULT_ADDRESS_BOOK }
      ),
      setSelectedReturnAddressBook: (addressBook = {}) => {
        const values = {
          inboundConsignment: {
            deliveryDetails: {
              address: omit(addressBook.address, ["countryName"]),
              contactDetails: addressBook.contactDetails,
            },
            deliveryInstructions: addressBook.deliveryInstructions,
            shippingRef1: addressBook.shippingRef1,
            shippingRef2: addressBook.shippingRef2,
            shippingRef3: addressBook.shippingRef3,
          },
        };
        dispatch(ShipmentActions.updateReturnShortName(addressBook.shortName));
        dispatch(ShipmentActions.setSelectedReturnAddressBook(addressBook));
        reInitializeForm(values);
        dispatch(touchFields(pageConfig.formName, values));
        dispatch(untouchEmptyFields(pageConfig.formName, values));
      },
    })
  ),
  withHandlers({
    defaultReturnAddressBookHandler:
      ({ fetchDefaultReturnAddressBook, setSelectedReturnAddressBook }) =>
      async () => {
        const newAddressBook = await fetchDefaultReturnAddressBook();
        if (get(newAddressBook, "address.countryCode") === GB) {
          setSelectedReturnAddressBook(newAddressBook);
        }
      },
  })
);
