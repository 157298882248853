import classNames from "classnames";
import moment from "moment";
import PropTypes from "prop-types";
import { compose, withHandlers, withProps } from "recompose";
import { Field, propTypes, reduxForm } from "redux-form";

import {
  Button,
  Card,
  FormControl,
  getFileAttributes,
  withPrompt,
} from "@dpdgroupuk/mydpd-ui";

import { localApi } from "~/apis";
import { DAY_DEFAULT_DATE_FORMAT } from "~/constants/dateFormats";
import { GENERATE_EXPORT_FILE_FORM, TemplatesEntity } from "~/constants/forms";
import * as S from "~/constants/strings";
import { filePathValidation } from "~/models/validators/additionalValidations";
import generateExportFileSchema from "~/models/validators/generateExportFileSchema";
import createValidator from "~/utils/joiReduxForm";

const GenerateExport = props => (
  <Card>
    <Card.Title
      className={classNames(
        "mb-4 d-flex justify-content-between",
        props.cardTitleClassName
      )}
    >
      {S.GENERATE_EXPORT_FILE}
    </Card.Title>
    <Field
      component={FormControl.Dropdown}
      label={S.TEMPLATE}
      name={TemplatesEntity.TEMPLATE}
      values={props.templates}
      helperText
      textTransform={S.UPPERCASE}
      required
    />
    <Field
      component={FormControl.DatePicker}
      label={S.DATE}
      name={TemplatesEntity.DATE}
      dateFormat={DAY_DEFAULT_DATE_FORMAT}
      required
    />
    <Field
      maxLength={200}
      component={FormControl.Input}
      label={S.EXPORT_FILE}
      name={TemplatesEntity.EXPORT_FILE}
      helperText={S.MAX_200_CHARACTERS}
      required
    />
    <Button
      variant="primary"
      className="align-self-end"
      disabled={
        props.invalid || props.submitting || !props.isImportServiceAvailable
      }
      onClick={props.generateExportConfirmation(
        props.handleSubmit(props.onSubmit)
      )}
    >
      {S.GENERATE}
    </Button>
  </Card>
);

GenerateExport.propTypes = {
  templates: PropTypes.arrayOf(PropTypes.object),
  onSubmit: PropTypes.func,
  shipmentExportTemplate: PropTypes.object,
  isImportServiceAvailable: PropTypes.bool,
  cardTitleClassName: PropTypes.string,
  ...propTypes,
};
GenerateExport.defaultProps = {};

export default compose(
  withPrompt,
  withProps(({ shipmentExportTemplate }) => ({
    // always one template by logic
    templates: [
      {
        label: "Shipment export",
        value: 0,
      },
    ],
    initialValues: {
      [TemplatesEntity.TEMPLATE]: "0",
      [TemplatesEntity.DATE]: moment().format(DAY_DEFAULT_DATE_FORMAT),
      [TemplatesEntity.EXPORT_FILE]: shipmentExportTemplate?.exportFile,
    },
  })),
  withHandlers({
    generateExportConfirmation:
      ({ prompt }) =>
      resolve =>
      () => {
        prompt.showConfirmation({
          header: S.ARE_YOU_SURE,
          message: S.TEMPLATE_IMPORT_CONFIRMATION_MESSAGE,
          onConfirm: () => {
            resolve();
          },
        });
      },
  }),
  reduxForm({
    form: GENERATE_EXPORT_FILE_FORM,
    validate: (values, props) =>
      createValidator(generateExportFileSchema, [
        () => filePathValidation(values),
      ])(values, props),
    asyncValidate: async values => {
      const { directoryPath } = getFileAttributes(
        values[TemplatesEntity.EXPORT_FILE]
      );

      if (directoryPath?.length) {
        return await localApi.fs.stat(directoryPath).catch(() => {
          // eslint-disable-next-line no-throw-literal
          throw {
            [TemplatesEntity.EXPORT_FILE]: S.PROVIDE_CORRECT_FILE_PATH,
          };
        });
      } else {
        // eslint-disable-next-line no-throw-literal
        throw {
          [TemplatesEntity.EXPORT_FILE]: S.PROVIDE_CORRECT_FILE_PATH,
        };
      }
    },
    asyncBlurFields: [TemplatesEntity.EXPORT_FILE],
    enableReinitialize: true,
  })
)(GenerateExport);
