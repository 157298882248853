import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { Field } from "redux-form";

import { FormControl } from "@dpdgroupuk/mydpd-ui";

import { ShipmentEntity } from "~/constants/forms";
import {
  CONTACT_NAME,
  CONTACT_TELEPHONE,
  MAX_15_CHARACTERS_INCLUDING_SPACES,
  MAX_35_CHARACTERS,
} from "~/constants/strings";
import { Normalizers } from "~/models";
import * as ShipmentTypes from "~/models/types/shipment";

const BaseContactForm = props => {
  const { disabledFields } = props;

  return (
    <>
      <Row>
        <Col>
          <Field
            component={FormControl.Input}
            label={CONTACT_NAME}
            name={
              ShipmentEntity[props.shipmentSection].DELIVERY_DETAILS
                .CONTACT_DETAILS.CONTACT_NAME
            }
            maxLength={35}
            helperText={MAX_35_CHARACTERS}
            onBlur={props.onFieldEntry}
            disabled={
              disabledFields[
                ShipmentEntity[props.shipmentSection].DELIVERY_DETAILS
                  .CONTACT_DETAILS.CONTACT_NAME
              ]
            }
            required={
              props.requiredFields[
                ShipmentEntity[props.shipmentSection].DELIVERY_DETAILS
                  .CONTACT_DETAILS.CONTACT_NAME
              ]
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            component={FormControl.Input}
            label={CONTACT_TELEPHONE}
            name={
              ShipmentEntity[props.shipmentSection].DELIVERY_DETAILS
                .CONTACT_DETAILS.TELEPHONE
            }
            helperText={MAX_15_CHARACTERS_INCLUDING_SPACES}
            onBlur={props.onFieldEntry}
            disabled={
              disabledFields[
                ShipmentEntity[props.shipmentSection].DELIVERY_DETAILS
                  .CONTACT_DETAILS.TELEPHONE
              ]
            }
            required={
              props.requiredFields[
                ShipmentEntity[props.shipmentSection].DELIVERY_DETAILS
                  .CONTACT_DETAILS.TELEPHONE
              ]
            }
            normalize={Normalizers.phoneNumberNormalize}
          />
        </Col>
      </Row>
    </>
  );
};

BaseContactForm.propTypes = {
  onFieldEntry: PropTypes.func,
  requiredFields: PropTypes.shape(ShipmentTypes.RequiredFields),
  shipmentSection: PropTypes.string.isRequired,
  disabledFields: PropTypes.object,
};

export default BaseContactForm;
