import { get } from "lodash";

import { Button } from "@dpdgroupuk/mydpd-ui";

import * as S from "~/constants/strings";

const MANIFESTS_TABLE_DATA_COLUMNS = [
  {
    text: S.CREATED_DATE,
    dataIndex: "createdDate",
    style: {
      width: "161px",
    },
  },
  {
    text: S.CONSIGNMENTS,
    dataIndex: "numberOfConsignments",
    style: {
      width: "161px",
    },
  },
  {
    text: S.PARCELS,
    dataIndex: "numberOfParcels",
    style: {
      width: "161px",
    },
  },
];

export const getManifestTableColumns = onReprint => [
  ...MANIFESTS_TABLE_DATA_COLUMNS,
  {
    text: S.REPRINT,
    render: ({ rowData }) => (
      <Button onClick={() => onReprint(get(rowData, "manifestId"))}>
        {S.PRINT}
      </Button>
    ),
    style: {
      width: "161px",
    },
  },
];
