import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import { apisClient } from "~/apis";

export const UmsActionTypes = createActionTypes("ums", {
  FETCH_PREFERENCES: createAsyncActionTypes("FETCH_PREFERENCES"),
  FETCH_CUSTOMER: createAsyncActionTypes("FETCH_CUSTOMER"),
  FETCH_CUSTOMER_PREFS: createAsyncActionTypes("FETCH_CUSTOMER_PREFS"),
  FETCH_CUSTOMER_SERVICES: createAsyncActionTypes("FETCH_CUSTOMER_SERVICES"),
  FETCH_ADVANCED_CONFIGURATION: createAsyncActionTypes(
    "FETCH_ADVANCED_CONFIGURATION"
  ),
  FETCH_SHIPPING_SETTINGS: createAsyncActionTypes("FETCH_SHIPPING_SETTINGS"),
  FETCH_CUSTOMER_ADDRESS: createAsyncActionTypes("FETCH_CUSTOMER_ADDRESS"),
  FETCH_SECURITY_SETTINGS: createAsyncActionTypes("FETCH_SECURITY_SETTINGS"),
});

export const fetchPreferences = createAsyncAction(
  () => apisClient.ums.fetchPreferences(),
  UmsActionTypes.FETCH_PREFERENCES
);

export const fetchCustomer = createAsyncAction(
  () => apisClient.ums.fetchCustomer(),
  UmsActionTypes.FETCH_CUSTOMER
);

export const fetchCustomerPrefs = createAsyncAction(
  () => apisClient.ums.fetchCustomerPrefs(),
  UmsActionTypes.FETCH_CUSTOMER_PREFS
);

export const fetchCustomerServices = createAsyncAction(
  () => apisClient.ums.fetchCustomerServices(),
  UmsActionTypes.FETCH_CUSTOMER_SERVICES
);

export const fetchAdvanceConfiguration = createAsyncAction(
  () => apisClient.ums.fetchAdvanceConfiguration(),
  UmsActionTypes.FETCH_ADVANCED_CONFIGURATION
);

export const fetchShippingSettings = createAsyncAction(
  () => apisClient.ums.fetchShippingSettings(),
  UmsActionTypes.FETCH_SHIPPING_SETTINGS
);

export const fetchCustomerAddress = createAsyncAction(
  () => apisClient.ums.fetchCustomerAddress(),
  UmsActionTypes.FETCH_CUSTOMER_ADDRESS
);

export const fetchSecuritySettings = createAsyncAction(
  () => apisClient.ums.fetchSecuritySettings(),
  UmsActionTypes.FETCH_SECURITY_SETTINGS
);
