import { Col, Row } from "react-bootstrap";
import { Field } from "redux-form";

import { FormControl } from "@dpdgroupuk/mydpd-ui";

import ShipmentCard from "~/components/ShipmentCard";
import { DAY_DEFAULT_DATE_FORMAT } from "~/constants/dateFormats";
import { TOOLS_FIELDS } from "~/constants/forms";
import * as S from "~/constants/strings";

import styles from "./EditCard.module.scss";
import { Normalizers } from "~/models";
import PropTypes from "prop-types";
import { useMemo } from "react";

const EditCard = ({
  groupDispatchServices,
  shippingSettings,
  filterDate,
  getDatesRange,
  isGroupsField,
  returnAddresses,
}) => {
  const labels = useMemo(
    () =>
      isGroupsField
        ? {
            line: S.SHIPMENTS_PER_LINE,
            weight: S.WEIGHT_PER_SHIPMENT,
          }
        : {
            line: S.LABELS_PER_LINE,
            weight: S.WEIGHT_PER_LABEL,
          },

    [isGroupsField]
  );

  const additionalFields = useMemo(
    () =>
      isGroupsField ? (
        <>
          <Col xs={12}>
            <Field
              component={FormControl.Input}
              label={S.YOUR_REFERENCE_2}
              name={TOOLS_FIELDS.REF_2}
              helperText={S.MAX_25_CHARACTERS}
              maxLength={25}
              required={shippingSettings.sendersRef2}
            />
          </Col>
          <Col xs={12}>
            <Field
              component={FormControl.Input}
              label={S.YOUR_REFERENCE_3}
              name={TOOLS_FIELDS.REF_3}
              helperText={S.MAX_25_CHARACTERS}
              maxLength={25}
              required={shippingSettings.sendersRef3}
            />
          </Col>
          <Col xs={12}>
            <Field
              readOnly
              component={FormControl.DatePicker}
              label={S.SHIPMENT_DATE}
              name={TOOLS_FIELDS.SHIPMENT_DATE}
              helperText={S.FORWARD_SHIPMENT_DATE}
              dateFormat={DAY_DEFAULT_DATE_FORMAT}
              getDatesRange={getDatesRange}
              filterDate={filterDate}
              required
            />
          </Col>
          <Col xs={12}>
            <Field
              component={FormControl.Input}
              label={S.DELIVERY_INFORMATION}
              name={TOOLS_FIELDS.DELIVERY_INSTRUCTION}
              helperText={S.MAX_50_CHARACTERS}
              maxLength={50}
              rows={4}
            />
          </Col>
          <Col xs={12}>
            <Field
              label={S.REQUIRED_SERVICE}
              component={FormControl.Dropdown}
              name={TOOLS_FIELDS.REQUIRED_SERVICE}
              values={groupDispatchServices}
              textTransform={S.UPPERCASE}
              placeholder={S.PLEASE_SELECT}
              required
            />
          </Col>
        </>
      ) : (
        <Col xs={12}>
          <Field
            label={S.RETURN_ADDRESS}
            component={FormControl.Dropdown}
            name={TOOLS_FIELDS.RETURN_ADDRESS}
            values={returnAddresses}
            textTransform={S.UPPERCASE}
            placeholder={S.PLEASE_SELECT}
            required
          />
        </Col>
      ),
    [isGroupsField]
  );

  return (
    <ShipmentCard
      title={S.EDIT_DETAILS_TITLE}
      classes={{
        container: styles.cardContainer,
      }}
    >
      <Row>
        <Col xs={6}>
          <Field
            component={FormControl.Input}
            label={labels.line}
            name={TOOLS_FIELDS.SHIPMENTS_PER_LINE}
            maxLength={2}
            normalize={Normalizers.integerNormalize}
            helperText
            required
          />
        </Col>
        <Col xs={6}>
          <Field
            component={FormControl.Input}
            label={labels.weight}
            name={TOOLS_FIELDS.WEIGHT_PER_SHIPMENT}
            maxLength={2}
            normalize={Normalizers.integerNormalize}
            helperText
            required
          />
        </Col>
        {additionalFields}
      </Row>
    </ShipmentCard>
  );
};

EditCard.propTypes = {
  groupDispatchServices: PropTypes.array,
  returnAddresses: PropTypes.array,
  shippingSettings: PropTypes.object,
  filterDate: PropTypes.func,
  getDatesRange: PropTypes.func,
  isGroupsField: PropTypes.bool,
};

export default EditCard;
