/* eslint-disable react/prop-types */
import { useEffect, useLayoutEffect, useRef } from "react";

import PropTypes from "prop-types";

const isWindows =
  "platform" in navigator && navigator.platform.indexOf("Win") === 0;

const Wrapper = props => {
  const { children, dependency } = props;

  // TODO: Replace this approach with Container Queries once it will be shipped
  // @see: https://css-tricks.com/next-gen-css-container/
  const ref = useRef(null);

  function updateSize() {
    const el = ref.current?.querySelector("tbody");
    if (el?.style) {
      el.style.marginRight = "-17px"; // Scroll width for Windows
    }
  }

  const getWidth = e =>
    ref.current?.querySelector(e)?.getBoundingClientRect()?.width || 0;

  useEffect(() => {
    // If initial height is greater than window's size, apply correction
    if (dependency?.length > 4) {
      updateSize();
    }
  }, [dependency]);

  useLayoutEffect(() => {
    function validateSize() {
      // If Layout shift caused by Vertical Scroll then adjust width
      if (getWidth("thead") - getWidth("tbody") > 2) {
        updateSize();
      }
    }

    try {
      new ResizeObserver(validateSize).observe(ref.current);
    } catch (e) {
      // Chrome <64 and IE doesn't support the Observer, so the Layout width is static
    }
  }, []);

  return <div ref={ref}>{children}</div>;
};

Wrapper.propTypes = {
  dependency: PropTypes.array,
};

const TableScrollResizer = ({ children, dependency }) =>
  isWindows ? <Wrapper dependency={dependency}>{children}</Wrapper> : children;

export default TableScrollResizer;
