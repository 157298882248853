import moment from "moment/moment";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";

import { SHIPPING_SHIPMENTS } from "~/apis/basePaths";
import { ShipmentReviewFilterFields } from "~/constants/forms";
import * as DateFormats from "~/constants/dateFormats";
import { formatISODate } from "~/utils/date";
import { getQueryFilters, stringifyQuery } from "~/utils/query";

export const SHIPMENT_FLAGS = {
  INITIAL: {
    allowVoid: false,
    allowUnvoid: false,
    allowHold: false,
    allowUnhold: false,
    allowDelete: false,
    allowChangeDate: false,
    allowPrintShipment: false,
    allowCopy: false,
    allowEdit: false,
  },
  ALLOW_ALL: {
    allowVoid: true,
    allowUnvoid: true,
    allowHold: true,
    allowUnhold: true,
    allowDelete: true,
    allowChangeDate: true,
    allowPrintShipment: true,
    allowCopy: true,
    allowEdit: true,
  },
};

export const getPermissionsForSelectedShipments = selectedShipments => {
  if (isEmpty(selectedShipments) || isEmpty(selectedShipments?.[0])) {
    return SHIPMENT_FLAGS.INITIAL;
  }

  return selectedShipments.reduce(
    (
      acc,
      {
        allowChangeDate,
        allowCopy,
        allowDelete,
        allowEdit,
        allowPrintShipment,
        allowUnvoid,
        allowVoid,
        allowHold,
        allowUnhold,
      }
    ) => {
      if (!allowChangeDate) {
        acc = { ...acc, allowChangeDate: false };
      }

      if (!allowCopy) {
        acc = { ...acc, allowCopy: false };
      }

      if (!allowDelete) {
        acc = { ...acc, allowDelete: false };
      }

      if (!allowEdit) {
        acc = { ...acc, allowEdit: false };
      }

      if (!allowPrintShipment) {
        acc = { ...acc, allowPrintShipment: false };
      }

      if (!allowUnvoid) {
        acc = { ...acc, allowUnvoid: false };
      }

      if (!allowVoid) {
        acc = { ...acc, allowVoid: false };
      }

      if (!allowHold) {
        acc = { ...acc, allowHold: false };
      }

      if (!allowUnhold) {
        acc = { ...acc, allowUnhold: false };
      }

      return acc;
    },
    { ...SHIPMENT_FLAGS.ALLOW_ALL }
  );
};

export const ShipmentListPropTypes = {
  authUser: PropTypes.object,
  location: PropTypes.object,
  clearFilterQuery: PropTypes.func,
  updateShipmentDate: PropTypes.func,
  shipments: PropTypes.object,
  enableWeekend: PropTypes.bool,
  eodUser: PropTypes.bool,
  onPrevious: PropTypes.func,
  onNext: PropTypes.func,
  onFirst: PropTypes.func,
  onLast: PropTypes.func,
  onClickVoid: PropTypes.func,
  onClickUnvoid: PropTypes.func,
  onClickHold: PropTypes.func,
  onClickUnhold: PropTypes.func,
  onClickChangeDate: PropTypes.func,
  fetchShipmentList: PropTypes.func,
  errors: PropTypes.object,
  isSubmitting: PropTypes.bool,
  onClickDelete: PropTypes.func,
  sendToFileHref: PropTypes.string,
  customerPrefs: PropTypes.object,
  shipmentsStats: PropTypes.object,
};

export const getExportShipmentsCsvHref = (apisBaseUrl, search, columns) => {
  const { searchDate, searchCriteria } = getQueryFilters(
    search,
    ShipmentReviewFilterFields,
    false
  );

  return `${apisBaseUrl}${SHIPPING_SHIPMENTS}/export?${stringifyQuery(
    {
      searchDate: searchDate ? formatISODate(searchDate) : null,
      searchCriteria,
      columns,
    },
    true,
    true
  )}`;
};

export const formatManifest = manifest => ({
  ...manifest,
  createdDate: moment(
    `${manifest.createdDate} ${manifest.createdTime}`,
    `${DateFormats.DEFAULT_DATE_FORMAT} ${DateFormats.FULL_TIME_FORMAT}`
  )
    .utc(true)
    .tz(DateFormats.EUROPE_LONDON_TIME_ZONE)
    .format(DateFormats.DMY_12_HOUR_TIME_FORMAT),
});
