import { ShipmentEntity } from "~/constants/forms";

export const SHIPMENT_TABLE_COLUMN_OPTIONS = [
  {
    value: ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_1,
    label: "Reference 1",
  },
  {
    value: ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_2,
    label: "Reference 2",
  },
  {
    value: ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_3,
    label: "Reference 3",
  },
  {
    value: "outboundConsignment.shippingRef4",
    label: "Reference 4",
  },
  {
    value: "outboundConsignment.shippingRef6",
    label: "Reference 6",
  },
  {
    value: ShipmentEntity.SHIPMENT_DATE,
    label: "Shipment Date",
  },
  {
    value: "outboundConsignment.consignmentNumber",
    label: "Consignment",
  },
  {
    value:
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTRY_CODE,
    label: "Country",
  },
  {
    value:
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE,
    label: "Postcode",
  },
  {
    value: ShipmentEntity.OUTBOUND_CONSIGNMENT.NETWORK_DESCRIPTION,
    label: "Product/Service",
  },
  {
    value: ShipmentEntity.OUTBOUND_CONSIGNMENT.NUMBER_OF_PARCELS,
    label: "Packages",
  },
  {
    value: "shipmentStatus",
    label: "Status",
  },
  {
    value: "isVoided",
    label: "Voided",
  },
  {
    value: "isPrinted",
    label: "Printed",
  },
  {
    value: "isManifested",
    label: "Manifested",
  },
  {
    value:
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.ORGANISATION,
    label: "Organisation/Name",
  },
  {
    value: ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.STREET,
    label: "Address Line 1",
  },
  {
    value:
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.LOCALITY,
    label: "Address Line 2",
  },
  {
    value: ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.TOWN,
    label: "Address Line 3",
  },
  {
    value: ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTY,
    label: "Address Line 4",
  },
  {
    value:
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.CONTACT_DETAILS
        .CONTACT_NAME,
    label: "Contact Name",
  },
  {
    value:
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.CONTACT_DETAILS
        .TELEPHONE,
    label: "Contact Number",
  },
  {
    value: ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_INSTRUCTION,
    label: "Additional Information",
  },
  {
    value:
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.NOTIFICATION_DETAILS
        .EMAIL,
    label: "Notification Email",
  },
  {
    value:
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.NOTIFICATION_DETAILS
        .MOBILE,
    label: "Notification Text",
  },
  {
    value: ShipmentEntity.OUTBOUND_CONSIGNMENT.CUSTOMS_VALUE,
    label: "Customs Value",
  },
  {
    value: ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DESCRIPTION,
    label: "Contents",
  },
  {
    value: "profileCode",
    label: "Profile Code",
  },
  {
    value: "profileName",
    label: "Profile Name",
  },
  {
    value: "shippingAccount",
    label: "Shipping Account",
  },
  {
    value: "userName",
    label: "User Name",
  },
  {
    value: ShipmentEntity.OUTBOUND_CONSIGNMENT.TOTAL_WEIGHT,
    label: "Total Weight",
  },
  {
    value: "holdStatus",
    label: "Held",
  },
];

export const DEFAULT_SHIPMENT_COLUMNS = [
  "selection",
  ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_1,
  ShipmentEntity.SHIPMENT_DATE,
  "outboundConsignment.consignmentNumber",
  ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTRY_CODE,
  ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE,
  ShipmentEntity.OUTBOUND_CONSIGNMENT.NETWORK_DESCRIPTION,
  ShipmentEntity.OUTBOUND_CONSIGNMENT.NUMBER_OF_PARCELS,
  "shipmentStatus",
  "isVoided",
  "isPrinted",
  "view",
  "edit",
];
