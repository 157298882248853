import classNames from "classnames";
import { get } from "lodash";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "recompose";
import withHandlers from "recompose/withHandlers";
import { Field, isValid, reduxForm } from "redux-form";

import { withNotifier, withPrinter } from "@dpdgroupuk/mydpd-app";
import { PRINTER_TYPE } from "@dpdgroupuk/mydpd-enums";
import {
  Button,
  Card,
  FormControl,
  Legend,
  Main,
  withOverlay,
} from "@dpdgroupuk/mydpd-ui";

import ReviewCard from "~/components/ReviewCard";
import Title from "~/components/Title";
import { CONTAINER_FORM, ContainerFields } from "~/constants/forms";
import * as S from "~/constants/strings";
import { Normalizers } from "~/models";
import {
  clearContainerForm,
  fetchLabels,
} from "~/pages/Container/redux/actions";
import { getContainerFormValues } from "~/pages/Container/redux/selectors";
import { validate } from "~/pages/Container/redux/validators";
import { AuthSelectors, UmsSelectors } from "~/redux";

import styles from "./Container.module.scss";

const Container = props => (
  <>
    <Main.Body>
      <Legend
        leftMessage={S.PLEASE_COMPLETE_ALL_REQUIRED_FIELDS}
        rightMessage={get(props, "authUser.username")}
        classes={{ container: "p-0" }}
      />
      <Card.Stack>
        <Col className="p-0">
          <Card>
            <Card.Title className="mb-4">{S.CONTAINER_LABELS}</Card.Title>
            <Row>
              <Col sm={12}>
                <form onSubmit={props.handleSubmit(props.printLabels)}>
                  <div>
                    <Row>
                      <Col lg={4} md={4}>
                        <Row>
                          <Col>
                            <Field
                              component={FormControl.Input}
                              label={S.NUMBERS_OF_LABELS}
                              name={ContainerFields.NUMBER_OF_LABELS}
                              maxLength={2}
                              minLength={1}
                              helperText={S.MAX_2_CHARACTERS}
                              normalize={Normalizers.numbersNormalize}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Field
                              component={FormControl.Dropdown}
                              label={S.TYPE_OF_LABELS}
                              name={ContainerFields.LABEL_TYPE}
                              values={S.CONTAINER_LABEL_TYPES}
                              textTransform={S.UPPERCASE}
                              disabled={true}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col md={2}></Col>
                      <Col lg={6} md={6}>
                        <ReviewCard
                          title={<Title />}
                          rounded
                          classes={{
                            card: classNames(styles.card),
                          }}
                        >
                          <Row>
                            <Col md={12}>
                              <p
                                className={classNames([
                                  styles.text,
                                  styles.uppercase,
                                ])}
                              >
                                {S.PLACE_LABEL_ON_CONTAINER}
                              </p>
                            </Col>
                          </Row>
                        </ReviewCard>
                      </Col>
                    </Row>
                  </div>
                </form>
              </Col>
            </Row>
          </Card>
        </Col>
      </Card.Stack>
    </Main.Body>
    <Main.Footer className="dark">
      <Button.Toolbar>
        <Button
          variant="light"
          onClick={props.handleSubmit(props.printLabels)}
          disabled={!props.formIsValid}
        >
          {S.PRINT}
        </Button>
      </Button.Toolbar>
    </Main.Footer>
  </>
);

Container.propTypes = {
  formIsValid: PropTypes.bool,
  authUser: PropTypes.object,
  handleSubmit: PropTypes.func,
  printLabels: PropTypes.func,
  currentPrinterType: PropTypes.string,
};

export default compose(
  withRouter,
  reduxForm({
    form: CONTAINER_FORM,
    initialValues: {
      [ContainerFields.LABEL_TYPE]: S.CONTAINER_LABEL_TYPES[0].value,
      [ContainerFields.NUMBER_OF_LABELS]: 1,
    },
    validate,
  }),
  connect(state => ({
    authUser: AuthSelectors.getAuthUser(state),
    formData: getContainerFormValues(state),
    currentPrinterType: UmsSelectors.getPrinterType(state),
    formIsValid: isValid(CONTAINER_FORM)(state),
  })),
  withOverlay,
  withNotifier,
  withPrinter,
  withHandlers({
    printLabels: props =>
      props.notifier.runAsync(
        async () => {
          try {
            props.overlay.show();
            const { labels, labelType } = props.formData;
            const labelsString = await props.dispatch(
              fetchLabels({
                labels,
                printerType: props.currentPrinterType,
                labelType,
              })
            );
            props.dispatch(clearContainerForm);

            if (props.currentPrinterType === PRINTER_TYPE.LASER) {
              return props.printer.printLabels(labelsString);
            }

            await props.printer.printLabels(labelsString);
          } finally {
            props.overlay.hide();
          }
        },
        { entityName: S.LABELS }
      ),
  })
)(Container);
