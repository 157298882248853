import Joi from "joi";
import { TOOLS_FIELDS } from "~/constants/forms";
import { basePerShipment, profileCode } from "~/models/validators/commonSchema";
import * as S from "~/constants/strings";

export const bulkReverseSchema = () =>
  Joi.object({
    [TOOLS_FIELDS.PROFILE_CODE]: profileCode.required(),
    [TOOLS_FIELDS.SHIPMENTS_PER_LINE]: basePerShipment.label(
      S.SHIPMENTS_PER_LINE
    ),
    [TOOLS_FIELDS.WEIGHT_PER_SHIPMENT]: basePerShipment.label(
      S.WEIGHT_PER_SHIPMENT
    ),
    [TOOLS_FIELDS.GROUP]: Joi.string().required().label(S.GROUP),
    [TOOLS_FIELDS.RETURN_ADDRESS]: Joi.string()
      .required()
      .label(S.RETURN_ADDRESS),
  });
