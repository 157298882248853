import { useMemo } from "react";

import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { compose } from "recompose";

import { Header } from "@dpdgroupuk/mydpd-app";
import { withTrack, withTrackProps } from "@dpdgroupuk/react-event-tracker";

import { COLLECTIONS_MENU, MENU } from "~/constants/analytics";
import {
  CONTAINER,
  CREATE_SHIPMENT,
  FAILED_IMPORTS,
  IMPORT_ACTIVITY,
  IMPORTS,
  SCANNING,
  SHIPMENT_REVIEW,
  TOOLS,
} from "~/constants/strings";
import { UmsSelectors } from "~/redux";
import * as routes from "~/router/constants";
import { isRestrictedTab } from "~/router/model";

const links = [
  {
    href: routes.CREATE,
    name: CREATE_SHIPMENT,
  },

  {
    href: routes.REVIEW,
    name: SHIPMENT_REVIEW,
  },
  {
    href: routes.CONTAINER,
    name: CONTAINER,
  },
  {
    href: routes.IMPORTS,
    name: IMPORTS,
  },
  {
    href: routes.IMPORT_ACTIVITY,
    name: IMPORT_ACTIVITY,
  },
  {
    href: routes.SCANNING,
    name: SCANNING,
  },
  {
    href: routes.FAILED_IMPORTS,
    name: FAILED_IMPORTS,
  },
  {
    href: routes.TOOLS,
    name: TOOLS,
  },
];

export const AppHeader = ({
  onPrimaryMenuSelect,
  onSecondaryMenuSelect,
  onThirdMenuSelect,
  thirdMenuItems,
  ...headerProps
}) => {
  const securitySettings = useSelector(UmsSelectors.getSecuritySettings); // TODO
  const shippingSettings = useSelector(UmsSelectors.getShippingSettings);
  const customerServices = useSelector(UmsSelectors.getCustomerServices);
  const secondaryMenuItems = useMemo(
    () =>
      links.filter(
        link =>
          !isRestrictedTab(
            { securitySettings, shippingSettings, customerServices },
            link.href
          )
      ),
    [securitySettings, shippingSettings]
  );

  const availableThirdMenuItems = useMemo(
    () =>
      thirdMenuItems.filter(
        link => !isRestrictedTab({ shippingSettings }, link.href)
      ),
    [shippingSettings, thirdMenuItems]
  );

  return (
    <Header
      secondaryMenuItems={secondaryMenuItems}
      thirdMenuItems={availableThirdMenuItems}
      onPrimaryMenuSelect={onPrimaryMenuSelect}
      onSelectSecondaryMenu={onSecondaryMenuSelect}
      onThirdMenuSelect={onThirdMenuSelect}
      {...headerProps}
    />
  );
};

AppHeader.defaultProps = {
  onThirdMenuSelect: () => null,
};

AppHeader.propTypes = {
  onPrimaryMenuSelect: PropTypes.func,
  onSecondaryMenuSelect: PropTypes.func,
  onThirdMenuSelect: PropTypes.func,
};

const PRIMARY_INDEX_TO_ACTION_ID = {
  0: MENU.CLICK_MYDPD,
  1: MENU.CLICK_SHIPPING,
  2: MENU.CLICK_COLLECTIONS,
  3: MENU.CLICK_DELIVERIES,
  4: MENU.CLICK_SHOP_RETURNS,
  5: MENU.CLICK_ADDRESS_BOOK,
  6: MENU.CLICK_DEPOT_FINDER,
};

const SECONDARY_INDEX_TO_ACTION_ID = {
  0: COLLECTIONS_MENU.CLICK_CREATE_COLLECTION,
  1: COLLECTIONS_MENU.CLICK_SEARCH,
  2: COLLECTIONS_MENU.CLICK_DASHBOARD,
  3: COLLECTIONS_MENU.CLICK_WATCH_LIST,
};

export default compose(
  withTrack(MENU.LOAD),
  withTrackProps({
    onPrimaryMenuSelect: index => PRIMARY_INDEX_TO_ACTION_ID[index],
    onSecondaryMenuSelect: index => SECONDARY_INDEX_TO_ACTION_ID[index],
    onClickDpdLogo: MENU.CLICK_DPD_LOGO,
    onClickHelp: MENU.CLICK_HELP,
    onClickLogOff: MENU.CLICK_LOG_OFF,
  })
)(AppHeader);
