import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";

import { roundToDecimal } from "~/utils/number";

export default conditions => (state, action) => {
  switch (action.type) {
    case "@@redux-form/BLUR": {
      if (conditions.find(fn => fn(action.meta.field))) {
        return {
          ...state,
          values: set(
            cloneDeep(state.values),
            action.meta.field,
            roundToDecimal(action.payload)
          ),
        };
      }
      return state;
    }
    default:
      return state;
  }
};
