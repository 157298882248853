import { createSelector } from "reselect";
import { getValue } from "~/utils/object";

const memoizedEmptyArray = [];

export const getData = state => state.app.addressBook;

export const getAddressBooks = createSelector(getData, ({ list }) => list);
export const getAddressBookGroups = createSelector(
  getData,
  ({ addressbookGroups }) => addressbookGroups
);
export const getGroupAddresses = createSelector(getData, ({ groupAddresses }) =>
  getValue(groupAddresses, "results", memoizedEmptyArray)
);
