import api from "./api";
import { SHIPPING_MANIFESTS, SHIPPING_SHIPMENTS } from "./basePaths";

export const getManifests = query =>
  api.get({
    path: SHIPPING_MANIFESTS,
    query,
  });

export const createManifest = async query => {
  const { data } = await api.post({
    path: SHIPPING_MANIFESTS,
    query,
  });

  return data;
};

export const getManifestById = async manifestId => {
  const { data } = await api.get({
    path: `${SHIPPING_MANIFESTS}/${manifestId}`,
  });

  return data;
};

export const generateManifestLabelById = async manifestId => {
  const { data } = await api.get({
    path: `${SHIPPING_MANIFESTS}/${manifestId}/label`,
  });
  return data;
};

export const getGeneratedManifest = async (query, options) => {
  const { data } = await api.get({
    path: `${SHIPPING_SHIPMENTS}/export/manifest`,
    query,
    options,
  });

  return data;
};
