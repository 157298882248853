import api from "~/apis/api";
import { SHIPPING_LABELS } from "~/apis/basePaths";

export const getLabelsByShipmentIds = query =>
  api.get({
    path: `${SHIPPING_LABELS}/`,
    query,
  });

export const getUnprintedLabels = query =>
  api.get({
    path: `${SHIPPING_LABELS}/unprinted`,
    query,
  });
export const getGroupStartLabel = (query, options) =>
  api.get({
    path: `${SHIPPING_LABELS}/groupDispatchStart`,
    query,
    options,
  });
export const getGroupEndLabel = (query, options) =>
  api.get({
    path: `${SHIPPING_LABELS}/groupDispatchEnd`,
    query,
    options,
  });
export const getBulkStartLabel = (query, options) =>
  api.get({
    path: `${SHIPPING_LABELS}/bulkReverseStart`,
    query,
    options,
  });
export const getBulkEndLabel = (query, options) =>
  api.get({
    path: `${SHIPPING_LABELS}/bulkReverseEnd`,
    query,
    options,
  });
