import { createContext, useContext } from "react";

export const Context = createContext({});
export const useImporter = () => useContext(Context);
export const useBatchImport = () => useImporter().batchImport;
export const useWorkstation = () => useImporter().workstation;
export const useImportLogger = () => useImporter().logger;

// eslint-disable-next-line react/display-name
const createHoc = (Context, propName) => Component => props => (
  <Context.Consumer>
    {context => <Component {...props} {...{ [propName]: context[propName] }} />}
  </Context.Consumer>
);

// eslint-disable-next-line react/display-name
export const withImporter = Component => props => (
  <Context.Consumer>
    {context => <Component {...props} importer={context} />}
  </Context.Consumer>
);

export const withBatchImport = createHoc(Context, "batchImport");
export const withImportLogger = createHoc(Context, "logger");
export const withWorkstation = createHoc(Context, "workstation");
