import { isEmpty, isNil } from "lodash";
import { connect } from "react-redux";
import { matchPath, Route, withRouter } from "react-router";
import { compose, lifecycle } from "recompose";

import { withOverlay } from "@dpdgroupuk/mydpd-ui";

import { ShipmentActions } from "~/pages/Shipment/redux";

import { ShipmentReviewSelectors } from "../../redux";

export default compose(
  withRouter,
  withOverlay,
  connect(
    state => ({
      shipment: ShipmentReviewSelectors.getSelectedShipment(state),
      isShipmentLoading:
        ShipmentReviewSelectors.getIsSelectedShipmentLoading(state),
    }),
    (dispatch, { history, overlay, redirectTo }) => {
      const fetchShipmentById = shipmentId =>
        dispatch(ShipmentActions.getShipmentById(shipmentId));

      return {
        loadFn: async shipmentId => {
          try {
            overlay.show();

            await fetchShipmentById(shipmentId);
          } catch (e) {
            history.replace({
              pathname: redirectTo,
            });
          } finally {
            overlay.hide();
          }
        },
      };
    }
  ),
  lifecycle({
    componentDidMount() {
      const { history, path, loadFn } = this.props;

      const match = matchPath(history.location.pathname, {
        path,
        exact: true,
        strict: false,
      });

      loadFn(match?.params?.shipmentId);
    },
    componentDidUpdate(prevProps) {
      const { shipment, isShipmentLoading, history, redirectTo } = this.props;

      if (
        (isEmpty(shipment) || isNil(shipment)) &&
        prevProps.isShipmentLoading &&
        !isShipmentLoading
      ) {
        history.replace({
          pathname: redirectTo,
        });
      }
    },
  })
)(({ component: Component, shipment, ...rest }) => (
  <Route
    {...rest}
    render={props => <Component shipment={shipment} {...props} />}
  />
));
