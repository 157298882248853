import { TEMPLATE_TYPE } from "@dpdgroupuk/mydpd-enums";

import { ActionTypes } from "~/redux/template/actions";

export const initialState = {
  templates: [],
  template: [],
  delimiters: [],
  addressbookDelimiters: [],
  shipmentImportTemplates: [],
  templatesByType: {},
  testImportFileResult: [],
  productBookDelimiters: [],
};

const createTemplateByTypeReducer =
  (type, setter = action => action.payload) =>
  (state, action) => ({
    ...state,
    templatesByType: {
      ...state.templatesByType,
      [type]: setter(action, state.templatesByType[type]),
    },
  });

const deliveryAddressBookTemplateReducer = createTemplateByTypeReducer(
  TEMPLATE_TYPE.DELIVERY_ADDRESS_BOOK
);
const returnAddressBookTemplateReducer = createTemplateByTypeReducer(
  TEMPLATE_TYPE.RETURN_ADDRESS_BOOK
);
const shipmentTemplateReducer = createTemplateByTypeReducer(
  TEMPLATE_TYPE.SHIPMENT,
  (action, prevState) => ({
    ...prevState,
    [action.payload.templateId]: action.payload,
  })
);
const shipmentReceiptTemplateReducer = createTemplateByTypeReducer(
  TEMPLATE_TYPE.SHIPMENT_RECEIPT
);
const shipmentExportTemplateReducer = createTemplateByTypeReducer(
  TEMPLATE_TYPE.SHIPMENT_EXPORT
);
const dpdDirectTemplateReducer = createTemplateByTypeReducer(
  TEMPLATE_TYPE.DPD_DIRECT_PRODUCT
);
const productTemplateReducer = createTemplateByTypeReducer(
  TEMPLATE_TYPE.PRODUCT
);

const productBookTemplateReducer = createTemplateByTypeReducer(
  TEMPLATE_TYPE.PRODUCT_BOOK
);

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_TEMPLATE_TYPES.SUCCESS: {
      return {
        ...state,
        templates: action.payload.data,
      };
    }
    case ActionTypes.FETCH_TEMPLATE.SUCCESS:
      return {
        ...state,
        template: action.payload,
      };
    case ActionTypes.GET_DPD_DIRECT_TEMPLATE.SUCCESS:
      return dpdDirectTemplateReducer(state, action);
    case ActionTypes.GET_TEMPLATE_PRODUCT_DETAILS.SUCCESS:
      return productTemplateReducer(state, action);
    case ActionTypes.GET_TEMPLATE_IMPORT_BY_ID.SUCCESS:
      return shipmentTemplateReducer(
        { ...state, template: action.payload },
        action
      );
    case ActionTypes.GET_TEMPLATE_RECEIPT_TEMPLATE.SUCCESS:
      return shipmentReceiptTemplateReducer(state, action);
    case ActionTypes.GET_TEMPLATE_SHIPMENT_EXPORT.SUCCESS:
      return shipmentExportTemplateReducer(state, action);
    case ActionTypes.GET_TEMPLATE_DELIVERY_ADDRESS_BOOK.SUCCESS:
      return deliveryAddressBookTemplateReducer(state, action);
    case ActionTypes.GET_RETURN_ADDRESS_BOOK_TEMPLATE.SUCCESS:
      return returnAddressBookTemplateReducer(state, action);
    case ActionTypes.GET_TEMPLATE_PRODUCTBOOK.SUCCESS:
      return productBookTemplateReducer(state, action);

    case ActionTypes.GET_TEMPLATE_IMPORT_BY_ID.FAILURE:
    case ActionTypes.FETCH_TEMPLATE.FAILURE: {
      return {
        ...state,
        template: initialState.template,
      };
    }
    case ActionTypes.GET_DELIMITERS.SUCCESS: {
      return {
        ...state,
        delimiters: action.payload.data,
      };
    }
    case ActionTypes.GET_ADDRESSBOOK_DELIMITERS.SUCCESS: {
      return {
        ...state,
        addressbookDelimiters: action.payload.data,
      };
    }
    case ActionTypes.GET_PRODUCTBOOK_DELIMITERS.SUCCESS: {
      return {
        ...state,
        productBookDelimiters: action.payload.data,
      };
    }
    case ActionTypes.GET_SHIPMENT_IMPORT_TEMPLATES.SUCCESS: {
      return {
        ...state,
        shipmentImportTemplates: action.payload.data,
      };
    }
    case ActionTypes.UPDATE_TEMPLATE: {
      return {
        ...state,
        template: action.payload,
      };
    }
    case ActionTypes.TEST_IMPORT_FILE.SUCCESS: {
      return {
        ...state,
        testImportFileResult: action.payload,
      };
    }
    default:
      return state;
  }
};
