import get from "lodash/get";

import { SHIPMENT_TYPES } from "@dpdgroupuk/mydpd-enums";

import * as S from "~/constants/strings";
import { toUppercaseValues } from "~/utils/object";

export const getScanShipmentInitialValues = (
  profile = {},
  countries,
  preferences
) => {
  const countryCode = get(preferences, "shippingDefaults.intCountry") || S.GB;
  const { profileCode } = profile;

  return toUppercaseValues({
    shipmentType: SHIPMENT_TYPES.NO_TYPE.toString(),
    generateCustomsData: null,
    profileCode,
    outboundConsignment: {
      deliveryDetails: {
        address: {
          ...(countries.length !== 0 ? { countryCode } : ""),
        },
      },
    },
  });
};
