import Joi from "joi";
import { TOOLS_FIELDS } from "~/constants/forms";
import {
  basePerShipment,
  deliveryInstructions,
  profileCode,
  shippingRef2,
  shippingRef3,
} from "~/models/validators/commonSchema";
import * as S from "~/constants/strings";
import { joiExtension } from "@dpdgroupuk/mydpd-express-helpers/utils";

export const groupDispatchSchema = (
  values,
  { user: { serviceExclusions }, shippingSettings }
) =>
  Joi.object({
    [TOOLS_FIELDS.PROFILE_CODE]: profileCode.required(),
    [TOOLS_FIELDS.DELIVERY_INSTRUCTION]: deliveryInstructions,
    [TOOLS_FIELDS.REF_2]: shippingSettings.sendersRef2
      ? shippingRef2.required()
      : shippingRef2,
    [TOOLS_FIELDS.REF_3]: shippingSettings.sendersRef3
      ? shippingRef3.required()
      : shippingRef3,
    [TOOLS_FIELDS.SHIPMENTS_PER_LINE]: basePerShipment.label(
      S.SHIPMENTS_PER_LINE
    ),
    [TOOLS_FIELDS.WEIGHT_PER_SHIPMENT]: basePerShipment.label(
      S.WEIGHT_PER_SHIPMENT
    ),
    [TOOLS_FIELDS.SHIPMENT_DATE]: Joi.string()
      .required()
      .label(S.SHIPMENT_DATE),
    [TOOLS_FIELDS.REQUIRED_SERVICE]: Joi.string()
      .required()
      .invalid(serviceExclusions)
      .label(S.SERVICE)
      .error(
        joiExtension.messages({
          "any.invalid": S.SERVICE_IS_RESTRICTED,
        })
      ),
    [TOOLS_FIELDS.GROUP]: Joi.string().required().label(S.GROUP),
  });
