import { compose, lifecycle, withHandlers, withState } from "recompose";

import { withLocalServiceState } from "@dpdgroupuk/mydpd-app";
import { withLocalServiceManager } from "@dpdgroupuk/mydpd-app/lib/features/Local/Context";
import { BANNERS_TYPES } from "@dpdgroupuk/mydpd-enums";
import { Banner } from "@dpdgroupuk/mydpd-ui";

import * as S from "~/constants/strings";

export default compose(
  Banner.withBanner,
  withState("localServiceStateBannerId", "setLocalServiceStateBannerId"),
  withLocalServiceState,
  withLocalServiceManager,
  withHandlers({
    showLocalServiceStateBanner:
      ({
        banner,
        localManager,
        setLocalServiceStateBannerId,
        localServiceStateBannerId,
      }) =>
      () => {
        if (!localServiceStateBannerId) {
          const bannerId = banner.showByType(BANNERS_TYPES.ALERT, {
            message: S.FAILED_TO_CONNECT_WITH_APP,
            closable: true,
            showIcon: true,
            actions: [
              {
                text: S.REFRESH_STATUS,
                handleClick: () => {
                  localManager.checkStatus();
                },
              },
            ],
          });
          setLocalServiceStateBannerId(bannerId);
        }
      },
    hideLocalServiceStateBanner:
      ({ localServiceStateBannerId, banner }) =>
      () => {
        localServiceStateBannerId && banner.hideById(localServiceStateBannerId);
      },
  }),
  lifecycle({
    componentDidMount() {
      const {
        localServiceState,
        showLocalServiceStateBanner,
        localManager,
        hideLocalServiceStateBanner,
      } = this.props;

      if (!localServiceState.isRunning) {
        showLocalServiceStateBanner();
      }

      this.removeStatusListener = localManager.addListener(
        "status",
        ({ detail }) => {
          detail.isRunning
            ? hideLocalServiceStateBanner()
            : showLocalServiceStateBanner();
        }
      );
    },
    componentWillUnmount() {
      this.removeStatusListener && this.removeStatusListener();
      this.props.hideLocalServiceStateBanner();
    },
  })
);
