import values from "lodash/values";
import zipObjectDeep from "lodash/zipObjectDeep";
import { connect } from "react-redux";
import { compose, withHandlers } from "recompose";

import { OUTBOUND_CONSIGNMENT, ShipmentEntity } from "~/constants/forms";
import { AddressModels } from "~/models";
import { toUppercaseValues } from "~/utils/object";
import { touchFields } from "~/utils/reduxForm";
import { DELIVERY } from "~/constants/strings";

export default formSection =>
  compose(
    connect(null, (dispatch, { reInitializeForm }) => ({
      updateForm: values => reInitializeForm(values),
    })),
    withHandlers({
      onPostcodeSelectionChange:
        ({
          createShipmentValues,
          updateForm,
          dispatch,
          pageConfig,
          updateImporterDetails,
          checkPostcodeIssue,
          prevPostcodeIssue,
          setPrevPostcodeIssue,
        }) =>
        (address = {}, shipmentSection) => {
          // @see: comments in the https://geopost.jira.com/browse/CSHIP-5594
          shipmentSection &&
            updateForm(
              zipObjectDeep([
                ...values(
                  ShipmentEntity[shipmentSection].DELIVERY_DETAILS
                    .CONTACT_DETAILS
                ),
                ...values(
                  ShipmentEntity[shipmentSection].DELIVERY_DETAILS
                    .NOTIFICATION_DETAILS
                ),
              ])
            );
          const result = {
            ...createShipmentValues,
            ...AddressModels.updateDeliveryDetails(formSection, address),
          };

          updateForm(toUppercaseValues(result));
          dispatch(touchFields(pageConfig.formName, result));

          if (
            formSection === OUTBOUND_CONSIGNMENT &&
            createShipmentValues[ShipmentEntity.GENERATE_CUSTOMS_DATA]
          ) {
            updateImporterDetails();
          }

          if (prevPostcodeIssue !== address.postcode) {
            checkPostcodeIssue(
              address.postcode,
              DELIVERY,
              issue => issue?.postcodeIssue?.[0]?.postcodeDelay !== 0
            );
            setPrevPostcodeIssue(address.postcode);
          }
        },
    })
  );
