import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { connect } from "react-redux";
import {
  compose,
  lifecycle,
  withHandlers,
  withProps,
  withState,
} from "recompose";
import { change, reduxForm, resetSection } from "redux-form";

import { withAppUserPreferences, withNotifier } from "@dpdgroupuk/mydpd-app";
import { REQUIRED_TYPE, SHIPMENT_TYPES } from "@dpdgroupuk/mydpd-enums";
import { Banner, withOverlay, withPrompt } from "@dpdgroupuk/mydpd-ui";

import {
  DELIVERY_DETAILS_SEARCH_FORM,
  INBOUND_CONSIGNMENT,
  INVOICE,
  OUTBOUND_CONSIGNMENT,
  ShipmentEntity,
} from "~/constants/forms";
import { SHOW_ALERT_DISPLAY_TIME } from "~/constants/snackbar";
import * as S from "~/constants/strings";
import withOnBlur from "~/hocs/withOnBlur";
import withPostcodeIssue from "~/hocs/withPostcodeIssue";
import withPrint from "~/hocs/withPrint";
import withServices from "~/hocs/withServices";
import { ServiceModels, ShipmentModels } from "~/models";
import { getServicesForUser } from "~/models/service/service";
import postcodeValidation from "~/models/validators/additionalPostcodeValidation";
import {
  countryValidation,
  invoiceImporterCountryValidation,
  outboundTotalWeightValidation,
  parcelCountryValidation,
  returnTotalWeightValidation,
  submissionErrorFirst,
  validateShipmentDate,
} from "~/models/validators/additionalValidations";
import { shipmentSchema } from "~/models/validators/shipmentSchema";
import { ShipmentActions, ShipmentSelectors } from "~/pages/Shipment/redux";
import { getPackageContentOrder } from "~/pages/Shipment/view";
import { ReferenceActions } from "~/redux";
import createValidator from "~/utils/joiReduxForm";
import { getDeepKeys, getValue } from "~/utils/object";
import { initializeForm } from "~/utils/reduxForm";
import { formatMessage } from "~/utils/string";

import withAddressBookAutocomplete from "./withAddressBookAutocomplete";
import withClearData from "./withClearData";
import withConnect from "./withConnect";
import withCreateShipmentAnalytics from "./withCreateShipmentAnalytics";
import withProfileChange from "./withProfileChange";
import withShipmentDate from "./withShipmentDate";
import withPostcodeAutocomplete from "./withShipmentPostcodeAutocomplete";

export default compose(
  connect((state, props) => ({
    form: props.pageConfig.formName,
    uiFields: ShipmentSelectors.getRegisteredFields(state, props.pageConfig),
    isEditProductFormActive: ShipmentSelectors.isEditProductFormActive(state),
  })),
  withAppUserPreferences,
  withNotifier,
  Banner.withBanner,
  withPrompt,
  withPrint,
  withOverlay,
  withOnBlur,
  withConnect,
  withProfileChange,
  withProps(({ selectedService }) => ({
    onClickPackageDetailsHelp: () => {}, // TODO: Add click logic
    onClickSaveToAddressBook: () => {}, // TODO: Add click logic
    selectedServiceCode: ServiceModels.getServiceCode(selectedService),
    selectedNetworkCode: ServiceModels.getNetworkCode(selectedService),
  })),
  withState("prevPostcodeIssue", "setPrevPostcodeIssue"),
  withState("inboundServiceErrorBannerId", "setInboundServiceErrorBannerId"),
  withState("outboundServiceErrorBannerId", "setOutboundServiceErrorBannerId"),
  withState("consignmentBannerId", "setConsignmentBannerId"),
  withServices,
  reduxForm({
    shouldError: () => true,
    onChange: (currentValues, dispatch, props, prevValues) => {
      /**
         save actual prevValues
         **/
      props.setPrevValues(prevValues);

      /**
         clear services queries when form was dropped to initial state
         **/

      if (props.pristine) {
        props.clearServicesQuery();
      }

      /**
         check if field was blurred
         **/
      if (
        !currentValues.activeField &&
        currentValues.activeField !== prevValues.activeField
      ) {
        /**
           - get actual prevValues
           - on blur callback, fires fetching inbound/outbound services
           **/
        const prev = props.getPrevValues();

        props.onBlurServiceRelatedField(currentValues, prev, props);
      }
    },
    validate: (values, props) =>
      createValidator(shipmentSchema(props), [
        ...(props.shipmentAdditionalValidation
          ? props.shipmentAdditionalValidation(props)
          : []),
        () =>
          postcodeValidation(
            props,
            props.selectedCountry,
            ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
              .POSTCODE
          ),
        () =>
          postcodeValidation(
            props,
            props.selectedReturnCountry,
            ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE
          ),
        () => {
          const country = props.countryList.find(
            ({ countryKey }) =>
              countryKey ===
              getValue(
                props.createShipmentValues,
                ShipmentEntity.INVOICE.EXPORTER_DETAILS.ADDRESS.COUNTRY_CODE,
                ""
              )
          );
          return postcodeValidation(
            props,
            country,
            ShipmentEntity.INVOICE.EXPORTER_DETAILS.ADDRESS.POSTCODE
          );
        },
        () => {
          const country = props.countryList.find(
            ({ countryKey }) =>
              countryKey ===
              getValue(
                props.createShipmentValues,
                ShipmentEntity.INVOICE.IMPORTER_DETAILS.ADDRESS.COUNTRY_CODE,
                ""
              )
          );
          return postcodeValidation(
            props,
            country,
            ShipmentEntity.INVOICE.IMPORTER_DETAILS.ADDRESS.POSTCODE
          );
        },
        () =>
          countryValidation(
            props,
            ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
              .COUNTRY_CODE
          ),
        () =>
          countryValidation(
            props,
            ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
              .COUNTRY_CODE
          ),
        () =>
          invoiceImporterCountryValidation(
            props,
            ShipmentEntity.INVOICE.IMPORTER_DETAILS.ADDRESS.COUNTRY_CODE
          ),
        () => parcelCountryValidation(props),
        () => validateShipmentDate(props, ShipmentEntity.SHIPMENT_DATE),
        joiValidationErrors =>
          returnTotalWeightValidation(props, joiValidationErrors),
        joiValidationErrors =>
          outboundTotalWeightValidation(props, joiValidationErrors),
        joiValidationErrors => submissionErrorFirst(props, joiValidationErrors),
      ])(values, props),
    onSubmitFail: (errors, dispatch, _, props) => {
      const mappedErrors = getDeepKeys(errors);
      props?.notifier.scrollToError(mappedErrors);

      if (props.isEditProductFormActive) {
        props.snackbar.showWarning({
          message: formatMessage(
            S.PLEASE_SAVE_$_BEFORE_PRINTING,
            `${getPackageContentOrder(props)}. ${S.CUSTOMS}`
          ),
        });
      }
    },
  }),
  withPostcodeIssue,
  withPostcodeAutocomplete(OUTBOUND_CONSIGNMENT),
  withAddressBookAutocomplete({
    formSection: OUTBOUND_CONSIGNMENT,
    searchFormName: DELIVERY_DETAILS_SEARCH_FORM,
    addressBookType: S.ADDRESS_BOOK_TYPES.DELIVERY,
  }),
  withShipmentDate,
  withClearData,
  withCreateShipmentAnalytics, // TODO: REFACTORING
  withHandlers({
    fetchCountriesProfilesCurrenciesExportReasonsUniqueSenderRef:
      ({
        fetchCountries,
        fetchProfiles,
        fetchCurrencies,
        fetchExportReasons,
        generateUniqueSenderRef1,
      }) =>
      async ({
        isAvailableInvoice,
        shouldGenerateUniqueSenderRef1,
        shipmentCountryCode,
        shipmentProfileCode,
      } = {}) => {
        let uniqueSenderRef1;
        const [
          { data: countries },
          { data: profiles },
          { data: currencies },
          { data: exportReasons },
        ] = await Promise.all([
          fetchCountries(),
          fetchProfiles(),
          fetchCurrencies(),
          isAvailableInvoice && fetchExportReasons(),
        ]).then(results =>
          results.map(result => ({
            data: result?.data || [],
          }))
        );

        // NOTE: should generate not for all pages
        if (shouldGenerateUniqueSenderRef1) {
          uniqueSenderRef1 = await generateUniqueSenderRef1();
        }

        const deliveryCountry =
          countries.find(
            ({ countryKey }) => countryKey === shipmentCountryCode
          ) || {};
        const activeProfile =
          profiles.find(
            ({ profileCode }) => profileCode === shipmentProfileCode
          ) || profiles[0];

        return {
          countries,
          profiles,
          currencies,
          exportReasons,
          uniqueSenderRef1,
          deliveryCountry,
          activeProfile,
        };
      },
    fetchAndProcessServices:
      ({
        fetchOutboundServices,
        outboundMapErrors,
        snackbar,
        fetchInboundServices,
        setOutboundQuery,
        setInboundQuery,
        inboundMapErrors,
        customerPrefs,
        authUser,
        preferences,
        createShipmentValues,
        userCustomerAddress,
        customer,
        countries,
      }) =>
      async ({
        shipment,
        profile,
        selectedCountry,
        setupDefaultService = true,
      }) => {
        const isReturnShipmentType =
          ShipmentModels.isSwapItOrReverseItShipmentType(shipment.shipmentType);

        let outboundErrors = {};
        let inboundErrors = {};
        let outboundNetworks = [];
        let inboundNetworks = [];
        let selectedOutboundNetwork;

        let isReturnShipmentTypeProhibited =
          !isReturnShipmentType ||
          ShipmentModels.isReturnShipmentTypeProhibited(
            customerPrefs,
            shipment.shipmentType
          );
        let inboundQuery = {};
        const outboundQuery = ShipmentModels.getNetworkQueryFromShipment(
          {
            ...shipment,
            shipmentType: isReturnShipmentTypeProhibited
              ? SHIPMENT_TYPES.NO_TYPE
              : shipment.shipmentType,
          },
          profile.profileCode,
          OUTBOUND_CONSIGNMENT,
          // NOTE: redefine collectionDetails because for loading Copy Shipment profile from props is incorrect
          ShipmentModels.getCollectionDetails(
            createShipmentValues,
            profile,
            userCustomerAddress,
            customer,
            countries
          )
        );

        try {
          const { data } = await fetchOutboundServices(outboundQuery);
          outboundNetworks = ServiceModels.getServicesForUser(
            authUser.user,
            profile,
            data
          );
          selectedOutboundNetwork =
            outboundNetworks?.find(
              service =>
                service.networkKey === shipment.outboundConsignment.networkCode
            ) ||
            (setupDefaultService
              ? ServiceModels.getDefaultOutboundService({
                  services: outboundNetworks,
                  authUser,
                  preferences,
                  profile,
                  createShipmentValues: shipment,
                  selectedCountry,
                })
              : {});

          if (
            !selectedOutboundNetwork?.collectOnDelivery &&
            isReturnShipmentType
          ) {
            isReturnShipmentTypeProhibited = true;
          }
        } catch (error) {
          isReturnShipmentTypeProhibited = true;
          outboundErrors = outboundMapErrors(error);
          const message =
            get(error, "errors[0].message") ||
            formatMessage(
              S.FAILED_TO_FETCH_ERROR_MESSAGE_$,
              S.OUTBOUND_SERVICES
            );
          snackbar.showAlert({
            message,
            displayTime: SHOW_ALERT_DISPLAY_TIME,
          });
        } finally {
          try {
            if (
              shipment.inboundConsignment &&
              !isReturnShipmentTypeProhibited
            ) {
              inboundQuery = ShipmentModels.getNetworkQueryFromShipment(
                shipment,
                profile.profileCode,
                INBOUND_CONSIGNMENT
              );
              const { data } = await fetchInboundServices(inboundQuery);
              inboundNetworks = getServicesForUser(
                authUser.user,
                profile,
                data
              );
            }
          } catch (error) {
            inboundErrors = inboundMapErrors(error);
            const message =
              get(error, "errors[0].message") ||
              formatMessage(
                S.FAILED_TO_FETCH_ERROR_MESSAGE_$,
                S.INBOUND_SERVICES
              );
            snackbar.showAlert({
              message,
              displayTime: SHOW_ALERT_DISPLAY_TIME,
            });
          } finally {
            setOutboundQuery(outboundQuery);
            shipment.inboundConsignment &&
              !isReturnShipmentTypeProhibited &&
              setInboundQuery(inboundQuery);
          }
        }

        return {
          outboundNetworks,
          inboundNetworks,
          selectedOutboundNetwork,
          outboundErrors,
          inboundErrors,
        };
      },
    runCheckPostcodeIssues:
      ({ checkPostcodeIssue, currentValues, collectionDetails }) =>
      async () => {
        const collectionPostcode = get(collectionDetails, "address.postcode");

        await checkPostcodeIssue(
          collectionPostcode,
          S.COLLECTION,
          issue =>
            !issue.allowCollection &&
            issue?.postcodeIssue?.[0]?.postcodeDelay !== 0
        );

        checkPostcodeIssue(
          get(
            currentValues,
            ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
              .POSTCODE
          ),
          S.DELIVERY,
          issue => issue?.postcodeIssue?.[0]?.postcodeDelay !== 0
        );
      },
    initializeShipment:
      ({ dispatch, pageConfig, touchShipmentForm, stopSubmitShipmentForm }) =>
      ({
        shipment,
        selectedOutboundNetwork,
        inboundNetworks,
        outboundErrors,
        inboundErrors,
        initialValues,
      }) => {
        dispatch(
          ReferenceActions.setActiveOutboundService(selectedOutboundNetwork)
        );

        if (!isEmpty(inboundNetworks)) {
          const inboundNetwork = inboundNetworks?.find(
            service =>
              service.networkKey ===
              initialValues.inboundConsignment.networkCode
          );
          dispatch(ReferenceActions.setActiveInboundService(inboundNetwork));
        }

        dispatch(initializeForm(pageConfig.formName, initialValues));

        // TODO: refactor it related to the new change service handler
        // update because onGenerateCustomsDataChange set unnecessary default values before
        if (selectedOutboundNetwork?.taxRequired === REQUIRED_TYPE.NEEDLESS) {
          dispatch(
            ShipmentActions.resetInvoiceAdditionalDataRequirements(pageConfig)
          );
        }
        if (!ServiceModels.isVisibleFdaNumber(selectedOutboundNetwork)) {
          dispatch(
            change(
              pageConfig.formName,
              ShipmentEntity.INVOICE.EXPORTER_DETAILS.FDA_NUMBER,
              undefined
            )
          );
        }

        // update because handleTaxIdAndGst set unnecessary default values before
        if (
          !ServiceModels.isVisibleDestinationTaxIdRegNo(
            selectedOutboundNetwork,
            initialValues.generateCustomsData
          )
        ) {
          dispatch(ShipmentActions.resetAdditionalDataRequirements(pageConfig));
        }

        // update because populateDefaultNetwork set unnecessary default values before
        if (isEmpty(initialValues.invoice)) {
          dispatch(resetSection(pageConfig.formName, INVOICE));
        }

        if (
          !shipment.isVoided &&
          (!isEmpty(outboundErrors) || !isEmpty(inboundErrors))
        ) {
          touchShipmentForm({
            ...outboundErrors,
            ...inboundErrors,
          });
          stopSubmitShipmentForm({
            ...outboundErrors,
            ...inboundErrors,
          });
        }
      },
  }),

  lifecycle({
    componentDidUpdate(prevProps) {
      const {
        dispatch,
        selectedService,
        customsFieldsFlags,
        parcelsTotalValue,
        populateDefaultCustomsValue,
        checkPostcodeIssue,
        createShipmentValues,
        activeField,
        pageConfig,
        allowedFields,
        setDefaultDeliveryDescription,
      } = this.props;
      const isVisibleTaxId =
        allowedFields[
          ShipmentEntity.OUTBOUND_CONSIGNMENT.DESTINATION_TAX_ID_REG_NO
        ];
      const prevIsVisibleTaxId =
        prevProps.allowedFields[
          ShipmentEntity.OUTBOUND_CONSIGNMENT.DESTINATION_TAX_ID_REG_NO
        ];

      if (
        customsFieldsFlags.customsValueDisabled &&
        parcelsTotalValue !== prevProps.parcelsTotalValue
      ) {
        populateDefaultCustomsValue(parcelsTotalValue);
      }

      // manage only manual changing generateCustomsData, dynamic changing is managed in the handleTaxIdAndGst
      if (
        selectedService &&
        prevProps.selectedService &&
        isVisibleTaxId !== prevIsVisibleTaxId
      ) {
        dispatch(
          ShipmentActions.setupAdditionalDataRequirements(
            isVisibleTaxId,
            pageConfig
          )
        );
      }

      if (
        prevProps.activeField ===
          ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
            .POSTCODE &&
        activeField !==
          ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE
      ) {
        const postcode = getValue(
          createShipmentValues,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE
        )?.toUpperCase();

        if (this.props.prevPostcodeIssue !== postcode) {
          checkPostcodeIssue(
            postcode,
            S.DELIVERY,
            issue => issue?.postcodeIssue?.[0]?.postcodeDelay !== 0
          );
          this.props.setPrevPostcodeIssue(postcode);
        }
      }

      if (!prevProps.allowedFields.customs && allowedFields.customs) {
        setDefaultDeliveryDescription();
      }

      if (
        !allowedFields.extendedLiability &&
        prevProps.allowedFields.extendedLiability
      ) {
        dispatch(
          change(
            pageConfig.formName,
            ShipmentEntity.OUTBOUND_CONSIGNMENT.LIABILITY,
            undefined
          )
        );
      }
    },
    componentWillUnmount() {
      this.props.prompt.onClose();
    },
  })
);
