import { useCallback } from "react";

import classNames from "classnames";
import { isEmpty } from "lodash/lang";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose, withHandlers } from "recompose";
import { Field, getFormValues, propTypes, reduxForm } from "redux-form";

import { withAppContext, withNotifier } from "@dpdgroupuk/mydpd-app";
import {
  Button,
  Card,
  FormControl,
  withOverlay,
  withSnackbar,
} from "@dpdgroupuk/mydpd-ui";

import { TEST_IMPORT_FILE_FORM, TestFileEntity } from "~/constants/forms";
import { SHOW_ALERT_DISPLAY_TIME } from "~/constants/snackbar";
import * as S from "~/constants/strings";
import useToggle from "~/hooks/useToggle";
import { TemplateActions, TemplateSelectors } from "~/redux";
import { getErrorMessage } from "~/utils/error";

import TestImportFileModal from "./TestImportFileModal";

const TestImportFile = ({
  shipmentTemplate,
  testImportFile,
  testImportFileResult,
  formValues,
  cardTitleClassName,
  fileExtension,
}) => {
  const testImportFileToggle = useToggle();

  const handleClick = useCallback(() => {
    testImportFile(testImportFileToggle.switchOn);
  }, [testImportFile, testImportFileToggle]);

  return (
    <Card className="h-100">
      <Card.Title
        className={classNames(
          "mb-4 d-flex justify-content-between",
          cardTitleClassName
        )}
      >
        {S.TEST_IMPORT_FILE}
      </Card.Title>
      <div className="d-flex flex-column">
        <Field
          component={FormControl.Input}
          label={S.TEST_FILE_LOCATION}
          name={TestFileEntity.FILE_PATH}
          type="file"
          accept={fileExtension}
          helperText={S.TEST_IMPORT_FILE_HELP_MESSAGE}
          onBlur={event => {
            event.preventDefault(); // used to avoid setting empty object to store when prompt window was opened
          }}
        />

        <Button
          variant="primary"
          className="align-self-end mt-1"
          onClick={handleClick}
          disabled={!formValues[TestFileEntity.FILE_PATH] || !shipmentTemplate}
        >
          {S.TEST}
        </Button>
      </div>
      <TestImportFileModal
        open={testImportFileToggle.value}
        onCancel={testImportFileToggle.switchOff}
        title={S.TEST_IMPORT_ERRORS}
        okButton={{ visible: true, text: S.OK }}
        size="md"
        testImportFileResult={testImportFileResult}
      />
    </Card>
  );
};

TestImportFile.propTypes = {
  ...propTypes,
  cardTitleClassName: PropTypes.string,
  fileExtension: PropTypes.string,
  testImportFile: PropTypes.func,
  testImportFileResult: PropTypes.array,
};
TestImportFile.defaultProps = { fileExtension: ".csv" };

export default compose(
  withNotifier,
  withSnackbar,
  withAppContext,
  withOverlay,
  connect(state => ({
    formValues: getFormValues(TEST_IMPORT_FILE_FORM)(state) || {},
    testImportFileResult: TemplateSelectors.getTestImportFileResult(state),
  })),
  reduxForm({
    form: TEST_IMPORT_FILE_FORM,
    enableReinitialize: true,
  }),
  withHandlers({
    testImportFile: ({
      shipmentTemplate,
      formValues,
      overlay,
      dispatch,
      snackbar,
    }) =>
      overlay.showWhile(
        async switchTestImportFileToggleOn => {
          const file = formValues.filePath[0];

          // If file has been changed and you will try to upload - Chrome will throw an error "ERR_UPLOAD_FILE_CHANGED"
          try {
            await file
              .slice(0, 1) // only the first byte
              .arrayBuffer();
          } catch (error) {
            return snackbar.showAlert({
              message: S.UPLOAD_FILE_CHANGED,
              displayTime: SHOW_ALERT_DISPLAY_TIME,
            });
          }

          const formData = new FormData();
          formData.append("fileImport", file);

          try {
            const testFileResults = await dispatch(
              TemplateActions.testImportFile(
                formData,
                shipmentTemplate.templateId
              )
            );

            !isEmpty(testFileResults) && switchTestImportFileToggleOn();

            snackbar.showSuccess({
              message: isEmpty(testFileResults)
                ? S.TEST_IMPORT_FILE_SUCCESS_NO_ERRORS_FOUND
                : S.TEST_IMPORT_FILE_SUCCESS,
            });
          } catch (e) {
            snackbar.showAlert({
              message: getErrorMessage(e, S.TEST_IMPORT_FILE),
              displayTime: SHOW_ALERT_DISPLAY_TIME,
            });
          }
        },
        { entityName: S.TEST_IMPORT_FILE }
      ),
  })
)(TestImportFile);
